import type { PingPongMessage } from "../types/Responses";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";

import { isSocketMessage } from "./isSocketMessage";

export const isPingPongMessage = (data: unknown): data is PingPongMessage =>
  isSocketMessage(data) &&
  data.responseType === "pong" &&
  hasPropertyOfType(data, "payload", "string");

import type { AppDispatch } from "@talktype/store";
import type { UpdateErrorToast as UpdateErrorToastType } from "@talktype/types";
import type { UpdateErrorToastProps } from "@talktype/ui/src/UpdateErrorToast";
import type { UpdateStepFailed } from "@talktype/updater";

import { connect } from "react-redux";

import { UpdateErrorToast as Component } from "@talktype/ui/src/UpdateErrorToast";
import { requestRetryUpdateStep } from "@talktype/updater";

type DispatchProps = {
  retryUpdate: (details: UpdateStepFailed) => void;
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  retryUpdate: (details: UpdateStepFailed) =>
    dispatch(requestRetryUpdateStep(details)),
});

const mergeProps = (
  _: unknown,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: UpdateErrorToastType
): UpdateErrorToastProps => ({
  ...ownProps,
  onClick: () => dispatchProps.retryUpdate(ownProps.details),
});

export const UpdateErrorToast = connect(
  null,
  mapDispatchToProps,
  mergeProps
)(Component);

import type {
  Chunk,
  Replacement,
  TranscriptTransformationSettings,
} from "@talktype/types";

import { lastDef } from "@carescribe/utilities/src/fp";

import { split } from "../../utils/split";

/**
 * Applies the specified replacements.
 * Reports back the replacements that were applied.
 *
 * chunks - The chunks to apply replacements to.
 *
 * shortcuts - The custom shortcuts to inform replacement behaviour.
 *
 * Matching is case-insensitive.
 *
 * @example
 * applyReplacements(
 *   [{ type: 'text', text: "Hello new line world! new paragraph" }],
 *   [
 *     {
 *       triggers: ["new line"],
 *       type: 'custom-shortcuts',
 *       segments: [{ type: "linebreak" }],
 *       inBuilt: true
 *     },
 *     {
 *       triggers: ["new paragraph"],
 *       type: 'custom-shortcuts',
 *       segments: [{ type: "paragraph" }],
 *       inBuilt: true
 *     },
 *   ],
 * );
 *
 * // Returns:
 * {
 *  replaced: [
 *    { type: 'text', text: 'Hello' },
 *    { type: 'linebreak', text: 'new line' },
 *    { type: 'text', text: 'world!' },
 *    { type: 'paragraph', text: 'new paragraph' }
 *  ],
 *  replacements: [
 *    {
 *     type: 'custom-shortcuts',
 *     trigger: 'new line',
 *     replacement: '',
 *     inBuilt: true
 *    },
 *   {
 *    type: 'custom-shortcuts',
 *    trigger: 'new paragraph',
 *    replacement: '',
 *    inBuilt: true
 *   }
 *  ]
 * }
 */
export const applyReplacements = (
  chunks: Chunk[],
  customShortcuts: TranscriptTransformationSettings["customShortcuts"]
): { replaced: Chunk[]; replacements: Replacement[] } => {
  // 1: Perform replacements
  const lowerCaseCustomShortcuts = new Map(
    customShortcuts.flatMap((shortcut) =>
      shortcut.matchers.map((trigger) => [trigger.toLowerCase(), shortcut])
    )
  );

  const matchers = Array.from(lowerCaseCustomShortcuts.keys());

  const getReplacements = (matchedText: string): Chunk[] | null =>
    lowerCaseCustomShortcuts.get(matchedText)?.segments ?? null;

  const replacements: Replacement[] = [];

  const replacedChunks = chunks.reduce((previousChunks: Chunk[], chunk) => {
    if (chunk.type !== "text") {
      return [...previousChunks, chunk];
    }

    const textSplitByMatchers = split(chunk.text, matchers);

    return [
      ...previousChunks,

      ...textSplitByMatchers.flatMap((text): Chunk[] => {
        const replacementChunks = getReplacements(text.toLowerCase());

        if (replacementChunks) {
          const instructions = lowerCaseCustomShortcuts.get(text.toLowerCase());

          if (!instructions) {
            return [{ type: "text", text }];
          }

          const { type, inBuilt } = instructions;

          const replacement = replacementChunks
            .map((chunk) => ("text" in chunk ? chunk.text : ""))
            .join(" ");

          replacements.push({
            type,
            trigger: text,
            replacement,
            inBuilt,
          });
        }

        return replacementChunks
          ? replacementChunks.map(
              (replacement): Chunk =>
                replacement.type === "text"
                  ? { type: "text", text: replacement.text }
                  : { type: replacement.type }
            )
          : [{ type: "text", text }];
      }),
    ];
  }, []);

  // 2: Consolidate adjacent text chunks
  const replaced = replacedChunks.reduce((previousChunks: Chunk[], chunk) => {
    const lastChunk = lastDef(previousChunks, null);

    if (chunk.type === "text" && lastChunk?.type === "text") {
      return [
        ...previousChunks.slice(0, -1),

        {
          ...lastChunk,
          text: lastChunk.text + chunk.text,
        },
      ];
    }

    return [...previousChunks, chunk];
  }, []);

  return { replaced, replacements };
};

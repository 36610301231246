import type { Stage } from "../../../types";

import { strict as chrono } from "chrono-node";
import { format as formatDate } from "date-fns";

const monthsRE =
  /january|february|march|april|may|june|july|august|september|october|november|december/i;

export const dates: Stage = ({ text, settings }) => {
  const dateFormat = settings.dateFormat;

  if (!dateFormat) {
    return text;
  }

  return (
    chrono
      // Get dates from the text
      .parse(text)
      // Filter out dates that are not months (chrono should do this, but
      // doesn't!)
      .filter((date) => monthsRE.test(date.text))
      // Replace the text with the formatted date
      .reduce((text, date) => {
        const formattedDate = formatDate(date.date(), dateFormat);
        return text.replace(new RegExp(`(the )?${date.text}`), formattedDate);
      }, text)
  );
};

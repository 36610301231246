import { createSelector } from "@reduxjs/toolkit";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { selectAdditionalParams } from "./selectAdditionalParams";
import { selectBaseAuthUrl } from "./selectBaseAuthUrl";
import { selectClientId } from "./selectClientId";
import { selectPkceCodePair } from "./selectPkceCodePair";
import { selectScopes } from "./selectScopes";

/**
 * Select oAuth URL
 *
 * Constructs a URL with the necessary parts for the PKCE OAuth flow.
 */
export const selectAuthUrlWithParams = createSelector(
  [
    selectBaseAuthUrl,
    selectClientId,
    selectPkceCodePair,
    selectScopes,
    selectAdditionalParams,
  ],
  (authUrl, clientId, pkceCodePair, scopes, additionalParams) => {
    const url = new URL(authUrl);
    const isDesktop = getAppContext() === "desktop";

    // OAuth parameters
    url.searchParams.append("client_id", clientId);
    url.searchParams.append("scope", scopes.join("+"));

    // PKCE parameters
    url.searchParams.append("response_type", "code");
    url.searchParams.append("response_mode", isDesktop ? "form_post" : "query");
    url.searchParams.append("code_challenge_method", "S256");
    url.searchParams.append(
      "code_challenge",
      pkceCodePair?.codeChallenge ?? ""
    );

    // Carescribe Web parameters
    Object.entries(additionalParams).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });

    return url.toString();
  }
);

import type { ActivityStatus } from "../types/ActivityStatus";
import type { UserState } from "@carescribe/idle-detector";
import type { Seconds } from "@carescribe/types";
import type { SagaIterator, EventChannel } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";

import { IdleDetector } from "@carescribe/idle-detector";
import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

import { activityStatusChanged } from "./actions";
import { trackActivityStatus } from "./trackActivityStatus";

const activeEvents = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "visibilitychange",
  "MSPointerDown",
  "MSPointerMove",
  "message",
];

type IdleDetectionOptions = { threshold: Seconds };

const createIdleDetectionEventChannel = ({
  threshold,
}: IdleDetectionOptions): EventChannel<UserState> =>
  eventChannel((emit) => {
    const idleDetector = new IdleDetector();
    const { signal, abort } = new AbortController();

    idleDetector.addEventListener("change", () => emit(idleDetector.userState));
    idleDetector.start({
      threshold: secondsToMilliseconds(threshold),
      signal,
      activeEvents,
    });

    return abort;
  });

/**
 * Starts up idle detection
 *
 * - Dispatches a `activityStatusChanged` action whenever the user's
 * activity status changes (based on the specified `threshold`).
 * - Tracks current activity status so that it may be retrieved at any point
 * using `getActivityStatus`
 */
export const setUpIdleDetection = function* ({
  threshold,
}: IdleDetectionOptions): SagaIterator<void> {
  const initial: ActivityStatus = "active";

  const idleDetectionEventChannel: SagaReturnType<
    typeof createIdleDetectionEventChannel
  > = yield call(createIdleDetectionEventChannel, { threshold });

  yield takeEvery(idleDetectionEventChannel, function* (newStatus) {
    yield put(activityStatusChanged(newStatus));
  });

  yield call(trackActivityStatus, { initial });
};

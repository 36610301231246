import type { UUID } from "@carescribe/types";
import type { EditorType } from "@talktype/editor/src/editor";
import type {
  DocumentFontSize,
  DocumentFontFamily,
  Leaf,
  Paragraph,
} from "@talktype/types";
import type {
  ReactElement,
  KeyboardEventHandler,
  MouseEventHandler,
  KeyboardEvent,
  MouseEvent,
  ClipboardEvent,
  ClipboardEventHandler,
} from "react";

import { Component } from "react";
import { Slate, Editable } from "slate-react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { RenderElement } from "@talktype/ui/src/Editor/RenderElement";
import { RenderLeaf } from "@talktype/ui/src/Editor/RenderLeaf";

import styles from "./editor.module.scss";
import { messages } from "./messages";

export type EditorProps = {
  id?: UUID;
  initialValue?: (Paragraph | Leaf)[];
  inProgress: boolean;
  style: { fontFamily: DocumentFontFamily; fontSize: DocumentFontSize };

  createEditor: () => EditorType;

  onLoad: (editor: EditorType) => void;
  onUnload: () => void;
  onChange: (operations: EditorType["operations"]) => void;
  onClick: MouseEventHandler<HTMLDivElement>;
  onKeyDown: KeyboardEventHandler;
  onKeyUp: KeyboardEventHandler;
  onCut: ClipboardEventHandler;
  onCopy: ClipboardEventHandler;
  onPaste: ClipboardEventHandler;
};

export class Editor extends Component<EditorProps> {
  private editor: EditorType;

  public constructor(props: EditorProps) {
    super(props);
    this.editor = this.props.createEditor();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleCut = this.handleCut.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
  }

  public componentDidMount(): void {
    this.props.onLoad(this.editor);
  }

  public componentWillUnmount(): void {
    this.props.onUnload();
  }

  /** N.B. below handlers are not passed directly into the component because
   * they can ultimately cause Slate to think its default behaviour is being
   * overridden if these handlers happen to return something (which TS
   * doesn't defend against)
   *
   * see https://docs.slatejs.org/libraries/slate-react/event-handling
   */

  private handleOnChange(): void {
    this.props.onChange(this.editor.operations);
  }

  private handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
    this.props.onKeyDown(e);
  }

  private handleKeyUp(e: KeyboardEvent<HTMLDivElement>): void {
    this.props.onKeyUp(e);
  }

  private handleMouseDown(e: MouseEvent<HTMLDivElement>): void {
    this.props.onClick(e);
  }

  private handleCut(e: ClipboardEvent): void {
    this.props.onCut(e);
  }

  private handleCopy(e: ClipboardEvent): void {
    this.props.onCopy(e);
  }

  private handlePaste(e: ClipboardEvent): void {
    this.props.onPaste(e);
  }

  public render(): ReactElement {
    return (
      <Slate
        key={this.props.id}
        editor={this.editor}
        initialValue={this.props.initialValue ?? []}
        onChange={this.handleOnChange}
      >
        <style>
          {`.${styles.editor} {
            --editor-font-family: "${this.props.style.fontFamily}";
            --editor-font-size: ${this.props.style.fontSize};
          }`}
        </style>
        <Editable
          data-hj-suppress
          aria-label={messages.label}
          className={classNames(
            styles.editor,
            createSelectorClassName("editor", "interactive"),
            [this.props.inProgress, styles.inProgress]
          )}
          renderElement={RenderElement}
          renderLeaf={RenderLeaf}
          onMouseDown={this.handleMouseDown}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          onCut={this.handleCut}
          onCopy={this.handleCopy}
          onPaste={this.handlePaste}
        />
      </Slate>
    );
  }
}

import type { SagaIterator } from "redux-saga";

import { call, takeEvery } from "redux-saga/effects";

import { helpSiteUrl } from "@talktype/config/src/helpSiteUrl";

import { requestOpenHelp } from "./actions";

/**
 * Set Up Open Help Centre
 *
 * Listens for the request to open the help centre and opens a new window/tab.
 */
export const setUpOpenHelpCentre = function* (): SagaIterator<void> {
  yield takeEvery(requestOpenHelp, function* () {
    yield call(window.open, helpSiteUrl, "blank");
  });
};

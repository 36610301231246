import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery, put } from "redux-saga/effects";
import { Transforms } from "slate";

import { documentIsEmpty } from "@carescribe/slate";
import { headDef } from "@carescribe/utilities/src/fp";

import { emptyParagraph } from "../../utils/entities";
import { requestClearEditorContents, clearedEditorContents } from "../actions";
import { getEditor } from "../utils";

/**
 * Listens for and responds to requests to clear the editor.
 */
export const setUpClear = function* (): SagaIterator<void> {
  yield takeEvery(requestClearEditorContents, function* () {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(clearedEditorContents(false));
      return;
    }

    const isAlreadyEmpty: SagaReturnType<typeof documentIsEmpty> = yield call(
      documentIsEmpty,
      editor
    );

    if (isAlreadyEmpty) {
      yield put(clearedEditorContents(true));
      return;
    }

    // Get top level paths of edge nodes (start & end)
    const start = [headDef(editor.start([]).path, 0)];
    const end = [headDef(editor.end([]).path, 0)];

    // Remove all nodes
    yield call(Transforms.removeNodes, editor, {
      at: {
        anchor: { path: start, offset: 0 },
        focus: { path: end, offset: 0 },
      },
    });

    // Insert new empty node
    yield call(Transforms.insertNodes, editor, emptyParagraph);

    yield put(clearedEditorContents(true));
  });
};

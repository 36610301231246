import type { DictateToAppState } from "../DictateToAppState";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { DictationHistoryEntry } from "@talktype/types";

type UpsertDictationHistoryEntry = (
  state: DictateToAppState,
  action: PayloadAction<DictationHistoryEntry>
) => void;

/**
 * Maximum number of entries to track, to prevent memory leaks if the app runs
 * indefinitely.
 */
const maxEntryCount = 100;

/**
 * Given a dictation history entry, adds it to a map collection if it doesn't
 * already exist. Otherwise, it updates the existing entry.
 */
export const upsertDictationHistoryEntry: UpsertDictationHistoryEntry = (
  state,
  { payload: entry }
) => {
  if (state.dictationHistory.size + 1 > maxEntryCount) {
    const oldestEntryId = state.dictationHistory.keys().next().value;
    state.dictationHistory.delete(oldestEntryId);
  }

  state.dictationHistory.set(entry.resultId, entry);
};

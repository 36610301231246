import { contactUsUrl } from "@talktype/config";

export const messages = {
  primaryText: {
    download: "TalkType update could not be downloaded",
    install: "TalkType could not be updated",
  },
  secondaryText: {
    download: `Something went wrong while downloading a TalkType update. If this issue persists, please contact our <a href='${contactUsUrl}' target='_blank' rel='noopener noreferrer'>support team</a>.`,
    install: `Something went wrong when installing a TalkType update. If this issue persists, please contact our <a href='${contactUsUrl}' target='_blank' rel='noopener noreferrer'>support team</a>.`,
  },
  label: "Retry update",
};

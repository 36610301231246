import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getLegacyPreferences } from "./getLegacyPreferences";
import { migrateLegacyPreferences } from "./migrateLegacyPreferences";
import { reshapeLegacyPreferences } from "./reshapeLegacyPreferences/reshapeLegacyPreferences";

export const setUpLegacyV2PreferencesMigration =
  function* (): SagaIterator<void> {
    yield all(
      [
        getLegacyPreferences,
        reshapeLegacyPreferences,
        migrateLegacyPreferences,
      ].map(retry)
    );
  };

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call } from "redux-saga/effects";

import { getCurrentSocket } from "./getCurrentSocket";
import { closeSocket } from "../../utils";
import { requestCloseCurrentWebSocket } from "../actions";

export const setUpClose = function* (): SagaIterator<void> {
  yield takeEvery(requestCloseCurrentWebSocket, function* () {
    const socket: SagaReturnType<typeof getCurrentSocket> = yield call(
      getCurrentSocket
    );

    yield call(closeSocket, socket);
  });
};

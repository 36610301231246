import type { Validator } from "../../types";

import { fieldRequired } from "../../rules";

type Output = Validator<{
  onChange: null;
  onSubmit: "FIELD_REQUIRED" | "PROHIBIT_RESERVED_KEYWORDS" | "DUPLICATE_ENTRY";
}>;

export const output: Output = {
  onChange: null,
  onSubmit: fieldRequired,
};

import type { PickToastConfig } from "@talktype/toasts/src/types";
import type { InvalidInstallationToast } from "@talktype/types/src/InvalidInstallationToast";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { getIsDevelopment } from "@carescribe/utilities/src/dev/getIsDevelopment";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { addToast } from "@talktype/toasts/src/sagas/actions";
import { getIpc } from "@talktype/utilities";

const INVALID_INSTALLATION_TOAST_ID = "invalid_installation";

const invalidInstallationToast =
  (): PickToastConfig<InvalidInstallationToast> => ({
    id: INVALID_INSTALLATION_TOAST_ID,
    type: "invalid_installation",
    dismissConfig: { after: null, notBefore: null },
    order: 0,
  });

/**
 * Mac/Desktop Only:
 *
 * Sends out a notification to notify the user if their installation is
 * invalid (not installed in Applications folder) as this may lead to issues
 * (e.g. auto updater)
 *
 * Sends a tracking event if the app is opened outside the Applications folder.
 */
export const notifyInvalidDesktopInstallation =
  function* (): SagaIterator<void> {
    const isDevelopment: SagaReturnType<typeof getIsDevelopment> = yield call(
      getIsDevelopment
    );

    if (isDevelopment) {
      return;
    }

    const appPlatform: SagaReturnType<typeof getPlatform> = yield call(
      getPlatform
    );

    if (appPlatform !== "mac") {
      return;
    }

    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    const getAppPath = ipc?.system?.appPath?.get?.v1;

    if (!getAppPath) {
      return;
    }

    const appPath: SagaReturnType<typeof getAppPath> = yield call(getAppPath);

    const isInApplications = /^\/Applications\/[^/]+\.app\//i.test(appPath);

    if (isInApplications) {
      return;
    }

    yield put(addToast(invalidInstallationToast()));
    yield put(
      requestTrackEvent({
        name: "Opened App Outside Applications Folder",
        data: null,
      })
    );
  };

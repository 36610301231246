import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, takeEvery, call } from "redux-saga/effects";

import { preventDefault } from "@carescribe/utilities/src/sagas";

import { selectInProgress } from "@talktype/results/src/state/selectors";

import { editorClicked, editorKeyDown } from "../actions";

/**
 * Prevent interactions whilst there is in-progress results:
 *
 * - editor is clicked
 * - key is pressed
 */
export const preventInteractions = function* (): SagaIterator<void> {
  yield takeEvery(
    [editorClicked, editorKeyDown],
    function* ({ payload: event }): SagaIterator<void> {
      const isInProgress: SagaReturnType<typeof selectInProgress> =
        yield select(selectInProgress);

      if (isInProgress) {
        yield call(preventDefault, event);
      }
    }
  );
};

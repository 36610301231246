import { Range, Point } from "slate";

/**
 * Combine Ranges
 *
 * Take two ranges and construct a new range with the outer bounds found within
 * the two. Makes a reasonable attempt to preserve range direction.
 */
export const combineRanges = (
  range1: Range | null,
  range2: Range | null
): Range | null => {
  if (!range1 || !range2) {
    return range1 ?? range2 ?? null;
  }

  // Get all the points
  const points: [Point, Point, Point, Point] = [
    range1.anchor,
    range1.focus,
    range2.anchor,
    range2.focus,
  ];

  // And find the bounds of the total range
  points.sort((a, b) => (Point.isAfter(a, b) ? 1 : -1));

  // The best guess at a range being reversed. There's no perfect answer to how
  // to determine if a combined range should be backwards, because once ranges
  // start to overlap, the water muddies. Combining a range with its mirror
  // image illustrates the conflict. Would the new range be reversed or not?
  // This is fine for our purposes, however.
  const isReversed = Range.isBackward({
    anchor: range1.anchor,
    focus: range2.focus,
  });

  const firstIndex = 0;
  const lastIndex = points.length - 1;

  return {
    anchor: points[isReversed ? lastIndex : firstIndex],
    focus: points[isReversed ? firstIndex : lastIndex],
  };
};

import type { RefObject } from "react";

const SCREEN_EDGE_PADDING = 4;

export const calculateHorizontalAdjustment = ({
  left,
  right,
  translate,
}: Pick<DOMRect, "left" | "right"> & { translate: number }): number => {
  // Account for the current translate already being applied
  const originalLeft = left - translate;
  const originalRight = right - translate;

  const viewportWidth = document.documentElement.clientWidth;

  const shouldAdjustLeft = originalLeft < SCREEN_EDGE_PADDING;
  if (shouldAdjustLeft) {
    return SCREEN_EDGE_PADDING - originalLeft;
  }

  const shouldAdjustRight = originalRight > viewportWidth - SCREEN_EDGE_PADDING;
  if (shouldAdjustRight) {
    return viewportWidth - SCREEN_EDGE_PADDING - originalRight;
  }

  const noLongerNeedsAdjustment = !shouldAdjustLeft && !shouldAdjustRight;
  if (noLongerNeedsAdjustment) {
    return 0;
  }

  return translate;
};

/**
 * Ensures an element remains within the horizontal bounds of the viewport
 * by adjusting its position using the `translate` property.
 *
 * @param ref - Reference to the target element
 *
 * @example
 *
 * // Before adjustment (element partially off-screen)
 *
 *  +---------------------------- Screen -----------------------------+
 *  |------- Element ------------------------+                        |
 *  |                                        |                        |
 *  |                                        |                        |
 *  |----------------------------------------+                        |
 *  +-----------------------------------------------------------------+
 *
 * // After adjustment (element fully within screen)
 *
 *  +---------------------------- Screen -----------------------------+
 *  |  +---------------------- Element -----------------------+       |
 *  |  |                                                      |       |
 *  |  |                                                      |       |
 *  |  +------------------------------------------------------+       |
 *  |                                                                 |
 *  +-----------------------------------------------------------------+
 */
export const keepInViewport = (ref: RefObject<HTMLElement>): void => {
  const element = ref.current;
  if (!element) {
    return;
  }
  const { left, right } = element.getBoundingClientRect();

  const computedStyles = window.getComputedStyle(element);
  const translateValue = parseInt(computedStyles.translate.split(" ")[0]);
  const translate = isNaN(translateValue) ? 0 : translateValue;

  const horizontalAdjustment = calculateHorizontalAdjustment({
    left,
    right,
    translate,
  });

  element.style.translate = `${horizontalAdjustment}px`;
};

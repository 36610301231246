import type { TimingsState } from "../types";

import {
  addSeconds,
  millisecondsToSeconds,
  subtractSeconds,
} from "@carescribe/utilities/src/timing";

export const setLastDisconnectionTimestampReducer = (
  state: TimingsState
): void => {
  state.lastDisconnectionTimestamp = millisecondsToSeconds(performance.now());

  const timeSinceLastConnection = subtractSeconds(
    state.lastDisconnectionTimestamp,
    state.lastConnectionTimestamp
  );

  state.sumOfCompletedDictationDurations = addSeconds(
    state.sumOfCompletedDictationDurations,
    timeSinceLastConnection
  );
};

import type {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
} from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./checkboxSlider.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type CheckboxSliderProps = {
  checkboxProps: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  size: "sm" | "md";
};

export const CheckboxSlider = ({
  checkboxProps,
  size,
}: CheckboxSliderProps): ReactElement => (
  <span
    className={classNames(styles.container, styles[size], [
      checkboxProps.checked,
      styles.active,
    ])}
  >
    <span className={styles.blob} aria-hidden />

    <ScreenReaderOnly tag="span">
      <input type="checkbox" {...checkboxProps} />
    </ScreenReaderOnly>
  </span>
);

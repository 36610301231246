import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./featuredIcon.module.scss";
import { IconContainer } from "../IconContainer";

export type FeaturedIconProps = {
  style: "brand" | "positive" | "alert" | "destructive";
  weight: "light" | "heavy";
  size: "xs" | "sm" | "md" | "lg" | "xl";
  children: ReactNode;
};

export const FeaturedIcon = ({
  style,
  weight,
  size,
  children,
}: FeaturedIconProps): ReactElement => (
  <div
    className={classNames(
      styles.container,
      styles[style],
      styles[weight],
      styles[size]
    )}
    aria-hidden
  >
    <IconContainer className={styles.icon}>{children}</IconContainer>
  </div>
);

import type { Me } from "../types/Me";
import type { DictationSessionOrError } from "@talktype/types";
import type { AnyAction } from "redux-saga";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

import { setLoginStatus } from "../reducer";

export const action = prefixActionCreator("saga/talktype-user");

export const optimisticLogin = action<void, "optimistic-login">(
  "optimistic-login"
);

export const notLoggedIn = (action: AnyAction): boolean =>
  action.type === setLoginStatus.type && action.payload !== "loggedIn";

export const loginComplete = action<Me, "login-complete">("login-complete");

export const logoutComplete = action<void, "logout-complete">(
  "logout-complete"
);

export const requestLicenceKeyRedemptionStatus = action<
  void,
  "request-licence-key-redemption-status"
>("request-licence-key-redemption-status");

export const gotLicenceKeyRedemptionStatus = action<
  "redeemable" | "unredeemable",
  "got-licence-key-redemption-status"
>("got-licence-key-redemption-status");

export const requestGetMe = action<void, "request-get-me">("request-get-me");

export const requestDictationSession = action<
  void,
  "request-dictation-session"
>("request-dictation-session");

export const gotDictationSession = action<
  DictationSessionOrError,
  "got-dictation-session"
>("got-dictation-session");

export const requestLogout = action<void, "request-logout">("request-logout");

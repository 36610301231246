import type { DeviceInfo } from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";

import { createSlice, createSelector } from "@reduxjs/toolkit";

import { headDef } from "@carescribe/utilities/src/fp";

type AudioState = {
  inputDevices: DeviceInfo[];
  selectedInputDeviceId: string | null;
};

type CombinedState = {
  audio: AudioState;
};

// initial state
const initialState: AudioState = {
  inputDevices: [],
  selectedInputDeviceId: null,
};

// reducers
const setInputDevicesReducer = (
  state: AudioState,
  { payload }: PayloadAction<DeviceInfo[]>
): void => {
  state.inputDevices = payload;
};

const setSelectedInputDeviceIdReducer = (
  state: AudioState,
  { payload }: PayloadAction<string>
): void => {
  state.selectedInputDeviceId = payload;
};

const slice = createSlice({
  name: "audio",
  initialState: initialState,
  reducers: {
    setInputDevices: setInputDevicesReducer,
    setSelectedInputDeviceId: setSelectedInputDeviceIdReducer,
  },
});

// exports
export const { setInputDevices, setSelectedInputDeviceId } = slice.actions;

export const reducer = slice.reducer;

// selectors
export const selectInputDevices = (rootState: CombinedState): DeviceInfo[] =>
  rootState.audio.inputDevices;

export const selectSelectedInputDevice = (
  rootState: CombinedState
): string | null => rootState.audio.selectedInputDeviceId;

export const selectSelectedInputDeviceName = createSelector(
  [selectInputDevices, selectSelectedInputDevice],
  (inputDevices, selectedDeviceId): string | null =>
    inputDevices.find((device) => device.deviceId === selectedDeviceId)
      ?.label ||
    headDef(inputDevices, null)?.label ||
    null
);

import type { PayloadAction } from "@reduxjs/toolkit";
import type { CustomShortcut, Preferences } from "@talktype/types";

type AddCustomShortcut = (
  state: Preferences,
  action: PayloadAction<CustomShortcut>
) => void;

export const addCustomShortcut: AddCustomShortcut = (
  state,
  { payload: shortcut }
) => {
  state.customShortcuts.push(shortcut);
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import {
  requestSendResultToDesktopApp,
  resultSent,
} from "@talktype/results/src/sagas/actions";
import { getIpc } from "@talktype/utilities";

import { convertTranscriptToTextOnly } from "./convertTranscriptToTextOnly";

/**
 * Manage Send Result to App
 *
 * Communicates the text portion of a result to the Dictate-to-App feature in
 * the desktop app so that text can be inserted into a 3rd party application.
 */
export const manageSendResultToApp = function* (): SagaIterator<void> {
  yield takeEvery(
    requestSendResultToDesktopApp,
    function* ({ payload: result }) {
      const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

      if (!ipc?.dictateToApp?.sendText?.v1) {
        return;
      }

      // Convert the transcript to text only
      const transcript: SagaReturnType<typeof convertTranscriptToTextOnly> =
        yield call(convertTranscriptToTextOnly, result);

      yield call(ipc.dictateToApp.sendText.v1, { ...result, transcript });

      yield put(resultSent(result));
    }
  );
};

import type { KeyboardEvent, MouseEvent } from "react";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

export const handleOpenSubmenu = function* (
  event: KeyboardEvent | MouseEvent
): SagaIterator<void> {
  const interactionMethod: SagaReturnType<
    typeof getInteractionMethodFromEvent
  > = yield call(getInteractionMethodFromEvent, event);

  yield put(
    requestTrackEvent({
      name: "Submenu Opened",
      data: { interactionMethod },
    })
  );
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery, take } from "redux-saga/effects";

import { selectionIsCollapsed } from "@carescribe/slate/src/utils/selectionIsCollapsed";

import { setMarks } from "@talktype/utilities";

import {
  requestRestoreMarks,
  requestEditorMarks,
  broadcastEditorMarks,
  restoredMarks,
} from "../../actions";
import { getEditor } from "../../utils";

/**
 * Handles requests to restore editor marks.
 *
 * It gets previously saved editor marks and sets them on the editor.
 * Unless selection is not collapsed, in which case it does nothing.
 */
export const restoreMarks = function* (): SagaIterator<void> {
  yield takeEvery(requestRestoreMarks, function* (): SagaIterator<void> {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(restoredMarks());
      return;
    }

    yield put(requestEditorMarks());
    const {
      payload: editorMarks,
    }: SagaReturnType<typeof broadcastEditorMarks> = yield take(
      broadcastEditorMarks
    );

    const isCollapsed: SagaReturnType<typeof selectionIsCollapsed> = yield call(
      selectionIsCollapsed,
      editor
    );

    if (editorMarks && isCollapsed) {
      yield call(setMarks, editor, editorMarks);
    }

    yield put(restoredMarks());
  });
};

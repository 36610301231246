import type { ValueOf } from "@carescribe/types/src/ValueOf";
import type { KeyPress } from "@talktype/types/src/Ipc/KeyPress";
import type {
  SystemTrayItem,
  SystemTraySlotId,
} from "@talktype/types/src/Ipc/SystemTrayItem";

import { requestToggleDictation } from "@talktype/actions";
import { InteractionMethod } from "@talktype/analytics";
import {
  requestFocusTalkType,
  requestOpenHelp,
  requestQuitTalkType,
} from "@talktype/system/src/sagas/actions";

import { messages } from "./messages";

export const defaultDictationHotkey: KeyPress = {
  key: "d",
  ctrlKey: true,
  metaKey: true,
  shiftKey: true,
};

export const toggleDictationId: SystemTraySlotId = "toggleDictation";

const toggleDictationAction = requestToggleDictation({
  interactionMethod: InteractionMethod.click,
});

type SystemTrayItemName =
  | "startDictation"
  | "stopDictation"
  | "open"
  | "separator"
  | "help"
  | "quit";

/**
 * System Tray Menu Items
 *
 * The items that appear in the system tray menu. This Record is order agnostic
 * and the order is managed elsewhere.
 *
 * The action property is intended to be dispatched when the item is clicked.
 *
 * Separators are not clickable and do not have an action, but if multiple are
 * needed they should be given unique IDs.
 */
export const trayMenuItems: Record<SystemTrayItemName, SystemTrayItem> = {
  startDictation: {
    label: messages.tray.startDictation,
    type: "item",
    action: toggleDictationAction,
    id: toggleDictationId,
    keypress: defaultDictationHotkey,
  },
  stopDictation: {
    label: messages.tray.stopDictation,
    type: "item",
    action: toggleDictationAction,
    id: toggleDictationId,
    keypress: defaultDictationHotkey,
  },
  open: {
    label: messages.tray.open,
    type: "item",
    id: "open",
    action: requestFocusTalkType(),
  },
  separator: { id: "separator", type: "separator" },
  help: {
    label: messages.tray.help,
    type: "item",
    id: "help",
    action: requestOpenHelp(),
  },
  quit: {
    label: messages.tray.quit,
    type: "item",
    id: "quit",
    action: requestQuitTalkType(),
  },
};

export type TrayMenuItemId = ValueOf<typeof trayMenuItems>["id"];

import type { SagaIterator } from "redux-saga";

import { put, take, takeLeading } from "redux-saga/effects";

import {
  accessTokenRevoked,
  pkceRequestFailed,
  requestRevokeAccessToken,
} from "@carescribe/pkce/src/sagas/actions";

import { crucialNetworkRequestFailed } from "@talktype/actions";

import { logoutComplete, requestLogout } from "./actions";
import { setLoginStatus, setMe } from "../reducer";

export const setUpLogout = function* (): SagaIterator<void> {
  yield takeLeading(
    [
      // Manual requests to log out
      requestLogout,
      // Log out when a network request that the application relies on fails
      crucialNetworkRequestFailed,
      // Log out when auth fails
      pkceRequestFailed,
    ],
    function* () {
      yield put(requestRevokeAccessToken());
      yield take(accessTokenRevoked);

      yield put(setLoginStatus("loggedOut"));
      yield put(setMe(null));
      yield put(logoutComplete());
    }
  );
};

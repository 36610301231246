import type { PkceReducer } from "../../types/PkceReducer";

export const addAdditionalParams: PkceReducer<Record<string, string>> = (
  state,
  { payload }
) => {
  state.additionalParams = {
    ...state.additionalParams,
    ...payload,
  };
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { attachGlobalKeyListener } from "./attachGlobalKeyListener";
import { manageHotkeys } from "./manageHotkeys";

export const setUpHotkeys = function* (): SagaIterator<void> {
  yield all([manageHotkeys, attachGlobalKeyListener].map(retry));
};

/**
 * Get Ordinal Suffix
 *
 * Given a number, returns the suffix for that number as an ordinal
 *
 * @example
 * getOrdinalSuffix(1); // "st"
 * getOrdinalSuffix(2); // "nd"
 * getOrdinalSuffix(3); // "rd"
 * getOrdinalSuffix(11); // "th"
 */
export const getOrdinalSuffix = (value: number): string => {
  const lastTwoDigits = Math.abs(Math.floor(value % 100));
  const lastDigit = lastTwoDigits % 10;

  // Teens all end in "th", even if they terminate in 1, 2 or 3
  if (lastTwoDigits < 20 && lastTwoDigits > 10) {
    return "th";
  }

  const suffixes = {
    1: "st",
    2: "nd",
    3: "rd",
  };

  const suffix =
    lastDigit in suffixes ? suffixes[lastDigit as keyof typeof suffixes] : "th";

  return suffix;
};

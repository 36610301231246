import type { PkceCodePair } from "../types/PkceCodePair";
import type { Tokens } from "../types/Tokens";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/pkce");

export const requestAuthenticate = action<void, "request-authenticate">(
  "request-authenticate"
);

export const authenticationComplete = action<
  boolean,
  "authentication-complete"
>("authentication-complete");

export const requestGetCode = action<void, "request-get-code">(
  "request-get-code"
);

export const gotCode = action<string | null, "got-code">("got-code");

export const requestGetCodeFromDeepLink = action<
  void,
  "request-get-code-from-deep-link"
>("request-get-code-from-deep-link");

export const gotCodeFromDeepLink = action<string, "got-code-from-deep-link">(
  "got-code-from-deep-link"
);

export const requestTokens = action<
  {
    code: string;
    codePair: PkceCodePair;
  },
  "request-tokens"
>("request-tokens");

export const requestRefreshAccessToken = action<
  Tokens,
  "request-refresh-access-token"
>("request-refresh-access-token");

export const requestRevokeAccessToken = action<
  void,
  "request-revoke-access-token"
>("request-revoke-access-token");

export const accessTokenRevoked = action<void, "access-token-revoked">(
  "access-token-revoked"
);

export const pkceRequestFailed = action<string, "pkce-request-failed">(
  "pkce-request-failed"
);

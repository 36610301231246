/* eslint-disable no-undefined */
import type {
  RootState,
  EncryptedStorage,
  DecryptedUserStorage,
} from "./types";

import {
  fallbackEditorState,
  fallbackPkceState,
  fallbackPreferences,
  fallbackPWAState,
  fallbackUserState,
} from "./fallbacks";
import {
  isEditorState,
  isPkceState,
  isPreferences,
  isPWAState,
  isUserState,
} from "./guards";
import {
  safeValue,
  getLegacyDataFromLocalStorage,
  encryptUserState,
  decryptUserState,
} from "./utils";
import { logError } from "../../../log";
import { IndexedDB } from "../../IndexedDB";

/**
 * This version introduces IndexedDB as the persistence layer. All data is
 * stored in a single "state" object store for simplicity. Legacy data which
 * was previously stored in local storage is migrated over.
 */
export const migrate = async (
  database: IDBDatabase,
  transaction: IDBTransaction,
  legacyLocalStorage: EncryptedStorage | null
): Promise<void> => {
  // Create the state object store
  database.createObjectStore("state");

  if (!legacyLocalStorage) {
    return;
  }

  // Migrate the legacy local storage data
  try {
    return await new Promise((resolve, reject) => {
      const objectStore = transaction.objectStore("state");
      const request = objectStore.put(legacyLocalStorage, "state");

      request.addEventListener("error", reject);
      request.addEventListener("success", (): void => resolve());
    });
  } catch (cause) {
    logError(
      new Error("Failed to migrate legacy local storage data", { cause })
    );
  }
};

export const databaseConfig = {
  version: 1,
  migrations: [{ version: 1, migrate }],
};

export const getUserId = (state: {
  user: { me: { uuid: string } | null };
}): string | null => state.user.me?.uuid ?? null;

export const checkShouldRehydrate = (
  previousState: RootState,
  newState: RootState
): boolean => getUserId(previousState) !== getUserId(newState);

export const getSharedStorage = (
  storage: Record<string, unknown>
): EncryptedStorage["shared"] => {
  const shared = safeValue({
    description: "shared",
    fallback: {},
    data: storage.shared,
    validate: (data): data is Record<string, unknown> =>
      typeof data === "object" && data !== null,
  });

  const pkce = safeValue({
    description: "pkce",
    fallback: fallbackPkceState,
    data: shared.pkce,
    validate: isPkceState,
  });

  const pwa = safeValue({
    description: "pwa",
    fallback: fallbackPWAState,
    data: shared.pwa,
    validate: isPWAState,
  });

  const user = safeValue({
    description: "user",
    fallback: fallbackUserState,
    data: shared.user,
    validate: isUserState,
  });

  return { pkce, pwa, user };
};

export const getUserStorage = async (
  storage: Record<string, unknown>,
  userId: string | null
): Promise<DecryptedUserStorage | null> => {
  if (userId === null) {
    return null;
  }

  const users = safeValue({
    description: "users",
    fallback: new Map(),
    data: storage.users,
    validate: (data): data is EncryptedStorage["users"] => data instanceof Map,
  });

  const decrypted = await decryptUserState(users, userId);
  if (decrypted === null) {
    return null;
  }

  const userState = safeValue({
    description: "user state",
    fallback: {},
    data: decrypted,
    validate: (data): data is Record<string, unknown> =>
      typeof data === "object" && data !== null,
  });

  const editor = safeValue({
    description: "editor",
    fallback: fallbackEditorState,
    data: userState.editor,
    validate: isEditorState,
  });

  const preferences = safeValue({
    description: "preferences",
    fallback: fallbackPreferences,
    data: userState.preferences,
    validate: isPreferences,
  });

  return { editor, preferences };
};

/**
 * Describes how new state should be combined with existing state in storage.
 *
 * Certain slices and their properties are intentionally omitted from
 * persistence.
 *
 * User data is encrypted.
 */
const mergeState = async (
  storage: Record<string, unknown>,
  { pkce, pwa, user, editor, preferences }: RootState
): Promise<EncryptedStorage> => {
  const shared = {
    pkce,
    pwa: { customPromptDismissedAt: pwa.customPromptDismissedAt },
    user,
  };
  const userState = {
    editor: {
      documents: editor.documents,
      currentDocumentId: editor.currentDocumentId,
      dictationMode: editor.dictationMode,
    },
    preferences,
  };
  const userId = getUserId(shared);
  const users = safeValue({
    description: "users",
    fallback: new Map(),
    data: storage.users,
    validate: (data): data is EncryptedStorage["users"] => data instanceof Map,
  });

  if (userId !== null) {
    const { key, encryptedData } = await encryptUserState(userId, userState);
    users.set(key, encryptedData);
  }

  return { shared, users };
};

export const persistState = async (state: RootState): Promise<void> => {
  const config = { ...databaseConfig, legacyLocalStorage: null };
  const database = new IndexedDB(config);
  await database.open();
  const data = await database.get();
  const storage = safeValue({
    description: "storage",
    fallback: {},
    data,
    validate: (data): data is Record<string, unknown> =>
      typeof data === "object" && data !== null,
  });
  const mergedState = await mergeState(storage, state);
  await database.put(mergedState);
};

/**
 * Describes how state should be pulled from storage and hydrated into
 * the existing application state.
 *
 * If a `currentUserId` is provided, it will be used as the `userId` for loading
 * the user data from storage. Otherwise, the `userId` will be derived from the
 * storage.
 *
 * The end result is a sandwich consisting of:
 * - Initial non-persisted state
 * - Hydrated shared state (pkce, pwa, user)
 * - Hydrated user state (editor, preferences)
 */
export const getHydratedStoreState = async <InitialState extends RootState>({
  initialState,
  userId: currentUserId,
  migrateLegacyLocalStorage,
}: {
  initialState: InitialState;
  userId: string | null | undefined;
  migrateLegacyLocalStorage: boolean;
}): Promise<InitialState> => {
  try {
    const legacyLocalStorage = migrateLegacyLocalStorage
      ? await getLegacyDataFromLocalStorage()
      : null;
    const config = { ...databaseConfig, legacyLocalStorage };

    const database = new IndexedDB(config);
    await database.open();
    const data = await database.get();
    const storage = safeValue({
      description: "storage",
      fallback: {},
      data,
      validate: (data): data is Record<string, unknown> =>
        typeof data === "object" && data !== null,
    });
    const sharedStorage = getSharedStorage(storage);
    const userId =
      currentUserId === undefined ? getUserId(sharedStorage) : currentUserId;
    const userStorage = await getUserStorage(storage, userId);

    const { pkce, user } = sharedStorage;
    const pwa = { ...initialState.pwa, ...sharedStorage.pwa };
    const { editor, preferences } = userStorage
      ? {
          editor: { ...initialState.editor, ...userStorage.editor },
          preferences: userStorage.preferences,
        }
      : initialState;

    return {
      ...initialState,
      pkce,
      pwa,
      user,
      editor,
      preferences,
    };
  } catch (cause) {
    logError(new Error("Failed to hydrate store state", { cause }));
    return initialState;
  }
};

/**
 * Rehydrates the user state from storage while leaving the rest of the shared
 * state intact. This is useful for when a user logs in/out and we want to
 * load their storage data into state.
 */
export const rehydrateUserState = async <State extends RootState>({
  state,
  initialState,
}: {
  state: State;
  initialState: State;
}): Promise<Pick<State, "editor" | "preferences">> => {
  const userId = getUserId(state);

  const { editor, preferences } = await getHydratedStoreState({
    initialState,
    userId,
    migrateLegacyLocalStorage: false,
  });

  return { editor, preferences };
};

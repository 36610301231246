import type { SagaIterator } from "redux-saga";

import { put, takeEvery, throttle } from "redux-saga/effects";

import {
  versionsAreUpToDate,
  updateReadyToInstall,
  updateError,
} from "@carescribe/updater";
import {
  secondsInDay,
  secondsToMilliseconds,
  toSeconds,
} from "@carescribe/utilities/src/timing";

import { addToast, dismissToast } from "@talktype/toasts/src/sagas/actions";

const UPDATE_AVAILABLE_TOAST_ID = "update_available";
const UPDATE_ERROR_TOAST_ID = "update_error";
const TWELVE_HOURS = toSeconds(secondsInDay / 2);

/**
 * This saga listens for desktop update related events and in response
 * dispatches the appropriate notifications.
 *
 *  - "Update available" notification:
 *    - Displayed when an update is available and ready to be installed
 *    - Throttled to show at most once every 12 hours
 *
 *  - "Update not available" notification:
 *    - Dismissed when an update is no longer available
 *
 * - "Update error" notification:
 *   - Displayed when an error occurs while downloading or installing an update
 */
export const manageNotifications = function* (): SagaIterator<void> {
  yield throttle(
    secondsToMilliseconds(TWELVE_HOURS),
    updateReadyToInstall,
    function* ({ payload: { type } }): SagaIterator<void> {
      yield put(
        addToast({
          id: UPDATE_AVAILABLE_TOAST_ID,
          type: "update_available",
          update: { type },
          order: 0,
          dismissConfig: { after: null, notBefore: null },
        })
      );
    }
  );

  yield takeEvery(versionsAreUpToDate, function* () {
    yield put(dismissToast(UPDATE_AVAILABLE_TOAST_ID));
  });

  yield takeEvery(updateError, function* ({ payload: details }) {
    yield put(
      addToast({
        id: UPDATE_ERROR_TOAST_ID,
        type: "update_error",
        details,
        order: 0,
        dismissConfig: { after: null, notBefore: null },
      })
    );
  });
};

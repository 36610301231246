import type { UpdateStepFailed } from "../types/UpdateStepFailed";
import type { UpdateType } from "../types/UpdateType";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/updater");
export const versionsAreUpToDate = action<void, "versions-are-up-to-date">(
  "versions-are-up-to-date"
);

export const updateReadyToDownload = action<void, "update-ready-to-download">(
  "update-ready-to-download"
);

export const updateReadyToInstall = action<
  { type: UpdateType },
  "update-ready-to-install"
>("update-ready-to-install");

export const requestDownloadUpdate = action<void, "request-download-update">(
  "request-download-update"
);

export const requestInstallUpdate = action<
  { type: UpdateType },
  "request-install-update"
>("request-install-update");

export const updateError = action<UpdateStepFailed, "update-error">(
  "update-error"
);

export const requestRetryUpdateStep = action<
  UpdateStepFailed,
  "request-retry-update-step"
>("request-retry-update-step");

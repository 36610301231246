import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery, select } from "redux-saga/effects";
import { match } from "ts-pattern";

import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";
import { appIsEqual } from "@talktype/utilities/src/appIsEqual";

import {
  requestAddResultToEditor,
  requestSendResult,
  requestSendResultToDesktopApp,
  resultSent,
} from "./actions";

export const sendResult = function* (): SagaIterator<void> {
  yield takeEvery(
    requestSendResult,
    function* ({ payload: result }): SagaIterator<void> {
      const activeApp: SagaReturnType<typeof selectActiveApp> = yield select(
        selectActiveApp
      );

      const isEqualToActiveApp: SagaReturnType<typeof appIsEqual> = yield call(
        appIsEqual,
        result.targetApp,
        activeApp
      );
      const isSelf = result.targetApp.isSelf;

      const action = match({
        isEqualToActiveApp,
        isSelf,
      })
        .with({ isEqualToActiveApp: false }, () => resultSent(result))
        .with({ isSelf: true }, () => requestAddResultToEditor(result))
        .with({ isSelf: false }, () => requestSendResultToDesktopApp(result))
        .exhaustive();

      yield put(action);
    }
  );
};

import type { Ipc } from "@talktype/types";
import type { EventChannel, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";

import { getDeepLinkFromConsole } from "@carescribe/electron/src/dev/getDeepLinkFromConsole";
import { gotCode } from "@carescribe/pkce/src/sagas/actions";

import { getIpc } from "@talktype/utilities";

import { logError } from "../../utils/log";

/**
 * Create Deep Link Channel
 *
 * Create a channel to listen for deep links.
 */
export const createDeepLinkChannel = (ipc: Ipc): EventChannel<string> =>
  eventChannel<string>((emit) => {
    const listener = (url: string): void => emit(url);

    const unsubscribe = ipc?.deepLink?.subscribe?.v1?.(listener);

    getDeepLinkFromConsole(emit);

    return (): void => unsubscribe?.();
  });

/**
 * Set Up Get Code From Deep Link
 *
 * Set up a channel to listen for arriving deep links and extract the code from
 * them.
 */
export const setUpGetCodeFromDeepLink = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  if (!ipc) {
    return;
  }

  const channel: SagaReturnType<typeof createDeepLinkChannel> = yield call(
    createDeepLinkChannel,
    ipc
  );

  yield takeEvery(channel, function* (url): SagaIterator<void> {
    const code = new URL(url).searchParams.get("code");

    if (code) {
      yield put(gotCode(code));
    } else {
      yield call(logError, "No code found in deep link");
    }
  });
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { setColourScheme, setPreferences } from "@talktype/preferences";
import { customInstallPromptVisibilityChanged } from "@talktype/pwa/src/sagas/actions";
import { replaceState } from "@talktype/store/src/actions";
import { setLoginStatus } from "@talktype/user/src/reducer";

import { createUserSystemPreferenceEventChannel } from "../../utils/createUserSystemPreferenceEventChannel";
import { requestUpdateStatusBarColour } from "../actions";

const actionsWhichImpactStatusBarColour = [
  setColourScheme,
  setPreferences,
  replaceState,
  setLoginStatus,
  customInstallPromptVisibilityChanged,
];

/**
 * Triggers an update to the status bar colour when the app loads and when any
 * factors impacting the status bar colour change:
 *
 * - The user's system preference changes
 * - The PWA install prompt visibility changes
 * - The user logs in or out
 * - The colour scheme is changed
 */
export const triggerStatusBarColourUpdate = function* (): SagaIterator<void> {
  const userSystemPreferenceEventChannel: SagaReturnType<
    typeof createUserSystemPreferenceEventChannel
  > = yield call(createUserSystemPreferenceEventChannel);

  yield put(requestUpdateStatusBarColour());

  yield takeEvery(actionsWhichImpactStatusBarColour, function* () {
    yield put(requestUpdateStatusBarColour());
  });

  // Event channels need their own separate `takeEvery` to work ¯\_(ツ)_/¯
  yield takeEvery(userSystemPreferenceEventChannel, function* () {
    yield put(requestUpdateStatusBarColour());
  });
};

import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  requestDownloadUpdate,
  requestInstallUpdate,
  requestRetryUpdateStep,
} from "@carescribe/updater/src/sagas/actions";

/**
 * Handles request to retry a specific step of the update process.
 *
 * This can happen as a result of a previously failed step.
 *
 * Retrying a step will dispatch the corresponding action for that step.
 *
 * Download:
 * - `requestDownloadUpdate`
 *
 * Install:
 * - `requestInstallUpdate`
 */
export const manageRetries = function* (): SagaIterator<void> {
  yield takeEvery(
    requestRetryUpdateStep,
    function* ({ payload: { type, step } }) {
      switch (step) {
        case "download":
          yield put(requestDownloadUpdate());
          break;
        case "install":
          yield put(requestInstallUpdate({ type }));
          break;
      }
    }
  );
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageInlineStyling } from "./manageInlineStyling";
import { storeSelectionStylesInRedux } from "./storeSelectionStylesInRedux";

export const setUpInlineStyling = function* (): SagaIterator<void> {
  yield all([manageInlineStyling, storeSelectionStylesInRedux].map(retry));
};

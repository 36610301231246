import type { TextNode } from "@talktype/types";

import { mergeRight } from "@carescribe/utilities/src/fp";

import { defaultLeafProperties } from "./entities/defaultLeafProperties";

export const textNode = (input?: Partial<TextNode> | string): TextNode => {
  const inputIsString = typeof input === "string";

  return mergeRight(defaultLeafProperties, {
    type: "text",
    text: inputIsString ? input : "",
    ...(!inputIsString && input ? input : {}),
  });
};

import type { AppDispatch, RootState } from "@talktype/store";
import type { ShortcutsProps } from "@talktype/ui/src/Shortcuts";

import { connect } from "react-redux";

import { input } from "@talktype/forms/src/validators/customShortcuts/input";
import { output } from "@talktype/forms/src/validators/customShortcuts/output";
import {
  requestAddCustomShortcut,
  requestEditCustomShortcut,
  requestDeleteCustomShortcut,
  selectCustomShortcuts,
} from "@talktype/preferences";
import { Shortcuts as Component } from "@talktype/ui/src/Shortcuts";

type StateProps = Pick<ShortcutsProps, "shortcuts" | "validators">;

const mapStateToProps = (state: RootState): StateProps => {
  const validators = { input: input(selectCustomShortcuts(state)), output };
  return {
    shortcuts: selectCustomShortcuts(state),
    validators: { add: validators, edit: validators },
  };
};

type DispatchProps = Pick<ShortcutsProps, "mutators">;

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  mutators: {
    add: (partialShortcut) =>
      dispatch(requestAddCustomShortcut(partialShortcut)),
    edit: (shortcut) => dispatch(requestEditCustomShortcut(shortcut)),
    delete: (id) => dispatch(requestDeleteCustomShortcut(id)),
  },
});

export const Shortcuts = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpCustomInstallPrompt } from "./custom-install-prompt/setUpCustomInstallPrompt";
import { setUpNativeInstallPrompt } from "./native-install-prompt/setUpNativeInstallPrompt";

export const setUpPWA = function* (): SagaIterator<void> {
  yield all([setUpCustomInstallPrompt, setUpNativeInstallPrompt].map(retry));
};

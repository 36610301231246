import type { HandleInteraction, IsActive, IsDisabled } from "..";
import type { Item } from "../config";
import type { InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { getPlatform } from "@carescribe/utilities/src/browser";

import { ToggleableListItem } from "../../ToggleableListItem";

type SubmenuItemProps = {
  item: Item;
  handleInteraction: HandleInteraction;
  isActive: IsActive;
  isDisabled: IsDisabled;
};

export const SubmenuItem = ({
  item,
  handleInteraction,
  isActive,
  isDisabled,
}: SubmenuItemProps): ReactElement => {
  if (item.type !== "button") {
    return <></>;
  }

  const { id, tooltipProps } = item;

  const onInteraction = (
    event: InteractionEvent<HTMLButtonElement | HTMLInputElement>
  ): void => handleInteraction(id, event);

  const active = isActive(id);
  const platform = getPlatform();

  return (
    <ToggleableListItem
      key={id}
      toggleable={item.toggleable}
      active={active}
      Icon={item.buttonProps.Icon}
      disabled={isDisabled(id)}
      label={tooltipProps.messages.visible({ active, platform })[0]}
      onInteraction={onInteraction}
    />
  );
};

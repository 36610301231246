import { createSelector } from "@reduxjs/toolkit";

import { selectSearchResults } from "./selectSearchResults";
import { selectSearchTerm } from "./selectSearchTerm";
import { listingStructure } from "../../listingStructure";

/**
 * Select Listing Display
 *
 * Selects the information about how the listing is displayed in the UI,
 * including:
 *
 * - The listing itself
 * - Whether the user is currently searching
 * - The number of results
 * - The search term
 */
export const selectListingDisplay = createSelector(
  [selectSearchResults, selectSearchTerm],
  (results, searchTerm) => {
    const notSearching = searchTerm.length < 3 && results.length === 0;

    const listing = notSearching ? listingStructure : results;

    const resultCount = results.reduce(
      (total, section) =>
        total +
        section.subsections.reduce(
          (subtotal, item) => subtotal + item.subsections.length,
          0
        ),
      0
    );

    return {
      listing,
      isSearching: !notSearching,
      resultCount,
      searchTerm,
    };
  }
);

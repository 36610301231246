import type { Callback } from "@carescribe/types";
import type { BaseOperation } from "slate";

import { Node } from "slate";

type CalculateTextChangeCount = Callback<
  BaseOperation[],
  { insertCount: number; removeCount: number }
>;

/**
 * Calculates number of characters inserted and removed from a
 * given set of operations.
 *
 * The method of how each operation is counted is as follows.
 *
 * - insert_text: number of characters in the text
 * - remove_text: number of characters in the text
 * - insert_node: number of characters in the node
 * - remove_node: number of characters in the node (min: 1)
 */
export const calculateTextChangeCount: CalculateTextChangeCount = (
  operations
) =>
  operations.reduce(
    ({ insertCount, removeCount }, operation) => {
      switch (operation.type) {
        case "insert_text":
          insertCount += operation.text.length;
          break;

        case "remove_text":
          removeCount += operation.text.length;
          break;

        case "insert_node":
          insertCount += Node.string(operation.node).length;
          break;

        case "remove_node":
          removeCount += Math.max(Node.string(operation.node).length, 1);
          break;
      }

      return { insertCount, removeCount };
    },
    {
      insertCount: 0,
      removeCount: 0,
    }
  );

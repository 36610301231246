import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationHistoryEntry } from "@talktype/types";
import type { DictationHistoryPopulatedProps } from "@talktype/ui/src/DictationHistoryPopulated";

import { connect } from "react-redux";

import {
  selectDictationHistoryEntries,
  requestCopyDictationHistoryEntry,
} from "@talktype/dictate-to-app";
import { selectInProgress } from "@talktype/results/src/state/selectors";
import { DictationHistoryPopulated as Component } from "@talktype/ui/src/DictationHistoryPopulated";

type StateProps = { entries: DictationHistoryEntry[]; inProgress: boolean };

const mapStateToProps = (state: RootState): StateProps => ({
  entries: selectDictationHistoryEntries(state),
  inProgress: selectInProgress(state),
});

type DispatchProps = {
  onCopy: ({ resultId }: { resultId: string }) => void;
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onCopy: (entry) => dispatch(requestCopyDictationHistoryEntry(entry)),
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps
): DictationHistoryPopulatedProps => {
  const entries = stateProps.entries.map((entry, index) => {
    const isLast = index === stateProps.entries.length - 1;
    const isFinal = !isLast || (isLast && !stateProps.inProgress);
    return {
      ...entry,
      onCopy: () => dispatchProps.onCopy(entry),
      isFinal,
    };
  });

  return { entries };
};

export const DictationHistoryPopulated = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

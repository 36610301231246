import { isObject } from "./guards/isObject";

type Success<T> = [T, null];
type Failure = [null, unknown];
type Outcome<T> = Success<T> | Failure;

/**
 * Regular JSON with added niceties:
 *
 * - `safeParse` method
 *   - Catches and returns any errors (e.g. invalid JSON format)
 *   - Strict return typing: `unknown` instead of `any`.
 *
 * - `replace` and `revive` methods
 *   - Easily add support for parsing and serialising `Map` and `Set` types.
 */
export const StrictJSON = {
  /**
   * Converts a JavaScript Object Notation (JSON) string into an object.
   *
   * @param text - A valid JSON string.
   * @param reviver - A function that transforms the results.
   *                  This function is called for each member of the object.
   *                  If a member contains nested objects, the nested objects
   *                  are transformed before the parent object is.
   *
   * @returns An Outcome tuple, which includes:
   *           - The result of the operation, and
   *           - An error if the operation fails.
   *
   * @example
   *
   * ```
   * const invalidJSON = "{lorem:ipsum}";
   *
   * const parsed = JSON.parse(invalidJSON); // throws error
   * const [parsed, error] = StrictJSON.safeParse(invalidJSON); // this is fine!
   * ```
   */
  safeParse: (...params: Parameters<typeof JSON.parse>): Outcome<unknown> => {
    try {
      return [JSON.parse(...params), null];
    } catch (error) {
      return [null, error];
    }
  },

  /**
   * Serialises `Map` and `Set` types for JSON stringification.
   *
   * @param value - The value to be serialised.
   *
   * @returns A serialisable object if the value is a `Map` or `Set`,
   *          otherwise returns the original value.
   *
   * @example
   *
   * ```
   * const map = new Map([['key', 'value']]);
   * JSON.stringify(map) // No support for Map
   * JSON.stringify(map, StrictJSON.replace); // Supports Map!
   * ```
   */
  replace: (_: string, value: unknown): unknown => {
    if (value instanceof Map) {
      return {
        __STRICT_JSON_UNSERIALISABLE_DATA_TYPE__: "Map",
        value: Array.from(value.entries()),
      };
    } else if (value instanceof Set) {
      return {
        __STRICT_JSON_UNSERIALISABLE_DATA_TYPE__: "Set",
        value: Array.from(value),
      };
    }
    return value;
  },

  /**
   * Revives `Map` and `Set` types from JSON strings.
   * Must have been serialised using the matching `StrictJSON.replace` method.
   *
   * @param value - The value to be revived.
   *
   * @returns A `Map` or `Set` if the value was serialised as such,
   *          otherwise returns the original value.
   *
   * @example
   *
   * ```
   * JSON.parse(jsonString) // No support for Map or Set
   * JSON.parse(jsonString, StrictJSON.revive); // Supports Map and Set!
   * ```
   */
  revive: (_: string, value: unknown): unknown => {
    const isSerialisedMap =
      isObject(value) &&
      value.__STRICT_JSON_UNSERIALISABLE_DATA_TYPE__ === "Map";
    if (isSerialisedMap) {
      return new Map(value.value as [unknown, unknown][]);
    }

    const isSerialisedSet =
      isObject(value) &&
      value.__STRICT_JSON_UNSERIALISABLE_DATA_TYPE__ === "Set";
    if (isSerialisedSet) {
      return new Set(value.value as unknown[]);
    }
    return value;
  },
};

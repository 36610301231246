import { isUndefined } from "./isUndefined";

/**
 * Regular map with added niceties:
 *
 * - `getStrict` is like `get`, but it throws if the key is not found.
 * - optional config:
 *   - `sizeLimit`: if set, the map size is capped at this value.
 *     Older entries are deleted to make space.
 */
export class StrictMap<K, V> extends Map<K, V> {
  private readonly sizeLimit: number = Infinity;

  public constructor(entries?: [K, V][], options?: { sizeLimit: number }) {
    super(entries);
    if (options) {
      this.sizeLimit = options.sizeLimit;
    }
  }

  public getStrict(key: K): V {
    const value = super.get(key);

    if (isUndefined(value)) {
      throw new Error(`Map key not found: ${key}`);
    }

    return value;
  }

  public set(key: K, value: V): this {
    const isFull = this.size === this.sizeLimit;
    if (isFull) {
      const oldestEntryId: K = this.keys().next().value;
      this.delete(oldestEntryId);
    }

    return super.set(key, value);
  }
}

import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./dictationControls.module.scss";

export type DictationControlsProps = { children: ReactNode };

export const DictationControls = ({
  children,
}: DictationControlsProps): ReactElement => (
  <div
    className={classNames(
      styles.wrapper,
      createSelectorClassName("dictation-controls", "section")
    )}
  >
    {children}
  </div>
);

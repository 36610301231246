import type { FormState } from "./FormState";
import type { SupportedErrorCode } from "./SupportedErrorCode";
import type { Callback } from "@carescribe/types";
import type { Rule } from "@talktype/forms";
import type { Dispatch, SetStateAction, ChangeEventHandler } from "react";

import { normaliseWhitespace } from "@carescribe/utilities/src/normaliseWhitespace";

type CreateOnChangeHandler = Callback<
  {
    setForm: Dispatch<SetStateAction<FormState>>;
    validate: Rule<SupportedErrorCode>;
  },
  ChangeEventHandler<HTMLInputElement>
>;

export const createOnChangeHandler: CreateOnChangeHandler =
  ({ setForm, validate }) =>
  ({ currentTarget: { value } }) =>
    setForm({ value, error: validate(normaliseWhitespace(value)) });

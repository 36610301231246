import type { HeaderBarProps } from "@talktype/ui/src/HeaderBar";
import type { WindowsControlsProps } from "@talktype/ui/src/WindowsControls";
import type { ReactElement } from "react";

import { getPlatform } from "@carescribe/utilities/src/browser";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { HeaderBar as Component } from "@talktype/ui/src/HeaderBar";

import { WindowsControls } from "./WindowsControls";

export const HeaderBar = ({
  children,
  style,
  colour,
}: Pick<HeaderBarProps, "children" | "style"> &
  Pick<WindowsControlsProps, "colour">): ReactElement => {
  const platform = getPlatform();
  const context = getAppContext();
  const isMacDesktop = platform === "mac" && context === "desktop";
  const isWindowsDesktop = platform === "win" && context === "desktop";

  return (
    <Component isMacDesktop={isMacDesktop} style={style}>
      {children}

      {isWindowsDesktop && <WindowsControls colour={colour} />}
    </Component>
  );
};

import type { BeforeInstallPromptEvent } from "../types/BeforeInstallPromptEvent";
import type { CustomInstallPromptVisibility } from "../types/CustomInstallPromptVisibility";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/pwa");

export const requestBeforeInstallPromptEvent = action<
  void,
  "request-before-install-prompt-event"
>("request-before-install-prompt-event");

export const broadcastedBeforeInstallPromptEvent = action<
  BeforeInstallPromptEvent | null,
  "broadcasted-before-install-prompt-event"
>("broadcasted-before-install-prompt-event");

export const requestPromptNativeInstall = action<
  void,
  "request-prompt-native-install"
>("request-prompt-native-install");

export const requestDismissCustomInstallPrompt = action<
  void,
  "request-dismiss-custom-install-prompt"
>("request-dismiss-custom-install-prompt");

export const customInstallPromptMounted = action<
  void,
  "custom-install-prompt-mounted"
>("custom-install-prompt-mounted");

export const customInstallPromptUnmounted = action<
  void,
  "custom-install-prompt-unmounted"
>("custom-install-prompt-unmounted");

export const customInstallPromptVisibilityChanged = action<
  void,
  "custom-install-prompt-visibility-changed"
>("custom-install-prompt-visibility-changed");

export const requestCustomInstallPromptVisibility = action<
  void,
  "request-custom-install-prompt-visibility"
>("request-custom-install-prompt-visibility");

export const broadcastedCustomInstallPromptVisibility = action<
  CustomInstallPromptVisibility,
  "broadcasted-custom-install-prompt-visibility"
>("broadcasted-custom-install-prompt-visibility");

import type { QuitAndInstall } from "../types/QuitAndInstall";
import type { UpdateType } from "../types/UpdateType";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, put, takeEvery, call } from "redux-saga/effects";

import {
  getActivityStatus,
  activityStatusChanged,
} from "@carescribe/idle-detection";

import {
  updateReadyToInstall,
  updateError,
  requestInstallUpdate,
} from "./actions";

const performInstall = function* ({
  type,
  methods,
}: {
  type: UpdateType;
  methods: {
    browser: { quitAndInstall: QuitAndInstall };
    desktop: { quitAndInstall: QuitAndInstall } | null;
  };
}): SagaIterator<void> {
  const method = methods[type]?.quitAndInstall;

  if (!method) {
    yield put(updateError({ type, step: "install" }));
    return;
  }

  const [, error]: SagaReturnType<typeof method> = yield call(method);

  if (error) {
    yield put(updateError({ type, step: "install" }));
  }
};

/**
 * Quits and installs browser/desktop updates whenever:
 *
 * - request is received via the `requestInstallUpdate` action
 * - update is ready to install and user is idle/later becomes idle
 *
 * If there is an error, dispatches `updateError` with `step` of
 * `install`.
 */
export const manageQuitAndInstall = function* ({
  browser,
  desktop,
}: {
  browser: { quitAndInstall: QuitAndInstall };
  desktop: { quitAndInstall: QuitAndInstall } | null;
}): SagaIterator<void> {
  yield takeEvery(requestInstallUpdate, function* ({ payload: { type } }) {
    yield call(performInstall, { type, methods: { desktop, browser } });
  });

  yield takeEvery(updateReadyToInstall, function* ({ payload: { type } }) {
    const activityStatus: SagaReturnType<typeof getActivityStatus> = yield call(
      getActivityStatus
    );

    const isIdle = activityStatus === "idle";

    if (!isIdle) {
      yield take(activityStatusChanged);
    }

    yield call(performInstall, { type, methods: { desktop, browser } });
  });
};

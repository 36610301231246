import type { LineBreak } from "@talktype/types";

import { v4 as uuid } from "uuid";

import { newLineCharacter } from "@carescribe/slate";
import { mergeRight } from "@carescribe/utilities/src/fp";

import { defaultLeafProperties } from "./entities/defaultLeafProperties";

export const lineBreakNode = (
  marks?: Omit<Partial<LineBreak>, "text">
): LineBreak =>
  mergeRight(defaultLeafProperties, {
    type: "linebreak" as const,
    text: newLineCharacter,
    id: uuid(),
    ...(marks ?? {}),
  });

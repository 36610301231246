import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { wsMessage } from "@carescribe/transcriber-connection/src";
import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { handleMessage } from "./handleMessage";
import { logError } from "./log";

/**
 * Listen for Message
 *
 * Takes raw messages from the transcriber web socket and attempts to convert
 * them into JSON. In the case that invalid JSON has been received from the
 * transcriber, nothing should blow up, but an error is logged. This should
 * mean the app continues, providing the transcriber starts sending valid
 * messages again.
 */
export const listenForMessage = function* (): SagaIterator<void> {
  yield takeEvery(wsMessage, function* (message): SagaIterator<void> {
    const [parsedMessage, error]: SagaReturnType<typeof StrictJSON.safeParse> =
      yield call(StrictJSON.safeParse, message.payload.data);

    if (error) {
      yield call(
        logError,
        "unexpected unparsable format received from transcriber:",
        message
      );

      return;
    }

    if (parsedMessage) {
      yield call(handleMessage, parsedMessage);
    }
  });
};

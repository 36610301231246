import type { TrackedWakeLock } from "./types/TrackedWakeLock";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/screen-wake-lock");

export const requestTrackScreenWakeLock = action<
  TrackedWakeLock,
  "request-track-screen-wake-lock"
>("request-track-screen-wake-lock");

export const requestBroadcastScreenWakeLock = action<
  void,
  "request-broadcast-screen-wake-lock"
>("request-broadcast-screen-wake-lock");

export const broadcastedScreenWakeLock = action<
  TrackedWakeLock,
  "broadcasted-screen-wake-lock"
>("broadcasted-screen-wake-lock");

export const requestResetScreenWakeLock = action<
  void,
  "request-reset-screen-wake-lock"
>("request-reset-screen-wake-lock");

export const requestEnableScreenWakeLock = action<
  void,
  "request-enable-screen-wake-lock"
>("request-enable-screen-wake-lock");

export const requestDisableScreenWakeLock = action<
  void,
  "request-disable-screen-wake-lock"
>("request-disable-screen-wake-lock");

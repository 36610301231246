import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { preventDefault } from "@carescribe/utilities/src/sagas";

import { insertLineBreak } from "../../utils/insertHandlers";
import { editorKeyDown } from "../actions";
import { getEditor } from "../utils";

/**
 * Detects when the "Enter" and "Shift" keys are pressed together and inserts
 * a new line into the editor.
 */
export const setUpInsertLineBreak = function* (): SagaIterator<void> {
  yield takeEvery(editorKeyDown, function* ({ payload: event }) {
    if (!(event.key === "Enter" && event.shiftKey)) {
      return;
    }

    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);
    if (!editor) {
      return;
    }

    yield call(preventDefault, event);
    yield call(insertLineBreak, editor, { inProgress: false });
    return;
  });
};

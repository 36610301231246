import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";
import { dateFormats } from "@talktype/config";

import { setDateFormat } from "../reducers";

export const trackSetDateFormat = function* (): SagaIterator<void> {
  yield takeEvery(setDateFormat, function* ({ payload }) {
    yield put(
      requestTrackEvent({
        name: "Preferences Updated",
        data: {
          preferencesProperty: "date format",
          dateFormat:
            dateFormats.find((format) => format.value === payload)?.display ??
            "",
        },
      })
    );
  });
};

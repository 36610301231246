import type { QuitAndInstall } from "@carescribe/updater";

import { call } from "redux-saga/effects";

import { isObjectWithMessage } from "@carescribe/utilities/src/guards/isObjectWithMessage";

const reload = (): void => window.location.reload();

/**
 * Describes how to quit and install the latest version of the app in the
 * browser context.
 *
 * It simply calls `window.location.reload()`, which will cause the browser
 * to reload, as well as error handling for the rare event that this should
 * fail.
 */
export const quitAndInstall: QuitAndInstall = function* () {
  try {
    yield call(reload);
    return [null, null];
  } catch (error) {
    const message = isObjectWithMessage(error)
      ? error.message
      : "Unknown error";
    return [null, { message }];
  }
};

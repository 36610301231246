import type { EditorType } from "../editor";
import type { Range } from "slate";

import { headDef, lastDef } from "@carescribe/utilities/src/fp";

import { getLeavesWithProperty } from "./getLeavesWithProperty";

/**
 * Returns the range of the in-progress text.
 */
export const getInProgressRange = (editor: EditorType): Range | null => {
  const inProgressLeaves = getLeavesWithProperty(editor, "inProgress", true);

  const head = headDef(inProgressLeaves, null);
  const last = lastDef(inProgressLeaves, null);

  if (!head || !last) {
    return null;
  }

  const anchor = { path: head[1], offset: 0 };
  const focus = { path: last[1], offset: last[0].text.length };

  return { anchor, focus };
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";
import type { DocumentFontSize } from "@talktype/types/src/DocumentFontSize";

import { clamp } from "@carescribe/utilities/src/fp";

import { documentFontSize } from "@talktype/config/src/documentFontSize";

type IncrementDocumentFontSize = (
  state: Preferences,
  action: PayloadAction<DocumentFontSize>
) => void;

/**
 * Handles the setting of document font size.
 */
export const incrementDocumentFontSize: IncrementDocumentFontSize = (
  state,
  { payload }
) => {
  state.document.fontSize = clamp(
    documentFontSize.min,
    documentFontSize.max,
    state.document.fontSize + payload
  );
};

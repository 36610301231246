import type { taskRef } from "./trackTask";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { delay, put } from "redux-saga/effects";

import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

import { requestFinaliseDictation } from "@talktype/actions";

export const timeoutCommand = function* (
  task: SagaReturnType<typeof taskRef>["payload"]
): SagaIterator<void> {
  yield delay(secondsToMilliseconds(task.current.deadline));

  yield put(requestFinaliseDictation());
};

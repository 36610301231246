import type { Rule } from "../types";
import type { Callback } from "@carescribe/types";

import { errors } from "@talktype/errors";

type InputWithinMaxLength = Callback<
  number,
  Rule<typeof errors.inputExceedsMaxLength.code>
>;

/**
 * Errors if the input length exceeds the limit.
 */
export const inputWithinMaxLength: InputWithinMaxLength = (length) => (entry) =>
  entry.length > length ? errors.inputExceedsMaxLength.code : null;

import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { putPayloadSaga } from "@carescribe/utilities/src/sagas";

import { requestProcessResult } from "@talktype/actions";
import { receivedResult } from "@talktype/transcriber/src/sagas/actions";

/**
 * Forwards new transcriber results to the results package for processing.
 */
export const processResults = function* (): SagaIterator<void> {
  yield takeEvery(receivedResult, putPayloadSaga(requestProcessResult));
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { DictationLanguage, Preferences } from "@talktype/types";

type SetDictationLanguage = (
  state: Preferences,
  action: PayloadAction<DictationLanguage>
) => void;

/**
 * Handles the setting of dictationLanguage.
 */
export const setDictationLanguage: SetDictationLanguage = (
  state,
  { payload }
) => {
  state.dictationLanguage = payload;
};

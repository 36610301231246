import type { Tokens } from "../../types/Tokens";
import type { SagaIterator } from "redux-saga";

import {
  type SagaReturnType,
  select,
  put,
  take,
  call,
} from "redux-saga/effects";

import {
  timeIsBeforeTime,
  millisecondsToSeconds,
  toSeconds,
  secondsToMilliseconds,
} from "@carescribe/utilities/src/timing";

import { setTokens } from "../../reducer";
import { selectTokens } from "../../reducer/selectors/selectTokens";
import { requestRefreshAccessToken } from "../actions";

/**
 * Get Tokens
 *
 * Return the current tokens, or refresh them if they are expired. If no tokens
 * are available, return null.
 */
export const getTokens = function* (): SagaIterator<Tokens | null> {
  const tokens: SagaReturnType<typeof selectTokens> = yield select(
    selectTokens
  );

  if (!tokens) {
    return null;
  }

  // Allow a one minute buffer to refresh the token. We wouldn't expect it to be
  // necessary, but it's a good safety net.
  const inOneMinute = millisecondsToSeconds(
    Date.now() + secondsToMilliseconds(toSeconds(60))
  );

  const refreshRequired: SagaReturnType<typeof timeIsBeforeTime> = yield call(
    timeIsBeforeTime,
    tokens.expiresAt,
    inOneMinute
  );

  if (!refreshRequired) {
    return tokens;
  }

  yield put(requestRefreshAccessToken(tokens));
  const { payload: refreshedTokens }: SagaReturnType<typeof setTokens> =
    yield take(setTokens);

  return refreshedTokens;
};

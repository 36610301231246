import type { Channel, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { actionChannel, call, take, takeEvery } from "redux-saga/effects";

import {
  toggleDictationId,
  trayMenuItems,
} from "@talktype/config/src/trayMenuItems";
import {
  dictationStarted,
  startDictating,
  stopDictating,
} from "@talktype/editor";
import activeIconColour from "@talktype/icons/src/tray-icon-active--colour.png";
import activeIconPlain from "@talktype/icons/src/tray-icon-active--plain.png";
import inactiveIconColour from "@talktype/icons/src/tray-icon-inactive--colour.png";
import inactiveIconPlain from "@talktype/icons/src/tray-icon-inactive--plain.png";
import {
  loginComplete,
  logoutComplete,
} from "@talktype/user/src/sagas/actions";
import { getIpc } from "@talktype/utilities/src/getIpc";

import { trayAdded } from "./actions";

// The dictation toggle button should always appear as the first item
const dictationMenuItemIndex = 0;

/**
 * Register Dictation Tray Item
 *
 * - Awaits for the tray to be added
 * - Adds the start dictation item to the tray when the user logs in
 * - Updates the tray icon and menu item when dictation is started or stopped
 * - Removes the start dictation item when the user logs out
 */
export const registerDictationTrayItem = function* (): SagaIterator<void> {
  // The tray can take time to be added, so we must capture loginComplete
  // actions that occur before it is complete. Without this, we are at the
  // mercy of a race between the tray being added and the user logging in.
  const loginCompleteChannel: Channel<SagaReturnType<typeof loginComplete>> =
    yield actionChannel(loginComplete);

  yield take(trayAdded);

  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  const addItem = ipc?.system?.tray?.addItem?.v1;
  const removeItem = ipc?.system?.tray?.removeItem?.v1;
  const updateItem = ipc?.system?.tray?.updateItem?.v1;
  const setIcon = ipc?.system?.tray?.setIcon?.v1;
  const getPlatform = ipc?.system?.process?.getPlatform?.v1;

  if (!addItem || !removeItem || !updateItem || !setIcon || !getPlatform) {
    return;
  }

  const platform: SagaReturnType<typeof getPlatform> = yield call(getPlatform);

  const [inactiveIcon, activeIcon] =
    platform === "darwin"
      ? [inactiveIconPlain, activeIconPlain]
      : [inactiveIconColour, activeIconColour];

  yield takeEvery(startDictating, function* () {
    yield call(updateItem, [toggleDictationId, trayMenuItems.stopDictation]);
  });

  yield takeEvery(dictationStarted, function* () {
    yield call(setIcon, activeIcon);
  });

  yield takeEvery(stopDictating, function* () {
    yield call(setIcon, inactiveIcon);
    yield call(updateItem, [toggleDictationId, trayMenuItems.startDictation]);
  });

  yield takeEvery(loginCompleteChannel, function* () {
    yield call(
      addItem,
      [toggleDictationId, trayMenuItems.startDictation],
      dictationMenuItemIndex
    );
  });

  yield takeEvery(logoutComplete, function* () {
    yield call(removeItem, toggleDictationId);
  });
};

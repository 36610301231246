import type { BrowserOptions } from "@sentry/react";

import { init as electronInit } from "@sentry/electron/renderer";
import {
  init as reactInit,
  captureConsoleIntegration,
  replayIntegration,
} from "@sentry/react";

import { getIsDevelopment } from "@carescribe/utilities/src/dev/getIsDevelopment";
import { isElectronEnvironment } from "@carescribe/utilities/src/isElectronEnvironment";

import { filterAnalyticsBeforeSend } from "./utils/filterAnalytics";
import { log, logWarning } from "./utils/log";

/**
 * Initialises Sentry for Single Page Applications (SPAs).
 *
 * This function configures Sentry based on the running environment. It supports
 * both web and Electron environments.
 *
 * - Requires `dsn` and `environment` for setup.
 *   If either is missing, setup is skipped, and a warning is logged.
 * - Tags errors with `context: spa`.
 * - Console errors are captured and forwarded to Sentry.
 *
 * Web Environment
 * - Utilises `init` from `@sentry/react`.
 *
 * Electron Environment
 * - This approach forwards errors to the main process for handling,
 *   bypassing the need for `dsn` and `environment` config in the SPA.
 *
 * @param initialOptions - Initialisation options for Sentry.
 *
 * @example
 * initSpa({ dsn: "__DSN__", environment: "development" });
 */
export const initSpa = (initialOptions: BrowserOptions): void => {
  const initialTags =
    initialOptions.initialScope && "tags" in initialOptions.initialScope
      ? initialOptions.initialScope.tags
      : {};

  const options: BrowserOptions = {
    ...initialOptions,

    // Capture 50% of all sessions
    replaysSessionSampleRate: 0.5,

    // Capture up to one minute of all errored sessions
    replaysOnErrorSampleRate: 1,

    integrations: [
      captureConsoleIntegration({ levels: ["error"] }),
      replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        mask: ["[data-hj-suppress='true']"],
        // Allow SVG icons to be captured
        unblock: ["svg"],
      }),
    ],
    initialScope: {
      tags: { ...initialTags, context: "spa" },
    },
    beforeSend: filterAnalyticsBeforeSend,
  };

  if (!(options.dsn && options.environment)) {
    if (!getIsDevelopment()) {
      logWarning("SPA | Missing Sentry Config, skipping setup");
    }
    return;
  }

  log(`SPA | Setting up Sentry with options: ${JSON.stringify(options)}`);

  isElectronEnvironment()
    ? electronInit(options, reactInit)
    : reactInit(options);
};

import type {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEventHandler,
  ReactElement,
} from "react";

import { CaretDown } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { LogoIcon } from "@talktype/icons/src/LogoIcon";

import styles from "./logoActionButton.module.scss";
import { messages } from "./messages";
import { IconContainer } from "../IconContainer";

type LogoActionButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  onClick: MouseEventHandler;
};

export const LogoActionButton = (
  buttonProps: LogoActionButtonProps
): ReactElement => (
  <button
    {...buttonProps}
    className={classNames(
      styles.button,
      createSelectorClassName("logo-action-button", "interactive")
    )}
    aria-label={messages.label}
  >
    <IconContainer className={styles.logo}>
      <LogoIcon />
    </IconContainer>

    <IconContainer className={styles.caret}>
      <CaretDown />
    </IconContainer>
  </button>
);

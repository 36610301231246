import type { AppDispatch, RootState } from "@talktype/store";
import type { DrawerPage } from "@talktype/types";
import type { DrawerProps } from "@talktype/ui/src/Drawer";
import type { ReactNode } from "react";

import { connect } from "react-redux";

import { selectListingDisplay } from "@talktype/listing/src/reducer/selectors/selectListingDisplay";
import { closeDrawer } from "@talktype/preferences";
import {
  selectDrawerIsOpen,
  selectDrawerPage,
} from "@talktype/preferences/src/reducers/";
import { Drawer as Component } from "@talktype/ui/src/Drawer";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { CustomWords } from "./CustomWords";
import { DictationHistory } from "./DictationHistory";
import { Shortcuts } from "./Shortcuts";
import { VoiceCommands } from "./VoiceCommands";
import { VoiceCommandsSearch } from "./VoiceCommandsSearch";

const drawerPages: Record<DrawerPage, ReactNode> = {
  voiceCommands: <VoiceCommands />,
  shortcuts: <Shortcuts />,
  customWords: <CustomWords />,
  history: <DictationHistory />,
};

const mapStateToProps = (
  state: RootState
): Pick<DrawerProps, "page" | "children" | "isOpen" | "headerContent"> => {
  const page = selectDrawerPage(state);

  return {
    page,
    children: page ? drawerPages[page] : null,
    headerContent:
      page === "voiceCommands" ? (
        <VoiceCommandsSearch {...selectListingDisplay(state)} />
      ) : null,
    isOpen: selectDrawerIsOpen(state),
  };
};

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<DrawerProps, "onClose"> => ({
  onClose: (event) =>
    dispatch(
      closeDrawer({
        interactionMethod: getInteractionMethodFromEvent(event),
        interactionLocation: "close button",
      })
    ),
});

export const Drawer = connect(mapStateToProps, mapDispatchToProps)(Component);

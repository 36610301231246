import type { SagaIterator } from "redux-saga";

import { call } from "redux-saga/effects";

import { setUpIdleDetection } from "@carescribe/idle-detection";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { USER_CONSIDERED_IDLE_AFTER_SECONDS } from "../env";

const threshold = toSeconds(parseInt(USER_CONSIDERED_IDLE_AFTER_SECONDS));

export const configureIdleDetection = function* (): SagaIterator<void> {
  yield call(setUpIdleDetection, { threshold });
};

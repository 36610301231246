import type { SocketEvent } from "../../types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take, takeEvery } from "redux-saga/effects";

import { actionType } from "@carescribe/utilities/src/actionType";
import { assertUnreachable } from "@carescribe/utilities/src/types";

import { close } from "./close";
import { log, createSocketChannel } from "../../utils";
import {
  wsOpen,
  wsClose,
  wsMessage,
  transcriberSocketConnected,
  transcriberSocketClosed,
  transcriberSocketCreated,
  wsError,
} from "../actions";

const handleSocketEvent = function* (
  socket: WebSocket,
  event: SocketEvent
): SagaIterator<void> {
  switch (event.type) {
    case actionType(wsOpen):
      return yield put(transcriberSocketConnected(socket));

    case actionType(wsMessage):
    case actionType(wsError):
      return yield put(event);

    case actionType(wsClose):
      yield call(close, event.payload);
      return;

    default:
      assertUnreachable(event);
  }
};

export const setUpCreation = function* (): SagaIterator<void> {
  yield takeEvery(
    transcriberSocketCreated,
    function* ({ payload: socket }): SagaIterator<void> {
      yield call(log, "Creating transcriber socket channel");

      const channel: SagaReturnType<typeof createSocketChannel> = yield call(
        createSocketChannel,
        socket
      );

      yield takeEvery(channel, handleSocketEvent, socket);

      yield take(transcriberSocketClosed);

      yield call(log, "Closing transcriber socket channel");
      yield call([channel, "close"]);
    }
  );
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take, takeEvery } from "redux-saga/effects";

import { setNativeInstallPromptResult } from "../../state";
import { logError } from "../../utils/log";
import {
  requestBeforeInstallPromptEvent,
  broadcastedBeforeInstallPromptEvent,
  requestPromptNativeInstall,
} from "../actions";

/**
 * Listens for requests to show the native install prompt and:
 * - logs an error if a beforeInstallPromptEvent is not available
 * - shows the prompt if a beforeInstallPromptEvent is available
 * - saves the result of the prompt
 */
export const manageNativeInstallPrompt = function* (): SagaIterator<void> {
  yield takeEvery(requestPromptNativeInstall, function* () {
    yield put(requestBeforeInstallPromptEvent());
    const {
      payload: beforeInstallPromptEvent,
    }: SagaReturnType<typeof broadcastedBeforeInstallPromptEvent> = yield take(
      broadcastedBeforeInstallPromptEvent
    );

    if (!beforeInstallPromptEvent) {
      yield call(logError, "Native install prompt is not available.");
      return;
    }

    const result: SagaReturnType<typeof beforeInstallPromptEvent.prompt> =
      yield call([beforeInstallPromptEvent, beforeInstallPromptEvent.prompt]);

    yield put(setNativeInstallPromptResult(result));
  });
};

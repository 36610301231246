import type { ReactElement } from "react";

import React from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { noOp } from "@carescribe/utilities/src/fp";

import styles from "./screenReaderOnly.module.scss";

export type ScreenReaderOnlyProps<Tag extends keyof JSX.IntrinsicElements> = {
  tag: Tag;
} & JSX.IntrinsicElements[Tag];

/**
 * Screen Reader Only
 *
 * Renders an element that is only for screen readers, and will not be seen
 * visually. Pass any props needed by that element in as props to this one.
 *
 * @example
 * return (
 *   <ScreenReaderOnly
 *     tag="h1"
 *     className={styles.title}
 *   >
 *     {messages.title}
 *   </ScreenReaderOnly>
 * );
 */
export const ScreenReaderOnly = <Tag extends keyof JSX.IntrinsicElements>({
  children,
  tag,
  className,
  ...props
}: ScreenReaderOnlyProps<Tag>): ReactElement =>
  React.createElement(
    tag,
    {
      className: classNames(styles.element, className ?? ""),
      /**
       * Click events on screen reader only elements must have an `onClick`
       * listener defined.
       *
       * Otherwise, for unknown reasons, Electron may pick up double-clicks if
       * originating from a draggable window control region and toggle
       * maximising.
       */
      onClick: noOp,
      ...props,
    },
    children
  );

/**
 * ### Entries
 *
 * Add typing support to the keys of objects when that is not available when
 * using `Object.entries`.
 *
 * `Object.entries` returns the first item in the nested arrays as a string even
 * when the type of the key in the object itself was typed as something else.
 *
 * @example
 *
 * type State = "idle" | "busy" | "pending";
 * const myOb: Record<State, string> = {
 *   "idle": "I'm idle.",
 *   "busy": "I'm busy.",
 *   "pending": "I will tell you in a minute."
 * };
 *
 * const myEntries = Object.entries();
 * // [string, string][]
 *
 * const myBetterEntries = entries(myOb);
 * // [State, string][]
 */
export const entries = <Key extends string, Value>(object: {
  [s in Key]?: Value;
}): [Key, Value][] => Object.entries(object) as [Key, Value][];

export const keys = <Key extends string>(object: {
  [s in Key]?: unknown;
}): Key[] => Object.keys(object) as [Key];

export const fromEntries = <Key extends string, Value>(
  entries: [Key, Value][]
): Record<Key, Value> => Object.fromEntries(entries) as Record<Key, Value>;

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";
import { match, P } from "ts-pattern";

import { handleError } from "./responseHandlers/handleError";
import { handleTokensResponse } from "./responseHandlers/handleTokensResponse";
import { handleUnauthorized } from "./responseHandlers/handleUnauthorized";
import { handleUnknown } from "./responseHandlers/handleUnknown";
import { REFRESH_URL } from "../../config/urls";
import { validTokensResponse } from "../../guards/isTokensResponse";
import { validUnauthorizedResponse } from "../../guards/isUnauthorisedResponse";
import { requestRefreshAccessToken } from "../actions";
import { pkceRequest } from "../utils/pkceRequest";

/**
 * Set Up Refresh Access Token
 *
 * Refresh the access token when requested.
 */
export const setUpRefreshAccessToken = function* (): SagaIterator<void> {
  yield takeEvery(requestRefreshAccessToken, function* ({ payload: tokens }) {
    const response: SagaReturnType<typeof pkceRequest> = yield call(
      pkceRequest,
      {
        pathname: REFRESH_URL,
        params: {
          refresh_token: tokens.refreshToken,
          grant_type: "refresh_token",
        },
      }
    );

    yield call(
      match(response)
        .with({ error: P.string }, handleError)
        .with({ data: validTokensResponse }, handleTokensResponse)
        .with({ data: validUnauthorizedResponse }, handleUnauthorized)
        .otherwise(handleUnknown)
    );
  });
};

import { useLayoutEffect, type RefObject } from "react";

import { useObservedSize } from "./useObservedSize";
import { keepInViewport } from "../utils/keepInViewport";

/**
 * Ensures an element remains within the horizontal bounds of the viewport
 * by adjusting its position using the `translate` property.
 *
 * @param ref - Reference to the target element
 *
 * Optimised to only re-calculate when the element takes up space on the DOM.
 *
 * @example
 * const ref = useRef(null);
 * useKeepInViewport(ref);
 */
export const useKeepInViewport = (ref: RefObject<HTMLElement>): void => {
  const { width, height } = useObservedSize(ref);
  const takesUpSpace = width > 0 && height > 0;

  useLayoutEffect(() => {
    if (!takesUpSpace) {
      return;
    }

    const positionElement = (): void => keepInViewport(ref);
    positionElement();

    window.addEventListener("resize", positionElement);
    return () => window.removeEventListener("resize", positionElement);
  }, [ref, takesUpSpace]);
};

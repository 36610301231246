import type { PayloadAction } from "@reduxjs/toolkit";
import type { SystemState } from "@talktype/types";

type SetAppVersion = (
  state: SystemState,
  action: PayloadAction<string | null>
) => void;

export const setAppVersion: SetAppVersion = (state, { payload }) => {
  state.appVersion = payload;
};

import type { RootState } from "@talktype/store";
import type { VersionDescriptionProps } from "@talktype/ui/src/PreferencesModal/VersionDescription";

import { connect } from "react-redux";

import { selectAppVersion } from "@talktype/system/src/reducers/system/selectors/selectAppVersion";
import { selectBuildId } from "@talktype/system/src/reducers/system/selectors/selectBuildId";
import { VersionDescription as Component } from "@talktype/ui/src/PreferencesModal/VersionDescription";

type StateProps = Pick<VersionDescriptionProps, "appVersion" | "buildId">;

const mapStateToProps = (state: RootState): StateProps => ({
  appVersion: selectAppVersion(state),
  buildId: selectBuildId(state),
});

export const VersionDescription = connect(mapStateToProps)(Component);

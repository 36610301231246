import { createSlice } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";

import { initialState } from "./initialState";
import * as reducers from "./reducers";

// Enable Immer to support Map and Set
enableMapSet();

export const { reducer, actions } = createSlice({
  name: "dictateToApp",
  initialState,
  reducers,
});

export const { upsertDictationHistoryEntry } = actions;

export * from "./selectors";

import type { SubscriptionState } from "./trackActiveAppSubscriptionState";
import type { AppMetadata } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, take, takeEvery, select } from "redux-saga/effects";

import { setActiveApp } from "@talktype/system/src/reducers/system";
import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";
import { getIpc } from "@talktype/utilities";
import { appIsEqual } from "@talktype/utilities/src/appIsEqual";

import {
  setActiveAppTracking,
  subscribedToActiveApp,
  unsubscribedFromActiveApp,
  requestActiveAppSubscriptionState,
  broadcastActiveAppSubscriptionState,
} from "./actions";
import { createActiveAppChangeChannel } from "./utils/createActiveAppChangeChannel";

function* getActiveAppSubscriptionState(): SagaIterator<SubscriptionState> {
  yield put(requestActiveAppSubscriptionState());
  const {
    payload: state,
  }: SagaReturnType<typeof broadcastActiveAppSubscriptionState> = yield take(
    broadcastActiveAppSubscriptionState
  );

  return state;
}

/**
 * Listens to changes in the active app and dispatches the `setActiveApp`
 * action with the new active app.
 */
export const manageActiveAppTracking = function* (): SagaIterator<void> {
  yield takeEvery(setActiveAppTracking, function* ({ payload: enabled }) {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    if (!ipc) {
      return;
    }

    switch (enabled) {
      case true:
        {
          const {
            onUnsubscribe,
          }: SagaReturnType<typeof getActiveAppSubscriptionState> = yield call(
            getActiveAppSubscriptionState
          );

          if (onUnsubscribe) {
            yield call(onUnsubscribe);
          }

          const activeAppEventChannel: SagaReturnType<
            typeof createActiveAppChangeChannel
          > = yield call(createActiveAppChangeChannel, ipc);

          yield put(
            subscribedToActiveApp({
              onUnsubscribe: function* (): SagaIterator<void> {
                yield call(activeAppEventChannel.close);
              },
            })
          );

          yield takeEvery(
            activeAppEventChannel,
            function* (activeApp: AppMetadata) {
              const previousActiveApp: SagaReturnType<typeof selectActiveApp> =
                yield select(selectActiveApp);

              const isEqual: SagaReturnType<typeof appIsEqual> = yield call(
                appIsEqual,
                previousActiveApp,
                activeApp
              );

              if (isEqual) {
                return;
              }

              /**
               * Why override if `isSelf` is true?
               * In development mode, the app name is reported as "Electron"
               * instead of TalkType which can lead to buggy behaviour.
               */
              const newActiveApp = activeApp.isSelf
                ? { name: "TalkType", isSelf: true }
                : activeApp;

              yield put(setActiveApp(newActiveApp));
            }
          );
        }
        return;

      case false: {
        const {
          onUnsubscribe,
        }: SagaReturnType<typeof getActiveAppSubscriptionState> = yield call(
          getActiveAppSubscriptionState
        );

        if (!onUnsubscribe) {
          return;
        }

        yield call(onUnsubscribe);

        yield put(unsubscribedFromActiveApp());

        yield put(setActiveApp({ name: "TalkType", isSelf: true }));
      }
    }
  });
};

import type { ReactElement } from "react";

export const WindowsMinimiseIcon = (): ReactElement => (
  <svg
    width="46"
    height="38"
    viewBox="0 0 46 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9697 19.5322C18.1137 19.6763 18.2943 19.752 18.498 19.752H28.5C28.7038 19.752 28.8843 19.6763 29.0283 19.5322C29.1723 19.3882 29.248 19.2077 29.248 19.0039C29.248 18.8009 29.1729 18.621 29.0301 18.4773C28.8872 18.3295 28.7062 18.25 28.5 18.25H18.498C18.2918 18.25 18.1109 18.3295 17.968 18.4773C17.8251 18.621 17.75 18.8009 17.75 19.0039C17.75 19.2077 17.8257 19.3882 17.9697 19.5322Z"
      fill="currentcolor"
      stroke="currentcolor"
      strokeWidth="0.5"
    />
  </svg>
);

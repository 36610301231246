import type { FormState } from "./FormState";
import type { Validators } from "./Validators";
import type { Callback, Void } from "@carescribe/types";
import type { CustomShortcut } from "@talktype/types";
import type { Dispatch, FormEventHandler, SetStateAction } from "react";

import { normaliseWhitespace } from "@carescribe/utilities/src/normaliseWhitespace";
import { entries, fromEntries } from "@carescribe/utilities/src/typedObject";

type CreateOnSubmitHandler = Callback<
  {
    form: FormState;
    validators: Validators;
    initialValues: Record<keyof FormState, string>;
    onValidSubmit: (partialShortcut: Omit<CustomShortcut, "id">) => void;
    setForm: Dispatch<SetStateAction<FormState>>;
    resetForm: Void;
  },
  FormEventHandler
>;

export const createOnSubmitHandler: CreateOnSubmitHandler =
  ({ form, initialValues, setForm, resetForm, validators, onValidSubmit }) =>
  (event) => {
    event.preventDefault();

    const updatedForm: FormState = fromEntries(
      entries(form).map(([field, { value }]) => {
        const initialValue = initialValues[field];
        const validator = validators[field];
        return [
          field,
          {
            value,
            error: validator.onSubmit(normaliseWhitespace(value), {
              initialValue,
            }),
          },
        ];
      })
    );

    const hasErrors = Object.values(updatedForm).some(({ error }) => !!error);

    if (hasErrors) {
      setForm(updatedForm);

      return;
    }

    onValidSubmit({
      input: normaliseWhitespace(form.input.value),
      output: normaliseWhitespace(form.output.value),
    });

    resetForm();
  };

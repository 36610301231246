import type { ErrorMessage } from "../types/Responses";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";

import { isSocketMessage } from "./isSocketMessage";

export const isErrorMessage = (data: unknown): data is ErrorMessage =>
  isSocketMessage(data) &&
  data.responseType === "error" &&
  hasPropertyOfType(data, "payload", "object") &&
  hasPropertyOfType(data.payload, "code", "string") &&
  hasPropertyOfType(data.payload, "reason", "string");

import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import * as reducers from "./reducers";

export const { reducer, actions } = createSlice({
  name: "pkce",
  initialState,
  reducers,
});

export const { setPkceCodePair, setTokens, setPkce, addAdditionalParams } =
  actions;

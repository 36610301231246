import type { AssignedResult, Result } from "@talktype/types";

import { mergeRight } from "@carescribe/utilities/src/fp";

import { textTranscriptSegment } from "@talktype/editor/src/utils/textTranscriptSegment";

/**
 * Converts a result transcript from string to a segment which includes marks.
 */
export const segmentTranscript = (result: AssignedResult): Result =>
  mergeRight(result, {
    transcript: [textTranscriptSegment(result.transcript)],
  });

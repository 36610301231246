import type { ReactElement } from "react";

import { DictateAnywhereExplainer } from "@talktype/icons/src/DictateAnywhereExplainer";

import styles from "./dictationHistoryEmpty.module.scss";
import { messages } from "./messages";
import { EmptyPageHelp } from "../EmptyPageHelp";

export const DictationHistoryEmpty = (): ReactElement => (
  <EmptyPageHelp
    title={messages.title}
    body={messages.body}
    decoration={<DictateAnywhereExplainer className={styles.decoration} />}
  />
);

import type { PkceReducer } from "../../types/PkceReducer";
import type { PkceState } from "../../types/PkceState";

export const setPkce: PkceReducer<Partial<PkceState>> = (
  state,
  { payload: newState }
) => ({
  ...state,
  ...newState,
});

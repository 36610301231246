import type { FormattingBarButtonDefinition } from "@talktype/types";
import type { ReactNode } from "react";

import {
  ArrowArcLeft,
  ArrowArcRight,
  CopySimple,
  FileX,
  TextB,
  TextItalic,
  TextUnderline,
} from "@phosphor-icons/react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { toSeconds } from "@carescribe/utilities/src/timing";

import { AutoPunctuation } from "@talktype/icons/src/AutoPunctuation";

import { messages } from "./messages";

type Spacer = { type: "spacer"; id: string };

type Node = { type: "node"; id: string; node: ReactNode };

export type Item = FormattingBarButtonDefinition | Spacer | Node;

export const bold: FormattingBarButtonDefinition = {
  id: "bold",
  type: "button",
  toggleable: true,
  buttonProps: {
    Icon: TextB,
    className: createSelectorClassName("bold", "interactive"),
  },
  tooltipProps: { messages: { visible: messages.bold } },
};

export const italic: FormattingBarButtonDefinition = {
  id: "italic",
  type: "button",
  toggleable: true,
  buttonProps: {
    Icon: TextItalic,
    className: createSelectorClassName("italic", "interactive"),
  },
  tooltipProps: { messages: { visible: messages.italic } },
};

export const underline: FormattingBarButtonDefinition = {
  id: "underline",
  type: "button",
  toggleable: true,
  buttonProps: {
    Icon: TextUnderline,
    className: createSelectorClassName("underline", "interactive"),
  },
  tooltipProps: { messages: { visible: messages.underline } },
};

export const undo: FormattingBarButtonDefinition = {
  id: "undo",
  type: "button",
  toggleable: false,
  buttonProps: {
    Icon: ArrowArcLeft,
    className: createSelectorClassName("undo", "interactive"),
  },
  tooltipProps: { messages: { visible: messages.undo } },
};

export const redo: FormattingBarButtonDefinition = {
  id: "redo",
  type: "button",
  toggleable: false,
  buttonProps: {
    Icon: ArrowArcRight,
    className: createSelectorClassName("redo", "interactive"),
  },
  tooltipProps: { messages: { visible: messages.redo } },
};

export const copy: FormattingBarButtonDefinition = {
  id: "copy",
  type: "button",
  toggleable: false,
  buttonProps: {
    Icon: CopySimple,
    className: createSelectorClassName("copy", "interactive"),
  },
  tooltipProps: {
    messages: {
      visible: messages.copy,
      confirming: { content: messages.copied, duration: toSeconds(2) },
    },
    // Match width of longer message to prevent layout shift
    minWidth: 145,
  },
};

export const clear: FormattingBarButtonDefinition = {
  id: "clear",
  type: "button",
  toggleable: false,
  buttonProps: {
    Icon: FileX,
    className: createSelectorClassName("clear", "interactive"),
  },
  tooltipProps: { messages: { visible: messages.clear } },
};

export const punctuation: FormattingBarButtonDefinition = {
  id: "punctuation",
  type: "button",
  toggleable: true,
  buttonProps: {
    Icon: AutoPunctuation,
    className: createSelectorClassName("punctuation", "interactive"),
  },
  tooltipProps: { messages: { visible: messages.punctuation } },
};

import type { ReactElement } from "react";

import { WindowsCloseIcon } from "@talktype/icons/src/WindowsCloseIcon";
import { WindowsMaximiseIcon } from "@talktype/icons/src/WindowsMaximiseIcon";
import { WindowsMinimiseIcon } from "@talktype/icons/src/WindowsMinimiseIcon";
import { WindowsUnmaximiseIcon } from "@talktype/icons/src/WindowsUnmaximiseIcon";

import { messages } from "./messages";
import styles from "./windowsControls.module.scss";
import { IconContainer } from "../IconContainer";
import { WindowsControlButton } from "../WindowsControlButton";

export type WindowsControlsProps = {
  colour: "neutral" | "brand";
  maximised: boolean;
  onMinimise: () => void;
  onMaximise: () => void;
  onUnmaximise: () => void;
  onClose: () => void;
};

export const WindowsControls = ({
  colour,
  maximised,
  onMinimise,
  onMaximise,
  onUnmaximise,
  onClose,
}: WindowsControlsProps): ReactElement => (
  <div className={styles.container}>
    <WindowsControlButton
      onClick={onMinimise}
      colour={colour}
      aria-label={messages.minimiseWindow}
    >
      <IconContainer className={styles.icon}>
        <WindowsMinimiseIcon />
      </IconContainer>
    </WindowsControlButton>

    {maximised ? (
      <WindowsControlButton
        onClick={onUnmaximise}
        colour={colour}
        aria-label={messages.restoreWindow}
      >
        <IconContainer className={styles.icon}>
          <WindowsUnmaximiseIcon />
        </IconContainer>
      </WindowsControlButton>
    ) : (
      <WindowsControlButton
        onClick={onMaximise}
        colour={colour}
        aria-label={messages.maximiseWindow}
      >
        <IconContainer className={styles.icon}>
          <WindowsMaximiseIcon />
        </IconContainer>
      </WindowsControlButton>
    )}

    <WindowsControlButton
      onClick={onClose}
      colour={colour}
      aria-label={messages.closeWindow}
    >
      <IconContainer className={styles.icon}>
        <WindowsCloseIcon />
      </IconContainer>
    </WindowsControlButton>
  </div>
);

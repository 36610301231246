import { createEditor as slateCreateEditor } from "slate";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";

import { enablePIIDataScrubbing } from "@carescribe/slate";
import { pipe } from "@carescribe/utilities/src/fp";

const withPlugins = pipe(withReact, withHistory);

export type EditorType = ReturnType<typeof withPlugins>;

export const createEditor = (): EditorType => {
  enablePIIDataScrubbing();

  return withPlugins(slateCreateEditor());
};

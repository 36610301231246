import { entries, keys } from "@carescribe/utilities/src/typedObject";

// Vocabulary regarding expressing numbers in English

const cardinalUnits = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
} as const;

const ordinalUnits = {
  zeroth: 0,
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  fifth: 5,
  sixth: 6,
  seventh: 7,
  eighth: 8,
  ninth: 9,
} as const;

export const digits = {
  ...ordinalUnits,
  ...cardinalUnits,
} as const;

const cardinalTeens = {
  ten: 10,
  eleven: 11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  fifteen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
  nineteen: 19,
} as const;

const ordinalTeens = {
  tenth: 10,
  eleventh: 11,
  twelfth: 12,
  thirteenth: 13,
  fourteenth: 14,
  fifteenth: 15,
  sixteenth: 16,
  seventeenth: 17,
  eighteenth: 18,
  nineteenth: 19,
} as const;

export const teens = {
  ...cardinalTeens,
  ...ordinalTeens,
} as const;

const cardinalTens = {
  twenty: 20,
  thirty: 30,
  forty: 40,
  fifty: 50,
  sixty: 60,
  seventy: 70,
  eighty: 80,
  ninety: 90,
} as const;

const ordinalTens = {
  twentieth: 20,
  thirtieth: 30,
  fortieth: 40,
  fiftieth: 50,
  sixtieth: 60,
  seventieth: 70,
  eightieth: 80,
  ninetieth: 90,
} as const;

export const tens = {
  ...cardinalTens,
  ...ordinalTens,
} as const;

export const cardinalMagnitudes = {
  "": 1,
  thousand: 1_000,
  million: 1_000_000,
  billion: 1_000_000_000,
  trillion: 1_000_000_000_000,
  quadrillion: 1_000_000_000_000_000,
} as const;

export const ordinalMagnitudes = {
  thousandth: 1_000,
  millionth: 1_000_000,
  billionth: 1_000_000_000,
  trillionth: 1_000_000_000_000,
  quadrillionth: 1_000_000_000_000_000,
} as const;

export const magnitudes = {
  ...cardinalMagnitudes,
  ...ordinalMagnitudes,
} as const;

export const cardinalHundred = "hundred";

export const ordinalHundred = "hundredth";

export const and = "and";

export const a = 1;

export const magnitudeDigits = { ...cardinalUnits, a } as const;

export const allDigits = { ...ordinalUnits, ...magnitudeDigits } as const;

export const allOrdinals = [
  ...keys(ordinalUnits),
  ...keys(ordinalTeens),
  ...keys(ordinalTens),
  ordinalHundred,
  ...keys(ordinalMagnitudes),
] as const;

export const descendingMagnitudes = entries(cardinalMagnitudes).sort(
  ([, a], [, b]) => (a < b ? 1 : -1)
);

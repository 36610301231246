import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the custom words from state.
 */
export const selectCustomWords = createSelector(selectPreferences, (state) =>
  state.customWords.slice().sort((a, b) => a.input.localeCompare(b.input))
);

import type { PayloadAction } from "@reduxjs/toolkit";
import type { DateFormat, Preferences } from "@talktype/types";

type SetDateFormat = (
  state: Preferences,
  action: PayloadAction<DateFormat["value"]>
) => void;

/**
 * Handles the setting of colourScheme.
 */
export const setDateFormat: SetDateFormat = (state, { payload }) => {
  state.dateFormat = payload;
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery, put, take } from "redux-saga/effects";

import { textTranscriptSegment } from "@talktype/editor/src/utils/textTranscriptSegment";
import {
  requestApplyTransformations,
  appliedTransformations,
  transformedTranscript,
  requestTransformTranscript,
} from "@talktype/results/src/sagas/actions";

import { getPrecedingText } from "./getPrecedingText";
import { getTransformSettings } from "./getTransformSettings";

/**
 * Manages the transformation of a result's transcript.
 *
 * Assembles configuration necessary for the transformations to be requested:
 * - preceding text from the editor
 * - default configuration
 *
 * Sends off the request to transform the transcript.
 * Listens when ready and signals that the transformations have been applied.
 */
export const manageTransformTranscript = function* (): SagaIterator<void> {
  yield takeEvery(requestApplyTransformations, function* ({ payload: result }) {
    const settings: SagaReturnType<typeof getTransformSettings> = yield call(
      getTransformSettings
    );

    const precedingText: SagaReturnType<typeof getPrecedingText> = yield call(
      getPrecedingText,
      result
    );

    const precedingChunk = textTranscriptSegment(precedingText);

    yield put(
      requestTransformTranscript({
        result,
        precedingChunk,
        settings,
      })
    );

    const {
      payload: { result: processedResult },
    }: ReturnType<typeof transformedTranscript> = yield take(
      transformedTranscript
    );

    yield put(appliedTransformations(processedResult));
  });
};

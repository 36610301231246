import type { Callback } from "@carescribe/types";
import type { CustomWord } from "@talktype/types";

import { v4 as uuid } from "uuid";

type CreateNewCustomWord = Callback<Omit<CustomWord, "id">, CustomWord>;

/**
 * Creates a new custom word, giving it a unique id.
 */
export const createNewCustomWord: CreateNewCustomWord = ({ input }) => ({
  id: uuid(),
  input,
});

import type { RefObject } from "react";

import { useEffect } from "react";

import { useObservedAttributes } from "@carescribe/ui/src/hooks/useObservedAttributes";
import { isClickInRect } from "@carescribe/utilities/src/isClickInRect";
import { isKeyboardClick } from "@carescribe/utilities/src/isKeyboardClick";

/**
 * Adds "light dismiss" behaviour to a dialog element.
 * This means that you can dismiss the dialog by touching/clicking outside it.
 *
 * The behaviour is designed to match what is natively provided by the {@link https://developer.mozilla.org/en-US/docs/Web/API/Popover_API/Using#auto_state_and_light_dismiss Popover API's "auto" state},
 * but aimed at modal dialogs (which cannot make use of the Popover API).
 *
 * - In particular for mouse use:
 *   - Closing is triggered on click release
 *      - Benefit: can be cancelled by dragging the mouse back inside the dialog
 *   - Closing is cancelled if the click position originated within the dialog
 *      - Benefit: prevents accidental closing of the dialog (e.g. in the case
 *        of selecting text in an input and dragging mouse out of dialog)
 *
 * See demo of a popover's "light dismiss" for reference: {@link https://mdn.github.io/dom-examples/popover-api/blur-background/}.
 *
 * There are discussions to bring the "light dismiss" behaviour to the dialog
 * element natively, so hopefully one day we can do away with this. {@link https://github.com/whatwg/html/issues/9373}
 *
 * Optimised to only listen for events when the dialog is open.
 *
 * @example
 * ```
 * const ref = useRef<HTMLDialogElement>(null);
 * useLightDismiss(ref);
 * ```
 */
export const useLightDismiss = (ref: RefObject<HTMLDialogElement>): void => {
  const { open } = useObservedAttributes(ref, { open: false });

  useEffect(() => {
    const dialog = ref.current;

    if (!dialog || !open) {
      return;
    }

    let clickStartedInDialog = false;

    const onMouseDown = (event: MouseEvent): void => {
      const dialogRect = dialog.getBoundingClientRect();
      clickStartedInDialog = isClickInRect(event, dialogRect);
    };

    const onClick = (event: MouseEvent): void => {
      if (isKeyboardClick(event) || clickStartedInDialog) {
        return;
      }

      const dialogRect = dialog.getBoundingClientRect();
      const clickFinishedInDialog = isClickInRect(event, dialogRect);

      if (clickFinishedInDialog) {
        return;
      }

      dialog.close();
    };

    document.addEventListener("mousedown", onMouseDown);
    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("click", onClick);
    };
  }, [ref, open]);
};

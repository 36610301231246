import type { Action } from "@reduxjs/toolkit";
import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

/**
 * Put Saga
 *
 * Creates a saga which puts the given action.
 *
 * @example
 *
 * // The following:
 * putSaga(actionA());
 *
 * // Returns:
 * function* () {
      yield put(actionA());
    })
 */
export const putSaga = <ActionType extends string>(
  action: Action<ActionType>
) =>
  function* (): SagaIterator<void> {
    yield put(action);
  };

import type { CheckForUpdates } from "../types/CheckForUpdates";
import type { Seconds } from "@carescribe/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, delay, call } from "redux-saga/effects";

import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

import {
  versionsAreUpToDate,
  updateReadyToDownload,
  updateReadyToInstall,
} from "./actions";
import { logError } from "../log";

const performCheck = function* ({
  methods,
}: {
  methods: {
    browser: { checkForUpdates: CheckForUpdates };
    desktop: { checkForUpdates: CheckForUpdates } | null;
  };
}): SagaIterator<
  Record<"desktop" | "browser", SagaReturnType<CheckForUpdates>>
> {
  const browser: SagaReturnType<typeof methods.browser.checkForUpdates> =
    yield call(methods.browser.checkForUpdates);

  if (!methods.desktop) {
    return {
      desktop: [false, null],
      browser,
    };
  }

  const desktop: SagaReturnType<typeof methods.desktop.checkForUpdates> =
    yield call(methods.desktop.checkForUpdates);

  return { desktop, browser };
};

/**
 * Checks if new browser and desktop updates are available:
 *
 * - On mount
 * - Every `pollFrequency` seconds
 *
 * When an update is detected, dispatchs the appropriate action to signal
 * the updater is ready for the next step:
 * - Desktop -> download update
 * - Browser -> install update (download not necessary)
 *
 * When both contexts are up-to-date, dispatches `versionsAreUpToDate`.
 *
 * Where both desktop and browser updates are detected, the desktop flow is
 * followed as restarting the app results in a browser reload anyways.
 *
 * When the check for update fails for one context but is successful on the
 * other, the updater attempts to proceed with installation on the succesful
 * context. This is done optimistically in hopes that the incoming update
 * may in itself fix the issue.
 */
export const manageCheckForUpdates = function* ({
  pollFrequency,
  browser,
  desktop,
}: {
  pollFrequency: Seconds;
  browser: { checkForUpdates: CheckForUpdates };
  desktop: { checkForUpdates: CheckForUpdates } | null;
}): SagaIterator<void> {
  while (true) {
    const {
      desktop: [desktopUpdate, desktopError],
      browser: [browserUpdate, browserError],
    }: SagaReturnType<typeof performCheck> = yield call(performCheck, {
      methods: { browser, desktop },
    });

    const hasError = desktopError || browserError;
    if (hasError) {
      yield call(logError, "Error(s) occurred while checking for updates", {
        desktop: desktopError,
        browser: browserError,
      });
    }

    const hasUpdate = desktopUpdate || browserUpdate;
    if (hasUpdate) {
      const action = desktopUpdate
        ? updateReadyToDownload()
        : updateReadyToInstall({ type: "browser" });
      yield put(action);
    }

    const isUpToDate = !hasError && !hasUpdate;
    if (isUpToDate) {
      yield put(versionsAreUpToDate());
    }

    yield delay(secondsToMilliseconds(pollFrequency));
  }
};

import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { setDictationMode } from "@talktype/editor";
import { setActiveAppTracking } from "@talktype/system/src/sagas/actions";

/**
 * Controls whether or not we track the user's current active app.
 * This check is done on mount and whenever dictation mode changes.
 *
 * - "talktype" - do not track the active app.
 * - "anywhere" - track the active app.
 */
export const trackActiveAppBasedOnDictationMode =
  function* (): SagaIterator<void> {
    yield takeEvery(setDictationMode, function* ({ payload: mode }) {
      const enabled = mode === "anywhere";
      yield put(setActiveAppTracking(enabled));
    });
  };

import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { setActiveApp as setActiveAppReducer } from "./reducers/setActiveApp";
import { setAppVersion as setAppVersionReducer } from "./reducers/setAppVersion";
import { setBuildId as setBuildIdReducer } from "./reducers/setBuildId";
import { setWindowMaximised as setWindowMaximisedReducer } from "./reducers/setWindowMaximised";

export const { reducer, actions } = createSlice({
  name: "system",
  initialState,
  reducers: {
    setActiveApp: setActiveAppReducer,
    setAppVersion: setAppVersionReducer,
    setBuildId: setBuildIdReducer,
    setWindowMaximised: setWindowMaximisedReducer,
  },
});

export const { setActiveApp, setAppVersion, setBuildId, setWindowMaximised } =
  actions;

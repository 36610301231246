import type { SagaIterator } from "redux-saga";

import { all, call } from "redux-saga/effects";

import * as channel from "./channel/setUpCreation";
import * as socket from "./socket";

export const setUpTranscriberSocket = function* (): SagaIterator<void> {
  yield all([
    call(socket.setUpCreation),
    call(socket.setUpBroadcast),
    call(socket.setUpClose),
    call(channel.setUpCreation),
  ]);
};

export * from "./socket";
export * from "./actions";

import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the profanity setting from state.
 */
export const selectFilterProfanity = createSelector(
  selectPreferences,
  (state) => state.filterProfanity
);

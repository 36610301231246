import type { Seconds } from "@carescribe/types/src/Units";
import type { SagaIterator, Task } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, cancel, fork, put, race, take, delay } from "redux-saga/effects";

import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

import { log } from "../../utils";
import {
  pongReceived,
  transcriberSocketClosed,
  transcriberSocketClosedEnableAutoReconnection,
  transcriberSocketConnected,
  transcriberSocketInterrupted,
  transcriberSocketMaintained,
} from "../actions";

export type PingPong = {
  frequency: Seconds;
  allowedFails: number;
};

const calculateWait = function* (
  lastPing: number,
  frequency: Seconds
): SagaIterator<number> {
  const now: SagaReturnType<typeof Date.now> = yield call([Date, "now"]);
  const nextPingAt = lastPing + secondsToMilliseconds(frequency);
  return nextPingAt < now ? 0 : nextPingAt - now;
};

const handleConnectionLoss = function* (socket: WebSocket): SagaIterator<void> {
  yield call(log, "Lost internet connection");

  // Close the socket and start the reconnect loop
  yield call([socket, "close"]);

  yield call(log, "Socket closed. Reconnection signalled.");
  yield put(transcriberSocketClosedEnableAutoReconnection());
  yield put(transcriberSocketClosed());
};

export const pingPongLifecycle = function* (
  socket: WebSocket,
  { frequency, allowedFails }: PingPong
): SagaIterator<void> {
  let fails = 0;
  let lastPing: number;

  while (true) {
    // If ping-pong fails too many times
    if (fails > allowedFails) {
      yield call(handleConnectionLoss, socket);
      return;
    }

    yield call([socket, "send"], "ping");
    lastPing = yield call([Date, "now"]);

    // Await a pong or fail to get one in the allotted time
    const [timeout]: [true | undefined] = yield race([
      delay(secondsToMilliseconds(frequency)),
      take(pongReceived),
    ]);

    if (timeout) {
      yield call(log, "Ping-pong failed");

      // Only send socket interrupted messages if the socket is open. There is
      // other code in place that handles socket closure, so if the socket is
      // not open, nothing should happen here.
      if (socket.readyState === WebSocket.OPEN) {
        fails += 1;
        yield put(transcriberSocketInterrupted({ fails }));
      }
    } else {
      yield put(transcriberSocketMaintained());
      fails = 0;

      // Wait before starting the next ping
      const wait: SagaReturnType<typeof calculateWait> = yield call(
        calculateWait,
        lastPing,
        frequency
      );

      yield delay(wait);
    }
  }
};

export const setUpPingPong = function* (options: PingPong): SagaIterator<void> {
  yield call(log, "Ping pong enabled");

  while (true) {
    const { payload: socket } = yield take(transcriberSocketConnected);

    // Let the transcriber get going. It tends to be a bit slow initially
    yield delay(1_000);

    yield call(log, "Starting ping pong");

    // Setup ping pong lifecycle
    const lifecycle: Task = yield fork(pingPongLifecycle, socket, options);

    // Kill ping-pong when a connection is closed
    yield fork(function* () {
      yield take(transcriberSocketClosed);
      yield cancel(lifecycle);
    });
  }
};

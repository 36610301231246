import type { PWAState } from "../types/PWAState";

import { checkIsRunningInPWA } from "../utils/checkIsRunningInPWA";
import { identifyInstallTarget } from "../utils/identifyInstallTarget";

export const initialState: PWAState = {
  customPromptDismissedAt: null,
  installTarget: identifyInstallTarget(),
  isRunningInPWA: checkIsRunningInPWA(),
  nativePromptResult: null,
  supportsNativeInstall: false,
};

import type { PayloadActionCreator } from "@reduxjs/toolkit";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, call, put } from "redux-saga/effects";

import { splitOnFirstBestMatch } from "@carescribe/utilities/src/splitOnFirstBestMatch";

type ProcessRangeCommandConfig = {
  // The parameters the user has spoken
  parameters: string | null;
  // The trigger and confirmation actions for the desired range flow
  desiredProcess: {
    request: PayloadActionCreator<{ from: string; to: string }>;
    confirmation: PayloadActionCreator<boolean>;
  };
  // The trigger and confirmation actions for the fallback flow
  fallbackProcess: {
    request: PayloadActionCreator<string>;
    confirmation: PayloadActionCreator<boolean>;
  };
  // The words to split on (e.g. "to", "two" etc)
  split: string[];
  // The word that triggers the range command (e.g. "from")
  trigger: string;
};

/**
 * Process Range Command
 *
 * Splits the parameters of a range command and dispatches the required actions.
 */
export const processRangeCommand = function* ({
  parameters,
  desiredProcess,
  fallbackProcess,
  split,
  trigger,
}: ProcessRangeCommandConfig): SagaIterator<boolean> {
  if (parameters === null) {
    return false;
  }

  const [start, end]: SagaReturnType<typeof splitOnFirstBestMatch> = yield call(
    splitOnFirstBestMatch,
    parameters,
    split
  );

  const isRangeCommand = start && end;

  if (isRangeCommand) {
    // We have found a way to split the parameters so trigger the desired
    // range-based action

    yield put(desiredProcess.request({ from: start.trim(), to: end.trim() }));

    const { payload }: SagaReturnType<typeof desiredProcess.confirmation> =
      yield take(desiredProcess.confirmation);

    return payload;
  } else {
    // We couldn't find a way to split the parameters, so the user may be
    // trying to do a different command that happens to start with "from"
    // e.g. just "TalkType select from" to select the word "from"

    yield put(fallbackProcess.request(`${trigger} ${parameters}`.trim()));

    const { payload }: SagaReturnType<typeof desiredProcess.confirmation> =
      yield take(fallbackProcess.confirmation);

    return payload;
  }
};

import type { CommandConfig } from "../types";

/**
 * Analyses an instruction and identifies parts that should be flagged as
 * data sensitive based on the provided command.
 *
 * - Splits the instruction into words.
 * - Flags words as data sensitive unless are part of the command's matchers
 *   or keywords.
 * - Consolidates consecutive words with the same data sensitivity.
 *
 * @param instruction - The instruction to analyse.
 * @param command - The command containing matchers and keywords to exclude
 *                  from being flagged.
 * @returns The instruction split into parts, each flagged for data
 *          sensitivity.
 *
 * @example
 * identifySensitiveKeywords({
 *   instruction: "select from lorem ipsum to dolor sit amet",
 *   command: {
 *     matchers: ["select"],
 *     keywords: ["from", "to"]
 *   }
 * });
 * // Returns: [
 * // { content: "select from", isDataSensitive: false },
 * // { content: "lorem ipsum", isDataSensitive: true },
 * // { content: "to", isDataSensitive: false },
 * // { content: "dolor sit amet", isDataSensitive: true }
 * ]
 */
export const identifySensitiveKeywords = ({
  instruction,
  command,
}: {
  instruction: string;
  command: Pick<CommandConfig, "matchers" | "keywords"> | null;
}): { content: string; isDataSensitive: boolean }[] => {
  const result: { content: string; isDataSensitive: boolean }[] = [];

  const words = instruction.split(" ");
  const commandKeywords = command
    ? command.matchers
        .concat(command.keywords ?? [])
        .flatMap((matcher) => matcher.split(" "))
    : [];

  const exclude = new Set(commandKeywords);

  words.forEach((word) => {
    const isDataSensitive = !exclude.has(word.toLowerCase());
    const previousEntry = result.at(-1);

    const shouldConsolidate =
      previousEntry && previousEntry.isDataSensitive === isDataSensitive;

    if (shouldConsolidate) {
      previousEntry.content += " " + word;
    } else {
      result.push({ content: word, isDataSensitive });
    }
  });

  return result;
};

import type { Rule } from "../types";
import type { Callback } from "@carescribe/types";

import { errors } from "@talktype/errors";

type EntryCountWithinLimit = Callback<
  { entries: unknown[]; limit: number },
  Rule<typeof errors.entityLimitReached.code>
>;

/**
 * Errors if the number of entries is greater than the limit.
 */
export const entryCountWithinLimit: EntryCountWithinLimit =
  ({ entries, limit }) =>
  () =>
    entries.length + 1 > limit ? errors.entityLimitReached.code : null;

import type { Reshaper } from "./types/Reshaper";

/**
 * Reshape Font Family
 *
 * Convert legacy font family and copy into preferences. If the font family was
 * set to anything other than OpenDyslexic3, revert to Plus Jakarta Sans
 */
export const reshapeFontFamily: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    typeof legacyPreferences.font !== "string"
      ? newPreferences
      : {
          ...newPreferences,
          document: {
            ...newPreferences.document,
            fontFamily:
              legacyPreferences.font === "OpenDyslexic3"
                ? "OpenDyslexic"
                : "Plus Jakarta Sans",
          },
        };

/**
 * Copy to Clipboard
 *
 * Copies text to the clipboard using the Clipboard API. Accepts plain text and
 * html content. If it is not possible to write the HTML, the plain text will be
 * used as a fallback.
 */
export const copyToClipboard = async ({
  text,
  html = text,
}: {
  text: string;
  html?: string;
}): Promise<void> => {
  if ("ClipboardItem" in window) {
    const item = new ClipboardItem({
      "text/html": new Blob([html], { type: "text/html" }),
      "text/plain": new Blob([text], { type: "text/plain" }),
    });
    navigator.clipboard.write([item]);
  } else {
    // Fallback for browsers that don't support clipboard.write
    navigator.clipboard.writeText(text);
  }
};

import type { Editor } from "slate";

import { isElement } from "../guards/isElement";
import { isText } from "../guards/isText";

export const documentIsEmpty = (document: Pick<Editor, "children">): boolean =>
  document.children.length === 1 &&
  isElement(document.children[0]) &&
  isText(document.children[0].children[0]) &&
  document.children[0].children[0].text === "";

import type { Editor as EditorType } from "slate";

import { Range } from "slate";

/**
 * Checks if the editor's selection is collapsed.
 *
 * Returns false if there is no selection.
 */
export const selectionIsCollapsed = (editor: EditorType): boolean => {
  const { selection } = editor;

  return selection ? Range.isCollapsed(selection) : false;
};

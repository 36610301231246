import type { SagaIterator } from "redux-saga";

import { all, take } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getAppVersion } from "./getAppVersion";
import { getBuildIdFromDOM } from "./getBuildIdFromDOM";
import { listenWindowIsMaximisedChanges } from "./listenWindowIsMaximisedChanges";
import { manageActiveAppTracking } from "./manageActiveAppTracking";
import { manageWindowControls } from "./manageWindowControls";
import { registerDictationHotkey } from "./registerDictationHotkey";
import { registerDictationTrayItem } from "./registerDictationTrayItem";
import { registerTray } from "./registerTray";
import { setUpFocusTalkType } from "./setUpFocusTalkType";
import { setUpOpenHelpCentre } from "./setUpOpenHelpCentre";
import { setUpQuitTalkType } from "./setUpQuitTalkType";
import { trackActiveAppSubscriptionState } from "./trackActiveAppSubscriptionState";
import { setBuildId } from "../reducers/system";

export const setUpSystem = function* (): SagaIterator<void> {
  yield all(
    [
      listenWindowIsMaximisedChanges,
      manageActiveAppTracking,
      manageWindowControls,
      registerDictationHotkey,
      registerDictationTrayItem,
      registerTray,
      setUpFocusTalkType,
      setUpOpenHelpCentre,
      setUpQuitTalkType,
      getAppVersion,
      getBuildIdFromDOM,
      trackActiveAppSubscriptionState,
    ].map(retry)
  );
  yield take(setBuildId.type);
};

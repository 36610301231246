import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeLatest, put, call, takeEvery } from "redux-saga/effects";

import { setActiveStyles, clearActiveStyles } from "../../reducers";
import { createEditorChangeChannel } from "../../utils/createEditorChangeChannel";
import { getSelectionMarks } from "../../utils/getSelectionMarks";
import { editorLoaded } from "../actions";

/**
 * Store Selection Styles in Redux
 *
 * Detects any changes in the editor and stores the styles at the user's
 * selection in the Redux state. This is used to style the formatting bar's
 * buttons to indicate whether a style is active or not
 */
export const storeSelectionStylesInRedux = function* (): SagaIterator<void> {
  // takeLatest: cancel any previous tasks associated with old editors
  yield takeLatest(
    editorLoaded,
    function* ({ payload: editor }): SagaIterator<void> {
      const channel: SagaReturnType<typeof createEditorChangeChannel> =
        yield call(createEditorChangeChannel, editor);

      yield takeEvery(channel, function* (): SagaIterator<void> {
        const marks: SagaReturnType<typeof getSelectionMarks> = yield call(
          getSelectionMarks,
          editor
        );

        if (marks) {
          yield put(setActiveStyles(marks));
        } else {
          yield put(clearActiveStyles());
        }
      });
    }
  );
};

import type { RefObject } from "react";

import { useRef, useState, useEffect } from "react";

/**
 * Provides the ability to watch for changes being made to a DOM element's
 * attributes.
 *
 * This hook uses the MutationObserver API to monitor changes in the attributes
 * of a referenced HTML element. It updates the React state accordingly,
 * ensuring that the component re-renders when the attributes change.
 *
 * Optimised to only re-render when the observed attributes change.
 *
 * @param elementRef - Reference to the HTML element
 * @param attributes - An object where keys are attributes to track and values
 *                     are their default values
 * @returns - An object with the current values of the tracked attributes
 *
 * @example
 * ```
 * const dialogRef = useRef<HTMLDialogElement>(null);
 * const { open } = useObservedAttributes(dialogRef, { open: false });
 * ```
 */
export const useObservedAttributes = <
  Element extends HTMLElement,
  Attributes extends Partial<{
    [K in Extract<keyof Element, string>]: Element[K];
  }>
>(
  elementRef: RefObject<Element>,
  attributes: Attributes
): Attributes => {
  const memoisedAttributesRef = useRef(attributes);
  const [values, setValues] = useState(attributes);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }

    const propertyNames = Object.keys(memoisedAttributesRef.current);

    const observer = new MutationObserver((mutations) => {
      for (const { attributeName } of mutations) {
        if (attributeName) {
          const value = element[attributeName as keyof Element];
          setValues((values) => ({ ...values, [attributeName]: value }));
        }
      }
    });

    observer.observe(element, { attributeFilter: propertyNames });
    return () => observer.disconnect();
  }, [elementRef, memoisedAttributesRef]);

  return values;
};

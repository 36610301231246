import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, debounce, select } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { incrementDocumentFontSize, selectDocumentFontSize } from "../reducers";

export const emitDocumentFontSizeTrackingRequest =
  function* (): SagaIterator<void> {
    const fontSize: SagaReturnType<typeof selectDocumentFontSize> =
      yield select(selectDocumentFontSize);

    yield put(
      requestTrackEvent({
        name: "Preferences Updated",
        data: {
          preferencesProperty: "document font size",
          documentFontSize: fontSize,
        },
      })
    );
  };

export const trackSetDocumentFontSize = function* (): SagaIterator<void> {
  yield debounce(
    1000,
    incrementDocumentFontSize,
    emitDocumentFontSizeTrackingRequest
  );
};

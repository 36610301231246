import type { PayloadActionCreator } from "@reduxjs/toolkit";

/**
 * Gets the action type from an action creator as the literal string as opposed
 * to just `string`. This allows for things like narrowing types of a group of
 * action creators for a switch statement.
 *
 * @example
 * // Not ideal:
 * const myAction = createAction('test');
 * const stringValueOfAction = myAction.toString(); // 'test'
 * type stringValueAsType = typeof stringValueOfAction; // 'string'
 *
 * // Better:
 * const myAction = createAction('test');
 * const stringValueOfAction = actionType(myAction); // 'test'
 * type stringValueAsType = typeof stringValueOfAction; // 'test'
 *
 * // In pratice:
 *
 * // An action that could be from one of several action creators
 * const message = ...;
 *
 * switch (message) {
 *   case actionType(connectActionCreator):
 *     // ...
 *   case actionType(disconnectActionCreator):
 *     // ...
 *   case actionType(errorActionCreator):
 *     // ...
 *   // etc.
 *   default:
 *     // Next line only possible because the message variable could be narrowed
 *     assertUnreachable(message);
 * }
 */
export const actionType = <P, T extends string>(
  action: PayloadActionCreator<P, T>
): T => action.toString() as ReturnType<typeof action>["type"];

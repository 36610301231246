import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import * as reducers from "./reducers";

const slice = createSlice({
  name: "listing",
  initialState,
  reducers,
});

export const {
  reducer,
  actions: { setSearchTerm },
} = slice;

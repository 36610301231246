import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { setDictationMode } from "../reducers";

/**
 * Fires off a tracking event whenever the dictation mode is toggled.
 */
export const trackToggleDictationModeEvents = function* (): SagaIterator<void> {
  yield takeEvery(setDictationMode, function* ({ payload: dictationMode }) {
    yield put(
      requestTrackEvent({
        name: "Dictation Mode Toggled",
        data: { dictationMode },
      })
    );
  });
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, put } from "redux-saga/effects";

import {
  broadcastCurrentTranscriberSocket,
  requestCurrentTranscriberSocket,
} from "../actions";

/**
 * Returns the current WebSocket
 */
export const getCurrentSocket = function* (): SagaIterator<WebSocket | null> {
  yield put(requestCurrentTranscriberSocket());

  const {
    payload: socket,
  }: SagaReturnType<typeof broadcastCurrentTranscriberSocket> = yield take(
    broadcastCurrentTranscriberSocket
  );

  return socket;
};

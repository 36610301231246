import type { Validator } from "../../../types";
import type { Callback } from "@carescribe/types";
import type { CustomWord } from "@talktype/types";

import {
  fieldRequired,
  inputWithinMaxLength,
  entryIsUnique,
} from "../../../rules";
import { combineRules } from "../../../utils";

type Input = Callback<
  CustomWord[],
  Validator<{
    onChange: "DUPLICATE_ENTRY" | "INPUT_EXCEEDS_MAX_LENGTH";
    onSubmit: "FIELD_REQUIRED" | "DUPLICATE_ENTRY" | "INPUT_EXCEEDS_MAX_LENGTH";
  }>
>;

export const input: Input = (customWords) => {
  const rules = [
    inputWithinMaxLength(100),

    entryIsUnique({
      entries: customWords,
      getString: ({ input }) => input,
    }),
  ];

  return {
    onChange: combineRules(rules),
    onSubmit: combineRules([fieldRequired, ...rules]),
  };
};

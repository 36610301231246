/**
 * Anchors a popover element to its target anchor element.
 *
 * This is especially useful in the case of popovers created with the
 * Popover API, where they are positioned relative to the viewport.
 * When CSS anchoring becomes available in Safari, it may then be preferable
 * to use that instead (see {@link https://caniuse.com/css-anchor-positioning}).
 *
 * @params anchorRef - The reference to the anchor element
 * @params popoverRef - The reference to the popover element
 * @params placement - The placement of the popover relative to the anchor
 *
 * @example
 * ```
 * const anchorRef = useRef<HTMLElement>(null);
 * const popoverRef = useRef<HTMLElement>(null);
 *
 * useAnchor({ anchorRef, anchoreeRef: popoverRef, placement: "top" });
 * ```
 */
export const anchor = ({
  anchorRef,
  anchoreeRef,
  placement,
}: {
  anchorRef: React.RefObject<HTMLElement>;
  anchoreeRef: React.RefObject<HTMLElement>;
  placement: "top" | "bottom";
}): void => {
  const anchor = anchorRef.current;
  const anchoree = anchoreeRef.current;
  if (!anchor || !anchoree) {
    return;
  }

  const anchorRect = anchor.getBoundingClientRect();
  const anchoreeRect = anchoree.getBoundingClientRect();

  const top =
    placement === "bottom"
      ? anchorRect.bottom
      : anchorRect.top - anchoreeRect.height;

  anchoree.style.top = `${top}px`;

  const left = anchorRect.left + (anchorRect.width - anchoreeRect.width) / 2;

  anchoree.style.left = `${left}px`;
};

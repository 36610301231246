import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the silence timeout from state.
 */
export const selectSilenceTimeout = createSelector(
  selectPreferences,
  (state) => state.silenceTimeout
);

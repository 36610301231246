import type { CombinedState } from "../types/CombinedState";

import { createSelector } from "@reduxjs/toolkit";

export const selectPWAState = (state: CombinedState): CombinedState["pwa"] =>
  state.pwa;

export const selectCustomPromptDismissedAt = createSelector(
  selectPWAState,
  (state) => state.customPromptDismissedAt
);

export const selectSupportsNativeInstall = createSelector(
  selectPWAState,
  (state) => state.supportsNativeInstall
);

export const selectInstallTarget = createSelector(
  selectPWAState,
  (state) => state.installTarget
);

export const selectIsInstalling = createSelector(
  selectPWAState,
  (state) => state.nativePromptResult?.outcome === "accepted"
);

export const selectIsRunningInPWA = createSelector(
  selectPWAState,
  (state) => state.isRunningInPWA
);

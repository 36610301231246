import type { Command } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  requestClearEditorContents,
  clearedEditorContents,
} from "@talktype/editor/src/sagas/actions";

export const clear: Command = function* () {
  yield put(requestClearEditorContents());

  const { payload: success }: SagaReturnType<typeof clearedEditorContents> =
    yield take(clearedEditorContents);

  return success;
};

import type { Result } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import { broadcastPrecedingText, requestPrecedingText } from "../actions";

/**
 * Get Preceding Text
 *
 * Getter responsible for returning the preceding text managed by
 * managePrecedingText
 */
export const getPrecedingText = function* (
  result: Result
): SagaIterator<string> {
  yield put(requestPrecedingText(result));

  const {
    payload: precedingText,
  }: SagaReturnType<typeof broadcastPrecedingText> = yield take(
    broadcastPrecedingText
  );

  return precedingText;
};

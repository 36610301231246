import { requireEnv } from "@carescribe/utilities/src/requireEnv";

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;

export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;

export const DASHBOARD_URL = requireEnv({
  key: "REACT_APP_DASHBOARD_URL",
  scope: "SPA",
  value: process.env.REACT_APP_DASHBOARD_URL,
  testValue: "https://example.com",
});

export const CLIENT_ID = requireEnv({
  key: "REACT_APP_CLIENT_ID",
  scope: "SPA",
  value: process.env.REACT_APP_CLIENT_ID,
  testValue: "123123123",
});

export const AUTO_UPDATE_DISABLED = requireEnv({
  key: "REACT_APP_AUTO_UPDATE_DISABLED",
  scope: "SPA",
  value: process.env.REACT_APP_AUTO_UPDATE_DISABLED,
  testValue: "",
});

export const AUTO_UPDATE_DESKTOP_URL = requireEnv({
  key: "REACT_APP_AUTO_UPDATE_DESKTOP_URL",
  scope: "SPA",
  value: process.env.REACT_APP_AUTO_UPDATE_DESKTOP_URL,
  testValue: "",
});

export const AUTO_UPDATE_POLL_FREQUENCY_SECONDS = requireEnv({
  key: "REACT_APP_AUTO_UPDATE_POLL_FREQUENCY_SECONDS",
  scope: "SPA",
  value: process.env.REACT_APP_AUTO_UPDATE_POLL_FREQUENCY_SECONDS,
  testValue: "",
});

export const USER_CONSIDERED_IDLE_AFTER_SECONDS = requireEnv({
  key: "REACT_APP_USER_CONSIDERED_IDLE_AFTER_SECONDS",
  scope: "SPA",
  value: process.env.REACT_APP_USER_CONSIDERED_IDLE_AFTER_SECONDS,
  testValue: "",
});

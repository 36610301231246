import type { SocketEvent } from "./types";
import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

import { createLogger } from "@carescribe/utilities/src/log";

import { wsOpen, wsClose, wsMessage, wsError } from "./sagas/actions";

export const { log, logError } = createLogger("Transcriber Connection");

export const createSocket = (socketUrl: string): WebSocket => {
  log("Creating new transcriber socket");

  const socket = new WebSocket(socketUrl);
  socket.addEventListener("error", (err) => logError(err));
  return socket;
};

export const closeSocket = (socket: WebSocket | null): void => {
  if (
    socket &&
    socket.readyState !== WebSocket.CLOSED &&
    socket.readyState !== WebSocket.CLOSING
  ) {
    log("Closing old socket");
    socket.close();
  }
};

export const createSocketChannel = (
  socket: WebSocket
): EventChannel<SocketEvent> =>
  eventChannel<SocketEvent>((emit) => {
    const openHandler = (): void => emit(wsOpen());
    const closeHandler = (e: CloseEvent): void => emit(wsClose(e));
    const messageHandler = (e: MessageEvent): void => emit(wsMessage(e));
    const errorHandler = (e: Event): void => emit(wsError(e));

    socket.addEventListener("open", openHandler);
    socket.addEventListener("close", closeHandler);
    socket.addEventListener("message", messageHandler);
    socket.addEventListener("error", errorHandler);

    return () => {
      socket.removeEventListener("open", openHandler);
      socket.removeEventListener("close", closeHandler);
      socket.removeEventListener("message", messageHandler);
      socket.removeEventListener("error", errorHandler);
    };
  });

import { createSelector } from "@reduxjs/toolkit";

import {
  selectInstallTarget as baseSelectInstallTarget,
  selectIsInstalling,
  selectIsRunningInPWA,
  selectCustomPromptDismissedAt,
} from "@carescribe/pwa/src/state/selectors";
import { daysToMilliseconds } from "@carescribe/utilities/src/timing";

export { selectSupportsNativeInstall } from "@carescribe/pwa/src/state/selectors";

const oneWeekInMilliseconds = daysToMilliseconds(7);

/**
 * Determines if the custom PWA install prompt is "dismissed" and should not be
 * shown again.
 *
 * We can check the date and time the prompt was dismissed and based on criteria
 * determine whether it should be shown again.
 *
 * Currently, if the prompt was dismissed more than two weeks ago, it returns
 * `false` to indicate the prompt should be shown again.
 */
export const selectIsCustomPromptDismissed = createSelector(
  selectCustomPromptDismissedAt,
  (customPromptDismissedAt) => {
    if (!customPromptDismissedAt) {
      return false;
    }

    const dismissedDate = new Date(customPromptDismissedAt);
    const currentDate = new Date();
    const differenceInMilliseconds =
      currentDate.getTime() - dismissedDate.getTime();

    return differenceInMilliseconds < oneWeekInMilliseconds;
  }
);

/**
 * Returns the current install target if install criteria is met:
 *
 * - The custom prompt is not dismissed
 * - The app is not installing
 * - The app is not already running in PWA mode
 */
export const selectInstallTarget = createSelector(
  selectIsCustomPromptDismissed,
  selectIsInstalling,
  selectIsRunningInPWA,
  baseSelectInstallTarget,
  (isCustomPromptDismissed, isInstalling, isRunningInPWA, installTarget) => {
    const installConditionWasNotMet =
      isCustomPromptDismissed || isInstalling || isRunningInPWA;

    if (installConditionWasNotMet) {
      return null;
    }

    return installTarget;
  }
);

import type { ReactElement } from "react";

import { AppControls as Component } from "@talktype/ui/src/AppControls";

import { MainMenu } from "./MainMenu";
import { QuickNav } from "./QuickNav";

export const AppControls = (): ReactElement => (
  <Component>
    <MainMenu />
    <QuickNav />
  </Component>
);

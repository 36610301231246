import { TimeoutManager } from "@carescribe/utilities/src/TimeoutManager";
import { millisecondsToSeconds } from "@carescribe/utilities/src/timing";

export type UserState = "active" | "idle";

/**
 * Userland implementation of the native {@link https://developer.mozilla.org/en-US/docs/Web/API/IdleDetector IdleDetector},
 * with some notable differences:
 *
 * A user is considered "active" following the detection of a number of
 * predefined events:
 *
 * - Mouse movement/wheel
 * - Keyboard input
 * - Touch start/move
 * - Visibility change
 *
 * ### Comparison
 * |                                       | This   | Native |
 * |---------------------------------------|--------|--------|
 * | Works across all modern browsers      | ✔      | ✘      |
 * | Requires user permission              | ✘      | ✔      |
 * | Requires a secure context (HTTPS)     | ✘      | ✔      |
 * | Can detect screen lock state          | ✘      | ✔      |
 *
 * The native implementation's detection is more "complete", not being limited
 * to predefined events. But also deemed experimental, and its usage currently
 * comes with a number of downsides.
 *
 * @example
 *
 * ```typescript
 * const idleDetector = new IdleDetector();
 * const { signal, abort } = new AbortController();
 *
 * idleDetector.addEventListener("change", () => {
 *   const userState = idleDetector.userState;
 *   console.log(`Idle change: ${userState}.`);
 * });
 *
 * const options = {
 *   activeEvents: ["mousemove", "keydown"],
 *   threshold: 60_000,
 *   signal
 * };
 *
 * await idleDetector.start(options);
 *
 * console.log("IdleDetector is active.");
 *
 * abort();
 *
 * console.log("IdleDetector is stopped.");
 * ```
 */
export class IdleDetector extends EventTarget {
  private _userState: UserState = "active";
  private idleTimeoutManager = new TimeoutManager();
  private threshold = 0;
  private activeEvents: string[] = [];

  private set userState(newState: UserState) {
    const oldState = this._userState;
    this._userState = newState;

    if (oldState !== newState) {
      this.dispatchEvent(new Event("change"));
    }
  }

  /**
   * The userState read-only property of the IdleDetector interface returns a
   * string indicating whether the user has interacted with the device since
   * the call to start().
   */
  public get userState(): UserState {
    return this._userState;
  }

  private onIdle(): void {
    this.userState = "idle";
  }

  private onActive(): void {
    this.userState = "active";
    this.startIdleTimeout();
  }

  private startIdleTimeout(): void {
    this.idleTimeoutManager.set({
      id: "idle",
      delay: millisecondsToSeconds(this.threshold),
      onTimeout: this.onIdle,
    });
  }

  private clearIdleTimeout(): void {
    this.idleTimeoutManager.clearAll();
  }

  private addEventListeners(): void {
    this.activeEvents.forEach((event) =>
      window.addEventListener(event, this.onActive, { passive: true })
    );
  }

  private removeEventListeners(): void {
    this.activeEvents.forEach((event) =>
      window.removeEventListener(event, this.onActive)
    );
  }

  private stop(): void {
    this.clearIdleTimeout();
    this.removeEventListeners();
    this.activeEvents = [];
  }

  public constructor() {
    super();
    this.onIdle = this.onIdle.bind(this);
    this.onActive = this.onActive.bind(this);
  }

  /**
   * The start() method of the IdleDetector interface returns a Promise that
   * resolves when the detector starts listening for changes in the user's idle
   * state. This method takes an optional options object with:
   *
   * @param threshold - The threshold in milliseconds where inactivity should be
   * reported
   * @param signal - An AbortSignal to abort the idle detector
   * @param activeEvents - An array of `window` events that signal the
   * user is active
   */
  public async start(options?: {
    threshold?: number;
    signal?: AbortSignal;
    activeEvents?: string[];
  }): Promise<void> {
    this.stop();

    if (options?.threshold) {
      this.threshold = options.threshold;
    }

    if (options?.signal) {
      options.signal.addEventListener("abort", this.stop);
    }

    if (options?.activeEvents) {
      this.activeEvents = options.activeEvents;
    }

    this.addEventListeners();
    this.startIdleTimeout();
  }
}

import type { FormState } from "./FormState";
import type { Validators } from "./Validators";
import type { CustomShortcut } from "@talktype/types";
import type { ReactElement, RefObject } from "react";

import { ArrowBendDoubleUpRight } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { createOnChangeHandler } from "./createOnChangeHandler";
import { createOnSubmitHandler } from "./createOnSubmitHandler";
import { messages } from "./messages";
import styles from "./shortcutsEditModal.module.scss";
import { FeaturedIcon } from "../FeaturedIcon";
import { Field } from "../Field";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";
import { StandardButton } from "../StandardButton";
import { TextInput } from "../TextInput";

const initialState: FormState = {
  input: { value: "", error: null },
  output: { value: "", error: null },
};

export type ShortcutsEditModalProps = {
  modalRef: RefObject<HTMLDialogElement>;
  shortcut: CustomShortcut;
  validators: Validators;
  handleEdit: (shortcut: CustomShortcut) => void;
  handleDelete: (id: string) => void;
  onClose: () => void;
};

export const ShortcutsEditModal = ({
  modalRef,
  shortcut,
  validators,
  handleEdit,
  handleDelete,
  onClose,
}: ShortcutsEditModalProps): ReactElement => {
  const closeModal = (): void => modalRef.current?.close();

  const [form, setForm] = useState<FormState>(initialState);
  const resetForm = (): void => setForm(initialState);

  const getError = (field: keyof FormState): string => {
    const { value, error } = form[field];

    if (!error) {
      return "";
    }

    const message = messages.errors[error];
    return typeof message === "function" ? message(value) : message;
  };

  const hasError = (field: keyof FormState): boolean => {
    const { error } = form[field];

    return error !== null;
  };

  const onDelete = (): void => {
    handleDelete(shortcut.id);
    closeModal();
  };

  const onValidSubmit = (partialShortcut: Omit<CustomShortcut, "id">): void => {
    handleEdit({ ...shortcut, ...partialShortcut });
    closeModal();
  };

  useEffect(() => {
    setForm({
      input: { value: shortcut.input, error: null },
      output: { value: shortcut.output, error: null },
    });
  }, [shortcut, setForm]);

  return (
    <Modal
      modalRef={modalRef}
      onClose={onClose}
      className={createSelectorClassName("edit-modal", "section")}
    >
      <ModalHeader
        style="compact"
        icon={
          <FeaturedIcon style="brand" weight="light" size="sm">
            <ArrowBendDoubleUpRight />
          </FeaturedIcon>
        }
        title={messages.editShortcut}
        onClose={closeModal}
      />
      <form
        onSubmit={createOnSubmitHandler({
          initialValues: shortcut,
          form,
          validators,
          setForm,
          resetForm,
          onValidSubmit,
        })}
        className={styles.form}
      >
        <Field
          label={{ text: messages.input.label, visible: false }}
          explainer={null}
          error={
            hasError("input")
              ? {
                  text: getError("input"),
                  props: {
                    className: createSelectorClassName(
                      "input-error",
                      "element"
                    ),
                  },
                }
              : null
          }
          className={createSelectorClassName("input", "interactive")}
        >
          <TextInput
            size="md"
            inputProps={{
              name: "input",
              autofocus: "",
              value: form.input.value,
              placeholder: shortcut?.input ?? "",
              onChange: createOnChangeHandler({
                field: "input",
                initialValue: shortcut.input,
                setForm,
                validate: validators.input.onChange,
              }),
              "aria-invalid": hasError("input"),
            }}
          />
        </Field>
        <Field
          label={{ text: messages.output.label, visible: false }}
          explainer={null}
          error={
            hasError("output")
              ? {
                  text: getError("output"),
                  props: {
                    className: createSelectorClassName(
                      "output-error",
                      "element"
                    ),
                  },
                }
              : null
          }
          className={createSelectorClassName("output", "interactive")}
        >
          <TextInput
            size="md"
            inputProps={{
              name: "output",
              value: form.output.value,
              placeholder: shortcut?.output ?? "",
              onChange: createOnChangeHandler({
                field: "output",
                initialValue: shortcut.output,
                setForm,
                validate: validators.output.onChange,
              }),
              "aria-invalid": hasError("output"),
            }}
          />
        </Field>
        <div className={styles.buttonGroup}>
          <StandardButton
            colour="destructive"
            hierarchy="primary"
            size="md"
            style="default"
            label={messages.delete.label}
            elementProps={{
              type: "button",
              className: createSelectorClassName("delete", "interactive"),
              onClick: onDelete,
            }}
          />
          <StandardButton
            colour="brand"
            hierarchy="primary"
            size="md"
            style="default"
            label={messages.submit.label}
            elementProps={{
              type: "submit",
              disabled: !(form.input.value && form.output.value),
              className: createSelectorClassName("submit", "interactive"),
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

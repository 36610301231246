import type { ReactElement } from "react";

import { DictationControls as Component } from "@talktype/ui/src/DictationControls";

import { DictationButton } from "./DictationButton";
import { DictationModeMenu } from "./DictationModeMenu";

export const DictationControls = (): ReactElement => (
  <Component>
    <DictationButton />
    <DictationModeMenu />
  </Component>
);

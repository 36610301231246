import type { ReactElement, ReactNode, RefObject } from "react";

import { isSafari } from "@carescribe/utilities/src/browser";
import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./modal.module.scss";

export type ModalProps = {
  className?: string;
  children: ReactNode;
  modalRef: RefObject<HTMLDialogElement>;
  onClose?: () => void;
};

export const Modal = ({
  children,
  onClose,
  className,
  modalRef,
}: ModalProps): ReactElement => (
  <dialog
    // this expect-error should begin to fail once we upgrade to TypeScript 5
    // @ts-expect-error onClose is not a valid prop for dialog
    onClose={onClose}
    ref={modalRef}
    className={classNames(
      styles.modal,
      [!isSafari(), styles.enableExitTransition],
      className
    )}
  >
    <div className={styles.contents}>{children}</div>
  </dialog>
);

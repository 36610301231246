import { createSelector } from "@reduxjs/toolkit";

import { selectDocumentFontFamily } from "./selectDocumentFontFamily";
import { selectDocumentFontSize } from "./selectDocumentFontSize";

export const selectDocumentStyle = createSelector(
  [selectDocumentFontSize, selectDocumentFontFamily],
  (fontSize, fontFamily) => ({
    fontSize,
    fontFamily,
  })
);

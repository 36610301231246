import type { Rule } from "../types";

import { errors } from "@talktype/errors";

type EntryIsUniqueParams<EntryType> = {
  entries: EntryType[];
  getString: (args: EntryType) => string;
};

/**
 * Errors if the entry is not unique.
 * Does not error if the entry is the same as the initial value (is self).
 *
 * @param entries - The list of existing entries.
 * @param getString - Function to get the string representation of an entry.
 * @returns The rule function.
 */
export const entryIsUnique =
  <EntryType>({
    entries,
    getString,
  }: EntryIsUniqueParams<EntryType>): Rule<typeof errors.duplicateEntry.code> =>
  (value, options) => {
    const targetUppercase = value.toUpperCase();

    const initialValueUppercase = options
      ? options.initialValue.toUpperCase()
      : null;
    const isSelf = initialValueUppercase === targetUppercase;

    if (isSelf) {
      return null;
    }

    const isDuplicate = entries.some((entry) => {
      const entryUppercase = getString(entry).toUpperCase();
      return entryUppercase === targetUppercase;
    });

    return isDuplicate ? errors.duplicateEntry.code : null;
  };

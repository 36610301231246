import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery, take } from "redux-saga/effects";

import { deleteInProgressRange } from "../../../utils/deleteInProgressRange";
import {
  requestClearInProgressRange,
  clearedInProgressRange,
  requestDisableSaving,
  requestEnableSaving,
  savingDisabled,
  savingEnabled,
} from "../../actions";
import { getEditor } from "../../utils";

/**
 * Responds to requests to clear the editor in-progress nodes range.
 *
 * Deletes in-progress range and returns the editor to a state
 * prior to the insertion of the in-progress range.
 */
export const clearInProgressText = function* (): SagaIterator<void> {
  yield takeEvery(
    requestClearInProgressRange,
    function* (): SagaIterator<void> {
      const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

      if (!editor) {
        return;
      }

      // Disable saving
      yield put(requestDisableSaving());
      yield take(savingDisabled);

      yield call(deleteInProgressRange, editor);

      // Enable saving
      yield put(requestEnableSaving());
      yield take(savingEnabled);

      yield put(clearedInProgressRange());
    }
  );
};

import type { ReactElement, ReactNode, RefObject } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import styles from "./preferencesModal.module.scss";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";

export type PreferencesModalProps = {
  modalRef: RefObject<HTMLDialogElement>;
  children: ReactNode;
};

export const PreferencesModal = ({
  modalRef,
  children,
}: PreferencesModalProps): ReactElement => {
  const closeModal = (): void => modalRef.current?.close();

  return (
    <Modal
      modalRef={modalRef}
      className={createSelectorClassName("preferences-modal", "section")}
    >
      <ModalHeader
        style="standard"
        title={messages.preferences}
        icon={null}
        onClose={closeModal}
      />
      <form
        tabIndex={-1}
        className={styles.form}
        // @ts-expect-error modals don't work well with `autoFocus`
        // eslint-disable-next-line react/no-unknown-property
        autofocus=""
      >
        {children}
      </form>
    </Modal>
  );
};

import type { Seconds } from "@carescribe/types";
import type { ReactElement } from "react";

import { useLayoutEffect, useRef } from "react";

import { usePrefersReducedMotion } from "@carescribe/ui/src/hooks/usePrefersReducedMotion";

const frames = [
  "M-100,192 C46.67,100,193.33,100,340,192 C486.67,290,633.33,290,780,192 C926.67,100,1073.33,100,1220,192 L1220 600 L-100 600",
  "M-100,100 C46.67,100,193.33,292,340,292 C486.67,292,633.33,100,780,100 C926.67,100,1073.33,292,1220,292 L1220 600 L-100 600",
  "M-100,192 C46.67,250,193.33,250,340,192 C486.67,150,633.33,150,780,192 C926.67,250,1073.33,250,1220,192 L1220 600 L-100 600",
  "M-100,292 C46.67,292,193.33,100,340,100 C486.67,100,633.33,292,780,292 C926.67,292,1073.33,100,1220,100 L1220 600 L-100 600",
  "M-100,192 C46.67,100,193.33,100,340,192 C486.67,290,633.33,290,780,192 C926.67,100,1073.33,100,1220,192 L1220 600 L-100 600",
];

const Wave = ({
  dur,
  beginFrame,
  direction,
}: {
  dur: number;
  beginFrame: 1 | 2 | 3 | 4;
  direction: "normal" | "reverse";
}): ReactElement => (
  <path opacity="0.2">
    <animate
      attributeName="d"
      dur={dur}
      begin={-(dur / beginFrame)}
      values={
        direction === "reverse"
          ? [...frames].reverse().join(";")
          : [...frames].join(";")
      }
      repeatCount="indefinite"
      // Add some easing to the motion (akin to `ease-in-out`)
      keyTimes="0; 0.25; 0.5; 0.75; 1"
      keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1"
      calcMode="spline"
    />
  </path>
);

type WaveProps = { className: string; duration: Seconds };

export const Waves = ({ className, duration }: WaveProps): ReactElement => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const wavesRef = useRef<SVGSVGElement>(null);

  useLayoutEffect(() => {
    const waves = wavesRef.current;

    if (!waves) {
      return;
    }

    prefersReducedMotion ? waves.pauseAnimations() : waves.unpauseAnimations();
  }, [prefersReducedMotion]);

  return (
    <svg
      ref={wavesRef}
      viewBox="0 0 1320 500"
      preserveAspectRatio="none"
      className={className}
      aria-hidden
    >
      <Wave dur={duration.magnitude} beginFrame={1} direction="normal" />
      <Wave dur={duration.magnitude} beginFrame={3} direction="reverse" />
    </svg>
  );
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, select, take } from "redux-saga/effects";

import {
  setAttemptedLegacyPreferencesMigration,
  setPreferences,
} from "../../reducers";
import { selectAttemptedLegacyPreferencesMigration } from "../../reducers/preferences/selectors/selectAttemptedLegacyPreferencesMigration";
import {
  gotLegacyPreferences,
  requestLegacyPreferences,
  requestReshapeLegacyPreferences,
  reshapedLegacyPreferences,
} from "../actions";

/**
 * Manage migration of legacy V2 preferences.
 *
 * Unless previously attempted, tries to retrieve legacy preferences via IPC.
 */
export const migrateLegacyPreferences = function* (): SagaIterator<void> {
  const attemptedLegacyPreferencesMigration: SagaReturnType<
    typeof selectAttemptedLegacyPreferencesMigration
  > = yield select(selectAttemptedLegacyPreferencesMigration);

  if (attemptedLegacyPreferencesMigration) {
    return;
  }

  yield put(requestLegacyPreferences());

  const {
    payload: legacyPreferences,
  }: SagaReturnType<typeof gotLegacyPreferences> = yield take(
    gotLegacyPreferences
  );

  if (legacyPreferences === null) {
    yield put(setAttemptedLegacyPreferencesMigration(true));
    return;
  }

  yield put(requestReshapeLegacyPreferences(legacyPreferences));

  const {
    payload: reshaped,
  }: SagaReturnType<typeof reshapedLegacyPreferences> = yield take(
    reshapedLegacyPreferences
  );

  if (reshaped) {
    yield put(setPreferences(reshaped));
  }
  yield put(setAttemptedLegacyPreferencesMigration(true));
};

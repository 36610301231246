import type { Reshaper } from "./types/Reshaper";

import { createNewCustomShortcut } from "@talktype/preferences/src/sagas/setUpCustomShortcuts/createNewCustomShortcut";

import { isLegacyCustomShortcuts } from "../../../../guards";

/**
 * Reshape Custom Shortcuts
 *
 * Convert legacy custom shortcuts and copy them into preferences. Removes new
 * line and new paragraph shortcuts from the legacy shortcuts, which were in
 * the format of `\n` and `\n\n`.
 */
export const reshapeCustomShortcuts: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    !isLegacyCustomShortcuts(legacyPreferences.custom_shortcut)
      ? newPreferences
      : {
          ...newPreferences,
          customShortcuts: legacyPreferences.custom_shortcut
            // Remove any new line characters
            .map(([input, output]) => [input, output.replaceAll("\\n", "")])
            // Filter out any values that were only new lines
            .filter(([, output]) => output)
            // Map to new format
            .map(([input, output]) =>
              createNewCustomShortcut({ input, output })
            ),
        };

import type { Ipc, AppMetadata } from "@talktype/types";
import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

export const createActiveAppChangeChannel = (
  ipc: Ipc
): EventChannel<AppMetadata> =>
  eventChannel((emit) => {
    const unsubscribe = ipc?.system?.subscribeToActiveApp?.v1?.(emit);

    return () => {
      unsubscribe?.();
    };
  });

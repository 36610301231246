import type { EditorType } from "../editor";

import { Range } from "slate";

import { getInProgressRange } from "./getInProgressRange";
import { isLeaf } from "../guards/isLeaf";

export const deleteInProgressRange = (editor: EditorType): void => {
  const inProgressRange = getInProgressRange(editor);

  if (!inProgressRange || Range.isCollapsed(inProgressRange)) {
    return;
  }

  /*
   * Normalisation necessary as performing multiple sequential operations
   * on the same node, therefore we need to guarantee persistance of the
   * inProgressRange location (otherwise leads to errors).
   */
  editor.withoutNormalizing(() => {
    /*
     * In-progress marks must be reset prior to deletion otherwise
     * they can "stick around" in the leftover text node leading to
     * unexpected behaviour.
     */
    editor.setNodes(
      { inProgress: false },
      {
        at: inProgressRange,
        match: isLeaf,
      }
    );
    editor.delete({ at: inProgressRange });
  });
};

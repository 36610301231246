import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, takeEvery } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

import { log, logGroupCollapsed, logGroupEnd } from "../../utils/log";
import { requestLegacyPreferences, gotLegacyPreferences } from "../actions";

export const getLegacyPreferences = function* (): SagaIterator<void> {
  yield takeEvery(requestLegacyPreferences, function* () {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    if (!ipc?.preferences?.getLegacyPreferences?.v1) {
      yield put(gotLegacyPreferences(null));
      return;
    }

    const fileContents: SagaReturnType<
      typeof ipc.preferences.getLegacyPreferences.v1
    > = yield call(ipc.preferences.getLegacyPreferences.v1);

    yield call(
      logGroupCollapsed,
      `received${fileContents === null ? " no " : " "}v2 preferences`
    );
    yield call(log, fileContents);
    yield call(logGroupEnd);

    yield put(gotLegacyPreferences(fileContents));
  });
};

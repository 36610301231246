import type { RefObject } from "react";

// TODO: Remove after upgrading TypeScript
// Also Cypress headless has issues with popovers

declare global {
  interface HTMLElement {
    hidePopover(): void;
    showPopover(): void;
  }
}

export const showPopover = (tooltipRef: RefObject<HTMLElement>): void => {
  try {
    tooltipRef.current?.showPopover();
  } catch (error) {
    console.error("Error showing tooltip", error);
  }
};

export const hidePopover = (tooltipRef: RefObject<HTMLElement>): void => {
  try {
    tooltipRef.current?.hidePopover();
  } catch (error) {
    console.error("Error hiding tooltip", error);
  }
};

import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import {
  setCustomPromptDismissedAt as setCustomPromptDismissedAtReducer,
  setNativeInstallPromptResult as setNativeInstallPromptResultReducer,
  setSupportsNativeInstall as setSupportsNativeInstallReducer,
} from "./reducers";

export const { reducer, actions } = createSlice({
  name: "pwa",
  initialState,
  reducers: {
    setCustomPromptDismissedAt: setCustomPromptDismissedAtReducer,
    setNativeInstallPromptResult: setNativeInstallPromptResultReducer,
    setSupportsNativeInstall: setSupportsNativeInstallReducer,
  },
});

export const {
  setCustomPromptDismissedAt,
  setNativeInstallPromptResult,
  setSupportsNativeInstall,
} = actions;

/**
 * Returns a Promise that resolves in the next moment.
 *
 * Makes use of setTimeout in the case where the document is not visible. This
 * is because most browsers (including electron's) do not run animation frames
 * when in the background. This can lead to features that wait for a short
 * period breaking when a desktop app is in the background.
 */
export const waitUntilNextAnimationFrame = (): Promise<void> => {
  return new Promise((resolve) => {
    if (document.hasFocus()) {
      requestAnimationFrame(() => resolve());
    } else {
      setTimeout(() => resolve(), 0);
    }
  });
};

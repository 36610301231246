import type { EditorType } from "../../editor";
import type { TextNode } from "@talktype/types";

import { Editor } from "slate";

import { zeroWidthSpace } from "@carescribe/slate";

import { insertText } from "./insertText";

export const insertParagraph = (
  editor: EditorType,
  marks: Partial<TextNode> = { text: "", inProgress: true }
): void => {
  const { text, ...marksWithoutText } = marks;

  Editor.withoutNormalizing(editor, () => {
    insertText(editor, { text: zeroWidthSpace, ...marksWithoutText });
    editor.insertBreak();
    insertText(editor, { text: zeroWidthSpace, ...marksWithoutText }, false);
  });
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { waitUntilNextAnimationFrame } from "@carescribe/utilities/src/waitUntilNextAnimationFrame";

import { moveCursor } from "./utils/moveCursor";
import { requestCursorMove, movedCursor } from "../..";

/**
 * Handles requests to modify the cursor position.
 */
export const manageCursor = function* (): SagaIterator<void> {
  yield takeEvery(requestCursorMove, function* ({ payload: direction }) {
    /**
     * Necessary to wait until next animation frame otherwise
     * can cause edge cases where the cursor is not moved.
     */
    yield call(waitUntilNextAnimationFrame);

    const moved: SagaReturnType<typeof moveCursor> = yield call(
      moveCursor,
      direction
    );

    yield put(movedCursor(moved));
  });
};

import type { PWAInstallPromptProps as BaseProps } from "@carescribe/ui/src/components/PWAInstallPrompt";
import type { ReactElement } from "react";

import { PWAInstallPrompt as BaseComponent } from "@carescribe/ui/src/components/PWAInstallPrompt";

import { TalkTypeLogo } from "@talktype/icons/src/TalkTypeLogo";

export type PWAInstallPromptProps = Omit<BaseProps, "app">;

export const PWAInstallPrompt = (
  props: PWAInstallPromptProps
): ReactElement => (
  <BaseComponent
    {...props}
    app={{ name: "TalkType", icon: <TalkTypeLogo /> }}
  />
);

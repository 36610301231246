import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";

type SetAttemptedLegacyPreferencesMigration = (
  state: Preferences,
  action: PayloadAction<boolean>
) => void;

// eslint-disable-next-line max-len
export const setAttemptedLegacyPreferencesMigration: SetAttemptedLegacyPreferencesMigration =
  (state, { payload }) => {
    state.attemptedLegacyPreferencesMigration = payload;
  };

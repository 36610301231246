import type {
  ListingStructure,
  SubSection,
  TopLevelSection,
} from "../../listingStructure";
import type { Listing } from "@talktype/types/src/Listing";

import { createSelector } from "@reduxjs/toolkit";

import { escapeStringRegexp } from "@carescribe/utilities/src/escapeStringRegexp";

import { selectSearchTerm } from "./selectSearchTerm";
import { listingStructure } from "../../listingStructure";

const LIMIT_FOR_SECTION = 4;
const LIMIT_FOR_SUBSECTION = 3;
const LIMIT_FOR_FUZZY_MATCH = 4;
const LIMIT_FOR_EXACT_MATCH = 2;

const inputsMatch = (inputs: string[], searchTerm: string): boolean =>
  searchTerm.length >= LIMIT_FOR_EXACT_MATCH &&
  inputs.some((input) => input.toLowerCase().includes(searchTerm));

const matchersMatch = (matchers: string[], searchTerm: string): boolean =>
  searchTerm.length >= LIMIT_FOR_EXACT_MATCH &&
  matchers.some((matcher) => matcher.toLowerCase().includes(searchTerm));

const outputMatches = (
  output: { value: string; pronunciation: string } | null,
  searchTerm: string
): boolean =>
  (output && output.value.toLowerCase().includes(searchTerm)) ?? false;

const listingMatchesTerm = (listing: Listing, searchTerm: string): boolean =>
  inputsMatch(listing.input, searchTerm) ||
  matchersMatch(listing.matchers, searchTerm) ||
  outputMatches(listing.output ?? null, searchTerm) ||
  (searchTerm.length > LIMIT_FOR_FUZZY_MATCH &&
    detectFuzzyMatch(listing, searchTerm));

/**
 * Detect Fuzzy Match
 *
 * Verify whether a listing contains a fuzzy match for a search term.
 */
export const detectFuzzyMatch = (
  listing: Listing,
  searchTerm: string
): boolean => {
  const terms = [
    ...listing.matchers,
    ...listing.input,
    ...(listing.output ? [listing.output.value] : []),
  ];

  const regex = new RegExp(
    searchTerm.replace(
      /./g,
      (character) => `(${escapeStringRegexp(character)}.*?)`
    ),
    "i"
  );

  return terms.some((value) => {
    const hasMatch = regex.test(value);
    if (!hasMatch) {
      return false;
    }

    const matches = value.split(regex).filter((value) => value.length === 1);

    return matches.length > LIMIT_FOR_FUZZY_MATCH;
  });
};

/**
 * Search Listing
 *
 * Selects the search results based on the search term.
 *
 * Matches section titles and matchers if the term is long enough.
 * Matches matchers fuzzily if the term is long enough.
 * Matches output at any length.
 */
export const searchListing = (searchTerm: string): ListingStructure => {
  if (searchTerm === "") {
    return [];
  }

  const results: ListingStructure = [];

  // Loop over sections and subsections, including them in their entirety if
  // their titles match the user's search term, and partially if their
  // contents do
  for (const section of listingStructure) {
    if (
      searchTerm.length >= LIMIT_FOR_SECTION &&
      section.title.toLowerCase().includes(searchTerm)
    ) {
      results.push(section);
      continue;
    }

    const resultingSection: TopLevelSection<SubSection<Listing>> = {
      ...section,
      subsections: [],
    };

    let includeSection = false;

    for (const subsection of section.subsections) {
      if (
        searchTerm.length >= LIMIT_FOR_SUBSECTION &&
        subsection.title.toLowerCase().includes(searchTerm)
      ) {
        includeSection = true;
        resultingSection.subsections.push(subsection);
        continue;
      }

      const resultingSubsection: SubSection<Listing> = {
        ...subsection,
        subsections: [],
      };

      let includeSubsection = false;

      for (const listing of subsection.subsections) {
        const matches = listingMatchesTerm(listing, searchTerm);
        if (matches) {
          includeSubsection = true;
          resultingSubsection.subsections.push(listing);
        }
      }

      if (includeSubsection) {
        resultingSection.subsections.push(resultingSubsection);
        includeSection = true;
      }
    }

    if (includeSection) {
      results.push(resultingSection);
    }
  }

  return results;
};

export const selectSearchResults = createSelector(
  [selectSearchTerm],
  searchListing
);

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { handleDisableScreenWakeLock } from "./handleDisableScreenWakeLock";
import { handleEnableScreenWakeLock } from "./handleEnableScreenWakeLock";
import { trackScreenWakeLock } from "./trackScreenWakeLock";

/**
 * Allows for interacting with the Screen Wake Lock API.
 *
 * This API allows for preventing the device screen from dimming/locking
 * automatically.
 *
 * Handles requests to:
 *
 * - Enable screen wake lock
 * - Disable screen wake lock
 */
export const setUpScreenWakeLock = function* (): SagaIterator<void> {
  yield all(
    [
      trackScreenWakeLock,
      handleEnableScreenWakeLock,
      handleDisableScreenWakeLock,
    ].map(retry)
  );
};

import type { Result } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  broadcastPrecedingText,
  requestDictateToAppPrecedingText,
} from "@talktype/results/src/sagas/actions";

/**
 * Get Dictate-to-App Preceding Text
 *
 * Getter responsible for returning the preceding text managed by
 * `manageDictateToAppPrecedingText`.
 */
export const getDictateToAppPrecedingText = function* (
  result: Result
): SagaIterator<string> {
  yield put(requestDictateToAppPrecedingText(result));

  const {
    payload: precedingText,
  }: SagaReturnType<typeof broadcastPrecedingText> = yield take(
    broadcastPrecedingText
  );

  return precedingText;
};

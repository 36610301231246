import type { Element } from "slate";

import { isObject } from "@carescribe/utilities/src/guards/isObject";

import { isText } from "./isText";

export const isElement = (element: unknown): element is Element =>
  isObject(element) &&
  Array.isArray(element.children) &&
  element.children.length > 0 &&
  (element.children.every(isText) || element.children.every(isElement));

import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the custom words from state.
 */
export const selectDateFormat = createSelector(
  selectPreferences,
  (state) => state.dateFormat
);

import type { TooltipProps } from "@talktype/types/src/TooltipProps";
import type { ButtonHTMLAttributes, ComponentType, ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./toggleableButton.module.scss";
import { IconContainer } from "../IconContainer";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { Tooltip } from "../Tooltip";

export type ToggleableButtonProps = {
  buttonProps: {
    Icon: ComponentType;
    active: boolean;
    highlight: boolean;
  } & ButtonHTMLAttributes<HTMLButtonElement>;
  tooltipProps: Omit<TooltipProps, "children">;
};

export const ToggleableButton = ({
  buttonProps: { Icon, active, highlight, ...buttonProps },
  tooltipProps,
}: ToggleableButtonProps): ReactElement => (
  <Tooltip {...tooltipProps}>
    <button
      {...buttonProps}
      className={classNames(
        active ? styles.active : styles.button,
        [highlight, styles.highlight],
        buttonProps.className
      )}
    >
      <ScreenReaderOnly tag="span">
        {tooltipProps.messages.visible}
      </ScreenReaderOnly>

      <IconContainer className={styles.icon}>
        <Icon />
      </IconContainer>
    </button>
  </Tooltip>
);

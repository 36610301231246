import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { cancel, put, take, takeEvery } from "redux-saga/effects";

import { taskRef } from "./trackTask";
import { defaultCommandDeadline } from "../../config";
import {
  cancelledTask,
  requestCancelTask,
  requestTrackDeadline,
  requestTrackTask,
  trackedDeadline,
  trackedTask,
  voiceCommandComplete,
} from "../actions";

/**
 * Cancel Task
 *
 * Cancels any running task and removes a reference to it in task state, so task
 * becomes `null`. Resets the task deadline to the default deadline.
 */
export const cancelTask = function* (): SagaIterator<void> {
  const { payload: task }: SagaReturnType<typeof taskRef> = yield take(taskRef);

  yield takeEvery(
    [requestCancelTask, voiceCommandComplete],
    function* (): SagaIterator<void> {
      if (task.current.task) {
        yield cancel(task.current.task);
        yield put(requestTrackTask(null));
        yield take(trackedTask);
      }

      yield put(requestTrackDeadline(defaultCommandDeadline));
      yield take(trackedDeadline);

      yield put(cancelledTask());
    }
  );
};

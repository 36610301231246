import type { Seconds } from "@carescribe/types";

import { id } from "./fp";
import {
  secondsInDay,
  secondsInHour,
  secondsInMinute,
  secondsInWeek,
} from "./timing";

/**
 * Format Seconds in Readable Divisions
 *
 * Returns a string formatted to show seconds in weeks, days, hours, minutes and
 * seconds.
 *
 * Does not include a division if its value is zero.
 *
 * @example
 * formatSecondsInReadableDivisions(174190);
 * // "2 days, 23 minutes, 10 seconds"
 *
 * formatSecondsInReadableDivisions(0);
 * // "0 seconds"
 */
export const formatSecondsInReadableDivisions = ({
  magnitude: seconds,
}: Seconds): string => {
  const roundedSeconds = Math.round(seconds);

  const values: [string, number][] = [
    ["week", Math.floor(roundedSeconds / secondsInWeek)],
    ["day", Math.floor(roundedSeconds / secondsInDay) % 7],
    ["hour", Math.floor(roundedSeconds / secondsInHour) % 24],
    ["minute", Math.floor(roundedSeconds / secondsInMinute) % 60],
    ["second", roundedSeconds % 60],
  ];

  return (
    values
      .map(([name, magnitude]) =>
        magnitude === 0 ? "" : `${magnitude} ${name}${magnitude > 1 ? "s" : ""}`
      )
      .filter(id)
      .join(", ") || "0 seconds"
  );
};

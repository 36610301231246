import type { Editor, Point, Location, EditorAfterOptions } from "slate";

import { emptyPoint } from "@carescribe/slate";

/**
 * Get the point before a location.
 *
 * If there is no point before the location (e.g. bottom of the document)
 * returns an empty point.
 */
export const safeAfter = (
  editor: Editor,
  location: Location,
  options?: EditorAfterOptions
): Point => editor.after(location, options) ?? emptyPoint;

import type { InteractionEvent } from "@talktype/types";
import type { DrawerInfo } from "@talktype/types/src/Drawer";
import type { ReactElement, ReactNode } from "react";

import { X } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./drawer.module.scss";
import { messages } from "./messages";
import { StandardButton } from "../StandardButton";

export type DrawerProps = {
  page: DrawerInfo["page"];
  headerContent?: ReactNode;
  onClose: (event: InteractionEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
  children: ReactNode;
};

export const Drawer = ({
  children,
  headerContent,
  onClose,
  isOpen,
  page,
}: DrawerProps): ReactElement => {
  const { title } = {
    title: messages.pages[page] ?? "",
  };

  return (
    <aside
      className={classNames(
        styles.drawer,
        [isOpen, styles.open],
        createSelectorClassName("drawer", "section")
      )}
      aria-hidden={!isOpen}
    >
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles.headerInner}>
            <h2
              className={classNames(
                styles.title,
                createSelectorClassName("title", "element")
              )}
            >
              {title}
            </h2>

            <StandardButton
              colour="neutral"
              hierarchy="link"
              size="xl"
              style="icon-only"
              label={messages.close}
              mainIcon={<X />}
              elementProps={{
                onClick: onClose,
                className: createSelectorClassName("close", "interactive"),
              }}
            />
          </div>

          {headerContent}
        </header>

        <div className={styles.body}>{children}</div>
      </div>
    </aside>
  );
};

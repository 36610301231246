import type { CheckboxProps } from "../../Checkbox";
import type { ReactElement } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import { Checkbox } from "../../Checkbox";
import { Field } from "../../Field";

export type FilterProfanityFieldProps = CheckboxProps;

export const FilterProfanityField = (
  checkboxProps: FilterProfanityFieldProps
): ReactElement => (
  <Field
    label={{ text: messages.label, visible: true }}
    explainer={{ text: messages.explainer }}
    error={null}
    className={createSelectorClassName("filter-profanity-field", "interactive")}
  >
    <Checkbox {...checkboxProps} />
  </Field>
);

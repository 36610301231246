import type { Style } from "./Style";
import type { Override } from "@carescribe/types/src/Override";
import type { DetailedHTMLProps, HTMLAttributes, ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./representation.module.scss";

type OutputProps = Override<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
  { style: Style }
>;

export const Output = ({ style, ...props }: OutputProps): ReactElement => (
  <samp
    {...props}
    className={classNames(props.className, styles.output, styles[style])}
  />
);

import type { SagaIterator as socket } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { closeSocket, createSocket } from "../../utils";
import {
  requestNewTranscriberSocket,
  transcriberSocketCreated,
} from "../actions";

/**
 * Set up socket creation
 *
 * Listens for any actions of type `requestNewTranscriberSocket` and responds
 * by closing any previous socket that was open.
 *
 * Makes the newly created socket available using the
 * `transcriberSocketCreated` action.
 */
export const setUpCreation = function* (): socket<void> {
  let previousSocket: WebSocket | null = null;

  yield takeEvery(
    requestNewTranscriberSocket,
    function* ({ payload: { url } }) {
      yield call(closeSocket, previousSocket);

      const socket: SagaReturnType<typeof createSocket> = yield call(
        createSocket,
        url
      );

      // the socket is ready to go
      yield put(transcriberSocketCreated(socket));
      previousSocket = socket;
    }
  );
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery, take } from "redux-saga/effects";

import { waitUntilNextAnimationFrame } from "@carescribe/utilities/src/waitUntilNextAnimationFrame";

import {
  requestAddResultToEditor,
  resultSent,
} from "@talktype/results/src/sagas/actions";

import { applySelectionMarks } from "../../utils/applySelectionMarks";
import { insertTranscriptSegment } from "../../utils/insertTranscriptSegment";
import { transcriptHasText } from "../../utils/transcriptHasText";
import {
  requestClearInProgressRange,
  clearedInProgressRange,
  requestRestoreMarks,
  restoredMarks,
  savingDisabled,
  savingEnabled,
  requestDisableSaving,
  requestEnableSaving,
} from "../actions";
import { getEditor } from "../utils";

/**
 * Updates the editor with transcript content from the in-progress dictation.
 */
export const handleInsertTranscript = function* (): SagaIterator<void> {
  yield takeEvery(requestAddResultToEditor, function* ({ payload: result }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(resultSent(result));
      return;
    }

    const { transcript, isFinal, targetApp } = result;

    // Clear in-progress range
    yield put(requestClearInProgressRange());
    yield take(clearedInProgressRange);

    // Restore marks to a state before inserting in-progress range
    if (transcriptHasText(transcript)) {
      yield put(requestRestoreMarks());
      yield take(restoredMarks);
    }

    if (isFinal) {
      /* Delay is needed to ensure that the HistoryEditor doesn't batch every
      /* new dictated result together. Why it does otherwise is a Slate
      * Mystery™. An animation frame seems to perform better than a timeout
      * with a time of 0 which was causing flickers in the transcript sometimes.
      */
      yield call(waitUntilNextAnimationFrame);
    }

    if (!isFinal) {
      // Pause history while inserting
      yield put(requestDisableSaving());
      yield take(savingDisabled);
    }

    // Insert each transcript segment separately
    for (const segment of transcript) {
      // Add selection inline marks to the segment
      const updatedSegment: SagaReturnType<typeof applySelectionMarks> =
        yield call(applySelectionMarks, {
          editor: editor,
          segment,
          inProgress: !isFinal,
        });

      const success: SagaReturnType<typeof insertTranscriptSegment> =
        yield call(insertTranscriptSegment, editor, updatedSegment, targetApp);

      if (segment.onInsert) {
        yield call(segment.onInsert, success);
      }
    }

    if (!isFinal) {
      // Resume history
      yield put(requestEnableSaving());
      yield take(savingEnabled);
    }

    yield put(resultSent(result));
  });
};

import type { UpdateStepFailed } from "@talktype/updater";
import type { ReactElement } from "react";

import { WarningCircle } from "@phosphor-icons/react";

import { Alert } from "@talktype/ui/src/Alert";

import { messages } from "./messages";

export type UpdateErrorToastProps = {
  onClick: () => void;
  dismiss: () => void;
  details: UpdateStepFailed;
};

export const UpdateErrorToast = ({
  onClick,
  dismiss,
  details,
}: UpdateErrorToastProps): ReactElement => (
  <Alert
    colour="alert"
    hierarchy="primary"
    format="stacked"
    icon={<WarningCircle />}
    onClose={dismiss}
    label={{ text: messages.label, onClick }}
    primaryText={messages.primaryText[details.step]}
    secondaryText={messages.secondaryText[details.step]}
  />
);

import { getIsDevelopment } from "@carescribe/utilities/src/dev/getIsDevelopment";

export const silenceAutoFocusWarning = (): void => {
  if (getIsDevelopment()) {
    const originalConsoleError = console.error;

    console.error = (...args: unknown[]): void => {
      const matches = [
        "Warning: Invalid DOM property `%s`. Did you mean `%s`?%s",
        "autofocus",
        "autoFocus",
      ];

      const shouldIgnore = matches.every(
        (match, index) => args[index] === match
      );

      if (shouldIgnore) {
        return;
      }

      originalConsoleError(...args);
    };
  }
};

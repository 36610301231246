import type { UserReducer } from "../../types/UserReducer";
import type { UserState } from "../../types/UserState";

/**
 * Set User
 *
 * Completely or partially overwrite the user state.
 */
export const setUser: UserReducer<Partial<UserState>> = (
  state,
  { payload: newState }
) => ({
  ...state,
  ...newState,
});

import type { HandleInteraction, IsActive, IsDisabled } from ".";
import type { Item } from "./config";
import type { InteractionEvent } from "@talktype/types";
import type { ReactElement } from "react";

import { getPlatform } from "@carescribe/utilities/src/browser";

import { Spacer } from ".";
import { ToggleableButton } from "../ToggleableButton";

type FormattingBarButtonProps = {
  item: Item;
  handleInteraction: HandleInteraction;
  isActive: IsActive;
  isDisabled: IsDisabled;
};

export const FormattingBarButton = ({
  item,
  handleInteraction,
  isActive,
  isDisabled,
}: FormattingBarButtonProps): ReactElement => {
  if (item.type === "spacer") {
    return <Spacer />;
  }

  if (item.type === "node") {
    return <>{item.node}</>;
  }

  const { id, buttonProps, tooltipProps } = item;

  const onInteraction = (event: InteractionEvent<HTMLButtonElement>): void =>
    handleInteraction(id, event);

  const active = isActive(id);
  const platform = getPlatform();

  return (
    <ToggleableButton
      key={id}
      buttonProps={{
        ...buttonProps,
        active,
        disabled: isDisabled(id),
        highlight: false,
        onMouseDown: onInteraction,
        onKeyDown: onInteraction,
      }}
      tooltipProps={{
        ...tooltipProps,
        messages: {
          ...tooltipProps.messages,
          visible: tooltipProps.messages.visible({ active, platform }),
        },
        placement: "bottom",
      }}
    />
  );
};

import type { ColourScheme } from "@talktype/types/src/ColourScheme";

import { messages } from "./messages";

export const colourSchemes: {
  display: string;
  icon: "Devices" | "Sun" | "Moon";
  value: ColourScheme;
}[] = [
  { display: messages.colourSchemes.system, icon: "Devices", value: "normal" },
  { display: messages.colourSchemes.light, icon: "Sun", value: "light" },
  { display: messages.colourSchemes.dark, icon: "Moon", value: "dark" },
];

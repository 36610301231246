import type {
  ReplacementInstruction,
  TranscriptTransformationSettings,
} from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select } from "redux-saga/effects";

import { joinStringsForRegExp } from "@carescribe/utilities/src/joinStringsForRegExp";

import { transformationSettings, reservedKeywords } from "@talktype/config";
import { textTranscriptSegment } from "@talktype/editor/src/utils/textTranscriptSegment";
import { selectTransformationPreferences } from "@talktype/preferences";

const reservedKeywordsRegExp = new RegExp(
  `(${joinStringsForRegExp(reservedKeywords)})`,
  "i"
);

/**
 * Responsible for getting transcript transformation settings.
 */
export const getTransformSettings =
  function* (): SagaIterator<TranscriptTransformationSettings> {
    const {
      customShortcuts: userDefinedCustomShortcuts,
      filterProfanity,
      dateFormat,
      numberFormat,
    }: SagaReturnType<typeof selectTransformationPreferences> = yield select(
      selectTransformationPreferences
    );

    const { customShortcuts: defaultCustomShortcuts } = transformationSettings;

    // Combine user defined custom shortcuts with default ones
    const customShortcuts = userDefinedCustomShortcuts
      .filter(({ input }) => !reservedKeywordsRegExp.test(input))
      .map(
        (shortcut): ReplacementInstruction => ({
          type: "custom-shortcut",
          segments: [textTranscriptSegment(shortcut.output)],
          matchers: [shortcut.input],
          inBuilt: false,
        })
      )
      .concat(defaultCustomShortcuts);

    return {
      ...transformationSettings,
      customShortcuts,
      filterProfanity,
      dateFormat,
      numberFormat,
    };
  };

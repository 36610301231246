import type { Leaf } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { getInlineStyle } from "@talktype/utilities";

import { InlineStyled } from "./InlineStyled";
import { LineBreak } from "./LineBreak";
import styles from "./renderLeaf.module.scss";

export type RenderLeafProps = {
  attributes: Record<string, unknown>;
  leaf: Leaf;
  children: ReactNode;
};

export const RenderLeaf = ({
  attributes,
  leaf: { type, inProgress, text, ...marks },
  children,
}: RenderLeafProps): ReactElement => (
  <span
    {...attributes}
    className={classNames(
      [inProgress, styles.inProgress],
      [inProgress, createSelectorClassName("in-progress", "element")]
    )}
  >
    {type === "linebreak" && <LineBreak />}
    <InlineStyled marks={getInlineStyle(marks)}>{children}</InlineStyled>
  </span>
);

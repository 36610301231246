import type { ActivityStatus } from "../types/ActivityStatus";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/idle-detection");

export const activityStatusChanged = action<
  ActivityStatus,
  "activity-status-changed"
>("activity-status-changed");

export const requestActivityStatus = action<void, "request-activity-status">(
  "request-activity-status"
);

export const broadcastActivityStatus = action<
  ActivityStatus,
  "broadcast-activity-status"
>("broadcast-activity-status");

import type {
  DetailedHTMLProps,
  ReactElement,
  SelectHTMLAttributes,
} from "react";

import { CaretDown } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./select.module.scss";
import { IconContainer } from "../IconContainer";

type Option = {
  display: string;
  value: string;
};

export type SelectProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  options: Option[];
};

export const Select = ({
  options,
  ...selectProps
}: SelectProps): ReactElement => (
  <span className={styles.container}>
    <select
      {...selectProps}
      className={classNames(styles.select, selectProps?.className)}
    >
      {options.map(({ display, value }) => (
        <option key={value} value={value}>
          {display}
        </option>
      ))}
    </select>
    <IconContainer className={styles.icon}>
      <CaretDown />
    </IconContainer>
  </span>
);

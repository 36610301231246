import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { processedResult } from "@talktype/actions";

import { finaliseInProgressText } from "../../utils/finaliseInProgressText";
import { getEditor } from "../utils";

/**
 * Finalises the in-progress text whenever a request to finalise is received.
 *
 * Toggles off the inProgress mark for all in-progress nodes.
 */
export const handleFinaliseText = function* (): SagaIterator<void> {
  yield takeEvery(processedResult, function* ({ payload: { isFinal } }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      return;
    }

    if (isFinal) {
      yield call(finaliseInProgressText, editor);
    }
  });
};

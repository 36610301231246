import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageHistory } from "./manageHistory";
import { manageInitialiseHistory } from "./manageInitialiseHistory";
import { toggleableSaving } from "./toggleableSaving";

export const setUpHistory = function* (): SagaIterator<void> {
  yield all(
    [manageHistory, manageInitialiseHistory, toggleableSaving].map((saga) =>
      retry(saga)
    )
  );
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { assembleTranscriptPipeline } from "./assembleTranscript";
import { categoriseResult } from "./categoriseResult";
import { manageAssignResult } from "./manageAssignResult";
import { manageFinaliseInProgressResult } from "./manageFinaliseInProgressResult";
import { manageTransformTranscript } from "./manageTransformTranscript";
import { managePrecedingText } from "./manageTransformTranscript/managePrecedingText";
import { processResults } from "./processResults";
import { reshapeResult } from "./reshapeResult";
import { resultsPipeline } from "./resultsPipeline";
import { sendResult } from "./sendResult";

export const setUpResults = function* (): SagaIterator<void> {
  yield all(
    [
      assembleTranscriptPipeline,
      categoriseResult,
      manageAssignResult,
      manageFinaliseInProgressResult,
      managePrecedingText,
      manageTransformTranscript,
      processResults,
      reshapeResult,
      resultsPipeline,
      sendResult,
    ].map(retry)
  );
};

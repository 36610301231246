import type { AppDispatch } from "@talktype/store";
import type { UpdateAvailableToast as UpdateAvailableToastType } from "@talktype/types";
import type { UpdateAvailableToastProps } from "@talktype/ui/src/UpdateAvailableToast";
import type { UpdateType } from "@talktype/updater";

import { connect } from "react-redux";

import { UpdateAvailableToast as Component } from "@talktype/ui/src/UpdateAvailableToast";
import { requestInstallUpdate } from "@talktype/updater";

type DispatchProps = {
  installUpdate: (type: UpdateType) => void;
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  installUpdate: (type: UpdateType) => dispatch(requestInstallUpdate({ type })),
});

const mergeProps = (
  _: unknown,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: UpdateAvailableToastType
): UpdateAvailableToastProps => ({
  ...ownProps,
  onClick: () => dispatchProps.installUpdate(ownProps.update.type),
});

export const UpdateAvailableToast = connect(
  null,
  mapDispatchToProps,
  mergeProps
)(Component);

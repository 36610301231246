import type { Rule } from "../types";
import type { AppError } from "@talktype/types";

/**
 * Creates a new rule that combines multiple rules into one.
 *
 * Returns the first error if one if detected, otherwise returns true.
 */
export const combineRules =
  <ErrorCode extends AppError["code"]>(
    rules: Rule<ErrorCode>[]
  ): Rule<ErrorCode> =>
  (entry, options) => {
    for (const rule of rules) {
      const error = rule(entry, options);

      if (typeof error === "string") {
        return error;
      }
    }

    return null;
  };

import type { Document } from "../types";
import type { Callback, UUID } from "@carescribe/types";

import { emptyChildren } from "./entities/emptyChildren";
import { emptyHistory } from "./entities/emptyHistory";

type CreateDocument = Callback<Partial<Document> & { id: UUID }, Document>;

export const createDocument: CreateDocument = (document) => ({
  children: emptyChildren,
  history: emptyHistory,
  selection: null,
  ...document,
});

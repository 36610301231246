import type { SagaIterator } from "redux-saga";

import { call, all } from "redux-saga/effects";

import { setUpPWA as baseSetUpPWA } from "@carescribe/pwa/src/sagas/setUpPWA";
import { registerServiceWorker } from "@carescribe/pwa/src/utils/registerServiceWorker";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpPWATracking } from "./setUpPWATracking";

export const setUpPWA = function* (): SagaIterator<void> {
  yield all(
    [
      setUpPWATracking,
      baseSetUpPWA,
      function* (): SagaIterator<void> {
        yield call(registerServiceWorker, { path: "/service-worker.js" });
      },
    ].map(retry)
  );
};

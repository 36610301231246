import type { SelectProps } from "../../Select";
import type { ReactElement } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import { Field } from "../../Field";
import { Select } from "../../Select";

export type AutoDateFormattingFieldProps = SelectProps;

export const AutoDateFormattingField = (
  selectProps: AutoDateFormattingFieldProps
): ReactElement => (
  <Field
    label={{ text: messages.label, visible: true }}
    explainer={null}
    error={null}
    className={createSelectorClassName(
      "auto-date-formatting-field",
      "interactive"
    )}
  >
    <Select {...selectProps} />
  </Field>
);

import type { Rule } from "../types";
import type { Callback } from "@carescribe/types";

import { joinStringsForRegExp } from "@carescribe/utilities/src/joinStringsForRegExp";

import { errors } from "@talktype/errors";

type ProhibitReservedKeywords = Callback<
  string[],
  Rule<typeof errors.prohibitReservedKeywords.code>
>;

/**
 * Errors if the input contains prohibited keywords (case insensitive).
 */
export const prohibitReservedKeywords: ProhibitReservedKeywords = (
  reservedWords: string[]
) => {
  const regex = new RegExp(`(${joinStringsForRegExp(reservedWords)})`, "i");

  return (entry) =>
    regex.test(entry) ? errors.prohibitReservedKeywords.code : null;
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, select, put } from "redux-saga/effects";

import { convertTextToStars } from "@carescribe/utilities/src/convertTextToStars";

import { requestTrackEvent } from "@talktype/analytics";

import {
  addCustomShortcut,
  selectCustomShortcuts,
  deleteCustomShortcut,
  editCustomShortcut,
} from "../../reducers";

const handleAdd = function* ({
  payload: shortcut,
}: SagaReturnType<typeof addCustomShortcut>): SagaIterator<void> {
  const customShortcuts: SagaReturnType<typeof selectCustomShortcuts> =
    yield select(selectCustomShortcuts);

  yield put(
    requestTrackEvent({
      name: "Shortcut Definition Updated",
      data: {
        shortcutDefinitionInput: convertTextToStars(shortcut.input),
        shortcutDefinitionOutput: convertTextToStars(shortcut.output),
        shortcutDefinitionInteraction: "added",
        totalShortcuts: customShortcuts.length,
      },
    })
  );
};

const handleDelete = function* (): SagaIterator<void> {
  const customShortcuts: SagaReturnType<typeof selectCustomShortcuts> =
    yield select(selectCustomShortcuts);

  yield put(
    requestTrackEvent({
      name: "Shortcut Definition Updated",
      data: {
        shortcutDefinitionInteraction: "removed",
        totalShortcuts: customShortcuts.length,
      },
    })
  );
};

const handleEdit = function* ({
  payload: shortcut,
}: SagaReturnType<typeof editCustomShortcut>): SagaIterator<void> {
  const customShortcuts: SagaReturnType<typeof selectCustomShortcuts> =
    yield select(selectCustomShortcuts);

  yield put(
    requestTrackEvent({
      name: "Shortcut Definition Updated",
      data: {
        shortcutDefinitionInput: convertTextToStars(shortcut.input),
        shortcutDefinitionOutput: convertTextToStars(shortcut.output),
        shortcutDefinitionInteraction: "edited",
        totalShortcuts: customShortcuts.length,
      },
    })
  );
};

export const trackCustomShortcuts = function* (): SagaIterator<void> {
  yield takeEvery(addCustomShortcut, handleAdd);
  yield takeEvery(deleteCustomShortcut, handleDelete);
  yield takeEvery(editCustomShortcut, handleEdit);
};

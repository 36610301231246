import type { Reshaper } from "./types/Reshaper";

import { isLegacyLanguage } from "../../../../guards";

/**
 * Reshape Language
 *
 * Convert legacy language and copy into preferences.
 */
export const reshapeLanguage: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    !isLegacyLanguage(legacyPreferences.language)
      ? newPreferences
      : {
          ...newPreferences,
          dictationLanguage:
            legacyPreferences.language === "auto"
              ? "en-GB"
              : legacyPreferences.language,
        };

import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationLanguage } from "@talktype/types/src/DictationLanguage";
import type { DictationLanguageFieldProps } from "@talktype/ui/src/PreferencesModal/DictationLanguageField";
import type { ChangeEventHandler } from "react";

import { connect } from "react-redux";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";
import { entries } from "@carescribe/utilities/src/typedObject";

import { dictationLanguages } from "@talktype/config";
import {
  selectDictationLanguage,
  setDictationLanguage,
} from "@talktype/preferences";
import { DictationLanguageField as Component } from "@talktype/ui/src/PreferencesModal/DictationLanguageField";
import { isDictationLanguage } from "@talktype/utilities";

type StateProps = { value: DictationLanguage };

type DispatchProps = { onChange: ChangeEventHandler<HTMLSelectElement> };

const mapStateToProps = (state: RootState): StateProps => ({
  value: selectDictationLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChange: ({ currentTarget: { value } }): void => {
    if (isDictationLanguage(value)) {
      dispatch(broadcast(setDictationLanguage(value)));
    }
  },
});

const mergeProps = (
  { value }: StateProps,
  { onChange }: DispatchProps
): DictationLanguageFieldProps => ({
  value,
  onChange,
  options: entries(dictationLanguages).map(([value, display]) => ({
    display,
    value,
  })),
});

export const DictationLanguageField = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

import type { Reshaper } from "./types/Reshaper";

/**
 * Reshape Profanity Filter
 *
 * Convert legacy profanity filter and copy into preferences.
 */
export const reshapeProfanityFilter: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    typeof legacyPreferences.profanity_filter !== "boolean"
      ? newPreferences
      : {
          ...newPreferences,
          filterProfanity: legacyPreferences.profanity_filter,
        };

export const messages = {
  editCustomWord: "Edit custom word",
  submit: { label: "Save changes" },
  input: { label: "Enter a custom word" },
  delete: { label: "Delete word" },
  errors: {
    FIELD_REQUIRED: "Field is required",
    DUPLICATE_ENTRY: (input: string): string =>
      `‘${input}’ already exists as a custom word`,
    INPUT_EXCEEDS_MAX_LENGTH: "Custom words must be fewer than 100 characters",
  },
};

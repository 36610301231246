import type { CustomWordsEditModalProps } from "../CustomWordsEditModal";
import type { CustomWord } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { useRef, useState } from "react";

import { useLightDismiss } from "@carescribe/ui";

import styles from "./customWordsPopulated.module.scss";
import { CustomWordsEditModal } from "../CustomWordsEditModal";
import { List } from "../List";
import { Listing } from "../Listing";
import { ListingButton } from "../ListingButton";
import { ListItem } from "../ListItem";

const initialState = { id: "", input: "" };

export type CustomWordsPopulatedProps = {
  words: CustomWord[];
  validators: CustomWordsEditModalProps["validators"];
  children: ReactNode;
  handleEdit: (word: CustomWord) => void;
  handleDelete: (id: string) => void;
};

export const CustomWordsPopulated = ({
  words,
  validators,
  children,
  handleEdit,
  handleDelete,
}: CustomWordsPopulatedProps): ReactElement => {
  const [selectedWord, setSelectedWord] = useState<CustomWord>(initialState);
  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = (): void => modalRef.current?.showModal();

  const selectWord = (word: CustomWord): void => {
    setSelectedWord(word);
    showModal();
  };
  const clearSelectedWord = (): void => setSelectedWord(initialState);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <List>
          {words.map((word) => (
            <ListItem key={word.id}>
              <ListingButton onClick={(): void => selectWord(word)}>
                <Listing
                  style="default"
                  input={word.input}
                  data-hj-suppress="true"
                />
              </ListingButton>
            </ListItem>
          ))}
        </List>
      </div>

      <footer className={styles.footer}>{children}</footer>

      <CustomWordsEditModal
        modalRef={modalRef}
        word={selectedWord}
        validators={validators}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        onClose={clearSelectedWord}
      />
    </div>
  );
};

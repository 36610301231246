import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { setDictationLanguage } from "../reducers";

export const trackSetDictationLanguage = function* (): SagaIterator<void> {
  yield takeEvery(setDictationLanguage, function* ({ payload }) {
    yield put(
      requestTrackEvent({
        name: "Preferences Updated",
        data: {
          preferencesProperty: "dictation language",
          dictationLanguage: payload,
        },
      })
    );
  });
};

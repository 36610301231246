import type { AppDispatch, RootState } from "@talktype/store";
import type {
  FormattingButtonProps,
  FormattingToolbarProps,
} from "@talktype/ui/src/FormattingToolbar";

import { connect } from "react-redux";

import {
  editorFormattingBarInteracted,
  selectDocumentIsEmpty,
  selectDocumentIsRedoable,
  selectDocumentIsUndoable,
} from "@talktype/editor";
import { selectAutoPunctuation } from "@talktype/preferences/src/reducers/preferences";
import { FormattingToolbar as Component } from "@talktype/ui/src/FormattingToolbar";
import { isInlineStyle } from "@talktype/utilities";

import { FontSettings } from "./FontSettings";

type StateProps = Pick<FormattingToolbarProps, "fontSettings"> & {
  buttonProps: Pick<FormattingButtonProps, "isActive" | "isDisabled">;
};

const mapStateToProps = (state: RootState): StateProps => ({
  buttonProps: {
    isActive: (id) =>
      (isInlineStyle(id) && state.editor.styles[id]) ||
      (id === "punctuation" && selectAutoPunctuation(state)),
    isDisabled: (id) =>
      (id === "undo" && !selectDocumentIsUndoable(state)) ||
      (id === "redo" && !selectDocumentIsRedoable(state)) ||
      (id === "clear" && selectDocumentIsEmpty(state)) ||
      (id === "copy" && selectDocumentIsEmpty(state)),
  },
  fontSettings: <FontSettings />,
});

type DispatchProps = {
  buttonProps: Pick<FormattingButtonProps, "handleInteraction">;
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  buttonProps: {
    handleInteraction: (id, event) =>
      dispatch(editorFormattingBarInteracted({ name: id, event })),
  },
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps
): Pick<FormattingToolbarProps, "buttonProps" | "fontSettings"> => ({
  fontSettings: stateProps.fontSettings,
  buttonProps: {
    ...stateProps.buttonProps,
    ...dispatchProps.buttonProps,
  },
});

export const FormattingToolbar = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

export const messages = {
  addAShortcut: "Add a shortcut",
  submit: { label: "Add Shortcut" },
  input: { label: "Add a trigger term", placeholder: "email" },
  output: { label: "Replace with…", placeholder: "jonny@talktype.com" },
  errors: {
    FIELD_REQUIRED: "Field is required",
    DUPLICATE_ENTRY: (input: string): string =>
      `‘${input}’ already exists as a custom word`,
    PROHIBIT_RESERVED_KEYWORDS:
      "This word is reserved for use by TalkType’s voice commands",
  },
};

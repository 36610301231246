import type { Toast } from "@talktype/toasts/src/types";
import type { ReactElement } from "react";

import { WifiX } from "@phosphor-icons/react";

import { messages } from "./messages";
import { Alert } from "../Alert";

export const FailedConnectionToast = (toast: Toast): ReactElement => (
  <Alert
    colour="destructive"
    hierarchy="primary"
    format="stacked"
    icon={<WifiX />}
    onClose={toast.dismiss}
    label={null}
    {...messages}
  />
);

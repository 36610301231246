import type { CheckForUpdates } from "@carescribe/updater";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

/**
 * Describes how to check for updates in the desktop context.
 *
 * This is done via the `Updater.checkForUpdates` IPC method.
 */
export const checkForUpdates = ({ url }: { url: string }): CheckForUpdates =>
  function* () {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    const method = ipc?.updater?.checkForUpdates?.v1;

    if (!method) {
      return [null, { message: "`checkForUpdates` IPC method not found" }];
    }

    const [result, error]: SagaReturnType<typeof method> = yield call(
      method,
      url
    );

    return error ? [null, error] : [!!result, error];
  };

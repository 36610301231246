import type { AppContext } from "@carescribe/types";

import { checkIsRunningInPWA } from "@carescribe/pwa/src/utils/checkIsRunningInPWA";

import { isMobile } from "./browser";
import { isElectronEnvironment } from "./isElectronEnvironment";

/**
 * Determines the context the app is running in.
 *
 * - desktop (Electron)
 * - mobile (Progressive Web App)
 * - browser
 *
 * @example
 * ```ts
 * const context = getAppContext(); // 'desktop'
 * ```
 */
export const getAppContext = (): AppContext => {
  if (isElectronEnvironment()) {
    return "desktop";
  }

  const isMobileInstalledPWA = isMobile() && checkIsRunningInPWA();
  if (isMobileInstalledPWA) {
    return "mobile";
  }

  return "browser";
};

import type { PayloadActionCreator } from "@reduxjs/toolkit";

import { createAction } from "@reduxjs/toolkit";

/**
 * Prefix Action Creator
 *
 * Creates a prefixed action creator. Useful for avoiding having to repeat the
 * scope of an action many times in a file
 *
 * @example
 * const myActionCreator = prefixActionCreator('my-scope');
 * const myAction = myActionCreator('action-name');
 * const myActionType = myAction.toString(); // 'my-scope/action-name'
 *
 * @example
 * const action = prefixActionCreator("saga/talktype-transcriber");
 * const receivedResult = action("received-result");
 * const action = receivedResult();
 * // { type: "saga/talktype-transcriber/received-result" }
 */
export const prefixActionCreator = <Prefix extends string>(prefix: Prefix) => {
  // Return a function behaves the same way as createAction, but creates actions
  // that are prefixed with the prefix provided
  return <PayloadType, ActionName extends string>(
    actionName: ActionName
  ): PayloadActionCreator<PayloadType, `${Prefix}/${ActionName}`> =>
    createAction<PayloadType, `${Prefix}/${ActionName}`>(
      `${prefix}/${actionName}`
    );
};

export const createSagaAction = prefixActionCreator("saga");

import type { MouseEvent, KeyboardEvent } from "react";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { editorContentsCopied, requestCopyEditorContents } from "../actions";

export const handleCopyButton = function* (
  event: KeyboardEvent | MouseEvent
): SagaIterator<void> {
  yield put(requestCopyEditorContents());

  const { payload: result }: SagaReturnType<typeof editorContentsCopied> =
    yield take(editorContentsCopied);

  const interactionMethod: SagaReturnType<
    typeof getInteractionMethodFromEvent
  > = yield call(getInteractionMethodFromEvent, event);

  yield put(
    requestTrackEvent({
      name: "Clipboard Action Performed",
      data: {
        interactionMethod,
        clipboardEventType: "copy",
        characterCount: result.characterCount,
      },
    })
  );
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, debounce, put } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { setSearchTerm } from "../reducer";
import { selectListingDisplay } from "../reducer/selectors/selectListingDisplay";

export const emitVoiceCommandSearchTrackingEvent =
  function* (): SagaIterator<void> {
    const {
      searchTerm,
      resultCount,
    }: SagaReturnType<typeof selectListingDisplay> = yield select(
      selectListingDisplay
    );

    if (!searchTerm) {
      return;
    }

    yield put(
      requestTrackEvent({
        name: "Voice Command Search Performed",
        data: {
          voiceCommandSearchResultCount: resultCount,
          voiceCommandSearchTerm: searchTerm,
        },
      })
    );
  };

export const trackVoiceCommandSearch = function* (): SagaIterator<void> {
  yield debounce(1000, setSearchTerm, emitVoiceCommandSearchTrackingEvent);
};

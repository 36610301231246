import type { RootState, AppDispatch } from "@talktype/store";
import type { WindowsControlsProps } from "@talktype/ui/src/WindowsControls";

import { connect } from "react-redux";

import { selectWindowMaximised } from "@talktype/system/src/reducers/system/selectors/selectWindowMaximised";
import { pressedWindowControlButton } from "@talktype/system/src/sagas/actions";
import { WindowsControls as Component } from "@talktype/ui/src/WindowsControls";

const mapStateToProps = (
  state: RootState
): Pick<WindowsControlsProps, "maximised"> => ({
  maximised: selectWindowMaximised(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<
  WindowsControlsProps,
  "onMinimise" | "onMaximise" | "onUnmaximise" | "onClose"
> => ({
  onMinimise: () => dispatch(pressedWindowControlButton("minimise")),
  onMaximise: () => dispatch(pressedWindowControlButton("maximise")),
  onUnmaximise: () => dispatch(pressedWindowControlButton("unmaximise")),
  onClose: () => dispatch(pressedWindowControlButton("close")),
});

export const WindowsControls = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { addToast } from "@talktype/toasts/src/sagas/actions";

import {
  voiceCommandListening,
  voiceCommandUnrecognised,
  voiceCommandUnsupported,
  voiceCommandComplete,
} from "./actions";

const DICTATION_TOAST_ID = "voice-command";

/**
 * This saga listens for command related events and in response dispatches
 * the appropriate notifications
 */
export const manageNotifications = function* (): SagaIterator<void> {
  yield takeEvery(
    voiceCommandListening,
    function* ({ payload: { instruction } }) {
      yield put(
        addToast({
          id: DICTATION_TOAST_ID,
          type: "dictation",
          status: "listening",
          text: instruction,
          order: 0,
          dismissConfig: { after: null, notBefore: null },
        })
      );
    }
  );

  yield takeEvery(voiceCommandUnrecognised, function* () {
    yield put(
      addToast({
        id: DICTATION_TOAST_ID,
        type: "dictation",
        status: "unrecognised",
        text: "",
        order: 0,
        dismissConfig: { after: toSeconds(3), notBefore: null },
      })
    );
  });

  yield takeEvery(
    voiceCommandUnsupported,
    function* ({ payload: { targetApp } }) {
      const text = targetApp.isSelf ? "TalkType" : targetApp.name;
      yield put(
        addToast({
          id: DICTATION_TOAST_ID,
          type: "dictation",
          status: "unsupported",
          text,
          order: 0,
          dismissConfig: { after: toSeconds(3), notBefore: null },
        })
      );
    }
  );

  yield takeEvery(
    voiceCommandComplete,
    function* ({
      payload: {
        outcome: { status, detail },
      },
    }) {
      yield put(
        addToast({
          id: DICTATION_TOAST_ID,
          type: "dictation",
          status,
          text: detail,
          order: 0,
          dismissConfig: { after: toSeconds(3), notBefore: null },
        })
      );
    }
  );
};

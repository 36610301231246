import { Editor } from "slate";

/**
 * Returns the editor's currently selected text.
 *
 * If there is no selection, returns an empty string.
 */
export const getSelectedText = (editor: Editor): string => {
  const { selection } = editor;

  if (selection) {
    return Editor.string(editor, selection);
  }

  return "";
};

import type {
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from "react";

/**
 * Tests whether an event is a click event.
 *
 * Detects both:
 * - Mouse clicks
 * - Mouse down
 *
 * Filters out keyboard clicks.
 */
export const isMouseClick = (
  event: KeyboardEvent | ReactKeyboardEvent | MouseEvent | ReactMouseEvent
): boolean =>
  (event.type === "click" || event.type === "mousedown") &&
  // `detail` counts numbers of clicks used to generate the event. In the case
  // of 0, it means the event was generated via a keyboard interaction.
  event.detail > 0;

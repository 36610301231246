import type { PayloadAction } from "@reduxjs/toolkit";
import type { CustomWord, Preferences } from "@talktype/types";

type AddCustomWord = (
  state: Preferences,
  action: PayloadAction<CustomWord>
) => void;

export const addCustomWord: AddCustomWord = (state, { payload: word }) => {
  state.customWords.push(word);
};

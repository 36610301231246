import type { Icon } from "@phosphor-icons/react";
import type { VoiceCommandListingViewEventTrackingRequest } from "@talktype/analytics/src/types/EventTrackingRequest/VoiceCommandListingViewEventTrackingRequest";
import type { ListingStructure } from "@talktype/listing/src/listingStructure";
import type { ReactElement } from "react";

import { Compass, Laptop, Keyboard, PencilCircle } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import styles from "./voiceCommandsPopulated.module.scss";
import { Expander } from "../../Expander";
import { List } from "../../List";
import { Listing } from "../../Listing";
import { ListItem } from "../../ListItem";

export type VoiceCommandsPopulatedProps = {
  listing: ListingStructure;
  isSearching: boolean;
  onClickExpander: (
    data: VoiceCommandListingViewEventTrackingRequest["data"]
  ) => void;
};

const icons: Record<string, Icon> = {
  Keyboard,
  PencilCircle,
  Compass,
  Laptop,
};

const getIcon = (icon: string): ReactElement | null => {
  if (!(icon in icons)) {
    return null;
  }

  const Component = icons[icon];

  return <Component className={styles.icon} weight="duotone" aria-hidden />;
};

export const VoiceCommandsPopulated = ({
  listing,
  isSearching,
  onClickExpander,
}: VoiceCommandsPopulatedProps): ReactElement => (
  <ul
    className={classNames(
      styles.container,
      createSelectorClassName("voice-commands", "section")
    )}
  >
    {listing.map((section) => (
      <li
        key={section.id}
        className={classNames(
          styles.section,
          createSelectorClassName("section", "section")
        )}
      >
        <h3
          className={classNames(
            styles.title,
            createSelectorClassName("title", "element")
          )}
        >
          {getIcon(section.icon)}
          {section.title}
        </h3>

        <ul className={styles.subsectionList}>
          {section.subsections.map((item) => (
            <li key={item.id} className={styles.subsectionItem}>
              <Expander
                startExpanded={isSearching}
                onClick={(event, action): void =>
                  onClickExpander({
                    sectionName: `${section.title} | ${item.title}`,
                    action,
                    interactionMethod: getInteractionMethodFromEvent(event),
                  })
                }
                className={styles.button}
                buttonContents={item.title}
              >
                <div className={styles.commandList}>
                  {item.subtitle && (
                    <p className={styles.explainer} aria-hidden>
                      {item.subtitle}
                    </p>
                  )}

                  <List>
                    {item.subsections.map((subitem) => (
                      <ListItem key={subitem.id}>
                        <Listing
                          style="outlined"
                          input={subitem.input}
                          output={subitem.output}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Expander>
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
);

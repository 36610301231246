import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, debounce } from "redux-saga/effects";
import { match } from "ts-pattern";

import { setStatusBarColour } from "@carescribe/utilities/src/setStatusBarColour";
import { entries } from "@carescribe/utilities/src/typedObject";

import { getFactorsAffectingStatusBarColour } from "../../utils/getFactorsAffectingStatusBarColour";
import { requestUpdateStatusBarColour } from "../actions";

type StatusBarOption = {
  property: string;
  colour: string;
  iOSStyle: "default" | "black" | "black-translucent";
};

export const getPresetStatusBarOptions = (): Record<
  "blurple" | "light" | "dark" | "offBlack",
  StatusBarOption
> => {
  const result: Record<
    "blurple" | "light" | "dark" | "offBlack",
    StatusBarOption
  > = {
    blurple: {
      property: "--colour-primary-blue-blue-600",
      colour: "",
      iOSStyle: "black-translucent",
    },
    light: {
      property: "--colour-neutrals-neutral-white",
      colour: "",
      iOSStyle: "default",
    },
    dark: {
      property: "--colour-neutrals-neutral-800",
      colour: "",
      iOSStyle: "black-translucent",
    },
    offBlack: {
      property: "--colour-neutrals-neutral-off-black",
      colour: "",
      iOSStyle: "black-translucent",
    },
  };

  const rootStyles = getComputedStyle(document.documentElement);

  entries(result).forEach(([key, value]) => {
    result[key].colour = rootStyles.getPropertyValue(value.property);
  });

  return result;
};

export function* performUpdate(): SagaIterator<void> {
  const supportedColourOptions: SagaReturnType<
    typeof getPresetStatusBarOptions
  > = yield call(getPresetStatusBarOptions);

  const factors: SagaReturnType<typeof getFactorsAffectingStatusBarColour> =
    yield call(getFactorsAffectingStatusBarColour);

  const options = match(factors)
    .with({ isPWAInstallPromptVisible: true }, ({ colourScheme }) =>
      colourScheme === "dark"
        ? supportedColourOptions.offBlack
        : supportedColourOptions[colourScheme]
    )
    .with({ isLoggedIn: false }, () => supportedColourOptions.blurple)
    .otherwise(({ colourScheme }) => supportedColourOptions[colourScheme]);

  yield call(setStatusBarColour, options);
}

/**
 * Updates the status bar colour.
 *
 * When the PWA install prompt is visible, the status bar should match its
 * background colour.
 *
 * When logged out, the status bar should match the blurple background colour.
 *
 * Otherwise, the status bar should match the rest of the app's colour scheme.
 *
 * In all cases, we want to avoid a status bar that is at odds with the
 * colours of the app. Such as the dreaded "colour sandwich" effect
 * (not delicious) where we may otherwise end up with, say, blurple,
 * followed by light/dark, and then back to blurple again!
 *
 * +---------------------+  <- Status Bar
 * |       Blurple       |
 * +---------------------+
 * |        White        |  <- PWA Install Prompt
 * |                     |
 * +---------------------+
 * |       Blurple       |  <- App Content
 * +---------------------+
 *
 * Some debouncing is in place to avoid sudden flashing of the status bar
 * colour. This is most common at start up when multiple actions are fired in
 * quick succession.
 */
export const updateStatusBarColour = function* (): SagaIterator<void> {
  yield debounce(250, requestUpdateStatusBarColour, performUpdate);
};

import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  requestDisableScreenWakeLock,
  requestEnableScreenWakeLock,
} from "@carescribe/screen-wake-lock";

import { startDictating, stopDictating } from "@talktype/editor";

/**
 * Attempts to keep the screen on and unlocked during dictation.
 */
export const keepScreenOnDuringDictation = function* (): SagaIterator<void> {
  yield takeEvery(startDictating, function* () {
    yield put(requestEnableScreenWakeLock());
  });

  yield takeEvery(stopDictating, function* () {
    yield put(requestDisableScreenWakeLock());
  });
};

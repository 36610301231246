import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageBackspace } from "./manageBackspace";
import { manageDeletePhrase } from "./manageDeletePhrase";
import { manageDeleteRange } from "./manageDeleteRange";

export const setUpDeletion = function* (): SagaIterator<void> {
  yield all(
    [manageBackspace, manageDeletePhrase, manageDeleteRange].map(retry)
  );
};

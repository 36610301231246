import type {
  Chunk,
  Replacement,
  TranscriptTransformationSettings,
} from "@talktype/types";

import { stages } from "../stages";

type ApplyStagesParams = {
  chunks: Chunk[];
  precedingChunk: Chunk;
  settings: TranscriptTransformationSettings;
};

export const applyStages = ({
  chunks,
  precedingChunk: precedingChunkInitial,
  settings,
}: ApplyStagesParams): {
  transformed: Chunk[];
  replacements: Replacement[];
} => {
  const result: Chunk[] = [];

  let precedingChunk: Chunk = precedingChunkInitial ?? {
    type: "text",
    text: "",
  };
  let precedingText: string =
    precedingChunk.type === "text" ? precedingChunk.text : "";

  const replacements: Replacement[] = [];

  const onReplace = (trigger: string, replacement: string): void => {
    replacements.push({
      type: "punctuation",
      trigger: trigger.trim(),
      replacement: replacement,
      inBuilt: true,
    });
  };

  chunks.forEach((chunk) => {
    // Add non-text chunks as-is
    if (!("text" in chunk)) {
      precedingChunk = chunk;

      result.push(chunk);

      return;
    }

    // Apply stages to text
    const text = stages.reduce<string>(
      (text, stage) =>
        stage({
          text,
          precedingText,
          precedingChunk,
          settings: { ...settings, onReplace },
        }),
      chunk.text
    );

    const modifiedChunk = { ...chunk, text };

    precedingChunk = modifiedChunk;
    precedingText = text;

    // Add chunk to results
    result.push(modifiedChunk);
  });

  return { transformed: result, replacements };
};

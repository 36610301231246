import type {
  AppMetadata,
  Transcript,
  TranscriptTextSegment,
} from "@talktype/types";
import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

import { newLineCharacter } from "@carescribe/slate";

import { voiceCommandUnsupported } from "@talktype/commands";
import { textTranscriptSegment } from "@talktype/editor/src/utils/textTranscriptSegment";

/**
 * Converts a transcript to a text-only version.
 *
 * - Linebreak nodes are converted to text nodes with a newline character.
 * - Paragraph nodes are converted to text nodes with two newline characters.
 * - Command nodes are notified of failure to insert.
 * - Other non-text nodes are removed.
 */
export const convertTranscriptToTextOnly = function* ({
  transcript,
  targetApp,
}: {
  transcript: Transcript;
  targetApp: AppMetadata;
}): SagaIterator<TranscriptTextSegment[]> {
  const textSegment: TranscriptTextSegment = textTranscriptSegment();

  for (const segment of transcript) {
    switch (segment.type) {
      case "linebreak":
        textSegment.text += newLineCharacter;
        break;

      case "paragraph":
        textSegment.text += newLineCharacter + newLineCharacter;
        break;

      case "text":
        textSegment.text += segment.text;
        break;

      case "command":
        if (segment.onInsert) {
          yield put(voiceCommandUnsupported({ targetApp }));
        }
    }
  }

  return textSegment.text.length > 0 ? [textSegment] : [];
};

import type { ReactElement, ReactNode } from "react";

import { X } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./alert.module.scss";
import { messages } from "./messages";
import { IconContainer } from "../IconContainer";
import { StandardButton } from "../StandardButton";

export type AlertProps = {
  colour: "brand" | "positive" | "alert" | "destructive";
  hierarchy: "primary" | "secondary";
  format: "stacked" | "row";
  icon: ReactNode;
  primaryText: string;
  secondaryText: string;
  label: { text: string; onClick: () => void } | null;
  onClose: (() => void) | null;
};
export const Alert = ({
  colour,
  hierarchy,
  format,
  icon,
  primaryText,
  secondaryText,
  label,
  onClose,
}: AlertProps): ReactElement => (
  <div
    className={classNames(
      styles.container,
      styles[colour],
      styles[hierarchy],
      styles[format],
      createSelectorClassName("alert", "element")
    )}
    role="alert"
  >
    {onClose && (
      <StandardButton
        colour={colour}
        hierarchy={hierarchy === "primary" ? "link-invert" : "link"}
        size="xs"
        style="icon-only"
        label={messages.close}
        mainIcon={<X />}
        elementProps={{
          onClick: onClose,
          className: classNames(
            styles.closeButton,
            createSelectorClassName("close", "interactive")
          ),
        }}
      />
    )}

    <div className={classNames(styles.textContainer, styles[format])}>
      <div className={styles.titleContainer}>
        <IconContainer className={styles.icon}>{icon}</IconContainer>
        <span className={styles.primaryText}>{primaryText}</span>
      </div>

      <span
        className={styles.secondaryText}
        dangerouslySetInnerHTML={{ __html: secondaryText }}
      />
    </div>

    {label && (
      <StandardButton
        colour={colour}
        hierarchy={hierarchy === "primary" ? "link-invert" : "link"}
        size="sm"
        style="default"
        label={label.text}
        elementProps={{
          onClick: label.onClick,
          className: createSelectorClassName("label", "interactive"),
        }}
      />
    )}
  </div>
);

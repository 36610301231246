import type { CustomInstallPromptVisibility } from "../../types/CustomInstallPromptVisibility";
import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  customInstallPromptMounted,
  customInstallPromptUnmounted,
  requestCustomInstallPromptVisibility,
  broadcastedCustomInstallPromptVisibility,
  customInstallPromptVisibilityChanged,
} from "../../sagas/actions";

export const getNowISOString = (): string => new Date().toISOString();

/**
 * Tracks the visibility of the custom install prompt:
 * - Records the current visibility state when the prompt is mounted
 * - Records the current visibility state when the prompt is unmounted
 * - Notifies when the visibility state changes
 * - Broadcasts the current visibility state when requested
 */
export const trackCustomInstallPrompVisibility =
  function* (): SagaIterator<void> {
    let visibilityState: CustomInstallPromptVisibility = "hidden";

    yield takeEvery(customInstallPromptMounted, function* () {
      visibilityState = "visible";
      yield put(customInstallPromptVisibilityChanged());
    });

    yield takeEvery(customInstallPromptUnmounted, function* () {
      visibilityState = "hidden";
      yield put(customInstallPromptVisibilityChanged());
    });

    yield takeEvery(requestCustomInstallPromptVisibility, function* () {
      yield put(broadcastedCustomInstallPromptVisibility(visibilityState));
    });
  };

import type { ShortcutsEditModalProps } from "../ShortcutsEditModal";
import type { CustomShortcut } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { useRef, useState } from "react";

import { useLightDismiss } from "@carescribe/ui";

import { messages } from "./messages";
import styles from "./shortcutsPopulated.module.scss";
import { List } from "../List";
import { Listing } from "../Listing";
import { ListingButton } from "../ListingButton";
import { ListItem } from "../ListItem";
import { ShortcutsEditModal } from "../ShortcutsEditModal";

const initialState = { id: "", input: "", output: "" };

export type ShortcutsPopulatedProps = {
  shortcuts: CustomShortcut[];
  validators: ShortcutsEditModalProps["validators"];
  children: ReactNode;
  handleEdit: (shortcut: CustomShortcut) => void;
  handleDelete: (id: string) => void;
};

export const ShortcutsPopulated = ({
  shortcuts,
  validators,
  children,
  handleEdit,
  handleDelete,
}: ShortcutsPopulatedProps): ReactElement => {
  const [selectedShortcut, setSelectedShortcut] =
    useState<CustomShortcut>(initialState);

  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = (): void => modalRef.current?.showModal();

  const selectShortcut = (shortcut: CustomShortcut): void => {
    setSelectedShortcut(shortcut);
    showModal();
  };
  const clearSelectedShortcut = (): void => setSelectedShortcut(initialState);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.explainer}>{messages.whileDictatingSaying}</p>

        <List>
          {shortcuts.map((shortcut) => (
            <ListItem key={shortcut.id}>
              <ListingButton onClick={(): void => selectShortcut(shortcut)}>
                <Listing
                  style="outlined"
                  input={shortcut.input}
                  output={shortcut.output}
                  data-hj-suppress="true"
                />
              </ListingButton>
            </ListItem>
          ))}
        </List>
      </div>

      <footer className={styles.footer}>{children}</footer>

      <ShortcutsEditModal
        modalRef={modalRef}
        shortcut={selectedShortcut}
        validators={validators}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        onClose={clearSelectedShortcut}
      />
    </div>
  );
};

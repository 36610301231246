/**
 * Escapes RegExp special characters.
 *
 * Code taken from https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js.
 *
 * Due to the way it's compiled, importing it as a dependency causes
 * substantial issues/errors with testing any functions that make use of it
 * (and any subsequent functions that make use of those).
 *
 * Therefore, minimal changes applied and copied directly into this package.
 */
export const escapeStringRegexp = (string: string): string => {
  /* Escape characters with special meaning either inside or outside character
   * sets. Use a simple backslash escape when it’s always valid, and a `\xnn`
   * escape when the simpler form would be disallowed by Unicode patterns’
   * stricter grammar.
   */
  return string.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
};

import type { LegacyPreferences } from "@talktype/types";

export const isLegacyCustomShortcuts = (
  value: unknown
): value is LegacyPreferences["custom_shortcut"] =>
  Array.isArray(value) &&
  value.every(
    (shortcut) =>
      Array.isArray(shortcut) &&
      shortcut.length === 2 &&
      shortcut.every((word) => typeof word === "string")
  );

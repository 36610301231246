import type { SelectProps } from "../../Select";
import type { Override } from "@carescribe/types/src/Override";
import type { AlwaysOnTopMode } from "@talktype/types/src/AlwaysOnTopMode";
import type { ReactElement } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import { Field } from "../../Field";
import { Select } from "../../Select";

export type PinTalkTypeToFrontFieldProps = Override<
  SelectProps,
  { value: AlwaysOnTopMode }
>;

export const PinTalkTypeToFrontField = (
  selectProps: PinTalkTypeToFrontFieldProps
): ReactElement => (
  <Field
    label={{ text: messages.label, visible: true }}
    explainer={{ text: messages.explainer[selectProps.value] }}
    error={null}
    className={createSelectorClassName(
      "pin-talktype-to-front-field",
      "interactive"
    )}
  >
    <Select {...selectProps} />
  </Field>
);

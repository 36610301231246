import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { revalidateDocumentOnError } from "./revalidateDocumentOnError";
import { setDocumentInRedux } from "./setDocumentInRedux";

export const setUpPersistentDocument = function* (): SagaIterator<void> {
  yield all([setDocumentInRedux, revalidateDocumentOnError].map(retry));
};

import type { RootState } from "@talktype/store";
import type { DictationHistoryProps } from "@talktype/ui/src/DictationHistory";

import { connect } from "react-redux";

import { selectDictationHistoryIsEmpty } from "@talktype/dictate-to-app/src/reducers/dictateToApp/selectors";
import { DictationHistory as Component } from "@talktype/ui/src/DictationHistory";

import { DictationHistoryPopulated } from "./DictationHistoryPopulated";

const mapStateToProps = (state: RootState): DictationHistoryProps => ({
  isEmpty: selectDictationHistoryIsEmpty(state),
  Populated: DictationHistoryPopulated,
});

export const DictationHistory = connect(mapStateToProps)(Component);

import { createSelector } from "@reduxjs/toolkit";

import { selectDrawer } from "./selectDrawer";

/**
 * Indicates whether the drawer is open.
 */
export const selectDrawerIsOpen = createSelector(
  selectDrawer,
  (drawer) => drawer.isOpen
);

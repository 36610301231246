export type CreateSelectorType = "section" | "interactive" | "element";

/**
 * Creates a class name for element selectors based on a specified type and
 * name. This utility supports analytics and user interaction tracking by
 * providing a standardized way to tag HTML elements for analytics services.
 *
 * The function supports three types of selectors:
 * - `section`: For larger areas or sections of a page, e.g., a navigation bar
 *   or a footer.
 * - `interactive`: For elements that users interact with, like buttons, links,
 *   or form elements.
 * - `element`: For anything that doesn't fit the other categories, such as
 *   static elements like images or text blocks.
 *
 * Each selector class name follows the following format:
 * `selector__<type>__<name>`.
 *
 * @example
 * ```tsx
 * // For a section like a navigation bar
 * const navBarClass = createSelectorClassName("nav-bar", "section");
 * // "selector__section__nav-bar"
 *
 * // For an interactive element like a button
 * const buttonClass = createSelectorClassName("submit-button", "interactive");
 * // "selector__interactive__submit-button"
 *
 * // For a general element like an image
 * const imageClass = createSelectorClassName("logo-image", "element");
 * // "selector__element__logo-image"
 * ```
 */
export const createSelectorClassName = (
  name: string,
  type: CreateSelectorType
): string => `selector__${type}__${name}`;

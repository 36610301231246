import type { AppDispatch, RootState } from "@talktype/store";
import type { NumberFormat } from "@talktype/types/src/NumberFormat";
import type { NumberFormattingFieldProps } from "@talktype/ui/src/PreferencesModal/NumberFormattingField";
import type { ChangeEventHandler } from "react";

import { connect } from "react-redux";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { numberFormats } from "@talktype/config";
import { isValidNumberFormat, setNumberFormat } from "@talktype/preferences";
import { selectNumberFormat } from "@talktype/preferences/src/reducers/preferences/selectors/selectNumberFormat";
import { NumberFormattingField as Component } from "@talktype/ui/src/PreferencesModal/NumberFormattingField";

type StateProps = { value: NumberFormat };

type DispatchProps = { onChange: ChangeEventHandler<HTMLSelectElement> };

const mapStateToProps = (state: RootState): StateProps => ({
  value: selectNumberFormat(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChange: ({ currentTarget: { value } }): void => {
    if (isValidNumberFormat(value)) {
      dispatch(broadcast(setNumberFormat(value)));
    }
  },
});

const mergeProps = (
  { value }: StateProps,
  { onChange }: DispatchProps
): NumberFormattingFieldProps => ({
  value,
  onChange,
  options: numberFormats,
});

export const NumberFormattingField = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

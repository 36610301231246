import type { InteractionEvent } from "@talktype/types/src/InteractionEvent";
import type { ComponentType, ReactElement } from "react";

import { noOp } from "@carescribe/utilities/src/fp";

import styles from "./toggleableListItem.module.scss";
import { CheckboxSlider } from "../CheckboxSlider";
import { IconContainer } from "../IconContainer";

export type ToggleableListItemProps = {
  label: string;
  Icon: ComponentType;
  active: boolean;
  disabled: boolean;
  onInteraction: (
    event: InteractionEvent<HTMLButtonElement | HTMLInputElement>
  ) => void;
  toggleable: boolean;
};

export const ToggleableListItem = ({
  toggleable,
  Icon,
  label,
  active,
  disabled,
  onInteraction,
}: ToggleableListItemProps): ReactElement => {
  const contents = (
    <>
      <IconContainer className={styles.icon}>
        <Icon />
      </IconContainer>

      <span className={styles.label}>{label}</span>

      {toggleable && (
        <div className={styles.checkbox}>
          <CheckboxSlider
            key={label}
            checkboxProps={{
              checked: active,
              disabled,
              onClick: onInteraction,
              onChange: noOp,
            }}
            size="sm"
          />
        </div>
      )}
    </>
  );

  return toggleable ? (
    <label className={styles.button}>{contents}</label>
  ) : (
    <button
      disabled={disabled}
      className={styles.button}
      onClick={onInteraction}
    >
      {contents}
    </button>
  );
};

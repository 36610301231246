import type { Hotkey } from "../types/Hotkey";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { requestToggleDictation } from "@talktype/actions";
import { InteractionMethod } from "@talktype/analytics";
import {
  requestToggleInlineStyle,
  requestHistory,
} from "@talktype/editor/src/sagas/actions";
import { openDrawer } from "@talktype/preferences/src/reducers";

const dictationHotkeys: Hotkey[] = [
  {
    type: "toggle-dictation",
    match: [
      { metaKey: true, shiftKey: true, key: "d" },
      { ctrlKey: true, shiftKey: true, key: "d" },
    ],
    action: requestToggleDictation({
      interactionMethod: InteractionMethod.hotkey,
    }),
    payload: null,
  },
];

const inlineStylingHotkeys: Hotkey[] = [
  // Toggle bold
  {
    type: "inline-style",
    match: [
      { metaKey: true, key: "b" },
      { ctrlKey: true, key: "b" },
    ],
    action: requestToggleInlineStyle("bold"),
    payload: "bold",
  },
  // Toggle italic
  {
    type: "inline-style",
    match: [
      { metaKey: true, key: "i" },
      { ctrlKey: true, key: "i" },
    ],
    action: requestToggleInlineStyle("italic"),
    payload: "italic",
  },
  // Toggle underline
  {
    type: "inline-style",
    match: [
      { metaKey: true, key: "u" },
      { ctrlKey: true, key: "u" },
    ],
    action: requestToggleInlineStyle("underline"),
    payload: "underline",
  },
];

const historyHotkeys: Hotkey[] = [
  // Redo
  {
    type: "history",
    match: [
      { metaKey: true, shiftKey: true, key: "z" },
      { ctrlKey: true, shiftKey: true, key: "z" },
    ],
    action: requestHistory("redo"),
    payload: "redo",
  },
  // Undo
  {
    type: "history",
    match: [
      { metaKey: true, key: "z" },
      { ctrlKey: true, key: "z" },
    ],
    action: requestHistory("undo"),
    payload: "undo",
  },
];

const dictationHistoryHotkey: Hotkey = {
  type: "quick-nav",
  match: [{ altKey: true, code: "Digit4" }],
  action: openDrawer({ drawerPage: "history" }),
  payload: "history",
};

const quickNavHotkeys: Hotkey[] = [
  // Open voice commands
  {
    type: "quick-nav",
    match: [{ altKey: true, code: "Digit1" }],
    action: openDrawer({ drawerPage: "voiceCommands" }),
    payload: "voiceCommands",
  },
  // Open custom words
  {
    type: "quick-nav",
    match: [{ altKey: true, code: "Digit2" }],
    action: openDrawer({ drawerPage: "customWords" }),
    payload: "customWords",
  },
  // Open shortcuts
  {
    type: "quick-nav",
    match: [{ altKey: true, code: "Digit3" }],
    action: openDrawer({ drawerPage: "shortcuts" }),
    payload: "shortcuts",
  },
  // Open dictation history
  ...(getAppContext() === "desktop" ? [dictationHistoryHotkey] : []),
];

export const supportedHotkeys: Hotkey[] = [
  ...dictationHotkeys,
  ...inlineStylingHotkeys,
  ...historyHotkeys,
  ...quickNavHotkeys,
];

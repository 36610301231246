import type { MouseEvent, KeyboardEvent } from "react";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import { requestHistory } from "../actions";

export const handleHistoryButtons = function* (
  name: "undo" | "redo",
  event: KeyboardEvent | MouseEvent
): SagaIterator<void> {
  yield put(requestHistory(name));

  const interactionMethod: SagaReturnType<
    typeof getInteractionMethodFromEvent
  > = yield call(getInteractionMethodFromEvent, event);

  yield put(
    requestTrackEvent({
      name: "Edits Reverted",
      data: { historyInteraction: name, interactionMethod },
    })
  );
};

import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { setAlwaysOnTop } from "../reducers/preferences";

export const trackSetAlwaysOnTop = function* (): SagaIterator<void> {
  yield takeEvery(setAlwaysOnTop, function* ({ payload: alwaysOnTop }) {
    yield put(
      requestTrackEvent({
        name: "Preferences Updated",
        data: {
          preferencesProperty: "always on top",
          alwaysOnTop,
        },
      })
    );
  });
};

import type { ResultMessage } from "../types/Responses";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";

import { isResult } from "./isResult";
import { isSocketMessage } from "./isSocketMessage";

export const isResultMessage = (data: unknown): data is ResultMessage =>
  isSocketMessage(data) &&
  data.responseType === "dictation" &&
  hasPropertyOfType(data, "payload", "object") &&
  isResult(data.payload);

import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

export const slice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {},
});

export const { reducer } = slice;

export * from "./selectors";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";
import { isObject } from "@carescribe/utilities/src/guards/isObject";

/**
 * Extract Text
 *
 * Recurses through any Slate-esque structure and creates a concatenation of
 * any `text` properties that are found with a string value.
 */
export const extractText = (value: unknown): string => {
  if (Array.isArray(value)) {
    return value.map(extractText).join("");
  }

  if (!isObject(value)) {
    return "";
  }

  if (Array.isArray(value.children)) {
    return extractText(value.children);
  }

  if (hasPropertyOfType(value, "text", "string")) {
    return value.text;
  }

  return "";
};

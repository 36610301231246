import type { RequestOptions } from "@carescribe/utilities/src/request";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select } from "redux-saga/effects";

import { getTokens } from "@carescribe/pkce/src/sagas/tokens/getTokens";
import { request } from "@carescribe/utilities/src/request";

import { selectTalkTypeDashboardUrl } from "../../reducer/selectors/selectTalkTypeDashboardUrl";
import { logError } from "../../utils/log";

type AuthedRequestOptions = Omit<RequestOptions, "url"> & { pathname: string };

/**
 * Authed Request
 *
 * Make a request to the server with the access token and base url added.
 */
export const authedRequest = function* ({
  pathname,
  params = {},
  ...config
}: AuthedRequestOptions): SagaIterator<SagaReturnType<typeof request>> {
  const tokens: SagaReturnType<typeof getTokens> = yield call(getTokens);

  if (!tokens) {
    yield call(logError, "No tokens found for authed request");
  }

  const dashboardUrl: SagaReturnType<typeof selectTalkTypeDashboardUrl> =
    yield select(selectTalkTypeDashboardUrl);

  return yield call(request, {
    url: `${dashboardUrl}${pathname}`,
    params: {
      access_token: tokens ? tokens.accessToken : "",
      ...params,
    },
    logError,
    ...config,
  });
};

import type { SagaIterator } from "redux-saga";
import type { BaseOperation } from "slate";

import { takeEvery, put } from "redux-saga/effects";
import { HistoryEditor } from "slate-history";

import {
  requestDisableSaving,
  requestEnableSaving,
  savingDisabled,
  savingEnabled,
  editorLoaded,
} from "../actions";

/**
 * Responds to requests to disable/enable editor saving.
 *
 * By default, the editor will add operations applied to the history stack.
 * This allows us to override that behaviour as required.
 */
export const toggleableSaving = function* (): SagaIterator<void> {
  yield takeEvery(editorLoaded, function* ({ payload: editor }) {
    const originalApply = editor.apply;

    yield takeEvery(requestDisableSaving, function* (): SagaIterator<void> {
      editor.apply = (operation: BaseOperation): void => {
        HistoryEditor.withoutSaving(editor, () => {
          originalApply(operation);
        });
      };

      yield put(savingDisabled());
    });

    yield takeEvery(requestEnableSaving, function* (): SagaIterator<void> {
      editor.apply = originalApply;

      yield put(savingEnabled());
    });
  });
};

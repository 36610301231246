import type { Reshaper } from "./types/Reshaper";

/**
 * Reshape Dark Mode
 *
 * Convert legacy dark mode and copy into preferences.
 */
export const reshapeFontSize: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    typeof legacyPreferences.font_size !== "number"
      ? newPreferences
      : {
          ...newPreferences,
          document: {
            ...newPreferences.document,
            fontSize: legacyPreferences.font_size,
          },
        };

import type { TokensResponse } from "../types/TokensResponse";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

export const validTokensResponse = {
  access_token: P.string,
  refresh_token: P.string,
  expires_in: P.number,
  created_at: P.number,
  scope: "dictate" as const,
  token_type: "Bearer" as const,
};

export const isTokensResponse =
  createGuard<TokensResponse>(validTokensResponse);

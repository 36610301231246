import type { Validator } from "../../types";
import type { Callback } from "@carescribe/types";
import type { CustomShortcut } from "@talktype/types";

import { reservedKeywords } from "@talktype/config";

import { fieldRequired, entryIsUnique } from "../../rules";
import { prohibitReservedKeywords } from "../../rules/prohibitReservedKeywords";
import { combineRules } from "../../utils";

type Input = Callback<
  CustomShortcut[],
  Validator<{
    onChange: "PROHIBIT_RESERVED_KEYWORDS" | "DUPLICATE_ENTRY";
    onSubmit:
      | "FIELD_REQUIRED"
      | "PROHIBIT_RESERVED_KEYWORDS"
      | "DUPLICATE_ENTRY";
  }>
>;

export const input: Input = (customShortcuts) => {
  const rules = [
    prohibitReservedKeywords(reservedKeywords),

    entryIsUnique({
      entries: customShortcuts,
      getString: ({ input }) => input,
    }),
  ];

  return {
    onChange: combineRules(rules),
    onSubmit: combineRules([fieldRequired, ...rules]),
  };
};

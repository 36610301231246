import type { PayloadAction } from "@reduxjs/toolkit";
import type { CustomShortcut, Preferences } from "@talktype/types";

type DeleteCustomShortcut = (
  state: Preferences,
  action: PayloadAction<CustomShortcut["id"]>
) => void;

/**
 * Handles the deletion of a custom shortcut from state.
 */
export const deleteCustomShortcut: DeleteCustomShortcut = (
  state,
  { payload: id }
) => {
  state.customShortcuts = state.customShortcuts.filter(
    (shortcut) => shortcut.id !== id
  );
};

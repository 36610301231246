import type { ReactElement } from "react";

import { ArrowsClockwise } from "@phosphor-icons/react";

import { Alert } from "@talktype/ui/src/Alert";

export type UpdateAvailableToastProps = {
  onClick: () => void;
  dismiss: () => void;
};

export const UpdateAvailableToast = ({
  onClick,
  dismiss,
}: UpdateAvailableToastProps): ReactElement => (
  <Alert
    colour="brand"
    hierarchy="primary"
    format="stacked"
    icon={<ArrowsClockwise />}
    onClose={dismiss}
    label={{ text: "Update Available", onClick }}
    primaryText="A new update is available."
    secondaryText="Click to install the update."
  />
);

/* eslint-disable no-undefined */
import type {
  EncryptedStorage,
  Document,
  EncryptedPayload,
  DecryptedUserStorage,
} from "./types";
import type { Keys } from "@carescribe/utilities/src/Storage";

import { v4 as uuid } from "uuid";

import { Storage } from "@carescribe/utilities/src/Storage";
import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { Encryption } from "./Encryption";
import {
  fallbackUserState,
  fallbackPkceState,
  fallbackPWAState,
  fallbackDictationMode,
  fallbackPreferences,
  fallbackLegacyDocument,
} from "./fallbacks";
import {
  isPkceState,
  isUserState,
  isCustomPromptDismissedAt,
  isDictationMode,
  isLegacyPreferences,
  isLegacyDocument,
} from "./guards";
import { logError } from "../../../log";

/**
 * Safely extracts a value (e.g. from local storage).
 *
 * If the value is not found, the fallback is returned.
 * If the value is found but fails validation, an error is logged and the
 * fallback is returned.
 */
export const safeValue = <StoredData>({
  description,
  data: initialData,
  fallback,
  validate,
}: {
  description: string;
  data: unknown | (() => unknown);
  fallback: StoredData;
  validate: (data: unknown) => data is StoredData;
}): StoredData => {
  try {
    const data =
      typeof initialData === "function" ? initialData() : initialData;

    if (!data) {
      return fallback;
    }

    const isValid = validate(data);
    if (!isValid) {
      logError(new Error(`Detected unexpected data shape: ${description}`), {
        data,
      });
      return fallback;
    }

    return data;
  } catch (cause) {
    logError(new Error(`Failed to get value: ${description}`, { cause }));
    return fallback;
  }
};

export const encryptUserState = async (
  userId: string,
  data: DecryptedUserStorage
): Promise<{ key: string; encryptedData: EncryptedPayload }> => {
  const cryptoKey = await Encryption.createKey(userId);
  const encryption = new Encryption(cryptoKey);
  const encryptedData = await encryption.encrypt(data);
  const key = await Encryption.hashUserId(userId);
  return { key, encryptedData };
};

export const decryptUserState = async (
  data: Map<string, EncryptedPayload>,
  userId: string
): Promise<unknown> => {
  const key = await Encryption.hashUserId(userId);
  const user = data.get(key);
  if (!user) {
    return null;
  }
  const cryptoKey = await Encryption.createKey(userId);
  const encryption = new Encryption(cryptoKey);
  return encryption.decrypt(user);
};

const checkHasLegacyLocalStorageData = (): boolean =>
  Storage.getItemKeys().some((key) => key.includes("talktype"));

const getFromLocalStorage = (keys: Keys) => (): unknown | null => {
  const item = Storage.getItem({ ...keys, app: "talktype" });
  if (!item) {
    return null;
  }
  const [parsed, cause] = StrictJSON.safeParse(item);

  if (cause) {
    logError(new Error("Failed to parse item from local storage", { cause }));
    return null;
  }

  return parsed;
};

const getLegacySharedDataFromLocalStorage = async (): Promise<
  EncryptedStorage["shared"]
> => {
  const user = safeValue({
    description: "user (legacy)",
    fallback: fallbackUserState,
    data: getFromLocalStorage({ key: "user" }),
    validate: isUserState,
  });

  const pkce = safeValue({
    description: "pkce (legacy)",
    fallback: fallbackPkceState,
    data: getFromLocalStorage({ key: "pkce" }),
    validate: isPkceState,
  });

  const pwa = {
    customPromptDismissedAt: safeValue({
      description: "pwa (legacy)",
      fallback: fallbackPWAState.customPromptDismissedAt,
      data: getFromLocalStorage({ key: "pwa" }),
      validate: isCustomPromptDismissedAt,
    }),
  };

  return { pkce, pwa, user };
};

const getLegacyUsersDataFromLocalStorage = async (): Promise<
  EncryptedStorage["users"]
> => {
  const keys = Storage.getItemKeys();
  const userIds = keys.reduce<Set<string>>((ids, key) => {
    const id = new URLSearchParams(key).get("id");
    if (id !== null) {
      ids.add(id);
    }
    return ids;
  }, new Set());
  const users: EncryptedStorage["users"] = new Map();

  for (const id of userIds) {
    const legacyDocument = safeValue({
      description: "document (legacy)",
      fallback: fallbackLegacyDocument,
      data: getFromLocalStorage({ key: "document", id }),
      validate: isLegacyDocument,
    });

    const documents = new Map<string, Document>();
    const document: Document = {
      id: uuid(),
      children: legacyDocument.children,
      selection: null,
      history: { redos: [], undos: [] },
    };
    documents.set(document.id, document);

    const dictationMode = safeValue({
      description: "dictation mode (legacy)",
      fallback: fallbackDictationMode,
      data: getFromLocalStorage({ key: "dictation_mode", id }),
      validate: isDictationMode,
    });

    const preferences = {
      ...safeValue({
        description: "preferences (legacy)",
        fallback: fallbackPreferences,
        data: getFromLocalStorage({ key: "preferences", id }),
        validate: isLegacyPreferences,
      }),
      /**
       * V1 introduces this property so we don't expect to find it in legacy
       * data. It is set to true as by the point a user is going through this
       * migration from local storage to the IndexedDB we have already migrated
       * their preferences from V2. Otherwise their V2 preferences will override
       * their previous V3 preferences.
       */
      attemptedLegacyPreferencesMigration: true,
    };

    const userState: DecryptedUserStorage = {
      editor: { documents, currentDocumentId: document.id, dictationMode },
      preferences,
    };
    const { key, encryptedData } = await encryptUserState(id, userState);
    users.set(key, encryptedData);
  }

  return users;
};

/**
 * Pulls legacy data from local storage and returns it in a format suitable
 * for the V1 database schema.
 */
export const getLegacyDataFromLocalStorage =
  async (): Promise<EncryptedStorage | null> => {
    const hasLegacyLocalStorageData = checkHasLegacyLocalStorageData();

    if (!hasLegacyLocalStorageData) {
      return null;
    }

    return {
      shared: await getLegacySharedDataFromLocalStorage(),
      users: await getLegacyUsersDataFromLocalStorage(),
    };
  };

import type { Ipc } from "@talktype/types";
import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

export const createWindowIsMaximisedChangeChannel = (
  ipc: Ipc
): EventChannel<boolean> =>
  eventChannel((emit) => {
    const unsubscribe = ipc?.system?.window?.subscribeToIsMaximised?.v1?.(emit);

    return () => unsubscribe?.();
  });

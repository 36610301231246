import type { CommandCreator } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  historyUpdated,
  requestHistory,
} from "@talktype/editor/src/sagas/actions";

export const updateHistory: CommandCreator<"redo" | "undo"> = (type) =>
  function* () {
    yield put(requestHistory(type));

    const { payload: success }: SagaReturnType<typeof historyUpdated> =
      yield take(historyUpdated);

    return success;
  };

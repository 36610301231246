import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences, PartialPreferences } from "@talktype/types";

type SetPreferences = (
  state: Preferences,
  action: PayloadAction<PartialPreferences>
) => Preferences;

/**
 * Handles the setting of preferences into state.
 */
export const setPreferences: SetPreferences = (
  state,
  { payload: preferences }
) => ({
  ...state,
  ...preferences,
  document: { ...state.document, ...preferences.document },
});

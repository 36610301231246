import { createSelector } from "@reduxjs/toolkit";

import { selectLicenceKey } from "./selectLicenceKey";
import { selectTalkTypeDashboardUrl } from "./selectTalkTypeDashboardUrl";
import { LICENCE_KEY_ENDPOINT } from "../../config/web";

export const selectLicenceKeyRedemptionUrl = createSelector(
  [selectTalkTypeDashboardUrl, selectLicenceKey],
  (dashboardUrl, licenceKey) =>
    `${dashboardUrl}${LICENCE_KEY_ENDPOINT}/${licenceKey}`
);

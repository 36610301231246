import type {
  Children,
  DictationMode,
  EditorState,
  PkceState,
  UserState,
  PreferencesState,
  PWAState,
  History,
  Document,
} from "./types";

import { P, isMatching } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

const validSeconds = { unit: "seconds", magnitude: P.number, quantity: "time" };
const validPkceCodePair = { codeChallenge: P.string, codeVerifier: P.string };
const validTokens = {
  accessToken: P.string,
  refreshToken: P.string,
  expiresAt: validSeconds,
  scope: "dictate",
  tokenType: "Bearer",
};
const validPkceState = {
  authUrl: P.string,
  additionalParams: {},
  scopes: P.array(P.string),
  clientId: P.string,
  tokens: P.union(validTokens, P.nullish),
  pkceCodePair: P.union(validPkceCodePair, P.nullish),
};
export const isPkceState = createGuard<PkceState>(validPkceState);

const validMe = {
  createdAt: P.string,
  email: P.string,
  id: P.number,
  updatedAt: P.string,
  uuid: P.string,
};
const validUserState = {
  licenceKey: P.union(P.string, P.nullish),
  loginStatus: P.union("loading", "migrating", "loggedOut", "loggedIn"),
  talkTypeDashboardUrl: P.string,
  me: P.union(validMe, P.nullish),
};
export const isUserState = createGuard<UserState>(validUserState);

export const isCustomPromptDismissedAt = (
  value: unknown
): value is string | null => isMatching(P.union(P.string, P.nullish), value);

const validPWAState = { customPromptDismissedAt: P.union(P.string, P.nullish) };
export const isPWAState = createGuard<PWAState>(validPWAState);

const validText = {
  bold: P.boolean,
  inProgress: P.boolean,
  italic: P.boolean,
  text: P.string,
  type: "text",
  underline: P.boolean,
} as const;

const validLineBreak = {
  bold: P.boolean,
  id: P.string,
  inProgress: P.boolean,
  italic: P.boolean,
  text: P.string,
  type: "linebreak",
  underline: P.boolean,
} as const;
const validParagraph = {
  type: "paragraph",
  children: P.array(P.union(validText, validLineBreak)),
} as const;

const validChild = P.union(validParagraph, validText, validLineBreak);
export const isChildren = (value: unknown): value is Children =>
  isMatching(P.array(validChild), value);

export const isCurrentDocumentId = (value: unknown): value is string | null =>
  isMatching(P.union(P.string, P.nullish), value);

export const isDictationMode = (value: unknown): value is DictationMode =>
  isMatching(P.union("talktype", "anywhere"), value);

const validPoint = { path: P.array(P.number), offset: P.number };
const validSelection = P.union(P.nullish, {
  anchor: validPoint,
  focus: validPoint,
});
export const isSelection = (value: unknown): value is Selection =>
  isMatching(P.union(validSelection), value);

const validBatch = {
  operations: P.array(P.any),
  selectionBefore: validSelection,
};
const validHistory = {
  redos: P.array(validBatch),
  undos: P.array(validBatch),
};
export const isHistory = createGuard<History>(validHistory);

export const isLegacyDocument = (
  value: unknown
): value is Pick<Document, "children"> =>
  isMatching({ children: P.when(isChildren) }, value);

export const isDocument = (value: unknown): value is Document =>
  isMatching(
    {
      id: P.string,
      children: P.when(isChildren),
      selection: P.when(isSelection),
      history: P.when(isHistory),
    },
    value
  );

export const isDocuments = (value: unknown): value is Map<string, Document> =>
  value instanceof Map && Array.from(value.values()).every(isDocument);

export const isEditorState = (value: unknown): value is EditorState =>
  isMatching(
    {
      documents: P.when(isDocuments),
      currentDocumentId: P.when(isCurrentDocumentId),
      dictationMode: P.when(isDictationMode),
    },
    value
  );

const validLegacyPreferences = {
  alwaysOnTop: P.union("always", "never"),
  colourScheme: P.union("normal", "light", "dark"),
  customShortcuts: P.array({ id: P.string, input: P.string, output: P.string }),
  customWords: P.array({ id: P.string, input: P.string }),
  dateFormat: P.union("", "dd/MM/yyyy"),
  dictationLanguage: P.union("en-GB", "en-US"),
  document: {
    fontFamily: P.union(
      "Plus Jakarta Sans",
      "OpenDyslexic",
      "Lexend",
      "Open Sans"
    ),
    fontSize: P.number,
  },
  drawer: {
    isOpen: P.boolean,
    page: P.union("voiceCommands", "customWords", "shortcuts", "history"),
  },
  filterProfanity: P.boolean,
  numberFormat: P.union("automatic", "numerals"),
  silenceTimeout: P.union(
    { quantity: "time", unit: "minutes", magnitude: P.number },
    ""
  ),
  autoPunctuation: P.boolean,
};

const validPreferences = {
  ...validLegacyPreferences,
  attemptedLegacyPreferencesMigration: P.boolean,
};

export const isLegacyPreferences = createGuard<
  Omit<PreferencesState, "attemptedLegacyPreferencesMigration">
>(validLegacyPreferences);

export const isPreferences = createGuard<PreferencesState>(validPreferences);

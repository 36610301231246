import type { Document } from "../types";
import type { UUID } from "@carescribe/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  DictationMode,
  DictationStatus,
  InlineStyles,
} from "@talktype/types";

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { uuid4 } from "@sentry/react/node_modules/@sentry/utils";
import { enableMapSet } from "immer";

import { documentIsEmpty, extractText } from "@carescribe/slate";
import { safeStructuredClone } from "@carescribe/utilities/src/safeStructuredClone";

import { startDictating } from "../sagas/actions";
import { createDocument } from "../utils/createDocument";

// Enable Immer to support Map and Set
enableMapSet();

export type EditorState = {
  documents: Map<string, Document>;
  currentDocumentId: UUID | null;
  dictationMode: DictationMode;
  dictationStatus: DictationStatus;
  styles: Record<InlineStyles, boolean>;
};

export type CombinedState = {
  editor: EditorState;
};

const createInitialState = (): EditorState => {
  const document = createDocument({ id: uuid4() });

  return {
    documents: new Map([[document.id, document]]),
    currentDocumentId: document.id,
    dictationMode: "talktype",
    dictationStatus: "inactive",

    styles: {
      bold: false,
      italic: false,
      underline: false,
    },
  };
};

const initialState = createInitialState();

const setDocumentReducer = (
  state: EditorState,
  { payload: document }: PayloadAction<Omit<Document, "id">>
): void => {
  const currentDocumentId = state.currentDocumentId;
  if (!currentDocumentId) {
    return;
  }

  const existingDocument = state.documents.get(currentDocumentId);
  if (!existingDocument) {
    return;
  }

  state.documents.set(currentDocumentId, {
    ...existingDocument,
    ...document,
    /**
     * Saving Slate editor's history without creating a deep copy
     * does not tend to go well. Leading to:
     *
     * - Redux errors relating to Immer's auto-freezing
     * See: https://github.com/reduxjs/redux-toolkit/discussions/1189
     *
     * - SerializableCheck errors
     */
    ...("history" in document && {
      history: safeStructuredClone(document.history),
    }),
  });
};

const setCurrentDocumentIdReducer = (
  state: EditorState,
  { payload }: PayloadAction<string>
): void => {
  state.currentDocumentId = payload;
};

const dictationLoadingReducer = (state: EditorState): void => {
  state.dictationStatus = "loading";
};

const dictationStartedReducer = (state: EditorState): void => {
  state.dictationStatus = "active";
};

const dictationStoppedReducer = (state: EditorState): void => {
  state.dictationStatus = "inactive";
};

const setDictationModeReducer = (
  state: EditorState,
  { payload }: { payload: DictationMode }
): void => {
  state.dictationMode = payload;
};

const setActiveStylesReducer = (
  state: EditorState,
  { payload }: PayloadAction<Partial<Record<InlineStyles, boolean>>>
): void => {
  state.styles = { ...state.styles, ...payload };
};

const clearActiveStylesReducer = (state: EditorState): void => {
  state.styles = {
    bold: false,
    italic: false,
    underline: false,
  };
};

const slice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setDocument: setDocumentReducer,
    setCurrentDocumentId: setCurrentDocumentIdReducer,
    dictationStarted: dictationStartedReducer,
    stopDictating: dictationStoppedReducer,
    setActiveStyles: setActiveStylesReducer,
    clearActiveStyles: clearActiveStylesReducer,
    setDictationMode: setDictationModeReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(startDictating, dictationLoadingReducer);
  },
});

export const {
  reducer,
  actions: {
    setDocument,
    setCurrentDocumentId,
    dictationStarted,
    stopDictating,
    setActiveStyles,
    clearActiveStyles,
    setDictationMode,
  },
} = slice;

export const selectDictationStatus = (state: CombinedState): DictationStatus =>
  state.editor.dictationStatus;

export const selectDictationMode = (state: CombinedState): DictationMode =>
  state.editor.dictationMode;

export const selectCurrentDocumentId = (state: CombinedState): string | null =>
  state.editor.currentDocumentId;

export const selectDocuments = (state: CombinedState): Map<string, Document> =>
  state.editor.documents;

export const selectCurrentDocument = createSelector(
  [selectCurrentDocumentId, selectDocuments],
  (id, documents) => (id === null ? null : documents.get(id) ?? null)
);

export const selectDocumentLength = createSelector(
  selectCurrentDocument,
  (document) => extractText(document).length
);

export const selectDocumentIsEmpty = createSelector(
  [selectCurrentDocument],
  (document) => (document ? documentIsEmpty(document) : true)
);

export const selectDocumentHistory = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.history : null)
);

export const selectDocumentIsUndoable = createSelector(
  [selectDocumentHistory],
  (history) => (history ? history.undos.length > 0 : false)
);

export const selectDocumentIsRedoable = createSelector(
  [selectDocumentHistory],
  (history) => (history ? history.redos.length > 0 : false)
);

export const selectDocumentSelection = createSelector(
  [selectCurrentDocument],
  (document) => (document ? document.selection : null)
);

export const selectDictating = createSelector(
  [selectDictationStatus],
  (dictationStatus) => dictationStatus === "active"
);

export const editorIsActive = createSelector(
  [selectDocumentIsEmpty, selectDictating],
  (empty, dictating) => !empty || dictating
);

import type { DocumentFontFamily } from "@talktype/types";
import type { ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { documentFontFamilies } from "@talktype/config";

import styles from "./fontFamilySettings.module.scss";
import { messages } from "./messages";
import { RadioGroup } from "../../../RadioGroup";
import { ScreenReaderOnly } from "../../../ScreenReaderOnly";

export type FontFamilySettingsProps = {
  chosenFontFamily: DocumentFontFamily;
  onChange: (fontFamily: DocumentFontFamily) => void;
};

export const FontFamilySettings = ({
  chosenFontFamily,
  onChange,
}: FontFamilySettingsProps): ReactElement => (
  <>
    <RadioGroup
      legend={{ text: messages.title, visible: false }}
      layout="vertical"
    >
      {documentFontFamilies.map((availableFontFamily) => {
        const isSelected = availableFontFamily === chosenFontFamily;

        return (
          <label
            key={availableFontFamily}
            className={classNames(
              styles.label,
              [isSelected, styles.selected],
              createSelectorClassName(
                isSelected ? "selected-font-family" : "font-family",
                "interactive"
              )
            )}
            style={{ fontFamily: availableFontFamily }}
          >
            {availableFontFamily}

            {/**
             * These placeholder spans here to ensure these styles get requested
             * when the font menu opens. This means that we avoid FOUT when the
             * user chooses a font, and has inline formatting in their document
             */}
            <span
              style={{ fontFamily: availableFontFamily }}
              className={styles.bold}
            />
            <span
              style={{ fontFamily: availableFontFamily }}
              className={styles.italic}
            />
            <span
              style={{ fontFamily: availableFontFamily }}
              className={classNames(styles.bold, styles.italic)}
            />

            <ScreenReaderOnly
              tag="input"
              type="radio"
              checked={isSelected}
              name="font-family"
              value={availableFontFamily}
              onChange={(): void => onChange(availableFontFamily)}
            />
          </label>
        );
      })}
    </RadioGroup>
  </>
);

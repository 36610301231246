import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestFinaliseDictation } from "@talktype/actions";

import { editorClicked, editorKeyDown } from "../actions";

/**
 * Finalise in-progress results whenever:
 *
 * - editor is clicked
 * - key is pressed
 */
export const finaliseDictation = function* (): SagaIterator<void> {
  yield takeEvery(
    [editorClicked, editorKeyDown],
    function* (): SagaIterator<void> {
      yield put(requestFinaliseDictation());
    }
  );
};

import type { SagaIterator } from "redux-saga";

import { all, call } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { identifyUser } from "./identifyUser";
import { manageTimestamps } from "./manageTimestamps";
import { trackAppLoaded } from "./trackAppLoaded";
import { trackAppUnloaded } from "./trackAppUnloaded";
import { trackAppVisibilityChanged } from "./trackAppVisibilityChanged";
import { setUpTrackTalkTypeEvents } from "./trackTalkTypeEvent";

export const setUpTracking = function* (
  genericEventData: Record<string, unknown>
): SagaIterator<void> {
  yield retry(function* () {
    yield call(setUpTrackTalkTypeEvents, genericEventData);
  });

  yield all(
    [
      manageTimestamps,
      identifyUser,
      trackAppLoaded,
      trackAppUnloaded,
      trackAppVisibilityChanged,
    ].map(retry)
  );
};

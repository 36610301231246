import type { SupportedAppErrors } from "@talktype/types";

export const errors: SupportedAppErrors = {
  noInternetConnection: {
    code: "NO_INTERNET_CONNECTION",
  },
  duplicateEntry: {
    code: "DUPLICATE_ENTRY",
  },
  fieldRequired: {
    code: "FIELD_REQUIRED",
  },
  inputExceedsMaxLength: {
    code: "INPUT_EXCEEDS_MAX_LENGTH",
  },
  entityLimitReached: {
    code: "ENTITY_LIMIT_REACHED",
  },
  prohibitReservedKeywords: {
    code: "PROHIBIT_RESERVED_KEYWORDS",
  },
};

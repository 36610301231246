import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, takeEvery, call } from "redux-saga/effects";

import {
  requestEditorPrecedingText,
  broadcastEditorPrecedingText,
} from "./actions";
import { getInProgressRange } from "../../utils/getInProgressRange";
import { getTextPrecedingRange } from "../../utils/getTextPrecedingRange";
import { getEditor } from "../utils";

/**
 * Handles requests for the preceding text in the editor.
 *
 * - Attempts getting the preceding text from the in-progress dictation
 * - Attempts getting the preceding text from the selection
 * - Otherwise, returns an empty string
 */
export const manageEditorPrecedingText = function* (): SagaIterator<void> {
  yield takeEvery(requestEditorPrecedingText, function* (): SagaIterator<void> {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(broadcastEditorPrecedingText(""));
      return;
    }

    const inProgressRange: SagaReturnType<typeof getInProgressRange> =
      yield call(getInProgressRange, editor);

    const selectionRange = editor.selection;
    const range = inProgressRange ?? selectionRange;

    if (range) {
      const precedingText: SagaReturnType<typeof getTextPrecedingRange> =
        yield call(getTextPrecedingRange, editor, range);

      yield put(broadcastEditorPrecedingText(precedingText));
      return;
    }

    // Default to empty string
    yield put(broadcastEditorPrecedingText(""));
  });
};

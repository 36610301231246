import type { Editor, Path, Node, Text } from "slate";

import { isLeaf } from "../guards/isLeaf";

export const getLeavesWithProperty = <
  Property extends keyof Text,
  Value extends Text[Property]
>(
  editor: Editor,
  property: Property,
  value: Value
): [Text, Path][] =>
  Array.from(
    editor.nodes({
      at: [],
      match: (node: Node): boolean => isLeaf(node) && node[property] === value,
    })
  );

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, take } from "redux-saga/effects";

import {
  requestApplyVoiceCommands,
  appliedVoiceCommands,
  requestApplyTransformations,
  appliedTransformations,
} from "@talktype/results/src/sagas/actions";

import { requestAssembleTranscript, assembledTranscript } from "./actions";

/**
 * Assembles a result's transcript.
 *
 * The transcript is assembled in a pipeline of effects:
 * - Apply voice commands
 * - Apply transformations
 */
export const assembleTranscriptPipeline = function* (): SagaIterator<void> {
  yield takeEvery(
    requestAssembleTranscript,
    function* ({ payload: result }): SagaIterator<void> {
      // Entry point -> apply voice commands
      yield put(requestApplyVoiceCommands(result));

      // Apply voice commands -> Apply transformations
      const {
        payload: resultAfterCommands,
      }: SagaReturnType<typeof appliedVoiceCommands> = yield take(
        appliedVoiceCommands
      );
      yield put(requestApplyTransformations(resultAfterCommands));

      // Apply transformations -> Exit point
      const {
        payload: transformedResult,
      }: SagaReturnType<typeof appliedTransformations> = yield take(
        appliedTransformations
      );
      yield put(assembledTranscript(transformedResult));
    }
  );
};

import type { AppMetadata } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, select, takeEvery } from "redux-saga/effects";

import { StrictMap } from "@carescribe/utilities/src/StrictMap";

import { selectActiveApp } from "@talktype/system/src/reducers/system/selectors/selectActiveApp";

import { assignedResult, requestAssignResult } from "./actions";

/**
 * Responsible for assigning the target app to each result.
 *
 * Once a result has been assigned to an app, subsequent results for the same
 * resultId will be assigned to the same app.
 */
export const manageAssignResult = function* (): SagaIterator<void> {
  const previouslyAssignedApps = new StrictMap<string, AppMetadata>([], {
    // We don't expect older history past this number of entries to be relevant
    sizeLimit: 30,
  });

  yield takeEvery(requestAssignResult, function* ({ payload: result }) {
    const previouslyAssignedApp = previouslyAssignedApps.get(result.resultId);

    if (previouslyAssignedApp) {
      yield put(
        assignedResult({ ...result, targetApp: previouslyAssignedApp })
      );
      return;
    }

    const activeApp: SagaReturnType<typeof selectActiveApp> = yield select(
      selectActiveApp
    );

    previouslyAssignedApps.set(result.resultId, activeApp);
    yield put(assignedResult({ ...result, targetApp: activeApp }));
  });
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageBodyColour } from "./body/manageBodyColour";
import { manageStatusBarColour } from "./status-bar/manageStatusBarColour";

export function* setUpColourScheme(): SagaIterator<void> {
  yield all([manageBodyColour, manageStatusBarColour].map(retry));
}

import type { ReactElement } from "react";

import { ArrowRight } from "@phosphor-icons/react";

import { messages } from "./messages";
import styles from "./shortcutDiagram.module.scss";
import { IconContainer } from "../../IconContainer";
import { Representation } from "../../Representation";

export const ShortcutDiagram = (): ReactElement => (
  <div className={styles.container}>
    <div className={styles.column}>
      <span className={styles.title}>{messages.triggerTitle}</span>

      <Representation.Input style="outlined">
        {messages.triggerExample}
      </Representation.Input>
    </div>

    <IconContainer className={styles.icon}>
      <ArrowRight />
    </IconContainer>

    <div className={styles.column}>
      <span className={styles.title}>{messages.replacementTitle}</span>
      <Representation.Output style="outlined">
        {messages.replacementExample}
      </Representation.Output>
    </div>
  </div>
);

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { restoreMarks } from "./restoreMarks";
import { trackMarks } from "./trackMarks";

/**
 * Slate stores marks meant for upcoming text in editor.marks.
 * Whenever in-progress text is added, it inherits these marks.
 *
 * When the transcriber sends an updated version of the in-progress
 * text, the previous in-progress text is deleted and the process starts
 * again. However, by default, the original editor.marks are overridden
 * by the deleted in-progress text marks and therefore "stick" around.
 *
 * This can lead to undesired behaviour (e.g. incorrect style changes).
 *
 * To counteract this, we save a snapshot of editor.marks before starting
 * to insert new in-progress text. Whenever in-progress text is updated,
 * we restore the marks from this snapshot.
 */
export const stickyMarks = function* (): SagaIterator<void> {
  yield all([trackMarks, restoreMarks].map(retry));
};

import type {
  DictationMode,
  Document,
  EditorState,
  PkceState,
  PreferencesState,
  PWAState,
  UserState,
} from "./types";

import { v4 as uuidv4 } from "uuid";

export const fallbackUserState: UserState = {
  licenceKey: "",
  talkTypeDashboardUrl: "",
  me: null,
  loginStatus: "loggedOut",
};

export const fallbackPkceState: PkceState = {
  authUrl: "",
  clientId: "",
  tokens: null,
  scopes: ["dictate"],
  additionalParams: { redirect_uri: "https://app.talk-type.com" },
  pkceCodePair: null,
};

export const fallbackPWAState: PWAState = { customPromptDismissedAt: null };

export const fallbackLegacyDocument: Pick<Document, "children"> = {
  children: [],
};

export const fallbackDictationMode: DictationMode = "talktype";

export const fallbackDocument: Document = {
  id: uuidv4(),
  children: [{ type: "paragraph", children: [] }],
  selection: null,
  history: { redos: [], undos: [] },
};

export const fallbackEditorState: EditorState = {
  documents: new Map([[fallbackDocument.id, fallbackDocument]]),
  currentDocumentId: fallbackDocument.id,
  dictationMode: "talktype",
};

export const fallbackPreferences: PreferencesState = {
  alwaysOnTop: "never",
  colourScheme: "normal",
  customShortcuts: [],
  customWords: [],
  dateFormat: "",
  dictationLanguage: "en-GB",
  filterProfanity: true,
  document: { fontFamily: "Plus Jakarta Sans", fontSize: 20 },
  numberFormat: "automatic",
  silenceTimeout: { magnitude: 5, unit: "minutes", quantity: "time" },
  drawer: { isOpen: false, page: "voiceCommands" },
  autoPunctuation: false,
  attemptedLegacyPreferencesMigration: false,
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities/src/getIpc";

import { setAppVersion } from "../reducers/system";

export const getAppVersion = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  const getAppVersion = ipc?.system?.appVersion?.get?.v1;

  if (!getAppVersion) {
    return;
  }

  const appVersion: SagaReturnType<typeof getAppVersion> = yield call(
    getAppVersion
  );

  yield put(setAppVersion(appVersion));
};

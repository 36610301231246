import type { AppDispatch, RootState } from "@talktype/store";
import type { FontSettingsProps } from "@talktype/ui/src/FormattingToolbar/FontSettings";

import { connect } from "react-redux";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import {
  selectDocumentFontSize,
  incrementDocumentFontSize,
  selectDocumentFontFamily,
  setDocumentFontFamily,
} from "@talktype/preferences";
import { FontSettings as Component } from "@talktype/ui/src/FormattingToolbar/FontSettings";

const mapStateToProps = (
  state: RootState
): Pick<FontSettingsProps, "fontSize" | "fontFamily"> => ({
  fontSize: selectDocumentFontSize(state),
  fontFamily: selectDocumentFontFamily(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<FontSettingsProps, "onChangeFontSize" | "onChangeFontFamily"> => ({
  onChangeFontSize: (size) =>
    dispatch(broadcast(incrementDocumentFontSize(size))),
  onChangeFontFamily: (fontFamily) =>
    dispatch(broadcast(setDocumentFontFamily(fontFamily))),
});

export const FontSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

import type {
  CSSProperties,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";

import { useEffect, useState, useRef } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./textInput.module.scss";
import { IconContainer } from "../IconContainer";

export type TextInputProps = {
  size: "xs" | "sm" | "md" | "lg";
  icon?: ReactNode;
  button?: ReactNode;
  inputProps: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    "data-hj-allow"?: true;
    /**
     * Only needed temporarily (hopefully) due to a bug with React's `autoFocus`
     * see {@link https://github.com/facebook/react/issues/23301}.
     */
    autofocus?: "";
  };
};

export const TextInput = ({
  inputProps,
  size,
  icon,
  button,
}: TextInputProps): ReactElement => {
  const [cssVariables, setCssVariables] = useState<CSSProperties>({});
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const width = buttonRef.current?.getBoundingClientRect().width ?? 0;
    const properties = { "--button-wrapper-width": `${width}px` };

    setCssVariables(properties as CSSProperties);
  }, [button]);

  return (
    <span className={classNames(styles.wrapper, styles[size])}>
      {icon && <IconContainer className={styles.icon}>{icon}</IconContainer>}

      <input
        {...inputProps}
        className={classNames(styles.input, inputProps.className)}
        style={cssVariables}
      />

      {button && (
        <span ref={buttonRef} className={styles.button}>
          {button}
        </span>
      )}
    </span>
  );
};

/**
 * Reference needed due to TSConfig limitations. TSConfig cannot merge array
 * values, and since we're already using the "types" property to add IPC to
 * the window, we cannot also use it to add analytics.
 */
/// <reference types="@carescribe/analytics/src/segment" />

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";
import type { EmptyObject } from "@carescribe/types";
import type { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { takeEvery, select, call } from "redux-saga/effects";

import { isTest } from "@carescribe/utilities/src/testing/isTest";
import { getISOString } from "@carescribe/utilities/src/getISOString";

// Type jiggery-pokery needed here due to the way TS handles overloads. We want
// to be able to call analytics.track indirectly through `call` for testing, but
// doing so causes type errors because Parameters<T> always resolves the last
// overload of T, which is not the overload we're using in this file.
type Tracker = (event: string, properties: Record<string, unknown>) => void;

export type TrackEvent<
  CombinedState,
  Payload extends { name: string; data: Record<string, unknown> | null }
> = (
  selector: (state: CombinedState) => Record<string, unknown>,
  actionCreator: ActionCreatorWithPayload<Payload>
) => SagaIterator<void>;

/**
 * Tracks an event
 *
 * Equivalent to calling `analytics.track`, but feeds in some
 * generic data to save from having to do so manually
 */
export const trackEvent = function* <
  CombinedState,
  Payload extends { name: string; data: Record<string, unknown> | null }
>(
  selector: (state: CombinedState) => Record<string, unknown>,
  actionCreator: ActionCreatorWithPayload<Payload>
): SagaIterator<void> {
  yield takeEvery(actionCreator, function* ({ payload: { name, data } }) {
    if (!("analytics" in window)) {
      return;
    }

    // Don't select in non-testing environments. This stop us having to update
    // all tests that call on sagas that trigger tracking to include state
    const shouldSelectData: SagaReturnType<typeof isTest> = yield call(isTest);

    const genericData: SagaReturnType<typeof selector> | EmptyObject =
      shouldSelectData ? {} : yield select(selector);

    const occurredAt: SagaReturnType<typeof getISOString> = yield call(
      getISOString
    );

    const combinedData = {
      ...genericData,
      ...data,
      occurredAt,
    };

    yield call<Tracker>(window.analytics.track, name, combinedData);
  });
};

import type { Void } from "@carescribe/types";

import { captureException } from "@sentry/react";

import { noOp, partition } from "./fp";
import { isObjectWithMessage } from "./guards/isObjectWithMessage";
import { isTest } from "./testing/isTest";

type ConsoleParams = Parameters<typeof console.info>;

const processError = (scope: string, messages: ConsoleParams): void => {
  const [stringMessages, nonStringMessages] = partition(
    (message) => typeof message === "string",
    messages
  );

  if (nonStringMessages.length === 0) {
    console.error(scope, "|", ...messages);
    return;
  }

  // Print error as a warning so it is not picked up by Sentry's console
  // integration but still visible for debugging purposes.
  console.warn(scope, "|", ...messages);

  captureException(new Error(scope + " | " + stringMessages.join(" ")), {
    extra: {
      messages: nonStringMessages.map((error) =>
        isObjectWithMessage(error) ? error.message : error
      ),
      cause: nonStringMessages.map((error) =>
        isObjectWithMessage(error) && "cause" in error ? error.cause : null
      ),
    },
  });
};

export const createLogger = (
  scope: string
): {
  log: (...messages: ConsoleParams) => void;
  logError: (...messages: ConsoleParams) => void;
  logWarning: (...messages: ConsoleParams) => void;
  logGroup: (...messages: ConsoleParams) => void;
  logGroupCollapsed: (...messages: ConsoleParams) => void;
  logGroupEnd: Void;
} =>
  isTest()
    ? {
        log: noOp,
        logError: noOp,
        logWarning: noOp,
        logGroup: noOp,
        logGroupCollapsed: noOp,
        logGroupEnd: noOp,
      }
    : {
        log: (...messages): void => {
          console.info(scope, "|", ...messages);
        },
        logError: (...messages): void => {
          processError(scope, messages);
        },
        logWarning: (...messages): void => {
          console.warn(scope, "|", ...messages);
        },
        logGroup: (...messages): void => {
          console.group(scope, "|", ...messages);
        },
        logGroupCollapsed: (...messages): void => {
          console.groupCollapsed(scope, "|", ...messages);
        },
        logGroupEnd: (): void => {
          console.groupEnd();
        },
      };

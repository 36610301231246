import { createSelector } from "@reduxjs/toolkit";

import { selectFeatureFlags } from "./selectFeatureFlags";

/**
 * Selects the dictate to app feature flag from state.
 */
export const selectDictateToApp = createSelector(
  selectFeatureFlags,
  (state) => state.dictateToApp
);

import type { Toast } from "@talktype/types";
import type { ReactElement } from "react";

import { MicrophoneSlash } from "@phosphor-icons/react";

import { Alert } from "@talktype/ui/src/Alert";

import { messages } from "./messages";

export const FailedMicrophoneAccessToast = (toast: Toast): ReactElement => (
  <Alert
    colour="destructive"
    hierarchy="primary"
    format="stacked"
    icon={<MicrophoneSlash />}
    onClose={toast.dismiss}
    label={null}
    {...messages}
  />
);

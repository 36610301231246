import type { DocumentFontSize } from "@talktype/types";

import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the document font size from state.
 */
export const selectDocumentFontSize = createSelector(
  selectPreferences,
  ({ document: { fontSize } }): DocumentFontSize => fontSize
);

import type { InternalRetryConfig } from "./types/InternalRetryConfig";
import type { SagaIterator } from "redux-saga";
import type { ForkEffect } from "redux-saga/effects";

import { call, delay, fork } from "redux-saga/effects";

import { getNextRetryConfig } from "./getNextRetryConfig";
import { createLogger } from "../../../log";
import { secondsToMilliseconds, toSeconds } from "../../../timing";

// How long to wait before resetting the retries count
const resetAfter = toSeconds(3);
// The frequency of retry attempts
const frequency = toSeconds(0.1);

const { logError } = createLogger("Retry");

/**
 * Retry
 *
 * The same as fork, but restarts itself in the case of an unhandled error.
 */
export const retry = (
  saga: () => SagaIterator<void> | void
): ForkEffect<void> => {
  const startingConfig: InternalRetryConfig = {
    // How many retries to attempt
    retries: 5,
    // The time the saga was initially forked
    startTime: +new Date(),
  };

  // Define an internal recursive function. This function always has access to
  // the original retry number, which is used for resetting the retry count
  // after an elapsed period
  const internalRetry = (
    currentConfig: InternalRetryConfig
  ): ForkEffect<void> =>
    fork(function* () {
      try {
        // Happy path: run the saga
        yield call(saga);
      } catch (error) {
        // In the case of an error, don't restart immediately to prevent speedy
        // infinite loops burning through the retry count immediately. This
        // provides some time for things like corrupted document recovery to
        // take effect before we try to restart
        yield delay(secondsToMilliseconds(frequency));

        if (currentConfig.retries > 0) {
          // Recurse with new start time and retry count
          yield internalRetry(
            getNextRetryConfig(startingConfig, currentConfig, resetAfter)
          );
        }

        yield call(logError, `Error in "${saga.name}"`, error, currentConfig);
      }
    });

  // Return the product of the recursion, which is a fork effect containing
  // the try/catch
  return internalRetry(startingConfig);
};

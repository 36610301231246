export const assertUnreachable = (unexpectedValue: never): never => {
  console.error("Unexpected Value:", unexpectedValue);
  throw new Error("Didn't expect to get here");
};

/**
 * Assert unreachable non-destructively
 *
 * This is similar to the `assertUnreachable` function, but does not throw an
 * error. When values originating from outside our type system are put through
 * a switch statement with an `assertUnreachable` in the default, if the value
 * is updated to contain additional information, it will cause our application
 * to crash, because a value will be present for JS that TS has no idea about.
 * In this case (e.g. a new property was added to an API response) we shouldn't
 * want JS to crash, but we would still like TS to warn us when the switch
 * doesn't cover everything that TS expects to be there. So in that case, use
 * this function instead of `assertUnreachable`.
 *
 * NB: the overloads of this function are there to ensure that we can type the
 * return type depending on the number of arguments passed in.
 */
export function assertUnreachableNonDestructively<T>(
  unexpectedValue: never,
  optionalReturnValue: T
): T;
export function assertUnreachableNonDestructively(
  unexpectedValue: never,
  optionalReturnValue?: never
): void;
export function assertUnreachableNonDestructively<T>(
  unexpectedValue: never,
  optionalReturnValue?: T
): T | void {
  console.error("Unexpected Value:", unexpectedValue);
  return optionalReturnValue;
}

/**
 * Flatten
 *
 * Flattens a union of types into a non-array version
 *
 * e.g. `Flatten<string[] | number>` = `string | number`
 */
export type Flatten<T> = T extends (infer U)[] ? Flatten<U> : T;

/**
 * Collections
 *
 * Only keeps array types from a union
 *
 * e.g. `Flatten<string[] | number>` = `string[]`
 */
export type Collections<T> = T extends unknown[] ? T : never;

import type { ErrorBoundaryProps } from "@carescribe/ui/src/components/ErrorBoundary";

import { connect } from "react-redux";

import { ErrorBoundary as Component } from "@carescribe/ui";

import { editorErrored } from "@talktype/editor";

const mapDispatchToProps: Pick<ErrorBoundaryProps, "onError"> = {
  onError: editorErrored,
};

export const ErrorBoundary = connect(null, mapDispatchToProps)(Component);

import type { InitialResult } from "@talktype/types";

import { hasPropertyOfType } from "@carescribe/utilities/src/guards/hasPropertyOfType";
import { isObject } from "@carescribe/utilities/src/guards/isObject";

export const isResult = (data: unknown): data is InitialResult =>
  isObject(data) &&
  hasPropertyOfType(data, "resultId", "string") &&
  data.resultId.length > 0 &&
  hasPropertyOfType(data, "transcript", "string") &&
  hasPropertyOfType(data, "isFinal", "boolean");

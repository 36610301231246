import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./dictationHistoryEntry.module.scss";
import { DictationHistoryCopyButton } from "../DictationHistoryCopyButton";
import { IconContainer } from "../IconContainer";

export type DictationHistoryEntryProps = {
  text: string;
  isFinal: boolean;
  icon: ReactNode;
  description: string;
  onCopy: () => void;
};

export const DictationHistoryEntry = ({
  text,
  isFinal,
  icon,
  description,
  onCopy,
}: DictationHistoryEntryProps): ReactElement => (
  <div className={classNames(styles.container)}>
    {text && (
      <p className={styles.text} data-hj-suppress>
        {text}
      </p>
    )}

    <div className={classNames(styles.details)}>
      <div className={classNames(styles.descriptionWrapper)}>
        {icon && <IconContainer className={styles.icon}>{icon}</IconContainer>}
        <p className={styles.description}>{description}</p>
      </div>
      {
        <div
          className={classNames(styles.copyButton, [isFinal, styles.visible])}
        >
          <DictationHistoryCopyButton onCopy={onCopy} />
        </div>
      }
    </div>
  </div>
);

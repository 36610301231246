import type { ReactElement, ReactNode } from "react";

import { X, Export } from "@phosphor-icons/react";
import { useEffect } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./appleInstruction.module.scss";
import { messages } from "./messages";

export const AppleInstruction = ({
  app,
  slideIn,
  onDismiss,
  onMount,
  onUnmount,
}: {
  app: { name: string; icon: ReactNode };
  slideIn?: boolean;
  onDismiss: () => void;
  onMount: (() => void) | null;
  onUnmount: (() => void) | null;
}): ReactElement => {
  useEffect(() => {
    onMount?.();
    return () => {
      onUnmount?.();
    };
  }, [onMount, onUnmount]);

  return (
    <aside className={styles.container}>
      <button
        onClick={onDismiss}
        className={classNames(styles.cancelButton, [!!slideIn, styles.slideIn])}
        aria-label={messages.cancel}
      >
        <X />
      </button>

      <div className={styles.appIcon}>{app.icon}</div>

      <div className={styles.explanation}>
        <h2 className={styles.appName}>{app.name}</h2>

        <p className={styles.addToHomeScreen}>{messages.addToHomeScreen}</p>

        <p className={styles.callToAction}>
          {messages.tap} <strong>{messages.share}</strong>
          <div className={styles.exportIcon}>
            <Export weight="bold" />
          </div>
          {messages.then} <strong>{messages.addToHomeScreen}</strong>
        </p>
      </div>
    </aside>
  );
};

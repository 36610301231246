import type { NativeInstallPromptResult } from "../types/NativeInstallPromptResult";
import type { PWAState } from "../types/PWAState";
import type { PayloadAction } from "@reduxjs/toolkit";

export const setCustomPromptDismissedAt = (
  state: PWAState,
  { payload }: PayloadAction<string | null>
): void => {
  state.customPromptDismissedAt = payload;
};

export const setSupportsNativeInstall = (
  state: PWAState,
  { payload }: PayloadAction<boolean>
): void => {
  state.supportsNativeInstall = payload;
};

export const setNativeInstallPromptResult = (
  state: PWAState,
  { payload }: PayloadAction<NativeInstallPromptResult | null>
): void => {
  state.nativePromptResult = payload;
};

import type { EditorType } from "../../editor";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";
import { ReactEditor } from "slate-react";

import { base64UrlEncode } from "@carescribe/utilities/src/base64UrlEncode";
import { copyToClipboard } from "@carescribe/utilities/src/copyToClipboard";
import { deepCloneElement } from "@carescribe/utilities/src/deepCloneElement";

import { editorContentsCopied, requestCopyEditorContents } from "../actions";
import { getEditor } from "../utils/getEditor";

/**
 * Get HTML
 *
 * Extract the HTML content from the editor, along with some processing:
 *
 * - Editor contents contain line breaks represented both by new line characters
 * and BR tags (for Slate display reasons). This results in double the number
 * of BR elements, hence we trim out the extra ones.
 *
 * - Add metadata to the root element of the copied content to allow Slate to
 * interpret the content when it is pasted back in.
 */
export const getHTML = (editor: EditorType): string => {
  const domNode = deepCloneElement(ReactEditor.toDOMNode(editor, editor));

  // Trim out extra line breaks
  domNode
    .querySelectorAll("br")
    .forEach((br) => br.nextElementSibling?.remove());

  // Set metadata
  domNode.firstElementChild?.setAttribute(
    "data-slate-fragment",
    base64UrlEncode(JSON.stringify(editor.children))
  );

  return domNode.innerHTML;
};

/**
 * Get Text
 *
 * Get the plain text equivalent of the editor's contents. Replaces new lines
 * with spaces to give the best approximation of the content when pasted into a
 * plain text editor.
 */
export const getText = (editor: EditorType): string =>
  editor.children.map((_, i) => editor.string([i])).join("\n\n");

export const setUpCopy = function* (): SagaIterator<void> {
  yield takeEvery(requestCopyEditorContents, function* () {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(editorContentsCopied({ characterCount: 0 }));
      return;
    }

    const html: SagaReturnType<typeof getHTML> = yield call(getHTML, editor);
    const text: SagaReturnType<typeof getText> = yield call(getText, editor);
    const characterCount = text.length;

    yield call(copyToClipboard, { html, text });

    yield put(editorContentsCopied({ characterCount }));
  });
};

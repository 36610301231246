import type { PayloadAction } from "@reduxjs/toolkit";
import type { CustomWord, Preferences } from "@talktype/types";

type EditCustomWord = (
  state: Preferences,
  action: PayloadAction<CustomWord>
) => void;

export const editCustomWord: EditCustomWord = (state, { payload: word }) => {
  const index = state.customWords.findIndex(
    (currentWord) => currentWord.id === word.id
  );
  if (index !== -1) {
    state.customWords[index] = word;
  }
};

import type { SyntheticEvent } from "react";

/**
 * Given an event, calls its preventDefault method.
 *
 * Useful when using within sagas.
 */
export const preventDefault = function (event: Event | SyntheticEvent): void {
  event.preventDefault();
};

/**
 * Joins the passed-in class name strings or conditional tuples into a
 * space-separated string for use in the DOM.
 *
 * A conditional tuple is a two-element array where the first element is a
 * boolean condition,and the second element is a string class name
 *
 * @example
 * // Using only strings
 * classNames("container", "header");
 * // "container header"
 *
 * @example
 * // Using a mix of strings and conditional tuples to create dynamic classes
 * classNames("button", [true, "primary"], [false, "disabled"]);
 * // "button primary"
 *
 */

export const classNames = (
  ...names: (string | undefined | [boolean | undefined, string | undefined])[]
): string =>
  names
    .reduce((acc: string, name) => {
      if (typeof name === "string") {
        return acc.concat(" ", name);
      }
      if (Array.isArray(name) && name[0] && name[1]) {
        return acc.concat(" ", name[1]);
      }
      return acc;
    }, "")
    .trim();

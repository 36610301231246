import type { Reshaper } from "./types/Reshaper";

import { isLegacyDateFormat } from "../../../../guards";

/**
 * Reshape Date Format
 *
 * Convert legacy date format and copy into preferences.
 */
export const reshapeDateFormat: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    !isLegacyDateFormat(legacyPreferences.date_format)
      ? newPreferences
      : {
          ...newPreferences,
          dateFormat:
            legacyPreferences.date_format === "Off" ? "" : "dd/MM/yyyy",
        };

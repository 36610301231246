type Direction = "up" | "down" | "left" | "right";

const modifyInstructions: Record<
  Direction,
  ["move", "forward" | "backward", "line" | "character"]
> = {
  up: ["move", "backward", "line"],
  down: ["move", "forward", "line"],
  left: ["move", "backward", "character"],
  right: ["move", "forward", "character"],
};

type ModifiableSelection = Selection & {
  modify: (alter: string, direction: string, granularity: string) => void;
};

/** Type guard necessary as while the built-in modify method is well
 * supported, it is not yet part of the standard TypeScript Selection interface.
 */
const hasModifyMethod = (
  selection: Selection
): selection is ModifiableSelection =>
  "modify" in selection &&
  typeof (selection as ModifiableSelection).modify === "function";

export const moveCursor = (direction: Direction): boolean => {
  const selection = window.getSelection();

  if (selection && hasModifyMethod(selection)) {
    selection.modify(...modifyInstructions[direction]);

    return true;
  }

  return false;
};

import type { StackedDropdownMenuRowProps } from "../StackedDropdownMenuRow";
import type { DictationMode, DictationStatus } from "@talktype/types";
import type { ReactElement } from "react";

import { Browser, Cursor } from "@phosphor-icons/react";

import { usePopover } from "@carescribe/ui";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./dictationModeMenu.module.scss";
import { messages } from "./messages";
import { DictationModeButton } from "../DictationModeButton";
import { ScreenReaderOnly } from "../ScreenReaderOnly";
import { StackedDropdownMenuRow } from "../StackedDropdownMenuRow";

export type DictationModeMenuProps = {
  mode: DictationMode;
  status: DictationStatus;
  onChangeMode: ((mode: DictationMode) => void) | null;
};

export const DictationModeMenu = ({
  mode,
  status,
  onChangeMode,
}: DictationModeMenuProps): ReactElement => {
  const { containerProps, buttonProps, popoverProps } =
    usePopover<HTMLDivElement>();

  const items: ({ id: DictationMode } & StackedDropdownMenuRowProps)[] = [
    {
      id: "talktype",
      title: messages.talktype.title,
      description: messages.talktype.description,
      Icon: Browser,
      onClick: (): void => {
        onChangeMode?.("talktype");
        buttonProps.onClick();
      },
    },
    {
      id: "anywhere",
      title: messages.anywhere.title,
      description: messages.anywhere.description,
      Icon: Cursor,
      onClick: (): void => {
        onChangeMode?.("anywhere");
        buttonProps.onClick();
      },
    },
  ];

  return (
    <div {...containerProps} className={styles.container}>
      <DictationModeButton {...buttonProps} disabled={onChangeMode === null}>
        <ScreenReaderOnly tag="span">
          {messages.changeDictationMode}
        </ScreenReaderOnly>
        <span aria-hidden>{messages[mode][status].title}</span>
      </DictationModeButton>
      <dialog
        {...popoverProps}
        className={classNames(
          styles.itemsSurface,
          createSelectorClassName("dictation-mode-menu", "section")
        )}
      >
        <ul className={styles.items}>
          {items.map((item) => (
            <li key={item.id}>
              <StackedDropdownMenuRow {...item} />
            </li>
          ))}
        </ul>
      </dialog>
    </div>
  );
};

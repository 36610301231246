import type { HTMLAttributes, ReactElement, ReactNode } from "react";

import { Fragment } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { intersperse } from "@carescribe/utilities/src/fp";

import styles from "./listing.module.scss";
import { messages } from "./messages";
import { Representation } from "../Representation";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type ListingProps = {
  style: "default" | "outlined";
  input: string | string[];
  output?: string | { value: string; pronunciation: string };
} & HTMLAttributes<HTMLParagraphElement>;

export const Listing = ({
  input,
  output,
  style,
  ...props
}: ListingProps): ReactElement => {
  const inputArray = Array.isArray(input) ? input : [input];
  const outputObject =
    typeof output === "string"
      ? { value: output, pronunciation: output }
      : output;

  const separator = (
    <>
      <ScreenReaderOnly tag="span">{messages.or}</ScreenReaderOnly>
      <span aria-hidden> / </span>
    </>
  );

  const inputs = inputArray.map((input) => (
    <Representation.Input key={input} style={style}>
      {outputObject && (
        <ScreenReaderOnly tag="span">{messages.saying} </ScreenReaderOnly>
      )}

      {input}
    </Representation.Input>
  ));

  return (
    <p
      {...props}
      className={classNames(
        createSelectorClassName("listing", "element"),
        styles.container
      )}
    >
      {intersperse<ReactNode>(
        // add a separator between each possible input, equivalent to .join,
        // but keep as an array
        separator,
        inputs
      ).map((element, index) => (
        <Fragment key={index}>{element}</Fragment>
      ))}

      {outputObject && (
        <>
          <span className={styles.explainerRelationship}>
            {" "}
            {messages.willWrite}{" "}
          </span>

          <Representation.Output style="outlined">
            <span aria-hidden>{outputObject.value}</span>
            <ScreenReaderOnly tag="span">
              {outputObject.pronunciation}
            </ScreenReaderOnly>
          </Representation.Output>

          <ScreenReaderOnly tag="span">
            {" "}
            {messages.whileDictating}
          </ScreenReaderOnly>
        </>
      )}
    </p>
  );
};

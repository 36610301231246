import type { HTMLProps, ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./iconContainer.module.scss";

export type IconContainerProps = HTMLProps<HTMLSpanElement> & {
  className?: string;
};

export const IconContainer = ({
  className,
  children,
  ...spanProps
}: IconContainerProps): ReactElement => (
  <span
    aria-hidden
    {...spanProps}
    className={classNames(styles.container, className)}
  >
    {children}
  </span>
);

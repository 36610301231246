import type { EditorType } from "../../editor";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, put } from "redux-saga/effects";

import { broadcastEditor, requestEditor } from "../actions";

/**
 * Get Editor
 *
 * Returns the currently loaded editor or `null` when no editor exists
 */
export const getEditor = function* (): SagaIterator<EditorType | null> {
  yield put(requestEditor());

  const { payload: editor }: SagaReturnType<typeof broadcastEditor> =
    yield take(broadcastEditor);

  return editor;
};

import type { RootState } from "@talktype/store";
import type { MainMenuProps } from "@talktype/ui/src/MainMenu";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { MainMenu as Component } from "@talktype/ui/src/MainMenu";
import { selectTalkTypeDashboardUrl } from "@talktype/user/src/reducer/selectors/selectTalkTypeDashboardUrl";
import { requestLogout } from "@talktype/user/src/sagas/actions";

import { AutoDateFormattingField } from "./AutoDateFormattingField";
import { DictationLanguageField } from "./DictationLanguageField";
import { FilterProfanityField } from "./FilterProfanityField";
import { NumberFormattingField } from "./NumberFormattingField";
import { PinTalkTypeToFrontField } from "./PinTalkTypeToFrontField";
import { ThemeField } from "./ThemeField";
import { VersionDescription } from "./VersionDescription";

const mapStateToProps = (
  state: RootState
): Pick<MainMenuProps, "preferencesFields" | "dashboardUrl"> => ({
  preferencesFields: (
    <>
      <ThemeField />
      <DictationLanguageField />
      {getAppContext() === "desktop" && <PinTalkTypeToFrontField />}
      <AutoDateFormattingField />
      <NumberFormattingField />
      <FilterProfanityField />
      <VersionDescription />
    </>
  ),
  dashboardUrl: selectTalkTypeDashboardUrl(state),
});

const mapDispatchToProps: Pick<MainMenuProps, "logOut"> = {
  logOut: requestLogout,
};

export const MainMenu = connect(mapStateToProps, mapDispatchToProps)(Component);

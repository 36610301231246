import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { newLineRegex } from "@carescribe/slate";
import { id } from "@carescribe/utilities/src/fp";

import { isLineBreak } from "../../guards/isLineBreak";
import { editorLoaded } from "../actions";

/**
 * Split Line Breaks
 *
 * Line break entities are not void (due to headache inducing reasons), but
 * should not be interactive. This normalizer ensures that if text makes its
 * way into a line break entity, it will be split.
 *
 * N.B. This normalizer was written before formatting was in place, and so may
 * need updating to function correctly respecting the formatting of following
 * nodes.
 */
export const splitLineBreaks = function* (): SagaIterator<void> {
  yield takeEvery(editorLoaded, ({ payload: editor }) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]): void => {
      if (!isLineBreak(node)) {
        normalizeNode([node, path]);
        return;
      }

      const segments = node.text.split(newLineRegex).filter(id);

      if (segments.length <= 1) {
        normalizeNode([node, path]);
        return;
      }

      const { text, ...properties } = node;

      const position = segments[0].length;

      editor.apply({
        type: "split_node",
        path,
        position,
        properties,
      });
    };
  });
};

import type { Leaf, Marks } from "@talktype/types";

import { omit } from "@carescribe/utilities/src/fp";

/**
 * Given a leaf node, returns the marks.
 *
 * @example
 * getMarks({
 *  type: "text",
 *  text: "lorem",
 *  bold: false,
 *  italic: false,
 *  underline: false
 * }); // {
 *  bold: false,
 *  italic: false,
 *  underline: false
 * }
 */
export const getMarks = (marks: Leaf): Marks => omit(["text", "type"], marks);

import type { DesktopMethods } from "@carescribe/updater";

import { checkForUpdates } from "./checkForUpdates";
import { downloadUpdate } from "./downloadUpdate";
import { quitAndInstall } from "./quitAndInstall";

/**
 * Creates a set of sagas that instruct the updater how to perform the relevant
 * update steps in the browser context using IPC.
 */
export const getDesktopMethods = ({
  url,
}: {
  url: string;
}): DesktopMethods => ({
  checkForUpdates: checkForUpdates({ url }),
  downloadUpdate,
  quitAndInstall,
});

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select, takeEvery } from "redux-saga/effects";

import { replaceState } from "@talktype/store/src/actions";
import { getIpc } from "@talktype/utilities";

import {
  setPreferences,
  setAlwaysOnTop,
  selectPreferences,
} from "../reducers/preferences";

/**
 * Notifies the desktop process when:
 *
 * - preferences are first loaded or updated as a whole
 * - setAlwaysOnTop is updated
 */
export const notifyDesktopOnChange = function* (): SagaIterator<void> {
  yield takeEvery(
    [setPreferences, setAlwaysOnTop, replaceState],
    function* (): SagaIterator<void> {
      const preferences: SagaReturnType<typeof selectPreferences> =
        yield select(selectPreferences);

      const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

      if (!ipc?.preferences?.onChange?.v1) {
        return;
      }

      yield call(ipc.preferences.onChange.v1, preferences);
    }
  );
};

import type { Callback } from "@carescribe/types";
import type { CustomShortcut } from "@talktype/types";

import { v4 as uuid } from "uuid";

type CreateNewCustomShortcut = Callback<
  {
    input: string;
    output: string;
  },
  CustomShortcut
>;

/**
 * Creates a new custom shortcut, giving it a unique id.
 */
export const createNewCustomShortcut: CreateNewCustomShortcut = ({
  input,
  output,
}) => ({
  id: uuid(),
  input,
  output,
});

import type { Item } from "./config";
import type { Callback } from "@carescribe/types";
import type {
  FormattingBarButtonName,
  InteractionEvent,
} from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import {
  bold,
  clear,
  copy,
  italic,
  punctuation,
  redo,
  underline,
  undo,
} from "./config";
import { FormattingBarButton } from "./FormattingBarButton";
import styles from "./formattingToolbar.module.scss";
import { Submenu } from "./Submenu";

export type HandleInteraction = (
  id: FormattingBarButtonName,
  event: InteractionEvent<HTMLButtonElement | HTMLInputElement>
) => void;

export type IsActive = Callback<FormattingBarButtonName, boolean>;

export type IsDisabled = Callback<FormattingBarButtonName, boolean>;

export type FormattingButtonProps = {
  handleInteraction: HandleInteraction;
  isActive: IsActive;
  isDisabled: IsDisabled;
};

export type FormattingToolbarProps = {
  fontSettings: ReactNode;
  buttonProps: FormattingButtonProps;
};

export const Spacer = (): ReactElement => (
  <div className={styles.spacer} aria-hidden />
);

export const FormattingToolbar = ({
  fontSettings,
  buttonProps,
}: FormattingToolbarProps): ReactElement => {
  const items: Item[] = [
    { type: "node", id: "fontSettings", node: fontSettings },
    { type: "spacer", id: "spacer0" },
    bold,
    italic,
    underline,
    { type: "spacer", id: "spacer1" },
    undo,
    redo,
    { type: "spacer", id: "spacer2" },
    copy,
    clear,
    { type: "spacer", id: "spacer3" },
    punctuation,
  ];

  return (
    <section
      className={classNames(
        styles.container,
        createSelectorClassName("formatting-toolbar", "section")
      )}
    >
      {items.map((item) => (
        <div className={styles.formattingBarButton} key={item.id}>
          <FormattingBarButton item={item} {...buttonProps} />
        </div>
      ))}

      <div className={styles.submenu}>
        <Submenu
          items={items}
          buttonProps={buttonProps}
          handleOpenSubmenu={(event): void => {
            buttonProps.handleInteraction("submenu", event);
          }}
        />
      </div>
    </section>
  );
};

import type { ReactElement } from "react";

export const WindowsCloseIcon = (): ReactElement => (
  <svg
    width="46"
    height="38"
    viewBox="0 0 46 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.963 24.5214L16.9629 24.5215L16.9697 24.5283C17.1137 24.6723 17.2943 24.748 17.498 24.748C17.7036 24.748 17.8868 24.6738 18.0322 24.5283L22.502 20.0586L26.9717 24.5283L26.9715 24.5285L26.9803 24.5365L27.0447 24.5951L27.0595 24.6086L27.0763 24.6196C27.2044 24.7031 27.3472 24.748 27.5 24.748C27.7057 24.748 27.889 24.6736 28.0319 24.5247L28.032 24.5248L28.0365 24.5197L28.0951 24.4553L28.1086 24.4405L28.1196 24.4237C28.2031 24.2956 28.248 24.1528 28.248 24C28.248 23.7952 28.1742 23.6127 28.0267 23.4701L23.5586 19.002L28.0283 14.5322C28.1741 14.3864 28.2539 14.2047 28.2539 13.998C28.2539 13.7915 28.1769 13.6092 28.0266 13.4679C27.8845 13.3227 27.7032 13.25 27.5 13.25C27.2952 13.25 27.1127 13.3238 26.97 13.4713L22.502 17.9394L18.0322 13.4697L18.0242 13.4617L18.0155 13.4544L17.9452 13.3958L17.9359 13.3881L17.926 13.3813C17.7979 13.294 17.653 13.25 17.498 13.25C17.2943 13.25 17.1137 13.3257 16.9697 13.4697L16.9617 13.4777L16.9544 13.4864L16.8958 13.5568L16.8899 13.5639L16.8845 13.5715C16.7941 13.698 16.75 13.843 16.75 13.998C16.75 14.2036 16.8242 14.3868 16.9697 14.5322L21.4394 19.002L16.9697 23.4717C16.8257 23.6157 16.75 23.7962 16.75 24C16.75 24.1993 16.8279 24.3755 16.963 24.5214Z"
      fill="currentcolor"
      stroke="currentcolor"
      strokeWidth="0.5"
    />
  </svg>
);

import type {
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
} from "react";

// Keys that can be used to interact in the same way as a click
const keyboardClickKeys = ["Enter", " "];

const keyboardClickRegex = new RegExp(keyboardClickKeys.join("|"));

/**
 * Is Keyboard Click
 *
 * Tests whether a keyboard event is one that can be used to "click" on a button
 * i.e. whether it is an Enter or Space press.
 */
export const isKeyboardClick = (
  event: KeyboardEvent | ReactKeyboardEvent | MouseEvent | ReactMouseEvent
): boolean =>
  // If the event has a key, it is a KeyboardEvent, so we can test if it matches
  // the keys that can be used to interact in the same way as a click
  ("key" in event && keyboardClickRegex.test(event.key)) ||
  // Otherwise, it may be a MouseEvent, in which case we can check if it is a
  // "click" by checking if the detail is 0, which counts numbers of clicks
  // used to generate the event. In the case of 0, it means the event was
  // generated via a keyboard interaction.
  (event.type === "click" && event.detail === 0);

import type { EditorType } from "../../editor";
import type { LineBreak } from "@talktype/types";

import { emptyRange } from "@carescribe/slate";

import { lineBreakNode } from "../lineBreakNode";

export const insertLineBreak = (
  editor: EditorType,
  marks: Partial<LineBreak>,
  select = true
): void =>
  editor.insertNode(lineBreakNode(marks), {
    at: editor.selection ?? emptyRange,
    select,
  });

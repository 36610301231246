export const messages = {
  talktype: {
    inactive: { title: "Dictate to TalkType" },
    loading: { title: "Dictate to TalkType" },
    active: { title: "Dictating to TalkType" },
    title: "Dictate to TalkType",
    description: "Dictate and format text into TalkType’s own text editor.",
  },
  anywhere: {
    inactive: { title: "Dictate Anywhere" },
    loading: { title: "Dictate Anywhere" },
    active: { title: "Dictating Anywhere" },
    title: "Dictate Anywhere",
    description:
      "Dictate into any app on your device. Place your cursor where you want to dictate.",
  },
  changeDictationMode: "Change dictation mode",
};

import type {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  KeyboardEventHandler,
  ReactElement,
} from "react";

import { isKeyboardClick } from "@carescribe/utilities/src/isKeyboardClick";

export type LinkProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

/**
 * Link
 *
 * An accessible link component that ensures that the user can activate the
 * link with the spacebar.
 */
export const Link = (props: LinkProps): ReactElement => {
  const handleKeyUp: KeyboardEventHandler<HTMLAnchorElement> = (event) => {
    if (isKeyboardClick(event)) {
      window.open(props.href, props.target, "noopener noreferrer");
    }

    if (props.onKeyUp) {
      props.onKeyUp(event);
    }
  };

  return <a {...props} onKeyUp={handleKeyUp} rel="noopener noreferrer" />;
};

import type { Command } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  requestSelectPhrase,
  selectedPhrase,
} from "@talktype/editor/src/sagas/actions";

export const selectPhrase: Command = function* (parameters) {
  if (!parameters) {
    return false;
  }

  yield put(requestSelectPhrase(parameters));

  const { payload: success }: SagaReturnType<typeof selectedPhrase> =
    yield take(selectedPhrase);

  return success;
};

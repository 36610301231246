import type { EditorType } from "../../editor";
import type { TextNode } from "@talktype/types";

import { emptyRange } from "@carescribe/slate";

import { textNode } from "../textNode";

export const insertText = (
  editor: EditorType,
  marks: Partial<TextNode>,
  select = true
): void =>
  editor.insertNode(textNode(marks), {
    at: editor.selection ?? emptyRange,
    select,
  });

import type { CheckForUpdates } from "@carescribe/updater";

import { call } from "redux-saga/effects";

import { isObjectWithMessage } from "@carescribe/utilities/src/guards/isObjectWithMessage";

/**
 * Describes how to check for updates in the browser context.
 *
 * The app's HTML contains a meta tag with the build id. A fetch of the current
 * page will return the latest build id, which we can compare against the
 * current one to determine if an update is available.
 */
export const checkForUpdates = ({
  buildId,
}: {
  buildId: string;
}): CheckForUpdates =>
  function* () {
    try {
      const fetchSelf = (): Promise<Response> => fetch(window.location.href);
      const response: Response = yield call(fetchSelf);

      if (!response.ok) {
        return [null, { message: "Failed to fetch updates" }];
      }

      const htmlText: string = yield call([response, "text"]);
      const buildIdMatch =
        htmlText.match(/<meta\s+name="build-id"\s+content="([^"]+)"/) ?? [];

      const newBuildId = buildIdMatch.at(1);

      if (!newBuildId) {
        return [null, { message: "Build id not found" }];
      }
      const hasChanged = newBuildId !== buildId;

      return [hasChanged, null];
    } catch (error) {
      const message = isObjectWithMessage(error)
        ? error.message
        : "Unknown error";
      return [null, { message }];
    }
  };

import type { CommandCreator } from "../../../types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import {
  requestDeleteRange,
  requestDeletePhrase,
  deletedRange,
  deletedPhrase,
} from "@talktype/editor/src/sagas/actions";

import { processRangeCommand } from "../../../config/commands/utils/processRangeCommand";

export const deleteRange: CommandCreator<{ to: string[]; from: string }> = ({
  to,
  from,
}) =>
  function* (
    parameters
  ): SagaIterator<SagaReturnType<typeof processRangeCommand>> {
    return yield call(processRangeCommand, {
      parameters,
      desiredProcess: {
        request: requestDeleteRange,
        confirmation: deletedRange,
      },
      fallbackProcess: {
        request: requestDeletePhrase,
        confirmation: deletedPhrase,
      },
      split: to,
      trigger: from,
    });
  };

import type { ReactElement } from "react";

import { messages } from "./messages";
import styles from "./voiceCommandsEmpty.module.scss";

export type VoiceCommandsEmptyProps = { searchTerm: string };

export const VoiceCommandsEmpty = ({
  searchTerm,
}: VoiceCommandsEmptyProps): ReactElement => (
  <div className={styles.container}>
    <p>
      {messages.noResults} <span className={styles.term}>“{searchTerm}”</span>
    </p>
    <p>{messages.tip}</p>
  </div>
);

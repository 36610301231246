/**
 * Split on First Best Match
 *
 * Splits a string into two on the first instance of a series of possible search
 * words. Searches the entire string for a place to split for the first string
 * then continues on to search for the next if no match is found.
 *
 * This allows searching and splitting preferentially, so if we wanted to split
 * a string on the first instance of "to", and only later split based on an
 * instance of "two", we could do so like this:
 *
 * @example
 * splitOnFirstBestMatch("one two three to four five six", ["to", "two"]);
 * // ["one two three", "four five six"]
 *
 * splitOnFirstBestMatch("one two three two four five six", ["to", "two"]);
 * // ["one", "three two four five six"]
 */
export const splitOnFirstBestMatch = (
  target: string,
  matchers: string[]
): [null, null] | [string, string] => {
  for (const matcher of matchers) {
    const expression = new RegExp(`\\b${matcher}\\b(.*)`, "i");
    const [start, end] = target.split(expression);

    if (start && end) {
      return [start, end];
    }
  }

  return [null, null];
};

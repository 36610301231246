import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import { stopDictating } from "@talktype/editor";
import {
  broadcastPrecedingText,
  requestDictateToAppPrecedingText,
  requestUpdateDictateToAppPrecedingText,
  updatedDictateToAppPrecedingText,
} from "@talktype/results/src/sagas/actions";
import { getFinalCharacterOfResult } from "@talktype/utilities";

/**
 * Manage Dictate-to-App Preceding Text
 *
 * Keeps track of the final character of the previously completed result. The
 * Dictate to App feature in Electron has no way of reporting what the
 * character to the left of the user's cursor is, so we use this to make the
 * best guess of what is probably there based on the last result to be inserted
 */
export const manageDictateToAppPrecedingText =
  function* (): SagaIterator<void> {
    // Set up some state to keep track of the previous result's text
    // content for the purposes of performing stateful grammar in
    // dictate-to-app mode.
    const previousLastCharacterPerActiveApp = new Map<string, string>();

    yield takeEvery(stopDictating, function* () {
      previousLastCharacterPerActiveApp.clear();
    });

    yield takeEvery(
      requestDictateToAppPrecedingText,
      function* ({ payload: { targetApp } }) {
        const previousText =
          previousLastCharacterPerActiveApp.get(targetApp.name) ?? "";
        yield put(broadcastPrecedingText(previousText));
      }
    );

    yield takeEvery(
      requestUpdateDictateToAppPrecedingText,
      function* ({ payload: result }) {
        if (!result.isFinal) {
          yield put(updatedDictateToAppPrecedingText());
          return;
        }

        const previousLastCharacter = previousLastCharacterPerActiveApp.get(
          result.targetApp.name
        );
        const currentLastCharacter = getFinalCharacterOfResult(result);

        // Overwrite the previous text with the current one when final so that
        // the next result can use it to determine the right transformation
        const newLastCharacter =
          currentLastCharacter || previousLastCharacter || "";

        previousLastCharacterPerActiveApp.set(
          result.targetApp.name,
          newLastCharacter
        );

        yield put(updatedDictateToAppPrecedingText());
      }
    );
  };

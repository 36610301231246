import type { Stage } from "../../../types";

import { flatPunctuationRules as rules } from "../../../config/punctuation";
import {
  concatRegex,
  group,
  lookahead,
  optional,
  replaceText,
} from "../../../utils";

export const punctuation: Stage = ({ text, settings: { onReplace } }) =>
  rules.reduce((text, rule) => {
    let regex = new RegExp(rule.word);

    if (rule.removeSpacesAfter) {
      regex = concatRegex([regex, /\s*/]);
    }

    if (rule.removeSpacesBefore) {
      regex = concatRegex([/\s*/, regex]);
    }

    if (rule.removeSpacesAfterIfFollowedBy) {
      regex = concatRegex([
        regex,
        optional([
          group([/\s*/, lookahead([rule.removeSpacesAfterIfFollowedBy])]),
        ]),
      ]);
    }

    if (
      rule.requireBefore &&
      !concatRegex([rule.requireBefore, regex]).test(text)
    ) {
      return text;
    }

    return replaceText({
      text: text,
      regex,
      replacement: rule.output?.value ?? "",
      onReplace,
    });
  }, text);

import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

export const createUserSystemPreferenceEventChannel = (): EventChannel<true> =>
  eventChannel((emit) => {
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    const listener = (): void => emit(true);

    mediaQueryList.addEventListener("change", listener);
    return () => mediaQueryList.removeEventListener("change", listener);
  });

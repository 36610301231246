import type { AppDispatch, RootState } from "@talktype/store";
import type { AlwaysOnTopMode } from "@talktype/types/src/AlwaysOnTopMode";
import type { PinTalkTypeToFrontFieldProps } from "@talktype/ui/src/PreferencesModal/PinTalkTypeToFrontField";
import type { ChangeEventHandler } from "react";

import { connect } from "react-redux";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { alwaysOnTopOptions } from "@talktype/config/src/alwaysOnTopOptions";
import {
  isValidAlwaysOnTopMode,
  selectAlwaysOnTop,
  setAlwaysOnTop,
} from "@talktype/preferences";
import { PinTalkTypeToFrontField as Component } from "@talktype/ui/src/PreferencesModal/PinTalkTypeToFrontField";

type StateProps = { value: AlwaysOnTopMode };

type DispatchProps = { onChange: ChangeEventHandler<HTMLSelectElement> };

const mapStateToProps = (state: RootState): StateProps => ({
  value: selectAlwaysOnTop(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChange: ({ currentTarget: { value } }): void => {
    if (isValidAlwaysOnTopMode(value)) {
      dispatch(broadcast(setAlwaysOnTop(value)));
    }
  },
});

const mergeProps = (
  { value }: StateProps,
  { onChange }: DispatchProps
): PinTalkTypeToFrontFieldProps => ({
  value,
  onChange,
  options: alwaysOnTopOptions,
});

export const PinTalkTypeToFrontField = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  broadcastEditorPrecedingText,
  requestEditorPrecedingText,
} from "./actions";

/**
 * Get Editor Preceding Text
 *
 * Getter responsible for returning the text before the cursor managed by
 * manageEditorPrecedingText
 */
export const getEditorPrecedingText = function* (): SagaIterator<string> {
  yield put(requestEditorPrecedingText());

  const {
    payload: precedingText,
  }: SagaReturnType<typeof broadcastEditorPrecedingText> = yield take(
    broadcastEditorPrecedingText
  );

  return precedingText;
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { stickyMarks } from "./stickyMarks";

export const setUpFixes = function* (): SagaIterator<void> {
  yield all([stickyMarks].map(retry));
};

import type { Store } from "@reduxjs/toolkit";
import type { ReactElement, ReactNode } from "react";
import type { Saga } from "redux-saga";

import { useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";

import { setUpStore } from "../setUpStore";

export const Provider = ({
  rootSaga,
  children,
}: {
  rootSaga: Saga;
  children: ReactNode;
}): ReactElement => {
  const [store, setStore] = useState<Store | null>(null);

  useEffect(() => {
    setUpStore(rootSaga).then(setStore);
    return () => setStore(null);
  }, [rootSaga]);

  return store ? (
    <ReduxProvider store={store}>{children}</ReduxProvider>
  ) : (
    <></>
  );
};

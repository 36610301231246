import { createSelector } from "@reduxjs/toolkit";

import { selectAuthUrlWithParams } from "./selectAuthUrlWithParams";
import { REGISTER_URL } from "../../config/urls";

/**
 * Creates a licence key redemption URL.
 */
export const selectRegisterUrl = createSelector(
  [selectAuthUrlWithParams],
  (onboardingUrl) => {
    const url = new URL(onboardingUrl);

    url.pathname = `${url.pathname}/${REGISTER_URL}`;
    url.searchParams.set("response_mode", "form_post");

    return url.toString();
  }
);

import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./radioGroup.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

type FieldProps = {
  legend: { visible: boolean; text: string };
  layout: "horizontal" | "vertical";
  className?: string;
  children: ReactNode;
};

export const RadioGroup = ({
  legend,
  layout,
  children,
  className,
}: FieldProps): ReactElement => (
  <fieldset className={classNames(styles.radioGroup, className)}>
    {legend.visible ? (
      <>
        {/* Use of span as legend does not respect grid positioning */}
        <span className={styles.legend}>{legend.text}</span>
      </>
    ) : (
      <ScreenReaderOnly tag="legend">{legend.text}</ScreenReaderOnly>
    )}
    <div className={classNames(styles.inputs, styles[layout])}>{children}</div>
  </fieldset>
);

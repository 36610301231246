import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeLatest, takeEvery, call } from "redux-saga/effects";

import { createEditorChangeChannel } from "../../utils/createEditorChangeChannel";
import { scrollCursorIntoView } from "../../utils/scrollCursorIntoView";
import { editorLoaded } from "../actions";

export const keepCursorWithinBounds = function* (): SagaIterator<void> {
  // takeLatest: cancel any previous tasks associated with old editors
  yield takeLatest(editorLoaded, function* ({ payload: editor }) {
    const changeChannel: SagaReturnType<typeof createEditorChangeChannel> =
      yield call(createEditorChangeChannel, editor);

    yield takeEvery(changeChannel, function* () {
      yield call(scrollCursorIntoView);
    });
  });
};

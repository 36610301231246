import type { TooltipProps } from "@talktype/types/src/TooltipProps";
import type { KeyboardEventHandler, ReactElement, Dispatch } from "react";

import {
  useRef,
  useLayoutEffect,
  useEffect,
  useReducer,
  useCallback,
} from "react";

import { anchor } from "@carescribe/ui/src/utils/anchor";
import { keepInViewport } from "@carescribe/ui/src/utils/keepInViewport";
import { classNames } from "@carescribe/utilities/src/classNames";
import { isKeyboardClick } from "@carescribe/utilities/src/isKeyboardClick";
import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

import { showPopover, hidePopover } from "./tooltip";
import styles from "./tooltip.module.scss";

type State = {
  stage: "hidden" | "visible" | "confirming";
  display: string[];
};

const useTooltipReducer = ({
  messages,
  disabled,
}: Pick<TooltipProps, "messages" | "disabled">): [
  State,
  Dispatch<State["stage"]>
] => {
  const initialState: State = { stage: "hidden", display: messages.visible };

  return useReducer((state: State, action: State["stage"]): State => {
    // Unhappy paths
    if (disabled) {
      return initialState;
    }
    if (state.stage === action) {
      return state;
    }
    if (state.stage === "confirming" && action !== "hidden") {
      return state;
    }

    // Happy paths
    switch (action) {
      case "hidden":
        return { ...state, stage: "hidden" };

      case "visible":
        return { stage: "visible", display: messages.visible };

      case "confirming":
        if (!messages.confirming) {
          return state;
        }
        return { stage: "confirming", display: messages.confirming.content };
    }
  }, initialState);
};

export const Tooltip = ({
  children,
  messages,
  placement,
  disabled,
  minWidth,
}: TooltipProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const [{ stage, display }, dispatch] = useTooltipReducer({
    messages,
    disabled,
  });
  const isHidden = stage === "hidden";
  const isConfirming = stage === "confirming";

  const onTooltipTrigger = (): void => dispatch("visible");
  const onTooltipDismiss = (): void => dispatch("hidden");
  const onContentClick = (): void =>
    dispatch(messages.confirming ? "confirming" : "hidden");
  const onContentKeydown: KeyboardEventHandler = (event) =>
    isKeyboardClick(event) && onContentClick();
  const onPressEscape = useCallback(
    ({ key }: KeyboardEvent) => key === "Escape" && dispatch("hidden"),
    [dispatch]
  );

  const positionTooltip = useCallback((): void => {
    anchor({ anchorRef: containerRef, anchoreeRef: tooltipRef, placement });
    keepInViewport(tooltipRef);
  }, [placement]);

  useLayoutEffect(() => {
    if (isHidden) {
      return;
    }

    window.addEventListener("resize", positionTooltip);
    window.addEventListener("keydown", onPressEscape);
    return () => {
      window.removeEventListener("resize", positionTooltip);
      window.removeEventListener("keydown", onPressEscape);
    };
  }, [isHidden, dispatch, onPressEscape, positionTooltip]);

  useEffect(() => {
    switch (stage) {
      case "hidden":
        hidePopover(tooltipRef);
        break;

      case "visible":
        showPopover(tooltipRef);
        positionTooltip();
        break;

      case "confirming": {
        if (!messages.confirming) {
          return;
        }

        showPopover(tooltipRef);
        positionTooltip();

        const onTimeout = (): void => dispatch("hidden");
        const timeout = setTimeout(
          onTimeout,
          secondsToMilliseconds(messages.confirming.duration)
        );
        return () => clearTimeout(timeout);
      }
    }
  }, [stage, messages.confirming, dispatch, positionTooltip]);

  return (
    <div
      ref={containerRef}
      className={styles.wrapper}
      onMouseEnter={onTooltipTrigger}
      onFocus={onTooltipTrigger}
      onTouchStart={onTooltipTrigger}
      onMouseLeave={onTooltipDismiss}
      onBlur={onTooltipDismiss}
    >
      <span onClick={onContentClick} onKeyDown={onContentKeydown}>
        {children}
      </span>
      <span
        aria-hidden
        className={classNames(styles.tooltip, [
          isConfirming,
          styles.confirming,
        ])}
        ref={tooltipRef}
        // @ts-expect-error -- TODO: Remove once React types are updated
        popover="manual"
      >
        <span
          className={classNames(styles.contents, [
            isConfirming,
            styles.confirming,
          ])}
          style={{ minWidth }}
        >
          {display.map((message, i) => (
            <span
              key={message}
              className={styles[i === 0 ? "title" : "shortcut"]}
            >
              {message}
            </span>
          ))}
        </span>
      </span>
    </div>
  );
};

import type { VoiceCommandsEmptyProps } from "./VoiceCommandsEmpty";
import type { VoiceCommandsPopulatedProps } from "./VoiceCommandsPopulated";
import type { ReactElement } from "react";

import { VoiceCommandsEmpty } from "./VoiceCommandsEmpty";
import { VoiceCommandsPopulated } from "./VoiceCommandsPopulated";

export type VoiceCommandsProps = VoiceCommandsPopulatedProps &
  VoiceCommandsEmptyProps;

export const VoiceCommands = ({
  searchTerm,
  listing,
  ...props
}: VoiceCommandsProps): ReactElement =>
  listing.length > 0 ? (
    <VoiceCommandsPopulated {...props} listing={listing} />
  ) : (
    <VoiceCommandsEmpty searchTerm={searchTerm} />
  );

import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import * as reducers from "./reducers";

export const { reducer, actions } = createSlice({
  name: "preferences",
  initialState,
  reducers,
});

export const {
  addCustomShortcut,
  editCustomShortcut,
  addCustomWord,
  editCustomWord,
  closeDrawer,
  deleteCustomShortcut,
  deleteCustomWord,
  incrementDocumentFontSize,
  openDrawer,
  setAlwaysOnTop,
  setColourScheme,
  setDateFormat,
  setDictationLanguage,
  setDocumentFontFamily,
  setFilterProfanity,
  setNumberFormat,
  setPreferences,
  setAttemptedLegacyPreferencesMigration,
  setSilenceTimeout,
  setAutoPunctuation,
} = actions;

export * from "./selectors";

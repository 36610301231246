import type { SagaIterator, Task } from "redux-saga";

import { all, cancel, put, take, takeLeading } from "redux-saga/effects";

import { setUpScreenWakeLock } from "@carescribe/screen-wake-lock";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpVoiceCommands } from "@talktype/commands";
import { setUpDictateAnywhere } from "@talktype/dictate-to-app/src/sagas";
import { setUpEditor } from "@talktype/editor/src/sagas/setUpEditor";
import { setUpHotkeys } from "@talktype/hotkeys";
import { trackVoiceCommandSearch } from "@talktype/listing/src/sagas/trackVoiceCommandSearch";
import { setUpPreferences } from "@talktype/preferences";
import { setUpResults } from "@talktype/results";
import { replaceState } from "@talktype/store/src/actions";
import { setUpConnection } from "@talktype/transcriber/src/sagas/setUpConnection";
import { setUpTranscriptTransformation } from "@talktype/transcript-transformation/src/sagas";
import { setLoginStatus } from "@talktype/user/src/reducer";
import {
  loginComplete,
  notLoggedIn,
  optimisticLogin,
} from "@talktype/user/src/sagas/actions";

import { connectOnDictationStart } from "./connectOnDictationStart";
import { manageDictationButton } from "./manageDictationButton";
import { manageDictationStatus } from "./manageDictationStatus";
import { setUpAudioSocket } from "./setUpAudioSocket";
import { setUpFailedMicrophoneAccessNotification } from "./setUpFailedMicrophoneAccessNotification";
import { setUpFinalisation } from "./setUpFinalisation";
import { trackActiveAppBasedOnDictationMode } from "./trackActiveAppBasedOnDictationMode";

const loggedInSagas = [
  connectOnDictationStart,
  manageDictationButton,
  manageDictationStatus,
  setUpAudioSocket,
  setUpConnection,
  setUpDictateAnywhere,
  setUpEditor,
  setUpFailedMicrophoneAccessNotification,
  setUpFinalisation,
  setUpHotkeys,
  setUpPreferences,
  setUpResults,
  setUpScreenWakeLock,
  setUpTranscriptTransformation,
  setUpVoiceCommands,
  trackActiveAppBasedOnDictationMode,
  trackVoiceCommandSearch,
];

/**
 * Set Up User Lifecycle
 *
 * On login:
 * - waits for stored data to be hydrated
 * - boots user sagas
 *
 * On logout:
 * - cancels user sagas
 */
export const setUpUserLifecycle = function* (): SagaIterator<void> {
  // Use takeLeading in order to allow for optimistic login to start sagas
  // pre-emptively, and not end up with duplicate sagas running once login is
  // confirmed.
  yield takeLeading([optimisticLogin, loginComplete], function* ({ type }) {
    /**
     * When a user performs a login, their data is re-hydrated (aka loaded from
     * the store into state). This can take a little while, during which we must
     * wait. Otherwise sagas may start to do their thing assuming the user state
     * is loaded, and those changes may be overwritten when the hydration
     * completes and the state is replaced. Not necessary for optimistic login
     * as no re-hydration takes place.
     */
    if (type === loginComplete.type) {
      yield take(replaceState);
    }

    // The following order is vital: logged in sagas must run before the editor
    // is shown because the editor component creates an editor instance that
    // must be captured by sagas.
    const tasks: Task[] = yield all(loggedInSagas.map(retry));

    yield put(setLoginStatus("loggedIn"));

    // Cleanup effects: reset anything relating to the user being logged in
    yield take(notLoggedIn);
    yield cancel(tasks);
  });
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";

type SetAutoPunctuation = (
  state: Preferences,
  action: PayloadAction<boolean>
) => void;

export const setAutoPunctuation: SetAutoPunctuation = (
  state,
  { payload }
): void => {
  state.autoPunctuation = payload;
};

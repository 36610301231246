import type { ReactElement } from "react";

import styles from "./versionDescription.module.scss";

export type VersionDescriptionProps = {
  appVersion: string | null;
  buildId: string | null;
};

export const VersionDescription = ({
  appVersion,
  buildId,
}: VersionDescriptionProps): ReactElement => {
  const version = [appVersion ? `v${appVersion}` : null, buildId]
    .filter(Boolean)
    .join("-");

  return <p className={styles.version}>Version: {version}</p>;
};

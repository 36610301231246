import type { EditorType } from "../../editor";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, call, takeEvery } from "redux-saga/effects";

import { safeStructuredClone } from "@carescribe/utilities/src/safeStructuredClone";

import { editorLoaded } from "..";
import { selectDocumentHistory } from "../../reducers";

const initialiseHistory = function* (editor: EditorType): SagaIterator<void> {
  const previousHistory: SagaReturnType<typeof selectDocumentHistory> =
    yield select(selectDocumentHistory);

  if (!previousHistory) {
    return;
  }

  /**
   * Saving Slate editor's history without creating a deep copy
   * does not tend to go well. Leading to:
   *
   * - Redux errors relating to Immer's auto-freezing
   * See: https://github.com/reduxjs/redux-toolkit/discussions/1189
   *
   * - SerializableCheck errors
   */
  const newHistory: typeof previousHistory = yield call(
    safeStructuredClone,
    previousHistory
  );

  editor.history = newHistory;
};

/**
 * Initialises history by loading a previous history from state
 * whenever the editor is loaded
 */
export const manageInitialiseHistory = function* (): SagaIterator<void> {
  yield takeEvery(
    editorLoaded,
    function* ({ payload: editor }): SagaIterator<void> {
      yield call(initialiseHistory, editor);
    }
  );
};

import type { PkceState } from "../types/PkceState";

export const createPkceState = (state: Partial<PkceState>): PkceState => ({
  authUrl: "",
  clientId: "",
  tokens: null,
  scopes: [],
  additionalParams: {},
  pkceCodePair: null,
  ...state,
});

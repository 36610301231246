import { createSelector } from "@reduxjs/toolkit";

import { selectAuthUrlWithParams } from "./selectAuthUrlWithParams";
import { AUTHORIZE_URL } from "../../config/urls";

export const selectAuthorizeUrl = createSelector(
  [selectAuthUrlWithParams],
  (oAuthUrl) => {
    const url = new URL(oAuthUrl);

    url.pathname = `${url.pathname}/${AUTHORIZE_URL}`;

    return url.toString();
  }
);

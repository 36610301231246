import type { ReactElement, ReactNode } from "react";

import { useEffect } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import { messages } from "./messages";
import styles from "./nativeInstruction.module.scss";

export const NativeInstruction = ({
  app,
  slideIn,
  onDismiss,
  onPrompt,
  onMount,
  onUnmount,
}: {
  app: { name: string; icon: ReactNode };
  slideIn?: boolean;
  onDismiss: () => void;
  onPrompt: () => void;
  onMount: (() => void) | null;
  onUnmount: (() => void) | null;
}): ReactElement => {
  useEffect(() => {
    onMount?.();
    return () => {
      onUnmount?.();
    };
  }, [onMount, onUnmount]);

  return (
    <aside
      className={classNames(styles.container, [!!slideIn, styles.slideIn])}
    >
      <header className={styles.explanation}>
        <div className={styles.appIcon}>{app.icon}</div>

        <div className={styles.explanationText}>
          <h2 className={styles.appName}>{app.name}</h2>
          <p className={styles.installToHomeScreen}>
            {messages.installToHomeScreen}
          </p>
        </div>
      </header>

      <div
        className={styles.actions}
        role="group"
        aria-label={messages.actions}
      >
        <button onClick={onDismiss} className={styles.cancelButton}>
          {messages.cancel}
        </button>

        <button onClick={onPrompt} className={styles.installButton}>
          {messages.install}
        </button>
      </div>
    </aside>
  );
};

export const messages = {
  addACustomWord: "Add a custom word",
  submit: { label: "Add word" },
  input: { label: "Enter a custom word" },
  errors: {
    FIELD_REQUIRED: "Field is required",
    DUPLICATE_ENTRY: (input: string): string =>
      `‘${input}’ already exists as a custom word`,
    INPUT_EXCEEDS_MAX_LENGTH: "Custom words must be fewer than 100 characters",
    ENTITY_LIMIT_REACHED:
      "Limit of 1,000 custom words has been reached. To add this word, please remove an existing custom word.",
  },
};

import type { InstallTarget } from "../types/InstallTarget";

import { isAppleDevice, isMobile } from "@carescribe/utilities/src/browser";

/**
 * Identifies a potential PWA install target, consisting of:
 * - The type of device (mobile or desktop)
 * - The type of system (apple or other)
 */
export const identifyInstallTarget = (): InstallTarget | null => {
  const type = isMobile() ? "mobile" : "desktop";
  const system = isAppleDevice() ? "apple" : "other";

  return { type, system };
};

import type { CombinedState } from "../CombinedState";
import type { DictationHistoryEntry } from "@talktype/types";

import { createSelector } from "@reduxjs/toolkit";

import { selectDictationHistory } from "./selectDictationHistory";

export const selectDictationHistoryEntry = ({
  resultId,
}: {
  resultId: DictationHistoryEntry["resultId"];
}): (({ dictateToApp }: CombinedState) => DictationHistoryEntry | undefined) =>
  createSelector(selectDictationHistory, (dictationHistory) =>
    dictationHistory.get(resultId)
  );

import type { Editor, Point, Location, EditorBeforeOptions } from "slate";

import { emptyPoint } from "@carescribe/slate";

/**
 * Get the point before a location.
 *
 * If there is no point before the location (e.g. top of the document)
 * returns an empty point.
 */
export const safeBefore = (
  editor: Editor,
  location: Location,
  options?: EditorBeforeOptions
): Point => editor.before(location, options) ?? emptyPoint;

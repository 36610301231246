import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call } from "redux-saga/effects";

import { setDictationMode, stopDictating } from "@talktype/editor";
import { setActiveApp } from "@talktype/system/src/reducers/system";
import { getIpc } from "@talktype/utilities";

/**
 * Reset Dictate to App
 *
 * When the user turns dictation off or dictation mode is changed, this saga
 * informs the desktop app that it should reset state associated with the
 * Dictate to App feature. This is so that its memory of how much text to
 * delete is succesfully reset and future sessions start as if they are the
 * first.
 */
export const resetDictateToApp = function* (): SagaIterator<void> {
  yield takeEvery(
    [stopDictating, setDictationMode, setActiveApp],
    function* () {
      const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

      if (!ipc?.dictateToApp?.reset?.v1) {
        return;
      }

      yield call(ipc.dictateToApp.reset.v1);
    }
  );
};

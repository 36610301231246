import type { CommandStatus } from "../../../types";
import type {
  VoiceCommandEventTrackingRequest,
  VoiceCommandEventTrackingData,
  VoiceCommandEventTrackingRequestBase,
} from "@talktype/analytics";

import { convertTextToStars } from "@carescribe/utilities/src/convertTextToStars";

import { InteractionMethod } from "@talktype/analytics";

export type CreateTrackerConfig = VoiceCommandEventTrackingRequestBase & {
  // Words that the user may have spoken, form part of the structure of the
  // command, e.g. "from" and "to" in range commands.
  keywords?: string[];
};

export type Tracker = (
  // Whether the command was successfully run or not
  status: CommandStatus,
  // The instruction part of a prompt i.e. all the words spoken after "TalkType"
  instruction: string
) => VoiceCommandEventTrackingRequest;

type CreateTracker = (config: CreateTrackerConfig) => Tracker;

/**
 * Create Tracker
 *
 * Creates a function that returns a voice command event tracking request.
 *
 * Anonymises the contents of the user's instruction, excluding any keywords
 * that compose the command itself. Sets the interaction method to voice, an
 * the command status and instruction to whatever is passed into the function
 * when it is run.
 */
export const createTracker: CreateTracker =
  ({ keywords = [], ...event }) =>
  (commandStatus, instruction) => {
    const voiceCommandData: VoiceCommandEventTrackingData = {
      commandStatus,
      instruction: convertTextToStars(instruction, { exclude: keywords }),
    };

    return {
      ...event,
      data: {
        ...event.data,
        ...voiceCommandData,
        interactionMethod: InteractionMethod.voice,
      },
      // TS is not particularly happy about copying properties from an object
      // into itself, so we have to do some type casting here.
    } as VoiceCommandEventTrackingRequest;
  };

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { all, put, takeEvery, call } from "redux-saga/effects";

import {
  customInstallPromptMounted,
  requestDismissCustomInstallPrompt,
  requestPromptNativeInstall,
} from "@carescribe/pwa/src/sagas/actions";
import { setNativeInstallPromptResult } from "@carescribe/pwa/src/state";
import { createDOMEventChannel } from "@carescribe/utilities/src/sagas";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";

export const trackMobileAppInstalled = function* (): SagaIterator<void> {
  const appInstalledChannel: SagaReturnType<typeof createDOMEventChannel> =
    yield call(createDOMEventChannel, window, "appinstalled");

  yield takeEvery(appInstalledChannel, function* () {
    yield put(requestTrackEvent({ name: "Mobile App Installed", data: null }));
  });
};

export const trackMobileAppInstallPromptShown =
  function* (): SagaIterator<void> {
    yield takeEvery(customInstallPromptMounted, function* () {
      yield put(
        requestTrackEvent({
          name: "Mobile App Install Prompt Shown",
          data: null,
        })
      );
    });
  };

export const trackMobileAppInstallPromptDismissed =
  function* (): SagaIterator<void> {
    yield takeEvery(requestDismissCustomInstallPrompt, function* () {
      yield put(
        requestTrackEvent({
          name: "Mobile App Install Prompt Dismissed",
          data: null,
        })
      );
    });
  };

export const trackMobileAppInstallPromptCallToActionClicked =
  function* (): SagaIterator<void> {
    yield takeEvery(requestPromptNativeInstall, function* () {
      yield put(
        requestTrackEvent({
          name: "Mobile App Install Prompt Call to Action Clicked",
          data: null,
        })
      );
    });
  };

export const trackMobileAppIntallPromptResultDetected =
  function* (): SagaIterator<void> {
    yield takeEvery(
      setNativeInstallPromptResult,
      function* ({ payload: result }) {
        yield put(
          requestTrackEvent({
            name: "Mobile App Install Prompt Result Detected",
            data: result,
          })
        );
      }
    );
  };

export const setUpPWATracking = function* (): SagaIterator<void> {
  yield all(
    [
      trackMobileAppInstalled,
      trackMobileAppInstallPromptShown,
      trackMobileAppInstallPromptDismissed,
      trackMobileAppInstallPromptCallToActionClicked,
      trackMobileAppIntallPromptResultDetected,
    ].map(retry)
  );
};

import type { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import type { InteractionMethod } from "@talktype/analytics";
import type { Preferences } from "@talktype/types";

type CloseDrawer = CaseReducer<
  Preferences,
  PayloadAction<{
    interactionLocation: "quick nav" | "close button";
    interactionMethod: InteractionMethod;
  }>
>;

/**
 * Handles the closing of drawer page.
 */
export const closeDrawer: CloseDrawer = (state): void => {
  state.drawer.isOpen = false;
};

import type { ModalProps } from "@carescribe/ui/src/components/Modal";
import type { ReactElement } from "react";

import { Modal as BaseModal } from "@carescribe/ui/src/components/Modal";
import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./modal.module.scss";

export type { ModalProps } from "@carescribe/ui/src/components/Modal";

export const Modal = ({
  className,
  children,
  ...modalProps
}: ModalProps): ReactElement => (
  <BaseModal {...modalProps} className={classNames(styles.modal, className)}>
    <div className={styles.contents}>{children}</div>
  </BaseModal>
);

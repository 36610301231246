import type { PayloadAction } from "@reduxjs/toolkit";
import type { NumberFormat, Preferences } from "@talktype/types";

type SetNumberFormat = (
  state: Preferences,
  action: PayloadAction<NumberFormat>
) => void;

/**
 * Handles the setting of numberFormat.
 */
export const setNumberFormat: SetNumberFormat = (state, { payload }) => {
  state.numberFormat = payload;
};

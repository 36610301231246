import type { ResultsState } from "../types/ResultsState";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AssignedResult } from "@talktype/types";

export const setInProgressResult = (
  state: ResultsState,
  { payload: result }: PayloadAction<AssignedResult | null>
): void => {
  state.inProgressResult = result;
};

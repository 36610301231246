import type { DictationStatus } from "@talktype/types";
import type { ReactElement } from "react";

import { Microphone, MicrophoneSlash, Spinner } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./statusIndicator.module.scss";
import { IconContainer } from "../../IconContainer";

type StatusIndicatorProps = { status: DictationStatus };

const icon = {
  inactive: <Microphone />,
  loading: <Spinner />,
  active: <MicrophoneSlash />,
};

export const StatusIndicator = ({
  status,
}: StatusIndicatorProps): ReactElement => (
  <IconContainer
    className={classNames(
      [status === "loading", styles.fadeInAndSpin],
      createSelectorClassName(status, "element")
    )}
  >
    {icon[status]}
  </IconContainer>
);

/**
 * Creates an array of combined strings from two given arrays.
 *
 * Each string from the first array (prefix) is combined with each
 * string from the second array (suffix), separated by a space.
 *
 * @example
 * createMatchers(["bold"], ["on", "off"]); // ["bold on", "bold off"]
 */
export const createMatchers = (prefix: string[], suffix: string[]): string[] =>
  prefix.flatMap((a) => suffix.map((b) => `${a} ${b}`));

import type { Reshaper } from "./types/Reshaper";

/**
 * Reshape Dark Mode
 *
 * Convert legacy dark mode and copy into preferences.
 */
export const reshapeDarkMode: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    legacyPreferences.dark_mode !== true
      ? newPreferences
      : { ...newPreferences, colourScheme: "dark" };

import type { Hotkey } from "../types/Hotkey";

import { caseInsensitiveEqual } from "@carescribe/utilities/src/caseInsensitiveEqual";
import { entries } from "@carescribe/utilities/src/typedObject";

export const matchHotkey = (
  event: KeyboardEvent,
  shortcuts: Hotkey[]
): Hotkey | undefined =>
  shortcuts.find(({ match: matchers }) =>
    matchers.some((matcher) =>
      entries(matcher).every(([key, expected]) => {
        const actual = event[key];

        return typeof actual === "string" && typeof expected === "string"
          ? caseInsensitiveEqual(expected, actual)
          : expected === actual;
      })
    )
  );

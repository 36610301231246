import { createSelector } from "@reduxjs/toolkit";

import { selectCustomShortcuts } from "./selectCustomShortcuts";
import { selectDateFormat } from "./selectDateFormat";
import { selectFilterProfanity } from "./selectFilterProfanity";
import { selectNumberFormat } from "./selectNumberFormat";

/**
 * Selects the settings needed for transcript transformation from state.
 */
export const selectTransformationPreferences = createSelector(
  [
    selectCustomShortcuts,
    selectFilterProfanity,
    selectDateFormat,
    selectNumberFormat,
  ],
  (customShortcuts, filterProfanity, dateFormat, numberFormat) => ({
    customShortcuts,
    filterProfanity,
    dateFormat,
    numberFormat,
  })
);

import type { PayloadAction } from "@reduxjs/toolkit";
import type { CustomShortcut, Preferences } from "@talktype/types";

type EditCustomShortcut = (
  state: Preferences,
  action: PayloadAction<CustomShortcut>
) => void;

export const editCustomShortcut: EditCustomShortcut = (
  state,
  { payload: shortcut }
) => {
  const index = state.customShortcuts.findIndex(
    (currentShortcut) => currentShortcut.id === shortcut.id
  );
  if (index !== -1) {
    state.customShortcuts[index] = shortcut;
  }
};

import { messages as baseMessages } from "../../messages";
import { createMatchers } from "../../utils/createMatchers";
import { messages as commonMessages } from "../messages";

const { arrow } = commonMessages;

export const messages = {
  matchers: createMatchers(arrow, ["left"]),
  successful: "Ok, pressed the left arrow",
  failed: baseMessages.failureWithoutReason,
  listing: ["Arrow left"],
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";
import { Range, Transforms } from "slate";

import { focusEditor } from "@carescribe/slate/src/utils/focusEditor";
import { getRangeOfLastInstances } from "@carescribe/slate/src/utils/range/getRangeOfLastInstances";

import { requestDeleteRange, deletedRange } from "..";
import { getEditor } from "../utils";

/**
 * Manage Deletion
 *
 * Handles requests to delete the selection. Uses the editor's delete method,
 * but only in the case where the selection is expanded, since delete will
 * delete a character when the selection is collapsed.
 */
export const manageDeleteRange = function* (): SagaIterator<void> {
  yield takeEvery(requestDeleteRange, function* ({ payload: { from, to } }) {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(deletedRange(false));
      return;
    }

    const matchedRange: SagaReturnType<typeof getRangeOfLastInstances> =
      yield call(getRangeOfLastInstances, editor, from, to);

    if (matchedRange && Range.isExpanded(matchedRange)) {
      yield call(focusEditor, editor);
      yield call(Transforms.delete, editor, { at: matchedRange });
      yield put(deletedRange(true));
    } else {
      yield put(deletedRange(false));
    }
  });
};

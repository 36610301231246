import type { EditorType } from "../../editor";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, call, takeEvery } from "redux-saga/effects";

import { editorLoaded } from "..";
import { selectDocumentSelection } from "../../reducers";
import { selectEditorEnd } from "../../utils/selectEditorEnd";

const initialiseSelection = function* (editor: EditorType): SagaIterator<void> {
  const previousSelection: SagaReturnType<typeof selectDocumentSelection> =
    yield select(selectDocumentSelection);

  if (previousSelection) {
    yield call(editor.select, previousSelection);
    return;
  }

  yield call(selectEditorEnd, editor);
};

/**
 * Initialises a selection by loading a previous selection from state if
 * available, otherwise selects the end of the editor.
 */
export const manageInitialiseSelection = function* (): SagaIterator<void> {
  yield takeEvery(
    editorLoaded,
    function* ({ payload: editor }): SagaIterator<void> {
      yield call(initialiseSelection, editor);
    }
  );
};

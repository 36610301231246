import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, takeEvery, call } from "redux-saga/effects";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { requestDismissCustomInstallPrompt } from "../../sagas/actions";
import { setCustomPromptDismissedAt } from "../../state";

export const getNowISOString = (): string => new Date().toISOString();

/**
 * Handles requests to dismiss the custom install prompt:
 * - Records the current timestamp when the prompt is dismissed helping ensure
 *   it is no longer shown in the UI
 * - Broadcasts the action across all windows to ensure they are updated
 */
export const manageCustomInstallPromptDismissal =
  function* (): SagaIterator<void> {
    yield takeEvery(requestDismissCustomInstallPrompt, function* () {
      const now: SagaReturnType<typeof getNowISOString> = yield call(
        getNowISOString
      );

      yield put(broadcast(setCustomPromptDismissedAt(now)));
    });
  };

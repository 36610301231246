import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { InteractionMethod, requestTrackEvent } from "@talktype/analytics";

import { closeDrawer, openDrawer } from "../reducers";

export const trackSetDrawerPage = function* (): SagaIterator<void> {
  yield takeEvery(
    openDrawer,
    function* ({ payload: { drawerPage, interactionMethod } }) {
      yield put(
        requestTrackEvent({
          name: "Drawer Interaction",
          data: {
            drawerPage,
            drawerInteractionType: "opened",
            drawerPageInteractionLocation: "quick nav",
            drawerPageInteractionMethod:
              interactionMethod ?? InteractionMethod.hotkey,
          },
        })
      );
    }
  );

  yield takeEvery(
    closeDrawer,
    function* ({ payload: { interactionLocation, interactionMethod } }) {
      yield put(
        requestTrackEvent({
          name: "Drawer Interaction",
          data: {
            drawerPageInteractionLocation: interactionLocation,
            drawerPageInteractionMethod:
              interactionMethod ?? InteractionMethod.hotkey,
            drawerInteractionType: "closed",
          },
        })
      );
    }
  );
};

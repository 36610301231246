import type { ReactElement, ComponentType } from "react";

import { DictationHistoryEmpty } from "../DictationHistoryEmpty";

export type DictationHistoryProps = {
  isEmpty: boolean;
  Populated: ComponentType;
};

export const DictationHistory = ({
  isEmpty,
  Populated,
}: DictationHistoryProps): ReactElement =>
  isEmpty ? <DictationHistoryEmpty /> : <Populated />;

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, take, takeEvery } from "redux-saga/effects";

import { headDef } from "@carescribe/utilities/src/fp";

import { promptKeywords } from "@talktype/config";
import { textTranscriptSegment } from "@talktype/editor/src/utils/textTranscriptSegment";
import {
  appliedVoiceCommands,
  requestApplyVoiceCommands,
} from "@talktype/results/src/sagas/actions";

import { requestHandlePrompt, handledPrompt } from "./actions";
import { commands } from "../config/commands";
import { processVoiceCommands } from "../utils";

/**
 * Handles requests requests to apply voice commands.
 *
 * - Interprets prompts found in the transcript and converts them to actions
 * - Signals voice commands have been applied when the results pipeline is
 * ready to continue
 */
export const manageVoiceCommands = function* (): SagaIterator<void> {
  yield takeEvery(requestApplyVoiceCommands, function* ({ payload: result }) {
    const { resultId, isFinal, transcript, targetApp } = result;

    const { prompt, segments }: SagaReturnType<typeof processVoiceCommands> =
      yield call(processVoiceCommands, {
        segment: headDef(transcript, textTranscriptSegment()),
        promptKeywords,
        commands: Object.values(commands),
        id: { resultId, commandIndex: 0 },
        targetApp,
      });

    if (prompt) {
      yield put(requestHandlePrompt({ prompt, isFinal }));
      yield take(handledPrompt);
    }

    yield put(appliedVoiceCommands({ ...result, transcript: segments }));
  });
};

import { messages as commonMessages } from "../../messages";

export const messages = {
  matchers: [`delete ${commonMessages.from}`],
  from: commonMessages.from,
  to: commonMessages.to,
  successful: "Great, deleted that range",
  failed: "Sorry, couldn't find that",
  listing: ["Delete from {word} to {word}"],
};

import type { EncryptedPayload } from "./types";

import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

/**
 * Provides methods for encryption and decryption of data.
 *
 * - Utilises the AES-GCM encryption algorithm.
 * - Uses SHA-256 hashing to derive encryption keys.
 *
 * ## Methodology
 *
 * - **Encryption**:
 *   - Converts a string key into a cryptographic key using SHA-256.
 *   - Generates a random initialisation vector (IV) for each encryption.
 *   - Encrypts data using AES-GCM with the derived key and IV.
 *   - User id can be hashed using a separate `hashUserId` method for purposes
 *     of obfuscation. A different algorithm is deliberately used to increase
 *     the difficulty of a potential attack.
 *
 * - **Decryption**:
 *   - Decrypts the data using the same AES-GCM algorithm and key.
 *
 * @example
 * const encryptionKey = await Encryption.createKey('key');
 * const encryption = new Encryption(encryptionKey);
 *
 * // Encrypting data
 * const encryptedData = await encryption.encrypt('data');
 *
 * // Decrypting data
 * const decryptedData = await encryption.decrypt(encryptedData);
 */
export class Encryption {
  private key: CryptoKey;

  public constructor(key: CryptoKey) {
    this.key = key;
  }

  private stringifyData(data: unknown): string {
    return JSON.stringify(data, StrictJSON.replace);
  }

  private parseData(data: string): unknown {
    return JSON.parse(data, StrictJSON.revive);
  }

  private static async hash(
    string: string,
    algorithm: AlgorithmIdentifier
  ): Promise<ArrayBuffer> {
    const textEncoder = new TextEncoder();
    const encodedString = textEncoder.encode(string);
    return await crypto.subtle.digest(algorithm, encodedString);
  }

  public static async hashUserId(userId: string): Promise<string> {
    const hashBuffer = await this.hash(userId, "SHA-384");
    return Array.from(new Uint8Array(hashBuffer))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  }

  public static async createKey(string: string): Promise<CryptoKey> {
    const hash = await this.hash(string, "SHA-256");

    const format = "raw";
    const algorithm = { name: "AES-GCM" };
    const extractable = false;
    const usages: KeyUsage[] = ["encrypt", "decrypt"];

    return crypto.subtle.importKey(
      format,
      hash,
      algorithm,
      extractable,
      usages
    );
  }

  public async encrypt(data: unknown): Promise<EncryptedPayload> {
    const iv = crypto.getRandomValues(new Uint8Array(12));

    const algorithm = { name: "AES-GCM", iv };
    const key = this.key;
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(this.stringifyData(data));

    const encryptedData = await crypto.subtle.encrypt(
      algorithm,
      key,
      encodedData
    );

    return { iv, data: encryptedData };
  }

  public async decrypt({
    iv,
    data: encryptedData,
  }: EncryptedPayload): Promise<unknown> {
    const algorithm = { name: "AES-GCM", iv };
    const key = this.key;

    const decrypted = await crypto.subtle.decrypt(
      algorithm,
      key,
      encryptedData
    );
    const textDecoder = new TextDecoder();
    const result = textDecoder.decode(decrypted);

    return this.parseData(result);
  }
}

import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

/**
 * Creates a DOM event channel that listens for an event on a given target.
 *
 * - `target` - The target to attach the event listener to.
 * e.g. `window`, `document`, or any DOM element.

 * - `type` - The type of the event to listen for.
 * e.g. `unload`, `click`, or any DOM event type.
 *
 * @example
 * ```typescript
 * // Listen for document visibility changes
 * const eventChannel: SagaReturnType<typeof createDOMEventChannel> =
 * yield call(createDOMEventChannel, document, "visibilitychange");
 *
 * // Wait for the next visibility change event
 * yield take(eventChannel);
 * ```
 */
export const createDOMEventChannel = <
  Target extends Window | Document | HTMLElement | EventTarget
>(
  target: Target,
  type: // Determines available event types based on the target type.
  Target extends Window
    ? keyof WindowEventMap
    : Target extends Document
    ? keyof DocumentEventMap
    : Target extends HTMLElement
    ? keyof HTMLElementEventMap
    : string
): EventChannel<Event> =>
  eventChannel((emit) => {
    target.addEventListener(type, emit);
    return () => target.removeEventListener(type, emit);
  });

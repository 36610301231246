import type { CombinedState } from "../types/CombinedState";

import { createSelector } from "@reduxjs/toolkit";

export const selectResultsState = (
  state: CombinedState
): CombinedState["results"] => state.results;

export const selectInProgressResult = createSelector(
  selectResultsState,
  (state) => state.inProgressResult
);

export const selectInProgress = createSelector(
  selectInProgressResult,
  (inProgressResult) => inProgressResult !== null
);

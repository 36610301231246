/**
 * Checks whether a given click event is within a given DOMRect.
 *
 * @example
 * ```
 * const rect = dialog.getBoundingClientRect();
 * const isClickInRect = isClickInRect(event, rect); // true
 * ```
 */
export const isClickInRect = (
  event: Pick<MouseEvent, "clientX" | "clientY">,
  rect: Pick<DOMRect, "top" | "bottom" | "left" | "right">
): boolean =>
  event.clientY >= rect.top &&
  event.clientY <= rect.bottom &&
  event.clientX >= rect.left &&
  event.clientX <= rect.right;

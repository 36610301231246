import { rules } from "@talktype/transcript-transformation";

import { promptKeywords } from "./promptKeywords";
import { transformationSettings } from "./transformationSettings";

export const reservedKeywords: string[] = [
  ...promptKeywords,
  ...transformationSettings.customShortcuts.flatMap(
    (shortcut) => shortcut.matchers
  ),
  ...rules.flatMap((rule) => rule.matchers),
];

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select } from "redux-saga/effects";

import { addAdditionalParams } from "@carescribe/pkce/src/reducer";
import { isUndefined } from "@carescribe/utilities/src/isUndefined";

import { getIpc } from "@talktype/utilities";
import { isValidLicenceKey } from "@talktype/utilities/src/guards/isValidLicenceKey";

import { LICENCE_KEY_PARAM } from "../../config/web";
import { setLicenceKey } from "../../reducer";
import { selectLicenceKey } from "../../reducer/selectors/selectLicenceKey";
import { log, logError, logGroupCollapsed, logGroupEnd } from "../../utils/log";

/**
 * Get Licence Key
 *
 * If not already present in state, get the licence key from the system.
 * Responsible for adding the licence key as a query parameter to the auth
 * params.
 */
export const getLicenceKey = function* (): SagaIterator<void> {
  const licenceKey: SagaReturnType<typeof selectLicenceKey> = yield select(
    selectLicenceKey
  );

  if (licenceKey) {
    yield put(addAdditionalParams({ [LICENCE_KEY_PARAM]: licenceKey }));
    yield put(setLicenceKey(licenceKey));
    return;
  }

  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  if (!ipc?.system?.licenceKey?.get?.v1) {
    yield put(setLicenceKey(null));
    return;
  }

  const fileContents: SagaReturnType<typeof ipc.system.licenceKey.get.v1> =
    yield call(ipc.system.licenceKey.get.v1);
  const trimmedContents = fileContents?.trim();

  if (isUndefined(trimmedContents)) {
    // No licence key present on the user's system, expected for v3 users or
    // new installs
    yield put(setLicenceKey(null));
    return;
  }

  if (!isValidLicenceKey(trimmedContents)) {
    // The user somehow has an invalid licence key on their system, log this as
    // an error to be reported in Sentry
    yield call(logGroupCollapsed, "received invalid v2 licence key");
    yield call(logError, `invalid licence key contents: "${fileContents}"`);
    yield call(logGroupEnd);

    yield put(setLicenceKey(null));
    return;
  }

  yield call(logGroupCollapsed, "received v2 licence key");
  yield call(log, trimmedContents);
  yield call(logGroupEnd);

  yield put(addAdditionalParams({ [LICENCE_KEY_PARAM]: trimmedContents }));
  yield put(setLicenceKey(fileContents));
};

import type { Document } from "@talktype/editor";
import type { RootState } from "@talktype/store";
import type { EditorProps } from "@talktype/ui/src/Editor/Editor";

import { connect } from "react-redux";

import {
  createEditor,
  editorLoaded,
  editorUnloaded,
  editorChanged,
  editorClicked,
  editorKeyDown,
  editorKeyUp,
  editorCut,
  editorCopy,
  editorPaste,
  selectCurrentDocument,
} from "@talktype/editor";
import { selectDocumentStyle } from "@talktype/preferences";
import { selectInProgress } from "@talktype/results/src/state/selectors";
import { Editor as Component } from "@talktype/ui/src/Editor/Editor";

type StateProps = Pick<EditorProps, "createEditor" | "style" | "inProgress"> & {
  document: Document | null;
};

type DispatchProps = Pick<
  EditorProps,
  | "onLoad"
  | "onUnload"
  | "onChange"
  | "onClick"
  | "onKeyDown"
  | "onKeyUp"
  | "onCut"
  | "onCopy"
  | "onPaste"
>;

const mapStateToProps = (state: RootState): StateProps => ({
  // Avoid passing in any non-primitives that don't come directly from a
  // selector here. It will cause issues with the way the selection position
  // updates in arrow key commands
  createEditor,
  style: selectDocumentStyle(state),
  inProgress: selectInProgress(state),
  document: selectCurrentDocument(state),
});

const mapDispatchToProps: DispatchProps = {
  onLoad: editorLoaded,
  onUnload: editorUnloaded,
  onChange: editorChanged,
  onClick: editorClicked,
  onKeyDown: editorKeyDown,
  onKeyUp: editorKeyUp,
  onCut: editorCut,
  onCopy: editorCopy,
  onPaste: editorPaste,
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps
): EditorProps => {
  const { document, ...rest } = stateProps;

  return {
    ...rest,
    ...dispatchProps,
    ...(document ? { id: document.id, initialValue: document.children } : {}),
  };
};

export const Editor = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, put, select, takeEvery } from "redux-saga/effects";

import {
  requestFinaliseDictation,
  finalisedDictation,
} from "@talktype/actions";
import {
  finalisedInProgressResult,
  requestFinaliseInProgressResult,
} from "@talktype/results/src/sagas/actions";
import { selectInProgress } from "@talktype/results/src/state/selectors";
import { requestNewResult } from "@talktype/transcriber/src/sagas/actions";

/**
 * Causes finalisation behaviours upon request.
 *
 * - Finalises in-progress result
 * - Requests a new result with the transcriber
 */
export const setUpFinalisation = function* (): SagaIterator<void> {
  yield takeEvery(requestFinaliseDictation, function* (): SagaIterator<void> {
    const isInProgress: SagaReturnType<typeof selectInProgress> = yield select(
      selectInProgress
    );

    if (isInProgress) {
      yield put(requestFinaliseInProgressResult());
      yield take(finalisedInProgressResult);

      yield put(requestNewResult());
    }

    yield put(finalisedDictation());
  });
};

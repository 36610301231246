import type { SagaIterator } from "redux-saga";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpCore } from "./setUpCore";
import { setUpUserLifecycle } from "./setUpUserLifecycle";

export const rootSaga = function* (): SagaIterator<void> {
  yield retry(setUpCore);
  yield retry(setUpUserLifecycle);
};

import type { SystemState } from "@talktype/types";

export const createSystemState = (
  system: Partial<SystemState>
): SystemState => ({
  activeApp: { name: "TalkType", isSelf: true },
  window: { maximised: false },
  appVersion: null,
  buildId: null,
  ...system,
});

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery, put } from "redux-saga/effects";

import { createDOMEventChannel } from "@carescribe/utilities/src/sagas";

import { requestTrackEvent } from "@talktype/analytics";

/**
 * Saga to track app visibility change events.
 */
export const trackAppVisibilityChanged = function* (): SagaIterator<void> {
  const visibilityChannel: SagaReturnType<typeof createDOMEventChannel> =
    yield call(createDOMEventChannel, document, "visibilitychange");

  yield takeEvery(visibilityChannel, function* () {
    yield put(
      requestTrackEvent({
        name: "Visibility Changed",
        data: { visibilityState: document.visibilityState },
      })
    );
  });
};

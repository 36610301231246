import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, select, take, takeEvery } from "redux-saga/effects";

import {
  authenticationComplete,
  gotCode,
  requestAuthenticate,
  requestGetCode,
  requestTokens,
} from "./actions";
import { setTokens } from "../reducer";
import { selectPkceCodePair } from "../reducer/selectors/selectPkceCodePair";

/**
 * Set Up Authenticate
 *
 * Handle the high level flow of the PKCE authentication process.
 */
export const setUpAuthenticate = function* (): SagaIterator<void> {
  yield takeEvery(requestAuthenticate, function* () {
    yield put(requestGetCode());
    const { payload: code }: SagaReturnType<typeof gotCode> = yield take(
      gotCode
    );

    const codePair: SagaReturnType<typeof selectPkceCodePair> = yield select(
      selectPkceCodePair
    );

    const hasCodeAndCodePair = !!code && !!codePair;

    if (hasCodeAndCodePair) {
      yield put(requestTokens({ code, codePair }));
      yield take(setTokens);
    }

    yield put(authenticationComplete(hasCodeAndCodePair));
  });
};

import type { Toast as BaseToast, Toasts, PickToastConfig } from "./types";
import type { Seconds } from "@carescribe/types";

import { StrictMap } from "@carescribe/utilities/src/StrictMap";
import { TimeoutManager } from "@carescribe/utilities/src/TimeoutManager";
import {
  millisecondsToSeconds,
  subtractSeconds,
} from "@carescribe/utilities/src/timing";

/**
 * Calculates the remaining duration in seconds between two points in time.
 *
 * @param start - The start time in milliseconds.
 * @param end - The end time in milliseconds.
 * @param duration - The criterion duration in seconds.
 * @returns The remaining duration in seconds.
 */
export const calculateRemainingDuration = ({
  start,
  end,
  duration,
}: {
  start: number;
  end: number;
  duration: Seconds;
}): Seconds => {
  const elapsedSeconds = millisecondsToSeconds(end - start);
  return subtractSeconds(duration, elapsedSeconds);
};

/**
 * `ToastManager` manages a collection of toasts.
 *
 * - **Constructor:**
 *   - `onChange`: `ToastManager` will call this function whenever the state
 *     of toasts changes
 *
 * - **Methods:**
 *   - `set`: Upserts a new toast to be displayed
 *   - `dismiss`: Dismisses a toast by its ID
 *   - `dismissAll`: Dismisses all toasts
 *
 * @example
 * const toastManager = new ToastManager();
 * const onChange = () => {
 *   const toasts = toastManager.getToasts();
 *   console.info(toasts);
 * }
 * toastManager.addEventListener("change", onChange);
 */
export class ToastManager<
  Toast extends BaseToast = BaseToast
> extends EventTarget {
  private toasts: Toasts<Toast>;
  private timeoutManagers: StrictMap<"after" | "notBefore", TimeoutManager>;

  public constructor() {
    super();
    this.toasts = new Map();
    this.timeoutManagers = new StrictMap([
      ["after", new TimeoutManager()],
      ["notBefore", new TimeoutManager()],
    ]);
  }

  private createToast = (config: PickToastConfig<Toast>): Toast =>
    ({
      ...config,
      dismiss: () => this.dismiss(config.id),
      createdAt: Date.now(),
    } as Toast);

  private notifyChange = (): void => {
    this.dispatchEvent(new CustomEvent("change"));
  };

  public getToasts = (): Toast[] =>
    Array.from(this.toasts.values()).sort((a, b) => a.order - b.order);

  /**
   * Upserts a toast to be displayed.
   *
   * @param config - The configuration of the toast.
   *
   * @example
   * toastManager.set({
   *   id: "1",
   *   dismissConfig: { after: null, notBefore: null },
   *   order: 0
   * });
   */
  public set = (config: PickToastConfig<Toast>): void => {
    const toast = this.createToast(config);

    this.timeoutManagers.forEach((timeoutManager) =>
      timeoutManager.clear(toast.id)
    );

    if (toast.dismissConfig.after) {
      this.timeoutManagers.getStrict("after").set({
        id: toast.id,
        delay: toast.dismissConfig.after,
        onTimeout: toast.dismiss,
      });
    }

    this.toasts.set(toast.id, toast);

    this.notifyChange();
  };

  /**
   * Dismisses a toast by its ID.
   *
   * @param id - The ID of the toast to dismiss.
   *
   * @example
   * toastManager.dismiss("1");
   */
  public dismiss = (id: string): void => {
    const toast = this.toasts.get(id);
    if (!toast) {
      return;
    }

    const delay = toast.dismissConfig.notBefore
      ? calculateRemainingDuration({
          start: toast.createdAt,
          end: Date.now(),
          duration: toast.dismissConfig.notBefore,
        })
      : null;

    if (delay && delay.magnitude > 0) {
      this.timeoutManagers.getStrict("notBefore").add({
        id,
        delay,
        onTimeout: toast.dismiss,
      });
      return;
    }

    this.timeoutManagers.forEach((timeoutManager) => timeoutManager.clear(id));
    this.toasts.delete(id);

    this.notifyChange();
  };

  /**
   * Dismisses all toasts.
   *
   * @example
   * toastManager.dismissAll();
   */
  public dismissAll = (): void => {
    this.timeoutManagers.forEach((timeoutManager) => timeoutManager.clearAll());
    this.toasts.clear();

    this.notifyChange();
  };
}

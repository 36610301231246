import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeLatest, takeEvery, call, put } from "redux-saga/effects";

import { setDocument } from "../../reducers/editor";
import { createEditorChangeChannel } from "../../utils/createEditorChangeChannel";
import { editorLoaded } from "../actions";

/**
 * Store Document
 *
 * Create a channel for any editor that is made, and dispatch the editor's
 * changes so that they can be stored in global state
 */
export const setDocumentInRedux = function* (): SagaIterator<void> {
  // takeLatest: cancel any previous tasks associated with old editors
  yield takeLatest(
    editorLoaded,
    function* ({ payload: editor }): SagaIterator<void> {
      const channel: SagaReturnType<typeof createEditorChangeChannel> =
        yield call(createEditorChangeChannel, editor);

      yield takeEvery(channel, function* (document): SagaIterator<void> {
        yield put(setDocument(document));
      });
    }
  );
};

import type { EnhancedStore } from "@reduxjs/toolkit";

/**
 * Adds a Redux store to the window object when Cypress is running
 *
 * This is so that Cypress can interact with the store to set up the state in
 * certain ways for tests, and examine the store to verify that data has been
 * updated correctly.
 */
export const addStoreToWindowForCypress = (
  window: Window,
  store: EnhancedStore
): void => {
  if ("Cypress" in window) {
    type Win = Window & { store: EnhancedStore } & { Cypress: unknown };

    (window as Win).store = store;
  }
};

import { getIsDevelopment } from "@carescribe/utilities/src/dev/getIsDevelopment";
import { isElectronEnvironment } from "@carescribe/utilities/src/isElectronEnvironment";

/**
 * Get Deep Link From Console
 *
 * Awful nonsense to bypass the fact that deeplinking doesn't work in until the
 * app is packaged:
 * {@link https://www.electronjs.org/docs/latest/tutorial/launch-app-from-url-in-another-app#packaging}
 *
 * Once a deep link has been generated, type `dev.deepLink("your-deep-link")`
 * in the console to trigger the deep link.
 */
export const getDeepLinkFromConsole = (
  onDeepLink: (input: string) => void
): void => {
  if (isElectronEnvironment() && getIsDevelopment()) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const win: any = window;
    win.dev = win.dev ?? {};
    win.dev.deepLink = onDeepLink;
  }
};

import type { CommandOutcome, PromptWithCommand } from "../types";
import type { Prompt } from "../types/Prompt";
import type { Seconds } from "@carescribe/types";
import type { AppMetadata } from "@talktype/types";
import type { IndexedCommandId } from "@talktype/types/src/IndexedCommandId";
import type { Task } from "redux-saga";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-commands");

export const requestHandlePrompt = action<
  { prompt: Prompt; isFinal: boolean },
  "request-handle-prompt"
>("request-handle-prompt");

export const handledPrompt = action<void, "handled-prompt">("handled-prompt");

export const requestTrackPrompt = action<Prompt | null, "request-track-prompt">(
  "request-track-prompt"
);

export const trackedPrompt = action<void, "tracked-prompt">("tracked-prompt");

export const requestTrackDeadline = action<Seconds, "request-track-deadline">(
  "request-track-deadline"
);

export const trackedDeadline = action<void, "tracked-deadline">(
  "tracked-deadline"
);

export const requestStartTask = action<void, "request-start-task">(
  "request-start-task"
);

export const startedTask = action<void, "started-task">("started-task");

export const requestTrackTask = action<Task | null, "request-track-task">(
  "request-track-task"
);

export const trackedTask = action<void, "tracked-task">("tracked-task");

export const requestCancelTask = action<void, "request-cancel-task">(
  "request-cancel-task"
);

export const cancelledTask = action<void, "cancelled-task">("cancelled-task");

export const voiceCommandComplete = action<
  {
    id: IndexedCommandId;
    prompt: PromptWithCommand;
    outcome: CommandOutcome;
  },
  "voice-command-complete"
>("voice-command-complete");

export const voiceCommandListening = action<
  { instruction: { content: string; isDataSensitive: boolean }[] },
  "voice-command-listening"
>("voice-command-listening");

export const voiceCommandUnrecognised = action<
  void,
  "voice-command-unrecognised"
>("voice-command-unrecognised");

export const voiceCommandUnsupported = action<
  { targetApp: AppMetadata },
  "voice-command-unsupported"
>("voice-command-unsupported");

export const uniqueCommandProcessed = action<
  {
    prompt: PromptWithCommand;
    outcome: CommandOutcome;
  },
  "unique-command-processed"
>("unique-command-processed");

export const requestSearchVoiceCommands = action<
  string,
  "request-search-voice-commands"
>("request-search-voice-commands");

import type { Action } from "redux";
import type { SagaIterator, EventChannel } from "redux-saga";

import { createAction } from "@reduxjs/toolkit";
import { eventChannel } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";

export const broadcast = createAction<Action>("broadcast");

/**
 * A saga which syncs actions across windows (& tabs).
 *
 * @param channelName - constant, unique name for the broadcast channel
 * used to communicate between windows.
 */
export const syncActionsAcrossWindows = function* ({
  channelName,
}: {
  channelName: string;
}): SagaIterator<void> {
  if (!("BroadcastChannel" in window)) {
    return;
  }

  const broadcastChannel = new BroadcastChannel(channelName);

  const broadcastEventChannel: EventChannel<Action> = yield call(
    eventChannel,
    (emit) => {
      const messageHandler = ({ data }: { data: Action }): void => emit(data);

      broadcastChannel.addEventListener("message", messageHandler);
      return () => {
        broadcastChannel.removeEventListener("message", messageHandler);
        broadcastChannel.close();
      };
    }
  );

  // Broadcast actions
  yield takeEvery(
    broadcast,
    function* ({ payload: action }): SagaIterator<void> {
      /**
       * Using [context, method] syntax necessary to ensure 'this'
       * inside postMessage is correctly preserved as 'broadcastChannel'.
       */
      yield call([broadcastChannel, broadcastChannel.postMessage], action);
      yield put(action);
    }
  );

  // Receive actions
  yield takeEvery(
    broadcastEventChannel,
    function* (action): SagaIterator<void> {
      yield put(action);
    }
  );
};

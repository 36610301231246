import type { Seconds } from "@carescribe/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { setUpUpdater as baseSetUpUpdater } from "@carescribe/updater";
import { getIsDevelopment } from "@carescribe/utilities/src/dev/getIsDevelopment";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { getBrowserMethods } from "./getBrowserMethods";
import { getDesktopMethods } from "./getDesktopMethods";
import { manageNotifications } from "./manageNotifications";

/**
 * Sets up the updater for the application.
 *
 * - If in development mode, the updater exits early (not supported)
 * - Manages updater related notifications
 * - Starts up the actual updater processes for both browser and desktop.
 *   The updater is agnostic to how it performs each of the update steps, and
 *   so we must provide the appropriate methods for each context.
 */
export const setUpUpdater = function* ({
  desktop,
  browser,
  pollFrequency,
}: {
  desktop: { url: string };
  browser: { buildId: string };
  pollFrequency: Seconds;
}): SagaIterator<void> {
  const isDevelopment: SagaReturnType<typeof getIsDevelopment> = yield call(
    getIsDevelopment
  );

  // Updater is not supported in development mode
  if (isDevelopment) {
    return;
  }

  yield retry(function* () {
    yield call(manageNotifications);
  });

  const context = getAppContext();

  const methods = {
    desktop: context === "desktop" ? getDesktopMethods(desktop) : null,
    browser: getBrowserMethods(browser),
  };

  yield retry(function* () {
    yield call(baseSetUpUpdater, { pollFrequency, ...methods });
  });
};

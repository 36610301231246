import type { Marks } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { getSelectionMarksOrDefault } from "../../../utils/getSelectionMarksOrDefault";
import {
  requestSaveEditorMarks,
  savedEditorMarks,
  requestClearSavedEditorMarks,
  clearedSavedEditorMarks,
  requestEditorMarks,
  broadcastEditorMarks,
} from "../../actions";
import { getEditor } from "../../utils";

/**
 * Keeps track of editor marks on-demand.
 *
 * - Handles requests to save editor marks
 * - Handles requests to clear saved editor marks
 * - Handles requests to broadcast editor marks
 */
export const trackMarks = function* (): SagaIterator<void> {
  let editorMarks: Marks | null = null;

  yield takeEvery(requestSaveEditorMarks, function* (): SagaIterator<void> {
    const editor: SagaReturnType<typeof getEditor> = yield call(getEditor);

    if (!editor) {
      yield put(savedEditorMarks());
      return;
    }

    const newMarks: SagaReturnType<typeof getSelectionMarksOrDefault> =
      yield call(getSelectionMarksOrDefault, editor);

    editorMarks = newMarks;

    yield put(savedEditorMarks());
  });

  yield takeEvery(
    requestClearSavedEditorMarks,
    function* (): SagaIterator<void> {
      editorMarks = null;

      yield put(clearedSavedEditorMarks());
    }
  );

  yield takeEvery(requestEditorMarks, function* () {
    yield put(broadcastEditorMarks(editorMarks));
  });
};

import type { ReactElement, ReactNode } from "react";

import { Paragraph } from "../Paragraph";

export type RenderElementProps = {
  children: ReactNode;
  attributes: Record<string, unknown>;
};

export const RenderElement = ({
  children,
  attributes,
}: RenderElementProps): ReactElement => (
  <Paragraph attributes={attributes}>{children}</Paragraph>
);

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take, takeEvery } from "redux-saga/effects";

import {
  broadcastCurrentTranscriberSocket,
  requestCurrentTranscriberSocket,
  transcriberSocketClosed,
  transcriberSocketCreated,
} from "../actions";

/**
 * Listens for requestCurrentTranscriberSocket and puts
 * broadcastCurrentTranscriberSocket with the current WebSocket
 */
export const setUpBroadcast = function* (): SagaIterator<void> {
  let currentSocket: WebSocket | null;

  yield takeEvery(requestCurrentTranscriberSocket, function* () {
    yield put(broadcastCurrentTranscriberSocket(currentSocket));
  });

  while (true) {
    const { payload: socket }: SagaReturnType<typeof transcriberSocketCreated> =
      yield take(transcriberSocketCreated);
    currentSocket = socket;

    yield take(transcriberSocketClosed);
    currentSocket = null;
  }
};

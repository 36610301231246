import type { LicenceKeyRedemptionStatusResponse } from "../../guards/isLicenceKeyRedemptionStatusResponse";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select, takeEvery } from "redux-saga/effects";
import { match, P } from "ts-pattern";

import { request } from "@carescribe/utilities/src/request";

import { getLicenceKey } from "./getLicenceKey";
import { LICENCE_KEY_MIGRATION_STATUS } from "../../config/web";
import { validLicenceKeyRedemptionStatusResponse } from "../../guards/isLicenceKeyRedemptionStatusResponse";
import { selectLicenceKey } from "../../reducer/selectors/selectLicenceKey";
import { selectLicenceKeyRedemptionUrl } from "../../reducer/selectors/selectLicenceKeyRedemptionUrl";
import { logError } from "../../utils/log";
import {
  gotLicenceKeyRedemptionStatus,
  requestLicenceKeyRedemptionStatus,
} from "../actions";

const handleError = ({ error }: { error: string }) =>
  function* (): SagaIterator<void> {
    yield put(gotLicenceKeyRedemptionStatus("unredeemable"));
    yield call(
      logError,
      "Error fetching licence key redemption status:",
      error
    );
  };

const handleValidResponse = ({
  data,
}: {
  data: LicenceKeyRedemptionStatusResponse;
}) =>
  function* (): SagaIterator<void> {
    const redeemable = data[LICENCE_KEY_MIGRATION_STATUS] === "unredeemed";

    const status = redeemable ? "redeemable" : "unredeemable";

    yield put(gotLicenceKeyRedemptionStatus(status));
  };

const handleInvalidResponse = (response: unknown) =>
  function* (): SagaIterator<void> {
    yield put(gotLicenceKeyRedemptionStatus("unredeemable"));
    yield call(
      logError,
      "Invalid licence key redemption status response:",
      JSON.stringify(response)
    );
  };

/**
 * Get Licence Key Redemption Status
 *
 * Makes a request to the server to determine the redemption status of the
 * currently held licence key.
 */
export const getLicenceKeyRedemptionStatus = function* (): SagaIterator<void> {
  yield takeEvery(
    requestLicenceKeyRedemptionStatus,
    function* (): SagaIterator<void> {
      yield call(getLicenceKey);

      const licenceKey: SagaReturnType<typeof selectLicenceKey> = yield select(
        selectLicenceKey
      );

      if (!licenceKey) {
        yield put(gotLicenceKeyRedemptionStatus("unredeemable"));
        return;
      }

      const url: SagaReturnType<typeof selectLicenceKeyRedemptionUrl> =
        yield select(selectLicenceKeyRedemptionUrl);

      const response: SagaReturnType<typeof request> = yield call(request, {
        url,
        logError,
      });

      yield call(
        match(response)
          .with({ error: P.string }, handleError)
          .with(
            { data: validLicenceKeyRedemptionStatusResponse },
            handleValidResponse
          )
          .otherwise(handleInvalidResponse)
      );
    }
  );
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";
import type { AlwaysOnTopMode } from "@talktype/types/src/AlwaysOnTopMode";

type SetAlwaysOnTop = (
  state: Preferences,
  action: PayloadAction<AlwaysOnTopMode>
) => void;

/**
 * Handles the setting of alwaysOnTop.
 */
export const setAlwaysOnTop: SetAlwaysOnTop = (state, { payload }): void => {
  state.alwaysOnTop = payload;
};

import type { QuitAndInstall } from "@carescribe/updater";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

/**
 * Describes how to check for updates in the desktop context.
 *
 * This is done via the `Updater.quitAndInstall` IPC method.
 */
export const quitAndInstall: QuitAndInstall = function* () {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  const method = ipc?.updater?.quitAndInstall?.v1;

  if (!method) {
    return [null, { message: "`quitAndInstall` IPC method not found" }];
  }

  const [, error]: SagaReturnType<typeof method> = yield call(method);

  return [null, error];
};

import type { ActivityStatus } from "../types/ActivityStatus";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import { broadcastActivityStatus, requestActivityStatus } from "./actions";

export function* getActivityStatus(): SagaIterator<ActivityStatus> {
  yield put(requestActivityStatus());

  const {
    payload: activityStatus,
  }: SagaReturnType<typeof broadcastActivityStatus> = yield take(
    broadcastActivityStatus
  );

  return activityStatus;
}

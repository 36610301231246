import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { createNewCustomWord } from "./createNewCustomWord";
import { addCustomWord } from "../../reducers/preferences";
import { requestAddCustomWord } from "../actions";

/**
 * Handles requests to add a custom word.
 */
export const manageAddCustomWord = function* (): SagaIterator<void> {
  yield takeEvery(
    requestAddCustomWord,
    function* ({ payload: customWordProperties }) {
      const word: SagaReturnType<typeof createNewCustomWord> = yield call(
        createNewCustomWord,
        customWordProperties
      );

      yield put(broadcast(addCustomWord(word)));
    }
  );
};

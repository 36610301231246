/**
 * Removes whitespace from both ends of a string and returns a new string,
 * without modifying the original string.
 *
 * Differs from String.prototype.trim() in that it retains line terminators
 * such as new line characters.
 */
export const trimWhitespace = (text: string): string => {
  /*
   * Regex explained:
   *
   * ^   : Matches the start of a line.
   * ' ' : Matches a SPACE character.
   * +   : Quantifier that means 'one or more' of the preceding element.
   * |   : Logical OR operator.
   * ' ' : Matches a SPACE character.
   * $   : Matches the end of a line.
   * /gm : 'g' stands for global search and
   *        'm' stands for multiline (allows start and end characters
   *        (^ and $) to work over multiple lines).
   */
  return text.replace(/^ +| +$/gm, "");
};

import { createSelector } from "@reduxjs/toolkit";

import { addSeconds, toSeconds } from "@carescribe/utilities/src/timing";

import { selectDictating } from "@talktype/editor/src/reducers";

import { selectSecondsSinceLastConnection } from "./selectSecondsSinceLastConnection";
import { selectSumOfCompletedDictationDurations } from "./selectSumOfCompletedDictationDurations";

export const selectTotalSecondsSpentDictating = createSelector(
  [
    selectSumOfCompletedDictationDurations,
    selectSecondsSinceLastConnection,
    selectDictating,
  ],
  (sumOfCompletedDictationDurations, timeSinceLastConnection, dictating) =>
    // If dictating, add the current time spent dictating to the total to get
    // an accurate figure
    addSeconds(
      sumOfCompletedDictationDurations,
      dictating ? timeSinceLastConnection : toSeconds(0)
    )
);

import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the custom shortcuts from state.
 */
export const selectCustomShortcuts = createSelector(
  selectPreferences,
  (state) =>
    state.customShortcuts.slice().sort((a, b) => a.input.localeCompare(b.input))
);

import type { FormState } from "./FormState";
import type { Validators } from "./Validators";
import type { CustomWord } from "@talktype/types";
import type { ReactElement, RefObject } from "react";

import { BookBookmark } from "@phosphor-icons/react";
import { useEffect, useState } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { createOnChangeHandler } from "./createOnChangeHandler";
import { createOnSubmitHandler } from "./createOnSubmitHandler";
import styles from "./customWordsEditModal.module.scss";
import { messages } from "./messages";
import { FeaturedIcon } from "../FeaturedIcon";
import { Field } from "../Field";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";
import { StandardButton } from "../StandardButton";
import { TextInput } from "../TextInput";

const initialState = { value: "", error: null };

export type CustomWordsEditModalProps = {
  modalRef: RefObject<HTMLDialogElement>;
  word: CustomWord;
  validators: Validators;
  handleEdit: (word: CustomWord) => void;
  handleDelete: (id: string) => void;
  onClose: () => void;
};

export const CustomWordsEditModal = ({
  modalRef,
  word,
  validators,
  handleEdit,
  handleDelete,
  onClose,
}: CustomWordsEditModalProps): ReactElement => {
  const closeModal = (): void => modalRef.current?.close();

  const [form, setForm] = useState<FormState>(initialState);

  const getError = (): string => {
    const { value, error } = form;

    if (!error) {
      return "";
    }

    const message = messages.errors[error];
    return typeof message === "function" ? message(value) : message;
  };

  const hasError = (): boolean => {
    const { error } = form;

    return error !== null;
  };

  const onDelete = (): void => {
    handleDelete(word.id);
    closeModal();
  };

  const onValidSubmit = (partialWord: Omit<CustomWord, "id">): void => {
    handleEdit({ ...word, ...partialWord });
    closeModal();
  };

  useEffect(() => {
    setForm({ value: word.input, error: null });
  }, [word, setForm]);

  return (
    <Modal
      modalRef={modalRef}
      onClose={onClose}
      className={createSelectorClassName("edit-modal", "section")}
    >
      <ModalHeader
        style="compact"
        icon={
          <FeaturedIcon style="brand" weight="light" size="sm">
            <BookBookmark />
          </FeaturedIcon>
        }
        title={messages.editCustomWord}
        onClose={closeModal}
      />
      <form
        onSubmit={createOnSubmitHandler({
          initialValue: word.input,
          value: form.value,
          setForm,
          validate: validators.input.onSubmit,
          onValidSubmit,
        })}
        className={styles.form}
      >
        <Field
          label={{ text: messages.input.label, visible: false }}
          explainer={null}
          error={
            hasError()
              ? {
                  text: getError(),
                  props: {
                    className: createSelectorClassName(
                      "input-error",
                      "element"
                    ),
                  },
                }
              : null
          }
          className={createSelectorClassName("input", "interactive")}
        >
          <TextInput
            size="md"
            inputProps={{
              name: "input",
              autofocus: "",
              value: form.value,
              placeholder: word.input,
              onChange: createOnChangeHandler({
                initialValue: word.input,
                setForm,
                validate: validators.input.onChange,
              }),
              "aria-invalid": hasError(),
            }}
          />
        </Field>
        <div className={styles.buttonGroup}>
          <StandardButton
            colour="destructive"
            hierarchy="primary"
            size="md"
            style="default"
            label={messages.delete.label}
            elementProps={{
              type: "button",
              className: createSelectorClassName("delete", "interactive"),
              onClick: onDelete,
            }}
          />
          <StandardButton
            colour="brand"
            hierarchy="primary"
            size="md"
            style="default"
            label={messages.submit.label}
            elementProps={{
              type: "submit",
              disabled: form.value === "",
              className: createSelectorClassName("submit", "interactive"),
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

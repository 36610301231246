import type { PkceState } from "../types/PkceState";
import type { SagaIterator } from "redux-saga";

import { all, put } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpAuthenticate } from "./setUpAuthenticate";
import { setUpGetCode } from "./setUpGetCode";
import { setUpPkceCodePair } from "./setUpPkceCodePair";
import { setUpRefreshAccessToken } from "./tokens/setUpRefreshAccessToken";
import { setUpRequestRevokeAccessToken } from "./tokens/setUpRequestRevokeAccessToken";
import { setUpRequestTokens } from "./tokens/setUpRequestTokens";
import { setPkce } from "../reducer";

export const setUpPkce = function* (
  initialState: Partial<PkceState>
): SagaIterator<void> {
  yield put(setPkce(initialState));

  yield all(
    [
      setUpAuthenticate,
      setUpGetCode,
      setUpPkceCodePair,
      setUpRefreshAccessToken,
      setUpRequestRevokeAccessToken,
      setUpRequestTokens,
    ].map(retry)
  );
};

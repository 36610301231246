import type { ToastManager } from "@carescribe/toasts/src/ToastManager";
import type { Toast } from "@talktype/types";
import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

export const createToastChangeEventChannel = (
  toastManager: ToastManager<Toast>
): EventChannel<Toast[]> =>
  eventChannel((emit) => {
    const onChange = (): void => emit(toastManager.getToasts());

    toastManager.addEventListener("change", onChange);
    return () => toastManager.removeEventListener("change", onChange);
  });

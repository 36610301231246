import type { MeResponse } from "../types/MeResponse";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

export const validMeResponse = {
  created_at: P.string,
  email: P.string,
  id: P.number,
  updated_at: P.string,
  uuid: P.string,
};

export const isMeResponse = createGuard<MeResponse>(validMeResponse);

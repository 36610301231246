import type { Leaf } from "@talktype/types";

import { mergeRight } from "@carescribe/utilities/src/fp";

import { defaultInlineStyleProperties } from "./defaultInlineStyleProperties";
import { defaultInProgressProperties } from "./defaultInProgressProperties";

export const defaultLeafProperties: Omit<Leaf, "type" | "text"> = mergeRight(
  defaultInlineStyleProperties,
  defaultInProgressProperties
);

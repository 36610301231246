import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { editCustomShortcut } from "../../reducers/preferences";
import { requestEditCustomShortcut } from "../actions";

export const manageEditCustomShortcut = function* (): SagaIterator<void> {
  yield takeEvery(requestEditCustomShortcut, function* ({ payload: shortcut }) {
    yield put(broadcast(editCustomShortcut(shortcut)));
  });
};

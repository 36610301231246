const verifierLength = 64;

const validCharacters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

/**
 * Generate Code Verifier
 *
 * Generate a code verifier. A code verifier is a random string of 64 characters
 * from the set [A-Z, a-z, 0-9].
 */
export const generateCodeVerifier = (): string => {
  let codeVerifier = "";

  for (let i = 0; i < verifierLength; i++) {
    codeVerifier += validCharacters.charAt(
      Math.floor(Math.random() * validCharacters.length)
    );
  }

  return codeVerifier;
};

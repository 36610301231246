import type { Path, Point, Range } from "slate";

export const zeroWidthSpace = "\uFEFF";

export const newLineCharacter = "\n";
export type NewLineCharacter = typeof newLineCharacter;
export const newLineRegex = new RegExp(`(${newLineCharacter})`);

export const emptyString = "";
export type EmptyString = typeof emptyString;

export const emptyPath: Path = [0, 0];

export const emptyPoint: Point = { offset: 0, path: emptyPath };

export const emptyRange: Range = { anchor: emptyPoint, focus: emptyPoint };

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { pipe } from "@carescribe/utilities/src/fp";
import { isObject } from "@carescribe/utilities/src/guards/isObject";
import { StrictJSON } from "@carescribe/utilities/src/StrictJSON";

import { reshapeCustomShortcuts } from "./reshapers/reshapeCustomShortcuts";
import { reshapeCustomWords } from "./reshapers/reshapeCustomWords";
import { reshapeDarkMode } from "./reshapers/reshapeDarkMode";
import { reshapeDateFormat } from "./reshapers/reshapeDateFormat";
import { reshapeFontFamily } from "./reshapers/reshapeFontFamily";
import { reshapeFontSize } from "./reshapers/reshapeFontSize";
import { reshapeLanguage } from "./reshapers/reshapeLanguage";
import { reshapeNumberFormat } from "./reshapers/reshapeNumberFormat";
import { reshapeProfanityFilter } from "./reshapers/reshapeProfanityFilter";
import {
  requestReshapeLegacyPreferences,
  reshapedLegacyPreferences,
} from "../../actions";

/**
 * Reshape Legacy Preferences
 *
 * Transform the string contents of a TalkType v2 settings.json into v2
 * preferences.
 */
export const reshapeLegacyPreferences = function* (): SagaIterator<void> {
  yield takeEvery(requestReshapeLegacyPreferences, function* ({ payload }) {
    const [parsed]: SagaReturnType<typeof StrictJSON.safeParse> = yield call(
      StrictJSON.safeParse,
      payload
    );

    if (!isObject(parsed)) {
      yield put(reshapedLegacyPreferences(null));
      return;
    }

    const result = pipe(
      reshapeCustomShortcuts(parsed),
      reshapeCustomWords(parsed),
      reshapeDarkMode(parsed),
      reshapeDateFormat(parsed),
      reshapeFontFamily(parsed),
      reshapeFontSize(parsed),
      reshapeLanguage(parsed),
      reshapeNumberFormat(parsed),
      reshapeProfanityFilter(parsed)
    )({});

    yield put(
      reshapedLegacyPreferences(
        Object.keys(result).length === 0 ? null : result
      )
    );
  });
};

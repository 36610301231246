import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, call } from "redux-saga/effects";

import { segmentTranscript } from "./utils/segmentTranscript";
import { requestReshapeResult, reshapedResult } from "../actions";

export const reshapeResult = function* (): SagaIterator<void> {
  yield takeEvery(requestReshapeResult, function* ({ payload: initialResult }) {
    const modifiedResult: SagaReturnType<typeof segmentTranscript> = yield call(
      segmentTranscript,
      initialResult
    );

    yield put(reshapedResult(modifiedResult));
  });
};

import { joinStringsForRegExp } from "@carescribe/utilities/src/joinStringsForRegExp";

export type AnalysedTranscript = {
  hasPrompt: boolean;
  dictation: string;
  command: string;
  parameters: string;
  instruction: string;
};

type AnalyseTranscriptConfig = {
  text: string;
  promptKeywords: string[];
  commandKeywords: string[];
};

const removePunctuation = (text: string): string =>
  text.replace(/(\p{P})/gu, "");

const separateAtPromptKeyword = (
  text: string,
  joinedKeywords: string
): { hasPrompt: boolean; instruction: string; dictation: string } => {
  const keywordRegex = new RegExp(`(${joinedKeywords})`, "i");
  const match = text.match(keywordRegex);

  if (!match) {
    return { hasPrompt: false, instruction: text, dictation: text };
  }

  const [matchedKeyword] = match;
  const startIndex = match.index ?? 0;
  const endIndex = startIndex + matchedKeyword.length;

  return {
    hasPrompt: true,
    dictation: text.slice(0, startIndex),
    instruction: text.slice(endIndex).trim(),
  };
};

/**
 * Analyse Prompt
 *
 * @example
 * `
 * ┌-dictation-┐┌-keyword-┐┌-----instruction-----┐
 * "lorem ipsum  talk type  select    lorem ipsum"
 *                         └command┘ └parameters-┘
 *              └------------prompt--------------┘
 * `
 *
 * @example
 * `
 * ┌-dictation-┐┌-keyword-┐┌instruction-┐
 * "lorem ipsum  talk type  underline on"
 *                         └--command---┘
 *              └--------prompt---------┘
 * `
 *
 * @example
 * `
 * ┌-keyword-┐┌instruction-┐
 * "talk type  underline on"
 *            └--command---┘
 * └--------prompt---------┘
 * `
 */
export const analysePrompt = ({
  text,
  promptKeywords,
  commandKeywords,
}: AnalyseTranscriptConfig): AnalysedTranscript => {
  const joinedKeywords = joinStringsForRegExp(promptKeywords);
  const joinedCommands = joinStringsForRegExp(
    [...commandKeywords].sort((a, b) => b.length - a.length)
  );

  const { hasPrompt, instruction, dictation } = separateAtPromptKeyword(
    removePunctuation(text),
    joinedKeywords
  );

  const space = "\\s*";

  const commandGroup = `.*?(?<command>${joinedCommands})${space}`;
  const parameterGroup = `(?<parameters>.*)?`;

  const allGroups = commandGroup + parameterGroup;

  const match = instruction.match(new RegExp(allGroups, "i"));
  const groups = match?.groups;

  const { command = "", parameters = "" } = groups ?? {};

  const result = {
    hasPrompt,
    dictation: !hasPrompt ? text : dictation,
    command: command.toLowerCase(),
    parameters,
    instruction,
  };

  return result;
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { addToast, dismissToast } from "./actions";
import { setToasts } from "../reducer";
import { createToastChangeEventChannel } from "../utils/createToastChangeEventChannel";
import { createToastManager } from "../utils/createToastManager";

export const setUpToasts = function* (): SagaIterator<void> {
  const toastManager: SagaReturnType<typeof createToastManager> = yield call(
    createToastManager
  );

  const toastsChangeEventChannel: SagaReturnType<
    typeof createToastChangeEventChannel
  > = yield call(createToastChangeEventChannel, toastManager);

  yield takeEvery(toastsChangeEventChannel, function* (toasts) {
    yield put(setToasts(toasts));
  });

  yield takeEvery(addToast, function* ({ payload: toast }) {
    yield call(toastManager.set, toast);
  });

  yield takeEvery(dismissToast, function* ({ payload: toast }) {
    yield call(toastManager.dismiss, toast);
  });
};

/**
 * Round to Places
 *
 * Round a number to a certain number of digits after the decimal point.
 *
 * @example
 *
 * roundToPlaces(8.23452, 3); // 8.235
 */
export const roundToPlaces = (
  number: number,
  decimalPlaces: number
): number => {
  const multiplier = Math.pow(10, decimalPlaces);

  return Math.round(number * multiplier) / multiplier;
};

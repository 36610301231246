import type { Toast as BaseToast } from "../types";
import type { ReactElement } from "react";

import { Fragment } from "react";

export type ToastsContainerProps<Toast extends BaseToast = BaseToast> = {
  toasts: Toast[];
  renderToast: (toast: Toast) => ReactElement;
};

/**
 * `ToastsContainer` is responsible for the rendering of toasts.
 *
 * It is agnostic to the shape of the toast contents, with this being handled
 * by the `Toast` component passed in.
 *
 * - **Props:**
 *   - `toasts`: the toasts to render
 *   - `Toast`: a component that takes a toast and returns a ReactNode
 */
export const ToastsContainer = <Toast extends BaseToast>({
  toasts,
  renderToast,
}: ToastsContainerProps<Toast>): ReactElement => (
  <>
    {toasts.map((toast) => (
      <Fragment key={toast.id}>{renderToast(toast)}</Fragment>
    ))}
  </>
);

import type { EventChannel, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, takeEvery } from "redux-saga/effects";

import { setWindowMaximised } from "@talktype/system/src/reducers/system";
import { getIpc } from "@talktype/utilities";

import { createWindowIsMaximisedChangeChannel } from "./utils/createWindowIsMaximisedChangeChannel";

/**
 * Listens to window maximised changes.
 */
export const listenWindowIsMaximisedChanges = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);
  const getIsMaximised = ipc?.system?.window?.isMaximised?.v1;

  if (!ipc || !getIsMaximised) {
    return;
  }

  const isMaximisedAtMount: SagaReturnType<typeof getIsMaximised> = yield call(
    getIsMaximised
  );

  yield put(setWindowMaximised(isMaximisedAtMount));

  const isMaximisedEventChannel: EventChannel<boolean> = yield call(
    createWindowIsMaximisedChangeChannel,
    ipc
  );

  yield takeEvery(isMaximisedEventChannel, function* (isMaximised) {
    yield put(setWindowMaximised(isMaximised));
  });
};

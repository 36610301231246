import type { EncryptedStorage } from "./versioned/v1/types";

type Migration = {
  version: number;
  migrate: (
    database: IDBDatabase,
    transaction: IDBTransaction,
    legacyLocalStorage: EncryptedStorage | null
  ) => Promise<void>;
};

type DatabaseConfig = {
  version: number;
  migrations: Migration[];
  legacyLocalStorage: EncryptedStorage | null;
};

/**
 * An IndexedDB interface for storing and retrieving data.
 *
 * - Provides methods to open a connection to the database
 * - Allows reading and writing of storage data
 * - Supports migrations
 *
 * @example
 * // Open the database
 * const database = new IndexedDB();
 * await database.open();
 *
 * // Getting data
 * const storage = await database.get();
 *
 * // Writing data
 * await database.put(storage);
 */
export class IndexedDB {
  private database: IDBDatabase | null = null;
  private name = "talktype";
  private version: number;
  private migrations: Migration[] = [];
  private legacyLocalStorage: EncryptedStorage | null = null;

  private onUpgradeNeeded = async (
    { result: database, transaction }: IDBOpenDBRequest,
    {
      oldVersion,
      newVersion,
    }: {
      oldVersion: number;
      newVersion: number | null;
    }
  ): Promise<void> => {
    if (!transaction) {
      throw new Error("No transaction available to perform migration");
    }

    // Database is being deleted
    if (newVersion === null) {
      return;
    }

    if (newVersion < oldVersion) {
      throw new Error("Database is outdated. Try reloading the page.");
    }

    for (const migration of this.migrations) {
      if (migration.version > oldVersion) {
        await migration.migrate(database, transaction, this.legacyLocalStorage);
      }
    }
  };

  /**
   * The database is shared between tabs/windows. When a new tab is opened
   * and a new version is detected:
   * - The database is closed in the older tabs
   *  - The new tab otherwise may be blocked from carrying out migrations
   * - The older tabs are reloaded
   *  - The stale clients will be expecting a different data shape
   *
   * This is to prevent blocking the new tab from carrying out migrations.
   *
   * For details, see {@link https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB#version_changes_while_a_web_app_is_open_in_another_tab}
   * Another great resource: {@link https://www.nerd.vision/post/how-we-solved-a-case-where-indexeddb-did-not-connect}
   */
  private onStale = ({ newVersion }: { newVersion: number | null }): void => {
    if (newVersion === null) {
      return;
    }
    this.database?.close();
    window.location.reload();
  };

  public constructor({
    version,
    migrations,
    legacyLocalStorage,
  }: DatabaseConfig) {
    this.version = version;
    this.migrations = migrations;
    this.legacyLocalStorage = legacyLocalStorage;
  }

  public open(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.database) {
        resolve();
        return;
      }

      const request = indexedDB.open(this.name, this.version);

      request.addEventListener("success", (): void => {
        const database = request.result;
        database.addEventListener("versionchange", this.onStale);
        this.database = database;
        resolve();
      });
      request.addEventListener("error", reject);
      request.addEventListener(
        "upgradeneeded",
        (event): void => void this.onUpgradeNeeded(request, event)
      );
      /**
       * This error is supposed to happen whenever the database attempts a
       * migration but is blocked by another tab that is currently using an
       * older version. In theory, we should not see this as new databases are
       * instructed to close whenever a `versionchange` event is detected.
       */
      request.addEventListener("blocked", (): void => {
        reject(
          new Error(
            "Database opening blocked. Please close other tabs or instances using the database and try again."
          )
        );
      });
    });
  }

  public async get(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (this.database === null) {
        reject(new Error("Failed to get data. Database not open"));
        return;
      }

      let databaseVersionMatches = true;

      const transaction = this.database.transaction(["state"], "readonly");

      this.database.addEventListener("versionchange", (): void => {
        databaseVersionMatches = false;
        reject(new Error("Database version changed"));
      });

      const objectStore = transaction.objectStore("state");
      const request = objectStore.get("state");
      request.addEventListener("success", async (): Promise<void> => {
        if (!databaseVersionMatches) {
          reject(new Error("Database version changed"));
          return;
        }

        resolve(request.result);
      });
      request.addEventListener("error", reject);
    });
  }

  public async put(data: unknown): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.database === null) {
        reject(new Error("Failed to put data. Database not open"));
        return;
      }

      const transaction = this.database.transaction("state", "readwrite");
      const objectStore = transaction.objectStore("state");
      const request = objectStore.put(data, "state");

      request.addEventListener("error", reject);
      request.addEventListener("success", (): void => resolve());
    });
  }
}

import type { TokensResponse } from "../../../types/TokensResponse";
import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { setTokens } from "../../../reducer";

export const handleTokensResponse = ({ data }: { data: TokensResponse }) =>
  function* (): SagaIterator<void> {
    yield put(
      setTokens({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresAt: toSeconds(data.created_at + data.expires_in),
        scope: data.scope,
        tokenType: data.token_type,
      })
    );
  };

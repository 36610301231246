import { createSagaAction } from "@carescribe/utilities/src/saga";

export const requestDisconnectAudio = createSagaAction<
  void,
  "request-audio-disconnect"
>("request-audio-disconnect");

export const createdAudioStream = createSagaAction<
  MediaStream,
  "created-audio-stream"
>("created-audio-stream");

export const createdMediaRecorder = createSagaAction<
  MediaRecorder,
  "created-media-recorder"
>("created-media-recorder");

export const requestInputDevices = createSagaAction<
  void,
  "request-input-devices"
>("request-input-devices");

export const audioConnected = createSagaAction<void, "audio-connected">(
  "audio-connected"
);

export const notAllowedUserMedia = createSagaAction<
  void,
  "not-allowed-user-media"
>("not-allowed-user-media");

export const notFoundUserMedia = createSagaAction<void, "not-found-user-media">(
  "not-found-user-media"
);

export const mediaStreamError = createSagaAction<void, "media-stream-error">(
  "media-stream-error"
);

import type { InvalidInstallationToast as InvalidInstallationToastType } from "@talktype/types";
import type { ReactElement } from "react";

import { FolderDashed } from "@phosphor-icons/react";

import { Alert } from "@talktype/ui/src/Alert";

import { messages } from "./messages";

export const InvalidInstallationToast = ({
  dismiss,
}: InvalidInstallationToastType): ReactElement => (
  <Alert
    colour="destructive"
    hierarchy="primary"
    format="stacked"
    icon={<FolderDashed />}
    onClose={dismiss}
    primaryText={messages.primaryText}
    secondaryText={messages.secondaryText}
    label={null}
  />
);

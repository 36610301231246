import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call, select, put } from "redux-saga/effects";

import { REVOKE_URL } from "../../config/urls";
import { setTokens } from "../../reducer";
import { selectTokens } from "../../reducer/selectors/selectTokens";
import { accessTokenRevoked, requestRevokeAccessToken } from "../actions";
import { pkceRequest } from "../utils/pkceRequest";

/**
 * Set Up Request Revoke Access Token
 *
 * Revoke the access token when requested.
 */
export const setUpRequestRevokeAccessToken = function* (): SagaIterator<void> {
  yield takeEvery(requestRevokeAccessToken, function* () {
    const tokens: SagaReturnType<typeof selectTokens> = yield select(
      selectTokens
    );

    yield call(pkceRequest, {
      pathname: REVOKE_URL,
      params: { token: tokens ? tokens.accessToken : "" },
    });

    yield put(setTokens(null));

    yield put(accessTokenRevoked());
  });
};

import type { BrowserMethods } from "@carescribe/updater";

import { checkForUpdates } from "./checkForUpdates";
import { quitAndInstall } from "./quitAndInstall";

/**
 * Creates a set of sagas that instruct the updater how to perform the relevant
 * update steps in the browser context.
 *
 * - Check for updates: This is done by fetching the current page and comparing
 *   the build id.
 * - Quit and install: This is done by calling `window.location.reload()`.
 */
export const getBrowserMethods = ({
  buildId,
}: {
  buildId: string;
}): BrowserMethods => ({
  checkForUpdates: checkForUpdates({ buildId }),
  quitAndInstall,
});

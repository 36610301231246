// Carescribe Web has opted to use American spelling for licence key, so be
// careful when noting the spelling ¯\_(ツ)_/¯

// The endpoint's path
export const LICENCE_KEY_ENDPOINT =
  "api/talktype/v2/license_keys/migration_status";

// Part of the JSON in the response from the endpoint
// e.g. { license_key_migration_status: "unredeemed" }
export const LICENCE_KEY_MIGRATION_STATUS = "license_key_migration_status";

// Used in the URL search params we append to the onboarding URL
// e.g. talk-type.com/talktype/redeem?license_key=1234
export const LICENCE_KEY_PARAM = "license_key";

export const ME_URL = "api/talktype/v2/me";

export const DICTATION_SESSIONS_URL = "api/talktype/v2/dictation_sessions";

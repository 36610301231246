import type { TrackedWakeLock } from "../types/TrackedWakeLock";
import type { SagaIterator } from "redux-saga";

import { call, put, takeEvery } from "redux-saga/effects";

import {
  broadcastedScreenWakeLock,
  requestBroadcastScreenWakeLock,
  requestResetScreenWakeLock,
  requestTrackScreenWakeLock,
} from "../actions";

/**
 * Tracks the lifecycle of an active screen wake lock.
 *
 * Allows for:
 *
 * - Tracking a new active screen wake lock
 * - Broadcasting the active screen wake lock
 * - Resetting the active screen wake lock
 *   - Lock's `reset` method is called to perform cleanup actions
 */
export const trackScreenWakeLock = function* (): SagaIterator<void> {
  let lock: TrackedWakeLock = null;

  yield takeEvery(requestTrackScreenWakeLock, function* ({ payload: newLock }) {
    lock = newLock;
  });

  yield takeEvery(requestBroadcastScreenWakeLock, function* () {
    yield put(broadcastedScreenWakeLock(lock));
  });

  yield takeEvery(requestResetScreenWakeLock, function* () {
    if (!lock) {
      return;
    }

    yield call(lock.reset);
    lock = null;
  });
};

/**
 * Checks if the application is running in a PWA.
 *
 * Criteria:
 * - Display mode is standalone (standard way of checking)
 * - navigator.standalone is true (iOS only)
 */
export const checkIsRunningInPWA = (): boolean =>
  window.matchMedia("(display-mode: standalone)").matches ||
  ("standalone" in window.navigator && window.navigator.standalone === true);

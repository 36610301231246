import type { ReactElement, ReactNode } from "react";

import { messages } from "./messages";
import { ShortcutDiagram } from "./ShortcutDiagram";
import { EmptyPageHelp } from "../EmptyPageHelp";

export type ShortcutsEmptyProps = { children: ReactNode };

export const ShortcutsEmpty = ({
  children,
}: ShortcutsEmptyProps): ReactElement => (
  <EmptyPageHelp
    title={messages.title}
    body={messages.body}
    decoration={<ShortcutDiagram />}
  >
    {children}
  </EmptyPageHelp>
);

import { messages as baseMessages } from "../../messages";
import { createMatchers } from "../../utils/createMatchers";
import { messages as commonMessages } from "../messages";

const { underline, on } = commonMessages;

export const messages = {
  matchers: createMatchers(underline, on),
  successful: "OK, underline is on!",
  failed: baseMessages.failureWithoutReason,
  listing: ["Underline on"],
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";

type SetFilterProfanity = (
  state: Preferences,
  action: PayloadAction<boolean>
) => void;

/**
 * Handles the setting of filterProfanity.
 */
export const setFilterProfanity: SetFilterProfanity = (state, { payload }) => {
  state.filterProfanity = payload;
};

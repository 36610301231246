import type { Magnitude } from "./types";

import * as dictionary from "./dictionary";

const digitWords = Object.keys(dictionary.digits).join("|");
const magnitudeDigitWords = Object.keys(dictionary.allDigits).join("|");
const teenWords = Object.keys(dictionary.teens).join("|");
const tenWords = Object.keys(dictionary.tens).join("|");

// Space is considered a hyphen or space, so forty-two and forty two both match.
// This also means that "one–-hundred  and-fortytwo" is considered valid.
const space = "(\\s|-)*";
const and = `(${dictionary.and}${space})?`;
const hundred = `${dictionary.cardinalHundred}|${dictionary.ordinalHundred}`;

/**
 * Create a regex to match the 3 digit pattern for a particular magnitude.
 *
 * @example
 * createThreeDigitPattern("thousand");
 * // Outputs a regex that matches "one hundred and twenty three thousand" and
 * // similar patterns
 */
const createThreeDigitPattern = (magnitudeName: Magnitude = ""): string => {
  const digitsType = magnitudeName ? magnitudeDigitWords : digitWords;

  // Named groups
  const hundreds = `(?<hundred${magnitudeName}s>${magnitudeDigitWords})`;
  const teens = `(?<teen${magnitudeName}s>${teenWords})`;
  const tens = `(?<ten${magnitudeName}s>${tenWords})?`;
  const digits = `(?<digit${magnitudeName}s>${digitsType})?`;
  const magnitude = `(?<magnitude${magnitudeName}>${magnitudeName}${
    magnitudeName ? `|${magnitudeName}th` : ""
  })`;

  // Other groupings
  const tensAndDigits = `(${tens}${space}${digits})?`;
  const tripleDigit = `(${hundreds}${space}(${
    !magnitudeName ? "?<hundredword>" : ""
  }${hundred}))?${space}`;
  const doubleAndSingleDigits = `(${teens}|${tensAndDigits})${space}`;

  return `(${tripleDigit}${and}${doubleAndSingleDigits}${magnitude})?`;
};

// Ensure that we're only matching from the start of a number word. Due to the
// flexibility of pattern, everything is optional, meaning it will happily
// match nothing. This lookahead means that it definitely searches for a match
const startingPattern = `(?=${magnitudeDigitWords}|${teenWords}|${tenWords})`;

// Create patterns for all magnitudes in descending order
const threeDigitPatterns = dictionary.descendingMagnitudes
  .map(([magnitude]) => createThreeDigitPattern(magnitude))
  .join(space);

// The final regex pattern to match numbers up to the highest defined magnitude.
// This regex ends up being quite long with lots of repetition, because
// backreferences are not supported in the browser, so repetition it is!
export const pattern = `\\b${startingPattern}${threeDigitPatterns}\\b`;

import type { RootState } from "@talktype/store";
import type { PWAInstallPromptProps } from "@talktype/ui/src/PWAInstallPrompt";

import { connect } from "react-redux";

import {
  requestDismissCustomInstallPrompt,
  requestPromptNativeInstall,
  customInstallPromptMounted,
  customInstallPromptUnmounted,
} from "@talktype/pwa/src/sagas/actions";
import {
  selectInstallTarget,
  selectSupportsNativeInstall,
} from "@talktype/pwa/src/state/selectors";
import { PWAInstallPrompt as Component } from "@talktype/ui/src/PWAInstallPrompt";

type StateProps = Pick<
  PWAInstallPromptProps,
  "target" | "supportsNativeInstall"
>;
type DispatchProps = Pick<
  PWAInstallPromptProps,
  "onDismiss" | "onPrompt" | "onMount" | "onUnmount"
>;

const mapStateToProps = (state: RootState): StateProps => ({
  target: selectInstallTarget(state),
  supportsNativeInstall: selectSupportsNativeInstall(state),
});

const mapDispatchToProps: DispatchProps = {
  onPrompt: requestPromptNativeInstall,
  onDismiss: requestDismissCustomInstallPrompt,
  onMount: customInstallPromptMounted,
  onUnmount: customInstallPromptUnmounted,
};

export const PWAInstallPrompt = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

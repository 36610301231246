import type { SubscriptionState } from "./trackActiveAppSubscriptionState";

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-system");

export const setActiveAppTracking = action<boolean, "set-active-app-tracking">(
  "set-active-app-tracking"
);

export const unsubscribedFromActiveApp = action<
  void,
  "unsubscribed-from-active-app"
>("unsubscribed-from-active-app");

export const subscribedToActiveApp = action<
  { onUnsubscribe: () => void },
  "subscribed-to-active-app"
>("subscribed-to-active-app");

export const requestActiveAppSubscriptionState = action<
  void,
  "request-active-app-subscription-state"
>("request-active-app-subscription-state");

export const broadcastActiveAppSubscriptionState = action<
  SubscriptionState,
  "broadcast-active-app-subscription-state"
>("broadcast-active-app-subscription-state");

export const pressedWindowControlButton = action<
  "minimise" | "maximise" | "unmaximise" | "close",
  "pressed-window-control-button"
>("pressed-window-control-button");

export const trayAdded = action<void, "tray-added">("tray-added");

export const requestOpenHelp = action<void, "request-open-help">(
  "request-open-help"
);

export const requestFocusTalkType = action<void, "request-focus-talktype">(
  "request-focus-talktype"
);

export const requestQuitTalkType = action<void, "request-quit-talktype">(
  "request-quit-talktype"
);

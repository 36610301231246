import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, select, takeEvery } from "redux-saga/effects";

import { convertTextToStars } from "@carescribe/utilities/src/convertTextToStars";

import { requestTrackEvent } from "@talktype/analytics";

import {
  addCustomWord,
  editCustomWord,
  deleteCustomWord,
  selectCustomWords,
} from "../../reducers";

const handleAdd = function* ({
  payload: word,
}: ReturnType<typeof addCustomWord>): SagaIterator<void> {
  const customWords: SagaReturnType<typeof selectCustomWords> = yield select(
    selectCustomWords
  );

  yield put(
    requestTrackEvent({
      name: "Custom Word Definition Updated",
      data: {
        customWordDefinitionTextContent: convertTextToStars(word.input),
        customWordDefinitionInteraction: "added",
        totalCustomWords: customWords.length,
      },
    })
  );
};

const handleDelete = function* (): SagaIterator<void> {
  const customWords: SagaReturnType<typeof selectCustomWords> = yield select(
    selectCustomWords
  );

  yield put(
    requestTrackEvent({
      name: "Custom Word Definition Updated",
      data: {
        customWordDefinitionInteraction: "removed",
        totalCustomWords: customWords.length,
      },
    })
  );
};

const handleEdit = function* ({
  payload: word,
}: ReturnType<typeof editCustomWord>): SagaIterator<void> {
  const customWords: SagaReturnType<typeof selectCustomWords> = yield select(
    selectCustomWords
  );

  yield put(
    requestTrackEvent({
      name: "Custom Word Definition Updated",
      data: {
        customWordDefinitionTextContent: convertTextToStars(word.input),
        customWordDefinitionInteraction: "edited",
        totalCustomWords: customWords.length,
      },
    })
  );
};

export const trackCustomWords = function* (): SagaIterator<void> {
  yield takeEvery(addCustomWord, handleAdd);
  yield takeEvery(deleteCustomWord, handleDelete);
  yield takeEvery(editCustomWord, handleEdit);
};

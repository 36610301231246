import type { Callback } from "@carescribe/types";
import type {
  Chunk,
  Replacement,
  TranscriptTransformationSettings,
} from "@talktype/types";

import { applyStages, applyReplacements } from "./";

type ApplyTranscriptTransformation = Callback<
  {
    chunks: Chunk[];
    precedingChunk: Chunk;
    settings: TranscriptTransformationSettings;
  },
  {
    transcript: Chunk[];
    replacements: { shortcut: Replacement[]; punctuation: Replacement[] };
  }
>;

/**
 * Applies a number of transformations to a transcript:
 *
 * - Stages: a series of conditional string manipulations
 * - Replacements: applying replacements specified in settings custom shortcuts
 * - Removes empty text segments
 */
export const applyTranscriptTransformation: ApplyTranscriptTransformation = ({
  chunks,
  precedingChunk,
  settings,
}) => {
  const { replaced, replacements: shortcutReplacements } = applyReplacements(
    chunks,
    settings.customShortcuts
  );

  const { transformed, replacements: punctuationReplacements } = applyStages({
    chunks: replaced,
    precedingChunk,
    settings,
  });

  const emptyTextSegmentsRemoved = transformed.filter(
    (segment) => segment.type !== "text" || segment.text.length > 0
  );

  return {
    transcript: emptyTextSegmentsRemoved,
    replacements: {
      shortcut:
        /* TODO: Necessary for now to prevent bullet point being logged twice
         * See: https://github.com/carescribe/caption.ed/pull/562#issuecomment-1864332586
         */
        shortcutReplacements.filter(({ type }) => type !== "punctuation"),
      punctuation: punctuationReplacements,
    },
  };
};

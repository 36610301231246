import { joinStringsForRegExp } from "@carescribe/utilities/src/joinStringsForRegExp";

// A space, word boundary or start of string lookbehind.
const start = "(?<=\\s|\\b|^)";
// A space, word boundary or end of string lookahead.
const end = "(?=\\s|\\b|$)";

/**
 * Splits text by a set of matchers specified by a string array.
 *
 * If matchers is empty, returns the non-split input as a single-element array.
 *
 * @example // Targeting a sentence by words 'lorem', 'ipsum'.
 *
 * split('lorem ipsum dolor sit amet', ['lorem', 'ipsum']);
 *
 * // returns ["", "lorem", " ipsum dolor ", "sit", " amet"]
 *
 * @example // Targetting a sentenced by a non-existent matcher.
 *
 * split('lorem ipsum dolor sit amet', ['non-existent]);
 *
 * // returns ['lorem ipsum dolor sit amet']
 */
export const split = (input: string, matchers: string[]): string[] =>
  matchers.length
    ? input.split(
        new RegExp(`${start}(${joinStringsForRegExp(matchers)})${end}`, "gi")
      )
    : [input];

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";
import type { ColourScheme } from "@talktype/types/src/ColourScheme";

type SetColourScheme = (
  state: Preferences,
  action: PayloadAction<ColourScheme>
) => void;

/**
 * Handles the setting of colourScheme.
 */
export const setColourScheme: SetColourScheme = (state, { payload }) => {
  state.colourScheme = payload;
};

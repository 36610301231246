import type { ReactElement, ReactNode } from "react";

import styles from "./paragraph.module.scss";

export type ParagraphProps = {
  attributes: Record<string, unknown>;
  children: ReactNode;
};

export const Paragraph = ({
  attributes,
  children,
}: ParagraphProps): ReactElement => (
  <p {...attributes} className={styles.paragraph}>
    {children}
  </p>
);

import { messages as baseMessages } from "../../messages";
import { createMatchers } from "../../utils/createMatchers";
import { messages as commonMessages } from "../messages";

const { italic, off } = commonMessages;

export const messages = {
  matchers: createMatchers(italic, off),
  successful: "OK, italic is off!",
  failed: baseMessages.failureWithoutReason,
  listing: ["Italic off"],
};

import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import * as reducers from "./reducers";

export const { reducer, actions } = createSlice({
  name: "user",
  initialState,
  reducers,
});

export const {
  setLicenceKey,
  setLoginStatus,
  setTalkTypeDashboardUrl,
  setMe,
  setUser,
} = actions;

import type { FormState } from "./FormState";
import type { SupportedErrorCode } from "./SupportedErrorCode";
import type { Callback } from "@carescribe/types";
import type { Rule } from "@talktype/forms";
import type { Dispatch, SetStateAction, ChangeEventHandler } from "react";

import { normaliseWhitespace } from "@carescribe/utilities/src/normaliseWhitespace";

type CreateOnChangeHandler = Callback<
  {
    field: keyof FormState;
    initialValue: string;
    setForm: Dispatch<SetStateAction<FormState>>;
    validate: Rule<SupportedErrorCode> | null;
  },
  ChangeEventHandler<HTMLInputElement>
>;

export const createOnChangeHandler: CreateOnChangeHandler =
  ({ field, initialValue, setForm, validate }) =>
  ({ currentTarget: { value } }) => {
    setForm((previous) => ({
      ...previous,
      [field]: {
        value,
        error: validate
          ? validate(normaliseWhitespace(value), { initialValue })
          : null,
      },
    }));
  };

import type { Descendant } from "slate";

import { extractText } from "@carescribe/slate/src/utils/extractText";

import { paragraphNode } from "../../src/utils/paragraphNode";
import { textNode } from "../../src/utils/textNode";

export const recoverCorruptedChildren = (
  children: unknown
): null | Descendant[] => {
  const recoveredText = extractText(children);

  if (!recoveredText) {
    return null;
  }

  return [paragraphNode(textNode(recoveredText))];
};

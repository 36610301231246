import { useLayoutEffect, useState, type RefObject } from "react";

/**
 * Provides the ability to watch for changes in the size of a DOM element.
 *
 * This hook uses the ResizeObserver API to monitor changes in the dimensions
 * (width and height) of a referenced HTML element. It updates the React state
 * accordingly, ensuring that the component re-renders when the size changes.
 *
 * Optimised to only re-render when the observed size changes.
 *
 * @param ref - Reference to the HTML element
 * @returns - An object with the current width and height of the element
 *
 * @example
 * ```
 * const ref = useRef<HTMLElement>(null);
 * const { width, height } = useObservedSize(ref);
 * ```
 */
export const useObservedSize = (
  ref: RefObject<HTMLElement>
): { width: number; height: number } => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      for (const {
        contentRect: { width, height },
      } of entries) {
        setSize({ width, height });
      }
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, [ref]);

  return size;
};

import type { InlineStyles } from "@talktype/types";
import type { MouseEvent, KeyboardEvent } from "react";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, call, put } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

import {
  broadcastedInlineStyles,
  requestBroadcastInlineStyles,
  requestSetInlineStyle,
} from "../actions";

export const handleStyleFormattingButtons = function* (
  name: InlineStyles,
  event: KeyboardEvent | MouseEvent
): SagaIterator<void> {
  yield put(requestBroadcastInlineStyles());

  const { payload: styles }: SagaReturnType<typeof broadcastedInlineStyles> =
    yield take(broadcastedInlineStyles);

  const newStyleStatus = !styles[name];

  yield put(requestSetInlineStyle([name, newStyleStatus]));

  const interactionMethod: SagaReturnType<
    typeof getInteractionMethodFromEvent
  > = yield call(getInteractionMethodFromEvent, event);

  yield put(
    requestTrackEvent({
      name: "Style Toggled",
      data: {
        formattingStyleApplied: name,
        interactionMethod,
        newStyleStatus: newStyleStatus ? "on" : "off",
      },
    })
  );
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { clearInProgressText } from "./clearInProgressText";
import { finaliseAndStopDictation } from "./finaliseAndStopDictation";
import { finaliseDictation } from "./finaliseDictation";
import { handleFinaliseText } from "./handleFinaliseText";
import { handleInsertTranscript } from "./handleInsertTranscript";
import { manageEditorPrecedingText } from "./manageEditorPrecedingText";
import { preventInteractions } from "./preventInteractions";

export const setUpResults = function* (): SagaIterator<void> {
  yield all(
    [
      clearInProgressText,
      handleInsertTranscript,
      handleFinaliseText,
      manageEditorPrecedingText,
      preventInteractions,
      finaliseAndStopDictation,
      finaliseDictation,
    ].map(retry)
  );
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { CustomWord, Preferences } from "@talktype/types";

type DeleteCustomWord = (
  state: Preferences,
  action: PayloadAction<CustomWord["id"]>
) => void;

/**
 * Handles the deletion of a custom word from state.
 */
export const deleteCustomWord: DeleteCustomWord = (state, { payload: id }) => {
  state.customWords = state.customWords.filter((word) => word.id !== id);
};

import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { createDOMEventChannel } from "@carescribe/utilities/src/sagas";

import { globalKeyDown } from "./actions";

export const attachGlobalKeyListener = function* (): SagaIterator<void> {
  const channel = createDOMEventChannel(window, "keydown");

  yield takeEvery(channel, function* (event: KeyboardEvent) {
    yield put(globalKeyDown(event));
  });
};

import type { BeforeInstallPromptEvent } from "../../types/BeforeInstallPromptEvent";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import {
  preventDefault,
  createDOMEventChannel,
} from "@carescribe/utilities/src/sagas";

import { setSupportsNativeInstall } from "../../state";
import {
  requestBeforeInstallPromptEvent,
  broadcastedBeforeInstallPromptEvent,
} from "../actions";

/**
 * Listens for the `beforeinstallprompt` event, stores it, and broadcasts it
 * when requested. Saves in state when the event is captured so that the UI
 * can reflect that the native install prompt is supported.
 *
 * - Must run early to avoid missing the event
 * - After calling the `prompt` method, the event prompt becomes "stale",
 *   meaning calling it again will no longer bring up the native install prompt.
 *   A new `beforeinstallprompt` event is fired after user is done interacting
 *   with the prompt, allowing us to capture a fresh instance.
 */
export const trackBeforeInstallPromptEvent = function* (): SagaIterator<void> {
  let beforeInstallPromptEvent: BeforeInstallPromptEvent | null = null;

  yield takeEvery(requestBeforeInstallPromptEvent, function* () {
    yield put(broadcastedBeforeInstallPromptEvent(beforeInstallPromptEvent));
  });

  const beforeInstallPromptEventChannel: SagaReturnType<
    typeof createDOMEventChannel
  > = yield call(createDOMEventChannel, window, "beforeinstallprompt");

  yield takeEvery(
    beforeInstallPromptEventChannel,
    function* (deferredPrompt: BeforeInstallPromptEvent) {
      yield call(preventDefault, deferredPrompt);
      yield put(setSupportsNativeInstall(true));
      beforeInstallPromptEvent = deferredPrompt;
    }
  );
};

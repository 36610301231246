import type { EditorType } from "..";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { isLeaf } from "../guards/isLeaf";

/**
 * Finalise In Progress Text
 *
 * Updates any nodes in the editor with `inProgress: true` to
 * `inProgress: false`
 */
export const finaliseInProgressText = function* (
  editor: EditorType
): SagaIterator<void> {
  const [anchor, focus]: SagaReturnType<typeof editor.edges> = yield call(
    editor.edges,
    []
  );

  yield call(
    editor.setNodes,
    { inProgress: false },
    { at: { anchor, focus }, match: isLeaf }
  );
};

import { messages as commonMessages } from "../../messages";

export const messages = {
  matchers: [`select ${commonMessages.from}`],
  from: commonMessages.from,
  to: commonMessages.to,
  successful: "Great, selected that",
  failed: "Sorry, couldn't find that",
  listing: ["Select from {word} to {word}"],
};

import type { Command } from "../../../types";

import { put, take } from "redux-saga/effects";

import {
  requestExecuteBackspace,
  executedBackspace,
} from "@talktype/editor/src/sagas/actions";

export const pressDelete: Command = function* () {
  yield put(requestExecuteBackspace());
  yield take(executedBackspace);

  return true;
};

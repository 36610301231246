import type {
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
} from "react";

import { Check } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./checkbox.module.scss";
import { IconContainer } from "../IconContainer";

export type CheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const Checkbox = ({
  className,
  checked,
  ...checkboxProps
}: CheckboxProps): ReactElement => (
  <span className={styles.container}>
    <input
      type="checkbox"
      {...checkboxProps}
      checked={checked}
      className={classNames(styles.checkbox, className)}
    />
    <IconContainer
      className={classNames(styles.icon, [!!checked, styles.iconChecked])}
    >
      <Check weight="bold" />
    </IconContainer>
  </span>
);

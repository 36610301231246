import { messages as baseMessages } from "../../messages";
import { createMatchers } from "../../utils/createMatchers";
import { messages as commonMessages } from "../messages";

const { italic, on } = commonMessages;

export const messages = {
  matchers: createMatchers(italic, on),
  successful: "OK, italic is on!",
  failed: baseMessages.failureWithoutReason,
  listing: ["Italic on"],
};

import type { MenuItem } from "@talktype/types";
import type { ReactElement } from "react";

import { usePopover } from "@carescribe/ui";
import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { LogoActionButton } from "@talktype/ui/src/LogoActionButton";

import { Item } from "./Item";
import styles from "./menu.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type MenuProps = { items: MenuItem[] };

export const Menu = ({ items }: MenuProps): ReactElement => {
  const { containerProps, buttonProps, popoverProps } = usePopover();

  return (
    <nav {...containerProps} className={styles.container}>
      {/* At least one h1 required on page for accessibility. */}
      <ScreenReaderOnly tag="h1">TalkType</ScreenReaderOnly>

      <LogoActionButton {...buttonProps} />

      <dialog
        {...popoverProps}
        className={classNames(
          styles.itemsSurface,
          createSelectorClassName("main-menu", "interactive")
        )}
      >
        <ul className={styles.items}>
          {items.map((item) => (
            <li key={item.id}>
              <Item {...item} />
            </li>
          ))}
        </ul>
      </dialog>
    </nav>
  );
};

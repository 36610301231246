import type { Transcript } from "@talktype/types";

import { isTextSegment } from "@talktype/utilities";

/**
 * Get Final Character of Result
 *
 * @returns
 * - null if the transcript is empty
 * - empty string if the last segment is a paragraph
 * - the final character of the result (last text segment)
 */
export const getFinalCharacterOfResult = ({
  transcript,
}: {
  transcript: Transcript;
}): string | null => {
  if (transcript.length === 0) {
    return null;
  }

  const lastSegment = transcript.at(-1);
  if (lastSegment?.type === "paragraph") {
    return "";
  }

  const lastTextSegment = transcript.filter(isTextSegment).at(-1);
  return lastTextSegment?.text?.trim().at(-1) ?? null;
};

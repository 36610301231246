import type { AppDispatch } from "@talktype/store/src/types/AppDispatch";
import type { VoiceCommandsSearchProps } from "@talktype/ui/src/VoiceCommands/VoiceCommandsSearch";

import { connect } from "react-redux";

import { setSearchTerm } from "@talktype/listing/src/reducer";
import { VoiceCommandsSearch as Component } from "@talktype/ui/src/VoiceCommands/VoiceCommandsSearch";

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<VoiceCommandsSearchProps, "onSearch"> => ({
  onSearch: (searchTerm) => dispatch(setSearchTerm(searchTerm)),
});

export const VoiceCommandsSearch = connect(null, mapDispatchToProps)(Component);

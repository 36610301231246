import type { Marks } from "@talktype/types";
import type { Editor } from "slate";

import { defaultLeafProperties } from "./entities";
import { getSelectionMarks } from "./getSelectionMarks";

/**
 * Get the marks that would be added to text at the current selection.
 *
 * If none found, returns the default leaf properties.
 */
export const getSelectionMarksOrDefault = (editor: Editor): Marks =>
  getSelectionMarks(editor) ?? defaultLeafProperties;

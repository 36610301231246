import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageAddCustomShortcut } from "./manageAddCustomShortcut";
import { manageDeleteCustomShortcut } from "./manageDeleteCustomShortcut";
import { manageEditCustomShortcut } from "./manageEditCustomShortcut";
import { trackCustomShortcuts } from "./trackCustomShortcuts";

export const setUpCustomShortcuts = function* (): SagaIterator<void> {
  yield all(
    [
      manageAddCustomShortcut,
      manageDeleteCustomShortcut,
      manageEditCustomShortcut,
      trackCustomShortcuts,
    ].map(retry)
  );
};

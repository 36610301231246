import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";
import { v4 as uuid } from "uuid";

import { newLineCharacter } from "@carescribe/slate";

import { isText } from "../../guards/isText";
import { editorLoaded } from "../actions";

/**
 * Convert Text to Line Breaks
 *
 * If any new line characters appear in text, they should be converted to
 * new line entities. In the case where text contains new line characters and
 * other characters, other normalisation takes care of splitting the characters
 * apart, so this normaliser can simply detect new lines and convert to line
 * breaks.
 */
export const convertTextToLineBreaks = function* (): SagaIterator<void> {
  yield takeEvery(editorLoaded, ({ payload: editor }) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]): void => {
      if (isText(node) && node.text.includes(newLineCharacter)) {
        editor.setNodes({ type: "linebreak", id: uuid() }, { at: path });
      }

      normalizeNode([node, path]);
    };
  });
};

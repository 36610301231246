type TypeMap = {
  string: string;
  number: number;
  boolean: boolean;
  object: Record<string, unknown>;
};

/**
 * Asserts that an object has a property of a certain type
 *
 * @example
 * const data: Record<string, unknown> = dataFromApi();
 * if (hasPropertyOfType(data, "payload", "string")) {
 *   // data now narrowed to having a payload property of type "string"
 * }
 */
export const hasPropertyOfType = <
  Data extends Record<string, unknown>,
  Key extends string,
  Type extends keyof TypeMap
>(
  data: Data,
  key: Key,
  type: Type
): data is Data & Record<typeof key, TypeMap[Type]> =>
  key in data && typeof data[key] === type;

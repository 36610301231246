import type { Result } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, call } from "redux-saga/effects";

import { convertTextToStars } from "@carescribe/utilities/src/convertTextToStars";
import { mergeRight } from "@carescribe/utilities/src/fp";

import { requestTrackEvent } from "@talktype/analytics";
import {
  requestTransformTranscript,
  transformedTranscript,
} from "@talktype/results/src/sagas/actions";

import { applyTranscriptTransformation } from "./utils";

/**
 * Applies transcript transformations on request and reports
 * the replacements to analytics.
 */
export const setUpTranscriptTransformation = function* (): SagaIterator<void> {
  yield takeEvery(
    requestTransformTranscript,
    function* ({ payload: { result, precedingChunk, settings } }) {
      const {
        transcript,
        replacements,
      }: SagaReturnType<typeof applyTranscriptTransformation> = yield call(
        applyTranscriptTransformation,
        {
          chunks: result.transcript,
          precedingChunk,
          settings,
        }
      );

      if (result.isFinal) {
        for (const {
          type,
          trigger,
          replacement,
          inBuilt,
        } of replacements.shortcut) {
          yield put(
            requestTrackEvent({
              name: "Text Replaced With Shortcut",
              data: {
                type,
                trigger: inBuilt ? trigger : convertTextToStars(trigger),
                replacement: inBuilt
                  ? replacement
                  : convertTextToStars(replacement),
                inBuilt,
              },
            })
          );
        }
        for (const { type, trigger, replacement } of replacements.punctuation) {
          yield put(
            requestTrackEvent({
              name: "Text Replaced With Punctuation",
              data: { type, trigger, replacement, inBuilt: true },
            })
          );
        }
      }

      yield put(
        transformedTranscript({
          result: mergeRight(result, { transcript }) as Result,
          precedingChunk,
          settings,
        })
      );
    }
  );
};

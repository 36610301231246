import type { SagaIterator } from "redux-saga";

import { call, put, takeEvery } from "redux-saga/effects";

import {
  loginComplete,
  logoutComplete,
} from "@talktype/user/src/sagas/actions";

import { requestTrackEvent } from "./actions";

type Identify = (userId: string, traits: object) => void;

export const identifyUser = function* (): SagaIterator<void> {
  if (!("analytics" in window)) {
    return;
  }

  yield takeEvery(loginComplete, function* ({ payload: user }) {
    const isEmployee = user.email.endsWith("@carescribe.io");
    const name = isEmployee ? `CS-${user.id}` : `TT-${user.id}`;
    const traits = isEmployee
      ? { ...user, name }
      : { id: user.id, uuid: user.uuid, name };

    yield call<Identify>(window.analytics.identify, name, traits);
    yield put(requestTrackEvent({ name: "Logged In", data: null }));
  });

  yield takeEvery(logoutComplete, function* () {
    yield call(window.analytics.reset);
    yield put(requestTrackEvent({ name: "Logged Out", data: null }));
  });
};

import type { ReactElement } from "react";

import { WifiX } from "@phosphor-icons/react";

import { Alert } from "@talktype/ui/src/Alert";

import { messages } from "./messages";

export const PoorConnectionToast = (): ReactElement => (
  <Alert
    colour="destructive"
    hierarchy="primary"
    format="stacked"
    icon={<WifiX />}
    onClose={null}
    label={null}
    {...messages}
  />
);

import type { FormattingButtonProps } from "..";
import type { Item } from "../config";
import type { MouseEventHandler, ReactElement } from "react";

import { DotsThree } from "@phosphor-icons/react";

import { usePopover } from "@carescribe/ui/src/hooks/usePopover";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import styles from "./submenu.module.scss";
import { SubmenuItem } from "./SubmenuItem";
import { ToggleableButton } from "../../ToggleableButton";

type SubmenuProps = {
  items: Item[];
  buttonProps: FormattingButtonProps;
  handleOpenSubmenu: MouseEventHandler<HTMLButtonElement>;
};

export const Submenu = ({
  items,
  buttonProps: externalButtonProps,
  handleOpenSubmenu,
}: SubmenuProps): ReactElement => {
  const {
    containerProps,
    buttonProps: popoverToggleProps,
    popoverProps,
    isOpen,
  } = usePopover<HTMLDivElement>();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event): void => {
    if (!isOpen) {
      handleOpenSubmenu(event);
    }

    return popoverToggleProps.onClick();
  };

  return (
    <div {...containerProps} className={styles.container}>
      <ToggleableButton
        buttonProps={{
          ...popoverToggleProps,
          Icon: DotsThree,
          className: createSelectorClassName("submenu", "interactive"),
          active: isOpen,
          disabled: false,
          highlight: false,
          onClick: handleClick,
        }}
        tooltipProps={{
          messages: { visible: [messages.tooltip] },
          placement: "bottom",
          disabled: isOpen,
        }}
      />

      <dialog {...popoverProps} className={styles.menu}>
        {items.map((item) => (
          <div className={styles.item} key={item.id}>
            <SubmenuItem item={item} {...externalButtonProps} />
          </div>
        ))}
      </dialog>
    </div>
  );
};

import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { messages } from "./messages";
import styles from "./notifications.module.scss";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type NotificationsProps = { children: ReactNode };

export const Notifications = ({
  children,
}: NotificationsProps): ReactElement => (
  <aside
    className={classNames(
      styles.container,
      createSelectorClassName("notifications", "section")
    )}
    aria-labelledby="notifications-title"
  >
    <ScreenReaderOnly id="notifications-title" tag="h2">
      {messages.notifications}
    </ScreenReaderOnly>

    {children}
  </aside>
);

import type { PayloadAction } from "@reduxjs/toolkit";
import type { SystemState } from "@talktype/types";

type SetWindowMaximised = (
  state: SystemState,
  action: PayloadAction<boolean>
) => void;

export const setWindowMaximised: SetWindowMaximised = (state, { payload }) => {
  state.window.maximised = payload;
};

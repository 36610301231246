import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select } from "redux-saga/effects";

import { request } from "@carescribe/utilities/src/request";

import { selectAdditionalParams } from "../../reducer/selectors/selectAdditionalParams";
import { selectBaseAuthUrl } from "../../reducer/selectors/selectBaseAuthUrl";
import { selectClientId } from "../../reducer/selectors/selectClientId";

type RequestConfig = {
  pathname: string;
  params?: Record<string, string>;
};

/**
 * PKCE Request
 *
 * Make a POST request to the auth server with credentials needed for PKCE flows
 */
export const pkceRequest = function* ({
  pathname,
  params = {},
}: RequestConfig): SagaIterator<SagaReturnType<typeof request>> {
  const baseUrl: SagaReturnType<typeof selectBaseAuthUrl> = yield select(
    selectBaseAuthUrl
  );

  const url = new URL(baseUrl);
  url.pathname += "/" + pathname.replace(/^\//, "");

  const additionalParams: SagaReturnType<typeof selectAdditionalParams> =
    yield select(selectAdditionalParams);

  const clientId: SagaReturnType<typeof selectClientId> = yield select(
    selectClientId
  );

  return yield call(request, {
    url: url.toString(),
    method: "POST",
    params: {
      client_id: clientId,
      grant_type: "authorization_code",
      ...additionalParams,
      ...params,
    },
  });
};

import type { EditorType } from "..";

import { Range } from "slate";

import { safeBefore, safeAfter } from "@carescribe/slate";
import { mergeRight } from "@carescribe/utilities/src/fp";

import { getAtParagraphStart } from "./getAtParagraphStart";

export const getTextPrecedingRange = (
  editor: EditorType,
  range: Range
): string => {
  const isCollapsed = Range.isCollapsed(range);

  const start = Range.start(range);

  if (!start || getAtParagraphStart(start)) {
    return "";
  }

  // Get a Point before the start of the range. When collapsed, this needs to
  // be adjusted forward due to unknowable Slate reasons
  const precedingPoint = isCollapsed
    ? safeAfter(editor, safeBefore(editor, start))
    : safeBefore(editor, start);

  // Get the string value of the range leading up to the range
  return editor.string({
    focus: precedingPoint,
    anchor: mergeRight(precedingPoint, { offset: 0 }),
  });
};

import type { FormState } from "./FormState";
import type { SupportedErrorCode } from "./SupportedErrorCode";
import type { Callback } from "@carescribe/types";
import type { Rule } from "@talktype/forms";
import type { CustomWord } from "@talktype/types";
import type { Dispatch, FormEventHandler, SetStateAction } from "react";

import { normaliseWhitespace } from "@carescribe/utilities/src/normaliseWhitespace";

type CreateOnSubmitHandler = Callback<
  {
    value: string;
    setForm: Dispatch<SetStateAction<FormState>>;
    validate: Rule<SupportedErrorCode>;
    onValidSubmit: (partialWord: Omit<CustomWord, "id">) => void;
  },
  FormEventHandler
>;

export const createOnSubmitHandler: CreateOnSubmitHandler =
  ({ value, setForm, validate, onValidSubmit }) =>
  (event) => {
    event.preventDefault();

    const input = normaliseWhitespace(value);

    const error = validate(input);

    if (error) {
      setForm((previous) => ({ ...previous, error }));

      return;
    }

    onValidSubmit({ input });

    setForm({ value: "", error: null });
  };

import type { DrawerPage } from "@talktype/types/src/Drawer";

export const messages: { pages: Record<DrawerPage, string>; close: string } = {
  pages: {
    customWords: "Custom words",
    voiceCommands: "Voice commands",
    shortcuts: "Shortcuts",
    history: "Dictation history",
  },
  close: "Close drawer",
};

import type { LoginStatus } from "@talktype/user/src/types/LoginStatus";
import type { ReactNode, ReactElement } from "react";

import { Fragment } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";
import { intersperse } from "@carescribe/utilities/src/fp";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { messages } from "./messages";
import styles from "./welcome.module.scss";
import { StandardButton } from "../StandardButton";

export type CtasProps = {
  variant: Exclude<LoginStatus, "loggedIn" | "loading">;
  loginLink: string;
  redemptionLink: string;
};

export const Ctas = ({
  variant,
  redemptionLink,
  loginLink,
}: CtasProps): ReactElement => {
  const firstButtonLink = variant === "migrating" ? redemptionLink : loginLink;
  const secondButtonLink = variant === "migrating" ? loginLink : redemptionLink;
  const firstButtonSelectorClassName =
    variant === "migrating" ? "activate" : "login";
  const secondButtonSelectorClassName =
    variant === "migrating" ? "login" : "activate";
  const secondButtonHierarchy =
    variant === "migrating" ? "link-invert" : "primary";

  return (
    <div className={styles.ctas}>
      <StandardButton
        link
        colour="brand"
        hierarchy="tertiary"
        size="md"
        style="default"
        label={messages[variant].cta}
        elementProps={{
          href: firstButtonLink,
          className: classNames(
            "light",
            styles.cta,
            createSelectorClassName(firstButtonSelectorClassName, "interactive")
          ),
        }}
      />

      <StandardButton
        link
        colour="brand"
        hierarchy={secondButtonHierarchy}
        size="md"
        style="default"
        label={messages[variant].fallback}
        elementProps={{
          href: secondButtonLink,
          className: classNames(
            [variant === "loggedOut", styles.cta],
            createSelectorClassName(
              secondButtonSelectorClassName,
              "interactive"
            )
          ),
        }}
      />

      {getAppContext() === "desktop" && (
        <p
          className={classNames(
            styles.explainer,
            [variant === "loggedOut", styles.explainertop],
            createSelectorClassName("explainer", "element")
          )}
        >
          {/* Split the explainer text lines with a break tag */}
          {intersperse<ReactNode>(<br />, messages[variant].explainer).map(
            (Component, i) => (
              <Fragment key={i}>{Component}</Fragment>
            )
          )}
        </p>
      )}
    </div>
  );
};

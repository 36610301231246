import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactElement,
  ReactNode,
} from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./standardButton.module.scss";
import { IconContainer } from "../IconContainer";

export type StandardButtonProps = {
  style: "default" | "icon-only";
  colour: "neutral" | "brand" | "positive" | "alert" | "destructive";
  hierarchy: "primary" | "secondary" | "tertiary" | "link" | "link-invert";
  size: "xs" | "sm" | "md" | "lg" | "xl";
  label: string;
  mainIcon?: ReactNode;
  trailingIcon?: ReactNode;
} & (
  | {
      link?: never;
      elementProps?: DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >;
    }
  | {
      link: true;
      elementProps?: DetailedHTMLProps<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
      >;
    }
);

export const StandardButton = ({
  colour,
  hierarchy,
  size,
  style,
  mainIcon,
  trailingIcon,
  label,
  elementProps,
  link,
}: StandardButtonProps): ReactElement => {
  const contents = (
    <>
      {mainIcon && (
        <IconContainer className={styles.mainIcon}>{mainIcon}</IconContainer>
      )}

      {style === "icon-only" ? null : label}

      {trailingIcon && (
        <IconContainer className={styles.trailingIcon}>
          {trailingIcon}
        </IconContainer>
      )}
    </>
  );

  return link ? (
    <a
      {...elementProps}
      className={classNames(
        elementProps?.className,
        styles.button,
        styles[colour],
        styles[hierarchy],
        styles[size],
        [style === "icon-only", styles.iconOnly]
      )}
      aria-label={elementProps?.["aria-label"] ?? label}
    >
      {contents}
    </a>
  ) : (
    <button
      {...elementProps}
      className={classNames(
        elementProps?.className,
        styles.button,
        styles[colour],
        styles[hierarchy],
        styles[size],
        [style === "icon-only", styles.iconOnly]
      )}
      aria-label={elementProps?.["aria-label"] ?? label}
    >
      {contents}
    </button>
  );
};

import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { setInProgressResult as setInProgressResultReducer } from "./reducers";

export const { reducer, actions } = createSlice({
  name: "results",
  initialState,
  reducers: { setInProgressResult: setInProgressResultReducer },
});

export const { setInProgressResult } = actions;

import type { ReactElement, KeyboardEventHandler } from "react";

import { PlusCircle, MinusCircle } from "@phosphor-icons/react";
import { useRef, useEffect } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { documentFontSize } from "@talktype/config";

import styles from "./fontSizeSettings.module.scss";
import { messages } from "./messages";
import { ScreenReaderOnly } from "../../../ScreenReaderOnly";
import { StandardButton } from "../../../StandardButton";

export type FontSizeSettingsProps = {
  fontSize: number;
  onChange: (increment: number) => void;
};

export const FontSizeSettings = ({
  fontSize,
  onChange,
}: FontSizeSettingsProps): ReactElement => {
  // Keep track of a timer that allows the user to click and hold the button to
  // change the font size continuously
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const clear = (): void => {
    if (interval.current !== null) {
      // N.B. timeouts and intervals use the same pool of IDs, so clearInterval
      // or clearTimeout both function correctly given an interval/timeout ID
      clearInterval(interval.current);
    }
  };

  /**
   * Repeat Increment
   *
   * Repeats an increment every 100ms after a 500ms delay. This simulates the
   * click-and-hold behaviour you see in the native browser's number input.
   */
  const repeatIncrement = (increment: number): void => {
    clear();

    // Set a timeout of 500ms before starting the interval. This prevents firing
    // the interval for normal clicks.
    interval.current = setTimeout(() => {
      // Start the interval after the timeout and allow for fast updates
      interval.current = setInterval(() => onChange(increment), 100);
    }, 500);
  };

  /**
   * Handle Mouse Down
   *
   * Allows the user to hold the button to change the font size continuously
   * with a single long press.
   */
  const handleMouseDown = (increment: number) => () =>
    repeatIncrement(increment);

  const handleKeyDown =
    (increment: number): KeyboardEventHandler =>
    (event) => {
      if (event.key === " " && event.repeat) {
        // Keydown events repeat anyway, so we don't need to delegate to our own
        // interval, but can rely on the user's configured keyboard repeat rate
        onChange(increment);
      }
    };

  // Clear any active timers
  useEffect(() => clear, []);

  const standardButtonProps = {
    style: "icon-only",
    colour: "neutral",
    hierarchy: "tertiary",
    size: "xs",
  } as const;

  const buttonProps = {
    onKeyUp: clear,
    onMouseLeave: clear,
    onMouseUp: clear,
    onTouchEnd: clear,
    onTouchCancel: clear,
  };

  return (
    <div className={styles.container}>
      <ScreenReaderOnly tag="h2">{messages.title}</ScreenReaderOnly>

      <StandardButton
        label={messages.decreaseFontSize}
        mainIcon={<MinusCircle />}
        elementProps={{
          onClick: () => onChange(-1),
          onMouseDown: handleMouseDown(-1),
          onTouchStart: handleMouseDown(-1),
          onKeyDown: handleKeyDown(-1),
          "aria-disabled": fontSize <= documentFontSize.min,
          className: classNames(
            styles.button,
            createSelectorClassName("decrement-font-size", "interactive")
          ),
          ...buttonProps,
        }}
        {...standardButtonProps}
      />

      <span
        className={classNames(
          styles.text,
          createSelectorClassName("font-size", "element")
        )}
        aria-live="polite"
      >
        <ScreenReaderOnly tag="span">
          {messages.currentFontSize}
        </ScreenReaderOnly>
        {fontSize}px
      </span>

      <StandardButton
        label={messages.increaseFontSize}
        mainIcon={<PlusCircle />}
        elementProps={{
          onClick: () => onChange(1),
          onMouseDown: handleMouseDown(1),
          onTouchStart: handleMouseDown(1),
          onKeyDown: handleKeyDown(1),
          "aria-disabled": fontSize >= documentFontSize.max,
          className: classNames(
            styles.button,
            createSelectorClassName("increment-font-size", "interactive")
          ),
          ...buttonProps,
        }}
        {...standardButtonProps}
      />
    </div>
  );
};

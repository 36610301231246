import type { FormState } from "./FormState";
import type { Validators } from "./Validators";
import type { CustomWord } from "@talktype/types";
import type { ReactElement, RefObject } from "react";

import { BookBookmark } from "@phosphor-icons/react";
import { useState } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { createOnChangeHandler } from "./createOnChangeHandler";
import { createOnSubmitHandler } from "./createOnSubmitHandler";
import styles from "./customWordsAddModal.module.scss";
import { messages } from "./messages";
import { FeaturedIcon } from "../FeaturedIcon";
import { Field } from "../Field";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";
import { StandardButton } from "../StandardButton";
import { TextInput } from "../TextInput";

const initialState = { value: "", error: null };

export type CustomWordsAddModalProps = {
  modalRef: RefObject<HTMLDialogElement>;
  validators: Validators;
  handleAdd: (partialWord: Omit<CustomWord, "id">) => void;
};

export const CustomWordsAddModal = ({
  modalRef,
  validators,
  handleAdd,
}: CustomWordsAddModalProps): ReactElement => {
  const [form, setForm] = useState<FormState>(initialState);
  const resetForm = (): void => setForm(initialState);
  const closeModal = (): void => modalRef.current?.close();

  const onValidSubmit = (partialWord: Omit<CustomWord, "id">): void => {
    handleAdd(partialWord);
    closeModal();
  };

  const getError = (): string => {
    const { value, error } = form;

    if (!error) {
      return "";
    }

    const message = messages.errors[error];
    return typeof message === "function" ? message(value) : message;
  };

  const hasError = (): boolean => {
    const { error } = form;

    return error !== null;
  };

  return (
    <Modal
      modalRef={modalRef}
      onClose={resetForm}
      className={createSelectorClassName("define-modal", "section")}
    >
      <ModalHeader
        style="compact"
        icon={
          <FeaturedIcon style="brand" weight="light" size="sm">
            <BookBookmark />
          </FeaturedIcon>
        }
        title={messages.addACustomWord}
        onClose={closeModal}
      />
      <form
        onSubmit={createOnSubmitHandler({
          setForm,
          validate: validators.input.onSubmit,
          onValidSubmit,
          value: form.value,
        })}
        className={styles.form}
      >
        <Field
          label={{ text: messages.input.label, visible: false }}
          explainer={null}
          error={
            hasError()
              ? {
                  text: getError(),
                  props: {
                    className: createSelectorClassName(
                      "input-error",
                      "element"
                    ),
                  },
                }
              : null
          }
          className={createSelectorClassName("input", "interactive")}
        >
          <TextInput
            size="md"
            inputProps={{
              name: "input",
              autofocus: "",
              value: form.value,
              onChange: createOnChangeHandler({
                setForm,
                validate: validators.input.onChange,
              }),
              "aria-invalid": hasError(),
            }}
          />
        </Field>
        <StandardButton
          colour="brand"
          hierarchy="primary"
          size="md"
          style="default"
          label={messages.submit.label}
          elementProps={{
            type: "submit",
            disabled: form.value === "",
            className: createSelectorClassName("submit", "interactive"),
          }}
        />
      </form>
    </Modal>
  );
};

import type { RequestError } from "@carescribe/utilities/src/request";
import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

import { setTokens } from "../../../reducer";
import { pkceRequestFailed } from "../../actions";

export const handleError = ({ error }: { error: RequestError }) =>
  function* (): SagaIterator<void> {
    yield put(setTokens(null));
    yield put(pkceRequestFailed(error));
  };

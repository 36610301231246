import type { EditorType } from "../editor";
import type { CommandStatus } from "@talktype/commands";
import type { AppMetadata, TranscriptSegment } from "@talktype/types";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { insertLineBreak, insertParagraph, insertText } from "./insertHandlers";

/**
 * Given a transcript segment, inserts it into the editor.
 * Uses the appropriate handler for the segment type.
 *
 * Returns a status to indicate whether the insertion was successful.
 */
export const insertTranscriptSegment = function* (
  editor: EditorType,
  segment: TranscriptSegment,
  targetApp: AppMetadata
): SagaIterator<CommandStatus> {
  switch (segment.type) {
    case "command": {
      const performCommand =
        segment.performCommand[targetApp.isSelf ? "talktype" : "app"];

      if (!performCommand) {
        return "unsupported";
      }

      const commandOutcome: SagaReturnType<typeof performCommand> = yield call(
        performCommand
      );

      return commandOutcome ? "successful" : "failed";
    }

    case "linebreak":
      yield call(insertLineBreak, editor, segment);
      return "successful";

    case "paragraph":
      yield call(insertParagraph, editor);
      return "successful";

    case "text":
      yield call(insertText, editor, segment);
      return "successful";
  }
};

/* eslint-disable no-undefined */
import type { Store } from "./types";
import type { Saga } from "redux-saga";

import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { addStoreToWindowForCypress } from "@carescribe/utilities/src/addStoreToWindowForCypress";
import { createLogger } from "@carescribe/utilities/src/log";

import { createPersistenceMiddleware } from "./persistence/createPersistenceMiddleware";
import {
  getHydratedStoreState,
  checkShouldRehydrate,
  rehydrateUserState,
  persistState,
} from "./persistence/versioned/v1";
import { reducer, getInitialState } from "./reducer";

const { logError } = createLogger("Uncaught Saga Error");

/**
 * Sets up the Redux store and saga middleware.
 *
 * Ensures store is accessible via `window` when in Cypress environment.
 */
export const setUpStore = async (rootSaga: Saga): Promise<Store> => {
  /**
   * Initial state is needed for the state persistence.
   * Each time the user changes or logs out, the state is re-hydrated with the
   * initial state.
   */
  const initialState = getInitialState();

  const preloadedState = await getHydratedStoreState({
    initialState,
    userId: undefined,
    migrateLegacyLocalStorage: true,
  });

  const sagaMiddleware = createSagaMiddleware({ onError: logError });
  const persistenceMiddleware = createPersistenceMiddleware({
    initialState,
    persistState,
    checkShouldRehydrate,
    rehydrateUserState,
  });
  const middlewares = [sagaMiddleware, persistenceMiddleware];

  const store = configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).prepend(
        ...middlewares
      ),
    devTools: process.env.NODE_ENV !== "production",
  });

  sagaMiddleware.run(rootSaga);

  addStoreToWindowForCypress(window, store);

  return store;
};

import type { UpdaterOptions } from "../types/UpdaterOptions";
import type { SagaIterator } from "redux-saga";

import { call } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageCheckForUpdates } from "./manageCheckForUpdates";
import { manageDownloadUpdate } from "./manageDownloadUpdate";
import { manageQuitAndInstall } from "./manageQuitAndInstall";
import { manageRetries } from "./manageRetries";

/**
 * Manages all desktop update related tasks
 *
 * Update flow (3 steps):
 *
 * - Checking for updates
 * - Downloading the update (desktop only)
 * - Quitting and installing
 *
 * Requests for retries in case of failure
 */
export function* setUpUpdater(options: UpdaterOptions): SagaIterator<void> {
  const sagas = [
    manageCheckForUpdates,
    manageQuitAndInstall,
    manageRetries,
    ...(options.desktop ? [manageDownloadUpdate] : []),
  ];

  for (const saga of sagas) {
    yield retry(function* () {
      yield call(saga, options);
    });
  }
}

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { filterCommandsForUniqueness } from "./filterCommandsForUniqueness";
import { finaliseVoiceCommands } from "./finaliseVoiceCommands";
import { manageNotifications } from "./manageNotifications";
import { managePrompt } from "./managePrompt";
import { manageVoiceCommands } from "./manageVoiceCommands";
import { cancelTask } from "./task/cancelTask";
import { startTask } from "./task/startTask";
import { trackTask } from "./task/trackTask";
import { trackCommands } from "./trackCommands";

export const setUpVoiceCommands = function* (): SagaIterator<void> {
  yield all(
    [
      cancelTask,
      filterCommandsForUniqueness,
      finaliseVoiceCommands,
      manageNotifications,
      managePrompt,
      manageVoiceCommands,
      startTask,
      trackCommands,
      trackTask,
    ].map(retry)
  );
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageCustomInstallPromptDismissal } from "./manageCustomInstallPromptDismissal";
import { trackCustomInstallPrompVisibility } from "./trackCustomInstallPrompVisibility";

export const setUpCustomInstallPrompt = function* (): SagaIterator<void> {
  yield all(
    [manageCustomInstallPromptDismissal, trackCustomInstallPrompVisibility].map(
      retry
    )
  );
};

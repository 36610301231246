import type { GenericTrackingEventData } from "../types";
import type { CombinedState, Selector } from "@reduxjs/toolkit";
import type { TimingsState } from "@talktype/analytics/src/reducers/timings/types";
import type { EditorState } from "@talktype/editor/src/reducers";

import { createSelector } from "@reduxjs/toolkit";

import {
  selectDictationMode,
  selectDictationStatus,
  selectDocumentLength,
} from "@talktype/editor/src/reducers";

import { selectDictationTimings } from "../reducers/timings/selectors";

export const createGenericEventDataSelector = (
  additionalData: Record<string, unknown>
): Selector<
  CombinedState<{ editor: EditorState; analytics: { timings: TimingsState } }>,
  GenericTrackingEventData
> =>
  createSelector(
    [
      selectDocumentLength,
      selectDictationMode,
      selectDictationStatus,
      selectDictationTimings,
    ],
    (
      documentLength,
      dictationMode,
      dictationStatus,
      dictationTimings
    ): GenericTrackingEventData => ({
      documentLength,
      dictationMode,
      dictationStatus,
      ...dictationTimings,
      ...additionalData,
    })
  );

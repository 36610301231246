import type { TranscriptSegment } from "@talktype/types";
import type { Editor } from "slate";

import { mergeRight } from "@carescribe/utilities/src/fp";

import { getSelectionInlineStyleMarks } from "./getSelectionInlineStyleMarks";

export type ApplySelectionMarksArgs = {
  editor: Editor;
  segment: TranscriptSegment;
  inProgress: boolean;
};

/**
 * Apply the selection marks to the given segment.
 */
export const applySelectionMarks = ({
  editor,
  segment,
  inProgress,
}: ApplySelectionMarksArgs): TranscriptSegment =>
  mergeRight(segment, {
    ...getSelectionInlineStyleMarks(editor),
    inProgress,
  });

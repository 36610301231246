import type { Command } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  requestDeletePhrase,
  deletedPhrase,
} from "@talktype/editor/src/sagas/actions";

export const deletePhrase: Command = function* (parameters) {
  if (!parameters) {
    return false;
  }

  yield put(requestDeletePhrase(parameters));

  const { payload: success }: SagaReturnType<typeof deletedPhrase> = yield take(
    deletedPhrase
  );

  return success;
};

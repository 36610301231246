import type { ReactElement } from "react";

export const AutoPunctuation = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M23.0378 12.2041L20.8604 14.3815C20.793 14.449 20.713 14.5025 20.6248 14.5391C20.5367 14.5756 20.4423 14.5944 20.3469 14.5944C20.2515 14.5944 20.1571 14.5756 20.069 14.5391C19.9809 14.5025 19.9008 14.449 19.8334 14.3815L17.656 12.2041C17.5198 12.0679 17.4433 11.8832 17.4433 11.6906C17.4433 11.498 17.5198 11.3133 17.656 11.1771C17.7922 11.0409 17.9769 10.9644 18.1695 10.9644C18.3621 10.9644 18.5468 11.0409 18.683 11.1771L19.6211 12.1161V11.6906C19.6219 10 19.0861 8.35283 18.0908 6.98629C17.0955 5.61976 15.6922 4.60442 14.0829 4.08655C12.4736 3.56869 10.7414 3.57502 9.13598 4.10464C7.53051 4.63426 6.1346 5.65983 5.14934 7.0336C5.03697 7.19001 4.86707 7.29537 4.67702 7.3265C4.48696 7.35764 4.29233 7.312 4.13592 7.19963C3.97952 7.08726 3.87416 6.91737 3.84302 6.72731C3.81189 6.53726 3.85753 6.34263 3.9699 6.18622C5.13436 4.56269 6.78412 3.3507 8.68152 2.72485C10.5789 2.09899 12.626 2.09158 14.5279 2.70368C16.4298 3.31578 18.0883 4.5158 19.2644 6.13085C20.4406 7.74591 21.0738 9.69264 21.0727 11.6906V12.1161L22.0108 11.1771C22.147 11.0409 22.3317 10.9644 22.5243 10.9644C22.7169 10.9644 22.9017 11.0409 23.0378 11.1771C23.174 11.3133 23.2506 11.498 23.2506 11.6906C23.2506 11.8832 23.174 12.0679 23.0378 12.2041Z"
      fill="currentColor"
    />
    <path
      d="M0.962706 11.1777L3.14014 9.00027C3.20755 8.93278 3.28759 8.87925 3.37571 8.84272C3.46382 8.8062 3.55826 8.7874 3.65365 8.7874C3.74903 8.7874 3.84348 8.8062 3.93159 8.84272C4.0197 8.87925 4.09975 8.93278 4.16716 9.00027L6.34459 11.1777C6.48078 11.3139 6.5573 11.4986 6.5573 11.6912C6.5573 11.8838 6.48078 12.0685 6.34459 12.2047C6.2084 12.3409 6.02368 12.4174 5.83108 12.4174C5.63848 12.4174 5.45376 12.3409 5.31757 12.2047L4.37946 11.2657V11.6912C4.37863 13.3818 4.91445 15.029 5.90973 16.3955C6.90501 17.762 8.30839 18.7774 9.91768 19.2952C11.527 19.8131 13.2591 19.8068 14.8646 19.2772C16.47 18.7475 17.866 17.722 18.8512 16.3482C18.9636 16.1918 19.1335 16.0864 19.3235 16.0553C19.5136 16.0242 19.7082 16.0698 19.8646 16.1822C20.021 16.2945 20.1264 16.4644 20.1575 16.6545C20.1887 16.8445 20.143 17.0392 20.0307 17.1956C18.8662 18.8191 17.2164 20.0311 15.319 20.657C13.4216 21.2828 11.3745 21.2902 9.47267 20.6781C7.57079 20.066 5.91229 18.866 4.73611 17.2509C3.55992 15.6359 2.92676 13.6892 2.92784 11.6912V11.2657L1.98973 12.2047C1.85354 12.3409 1.66882 12.4174 1.47622 12.4174C1.28361 12.4174 1.0989 12.3409 0.962706 12.2047C0.826515 12.0685 0.75 11.8838 0.75 11.6912C0.75 11.4986 0.826515 11.3139 0.962706 11.1777Z"
      fill="currentColor"
    />
    <path
      d="M12.5382 10.7744C10.0088 12.3173 8.64466 8.10941 11.8569 7.75529C14.5363 7.52196 16.3881 13.4282 10.2227 15.6025C9.6401 15.7984 9.36969 15.0057 10.0772 14.7177C13.368 13.5943 13.059 10.7715 12.5382 10.7744Z"
      fill="currentColor"
    />
  </svg>
);

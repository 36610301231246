import type { ParsedNumber } from "./types";

import {
  allDigits,
  allOrdinals,
  magnitudeDigits,
  teens,
  tens,
  magnitudes,
} from "./dictionary";
import { getOrdinalSuffix } from "./getOrdinalSuffix";

export type SummedNumber = {
  number: number;
  suffix: string | null;
};

/**
 * Get Three Digit Value
 *
 * Use for processing a 3 digit block of the 3 digit chunks that English
 * numbers are separated into. E.g. in the number 123,456 there are 2 sections
 * of 3 digits that are described with the same vocabulary.
 *
 * @example
 * Given config in this shape:
 *
 * ```ts
 * {
 *   hundred: "one",
 *   ten: "twenty",
 *   teen: "",
 *   digit: "three",
 *   magnitude: "thousand",
 *   hundredWord: ""
 * }
 * ```
 *
 * Will return:
 *
 * ```ts
 * {
 *   number: 123000,
 *   suffix: null
 * }
 * ```
 *
 * and
 *
 * ```ts
 * {
 *   hundred: "four",
 *   ten: "",
 *   teen: "sixteenth",
 *   digit: "",
 *   magnitude: "",
 *   hundredWord: ""
 * }
 * ```
 *
 * Will return:
 *
 * ```ts
 * {
 *   number: 416,
 *   suffix: "th"
 * }
 * ```
 *
 * and
 *
 * ```ts
 * {
 *   hundred: "2",
 *   ten: "",
 *   teen: "",
 *   digit: "",
 *   magnitude: "",
 *   hundredWord: "hundredth"
 * }
 * ```
 *
 * Will return:
 *
 * ```ts
 * {
 *   number: 200,
 *   suffix: "th"
 * }
 * ```
 */
export const getThreeDigitValue = ({
  hundred,
  ten,
  teen,
  digit,
  magnitude,
  hundredWord,
}: ParsedNumber): SummedNumber | null => {
  if (!hundred && !ten && !teen && !digit) {
    return null;
  }

  const hundredsValue = (magnitudeDigits[hundred] ?? 0) * 100;
  const tensValue = tens[ten] ?? 0;
  const teensValue = teens[teen] ?? 0;
  const digitsValue = allDigits[digit] ?? 0;

  const isOrdinal = [hundred, ten, teen, digit, magnitude, hundredWord].some(
    (value) => ([...allOrdinals] as string[]).includes(value)
  );

  const magnitudeValue = magnitudes[magnitude];

  const number =
    (hundredsValue + tensValue + teensValue + digitsValue) * magnitudeValue;
  const suffix = isOrdinal ? getOrdinalSuffix(number) : null;

  return { number, suffix };
};

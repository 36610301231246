import type { PayloadAction } from "@reduxjs/toolkit";
import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

/**
 * Put Payload Saga
 *
 * Creates a saga which puts the given actions with the received payload.
 *
 * @example
 * putPayloadSaga(actionA)
 *
 * equivalent:
 *
 * function* ({ payload }) {
      yield put(action(payload))
    })
 */
export const putPayloadSaga = <PayloadType>(
  action: (payload: PayloadType) => PayloadAction<PayloadType>
) =>
  function* ({ payload }: PayloadAction<PayloadType>): SagaIterator<void> {
    yield put(action(payload));
  };

import type { Platform } from "@carescribe/types/src/Platform";
import type { DictationStatus } from "@talktype/types";
import type { Message } from "@talktype/types/src/Message";
import type { Messages } from "@talktype/types/src/Messages";

export const messages: {
  tooltip: Messages<{ platform: Platform; status: DictationStatus }>;
  label: Message<{ status: DictationStatus }>;
} = {
  tooltip: ({ platform, status }) => [
    `${status === "inactive" ? "Start" : "Stop"} Dictating`,
    platform === "mac" ? "⌘ + ⇧ + D" : "Ctrl + ⇧ + D",
  ],
  label: ({ status }) => {
    switch (status) {
      case "inactive":
        return "Start dictating";
      case "loading":
        return "Stop dictating";
      case "active":
        return "Stop dictating";
    }
  },
};

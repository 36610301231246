import type { AppDispatch, RootState } from "@talktype/store";
import type { DictationMode } from "@talktype/types";
import type { DictationModeMenuProps } from "@talktype/ui/src/DictationModeMenu";

import { connect } from "react-redux";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import {
  selectDictationMode,
  selectDictationStatus,
  setDictationMode,
} from "@talktype/editor/src/reducers/editor";
import { DictationModeMenu as Component } from "@talktype/ui/src/DictationModeMenu";

const mapStateToProps = (
  state: RootState
): Pick<DictationModeMenuProps, "mode" | "status"> => ({
  mode: selectDictationMode(state),
  status: selectDictationStatus(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<DictationModeMenuProps, "onChangeMode"> => ({
  onChangeMode:
    getAppContext() === "desktop"
      ? (mode: DictationMode): void => void dispatch(setDictationMode(mode))
      : null,
});

export const DictationModeMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

import type {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ReactElement,
} from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./windowsControlButton.module.scss";

export type WindowsControlsButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  colour: "neutral" | "brand";
};

export const WindowsControlButton = ({
  colour,
  children,
  ...buttonProps
}: WindowsControlsButtonProps): ReactElement => (
  <button
    {...buttonProps}
    className={classNames(
      styles.button,
      buttonProps?.className,
      styles[colour]
    )}
    tabIndex={-1} // Match native OS windows control button behaviour
  >
    {children}
  </button>
);

import type { Style } from "./Style";
import type { Override } from "@carescribe/types/src/Override";
import type { DetailedHTMLProps, HTMLAttributes, ReactElement } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./representation.module.scss";

type InputProps = Override<
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
  { style: Style }
>;

export const Input = ({ style, ...props }: InputProps): ReactElement => (
  <kbd
    {...props}
    className={classNames(props.className, styles.input, styles[style])}
  />
);

import type { ReactElement } from "react";

import { Copy } from "@phosphor-icons/react";
import { useState, useEffect } from "react";

import { messages } from "./messages";
import { StandardButton } from "../StandardButton";

export type DictationHistoryCopyButtonProps = { onCopy: () => void };

/**
 * A copy button that, when clicked, executes the `onCopy` callback.
 *
 * The button displays a "copied" state for 2 seconds, and then
 * resets to the "copy" state.
 */
export const DictationHistoryCopyButton = ({
  onCopy,
}: DictationHistoryCopyButtonProps): ReactElement => {
  const [state, setState] = useState<"copy" | "copied">("copy");

  const onClick = (): void => {
    onCopy();
    setState("copied");
  };

  useEffect(() => {
    if (state === "copied") {
      const timeout = setTimeout(() => setState("copy"), 2000);
      return () => clearTimeout(timeout);
    }
  }, [state]);

  return (
    <StandardButton
      style="default"
      colour="brand"
      hierarchy="link"
      size="xs"
      label={messages[state]}
      mainIcon={<Copy />}
      elementProps={{ onClick }}
    />
  );
};

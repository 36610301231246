import type {
  ReactElement,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
} from "react";

import { CaretDown } from "@phosphor-icons/react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./dictationModeButton.module.scss";
import { IconContainer } from "../IconContainer";

export type DictationModeButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const DictationModeButton = ({
  className,
  children,
  onClick,
  disabled,
  ...buttonProps
}: DictationModeButtonProps): ReactElement => (
  <button
    {...buttonProps}
    className={classNames(
      className,
      styles.button,
      createSelectorClassName("dictation-mode-button", "interactive")
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
    {!disabled && (
      <IconContainer className={styles.icon}>
        <CaretDown weight="bold" />
      </IconContainer>
    )}
  </button>
);

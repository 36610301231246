import type { ReactElement } from "react";

import { Devices, Sun, Moon } from "@phosphor-icons/react";

import { messages } from "./messages";
import { RadioGroup } from "../../RadioGroup";
import { RadioInput } from "../../RadioInput";

const themeIcons = {
  Devices,
  Sun,
  Moon,
};

export type ThemeFieldProps = {
  options: {
    display: string;
    name: string;
    checked: boolean;
    icon: keyof typeof themeIcons;
    onChange: () => void;
  }[];
};

export const ThemeField = ({ options }: ThemeFieldProps): ReactElement => (
  <RadioGroup
    legend={{ text: messages.label, visible: true }}
    layout="horizontal"
  >
    {options.map(({ display, name, checked, icon, onChange }) => {
      const Icon = themeIcons[icon];
      return (
        <RadioInput
          key={display}
          label={display}
          style="default"
          colour={checked ? "brand" : "neutral"}
          hierarchy={checked ? "primary" : "tertiary"}
          size="md"
          mainIcon={<Icon />}
          inputProps={{
            name,
            checked,
            onChange,
          }}
        />
      );
    })}
  </RadioGroup>
);

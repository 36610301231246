import type { Marks, Leaf } from "@talktype/types";
import type { NodeEntry } from "slate";

import { Editor, Range, Text } from "slate";

import { mergeRight } from "@carescribe/utilities/src/fp";

import { getMarks } from "@talktype/utilities";

/**
 * Merges new marks into an existing marks object.
 *
 * If the property already exists and is false, it will be kept as false.
 * Otherwise, the value from the current node will be used.
 */
const mergeMarks = (marks: Marks, key: keyof Marks, newMarks: Marks): Marks => {
  // If the property already exists and is false, keep it as false
  if (marks[key] === false) {
    return marks;
  }
  // Otherwise, use the value from the current node
  return mergeRight(marks, { [key]: newMarks[key] });
};

/**
 * Consolidates the marks from a list of nodes.
 *
 * It retrieves the marks from each node using the `getMarks` function,
 * and then merges these into a single marks object.
 *
 * If a mark already exists in the accumulated marks, its value is updated
 * with the value from the current node unless it's explicitly set to false.
 **/
const consolidateMarks = (nodes: NodeEntry<Leaf>[]): Marks =>
  nodes.reduce((marks, [node]) => {
    const newMarks = getMarks(node);

    // Merge the new marks into the accumulated marks
    return Object.keys(newMarks).reduce(
      (acc, key) => mergeMarks(acc, key as keyof typeof newMarks, newMarks),
      marks
    );
  }, {} as Marks);

/**
 * Get the marks that would be added to text at the current selection.
 *
 * Unlike, the native Editor.marks, this takes into consideration a situation
 * where the user has selected multiple text nodes with varying marks
 * and consolidates them rather than always using the first node's marks
 *
 * e.g. if some text is bolded and some isn't, this will return { bold: false }
 */
export const getSelectionMarks = (editor: Editor): Marks | null => {
  const { marks, selection } = editor;

  if (selection && !marks && Range.isExpanded(selection)) {
    const selectedTextNodes = Array.from(
      Editor.nodes(editor, { at: selection, match: Text.isText })
    );

    return consolidateMarks(selectedTextNodes);
  }

  return Editor.marks(editor);
};

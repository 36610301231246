import type { SagaIterator } from "redux-saga";

import { all, put } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpDictationSessions } from "./api/setUpDictationSessions";
import { setUpGetMe } from "./api/setUpGetMe";
import { setUpGetCodeFromDeepLink } from "./auth/setUpGetCodeFromDeepLink";
import { getLicenceKeyRedemptionStatus } from "./licence-key/getLicenceKeyRedemptionStatus";
import { setUpLogin } from "./setUpLogin";
import { setUpLogout } from "./setUpLogout";
import { setTalkTypeDashboardUrl } from "../reducer";

type SetUpUserConfig = {
  dashboardUrl: string;
};

export const setUpUser = function* ({
  dashboardUrl,
}: SetUpUserConfig): SagaIterator<void> {
  yield put(setTalkTypeDashboardUrl(dashboardUrl));

  yield all(
    [
      getLicenceKeyRedemptionStatus,
      setUpGetMe,
      setUpDictationSessions,
      setUpLogin,
      setUpLogout,
      setUpGetCodeFromDeepLink,
    ].map(retry)
  );
};

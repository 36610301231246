import type { AssignedResult } from "@talktype/types";
import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { StrictMap } from "@carescribe/utilities/src/StrictMap";

import { requestCategoriseResult, categorisedResult } from "../actions";

/**
 * Categorise Results
 *
 * Take every result and determine how it should be processed.
 *
 * - isNew: Result is not the same as the in-progress result
 * - isStale: Final version of the result has previously been processed
 */
export const categoriseResult = function* (): SagaIterator<void> {
  const previouslyCategorisedResults = new StrictMap<string, AssignedResult>(
    [],
    // We don't expect older history past this number of entries to be relevant
    { sizeLimit: 30 }
  );

  yield takeEvery(
    requestCategoriseResult,
    function* ({ payload: result }): SagaIterator<void> {
      const existingEntry = previouslyCategorisedResults.get(result.resultId);

      const isNew = !existingEntry;
      const isStale = !!existingEntry?.isFinal;

      previouslyCategorisedResults.set(result.resultId, result);

      yield put(categorisedResult({ isNew, isStale }));
    }
  );
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, call, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { uniqueCommandProcessed } from "./actions";
import { createTracker } from "../config/commands/utils/createTracker";

export const trackCommands = function* (): SagaIterator<void> {
  yield takeEvery(
    uniqueCommandProcessed,
    function* ({ payload: { prompt, outcome } }) {
      const tracker =
        prompt.command.track ??
        createTracker({ name: "Unknown Voice Command Issued", data: {} });

      const trackingRequest: SagaReturnType<typeof tracker> = yield call(
        tracker,
        outcome.status,
        prompt.instruction
      );

      yield put(requestTrackEvent(trackingRequest));
    }
  );
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { triggerStatusBarColourUpdate } from "./triggerStatusBarColourUpdate";
import { updateStatusBarColour } from "./updateStatusBarColour";

export function* manageStatusBarColour(): SagaIterator<void> {
  yield all([triggerStatusBarColourUpdate, updateStatusBarColour].map(retry));
}

import type { EventChannel, Saga, SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, put, call, take } from "redux-saga/effects";

import {
  createDOMEventChannel,
  waitForDocumentToBeVisible,
} from "@carescribe/utilities/src/sagas";

import {
  requestEnableScreenWakeLock,
  requestTrackScreenWakeLock,
  requestResetScreenWakeLock,
} from "../actions";
import { log, logWarning } from "../log";

/*
 * Wrappers necessary as interacting with wakeLock directly in a saga
 * leads to an "Illegal Invocation" error.
 */
export const requestWakeLock = (
  navigator: Navigator
): Promise<WakeLockSentinel> => navigator.wakeLock.request("screen");
export const releaseWakeLock = (
  wakeLock: WakeLockSentinel
): Promise<undefined> => wakeLock.release();

/**
 * Create a callback to run whenever the active lock is reset.
 *
 * - Closes the release event channel
 * - Releases the lock
 */
export const createOnReset = (
  lock: WakeLockSentinel,
  releaseEventChannel: EventChannel<Event>
): Saga => {
  return function* () {
    yield call(releaseEventChannel.close);
    yield call(releaseWakeLock, lock);
  };
};

export const onRequestEnableScreenWakeLock = function* (): SagaIterator<void> {
  if (!("wakeLock" in navigator)) {
    yield call(log, "Screen Wake Lock API is not supported by this device.");
    return;
  }

  // Clean up previous screen lock and event listeners to prevent memory leaks.
  yield put(requestResetScreenWakeLock());

  try {
    const lock: SagaReturnType<typeof requestWakeLock> = yield call(
      requestWakeLock,
      navigator
    );

    /**
     * An event channel to listen for the release event of the lock.
     * This is needed in case the lock is released by the system for any reason
     * in order to attempt re-enabling it.
     */
    const releaseEventChannel: SagaReturnType<typeof createDOMEventChannel> =
      yield call(createDOMEventChannel, lock, "release");

    const reset: SagaReturnType<typeof createOnReset> = yield call(
      createOnReset,
      lock,
      releaseEventChannel
    );
    yield put(requestTrackScreenWakeLock({ reset }));

    yield take(releaseEventChannel);

    /**
     * Proceeding to request a new lock when the document is still not visible
     * will not succeed and so we wait before trying again.
     */
    if (document.visibilityState !== "visible") {
      yield call(waitForDocumentToBeVisible);
    }

    yield call(onRequestEnableScreenWakeLock);
  } catch (error) {
    yield call(logWarning, `Failed to obtain screen wake lock. ${error}`);
  }
};

/**
 * Handles requests to enable the screen wake lock.
 *
 * It requests a screen wake lock from the system.
 *
 * A request for a new screen wake lock may be rejected by the system for
 * any number of valid reasons. In this case, a warning is logged.
 *
 * - document is not visible
 * - user is inactive
 * - device has a power saving mode enabled
 * - restricted by security policy
 *
 * Once granted, a lock may be released at any time by the system.
 * When this happens, reacquiring is attempted once the document is visible.
 */
export const handleEnableScreenWakeLock = function* (): SagaIterator<void> {
  yield takeEvery(requestEnableScreenWakeLock, onRequestEnableScreenWakeLock);
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

import { pressedWindowControlButton } from "./actions";

/**
 * Responds to window control button presses.
 *
 * - Minimises the window
 * - Maximises the window
 * - Unmaximises the window
 * - Closes the window
 */
export const manageWindowControls = function* (): SagaIterator<void> {
  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  yield takeEvery(pressedWindowControlButton, function* ({ payload: button }) {
    const method = ipc?.system?.window?.[button]?.v1;

    if (!method) {
      return;
    }

    yield call(method);
  });
};

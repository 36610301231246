import type { AppDispatch } from "@talktype/store/src/types/AppDispatch";
import type { RootState } from "@talktype/store/src/types/RootState";
import type { VoiceCommandsProps } from "@talktype/ui/src/VoiceCommands";

import { connect } from "react-redux";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import { selectListingDisplay } from "@talktype/listing/src/reducer/selectors/selectListingDisplay";
import { VoiceCommands as Component } from "@talktype/ui/src/VoiceCommands";

const mapStateToProps = (
  state: RootState
): Pick<VoiceCommandsProps, "listing" | "isSearching" | "searchTerm"> =>
  selectListingDisplay(state);

const mapDispatchToProps = (
  dispatch: AppDispatch
): Pick<VoiceCommandsProps, "onClickExpander"> => ({
  onClickExpander: (data) =>
    dispatch(requestTrackEvent({ name: "Voice Command Listing Viewed", data })),
});

export const VoiceCommands = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

import type { ReactElement } from "react";

export const WindowsMaximiseIcon = (): ReactElement => (
  <svg
    width="46"
    height="38"
    viewBox="0 0 46 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.615 24.0129L18.6149 24.0129L18.6193 24.0154C18.8888 24.1708 19.184 24.248 19.5 24.248H26.4961C26.8121 24.248 27.106 24.1707 27.3725 24.0145L27.3736 24.0138C27.6396 23.8561 27.8517 23.6445 28.0064 23.3809C28.1662 23.1111 28.2461 22.8152 28.2461 22.498V15.502C28.2461 15.1854 28.1665 14.8913 28.0064 14.625C27.8521 14.3581 27.64 14.146 27.3731 13.9917C27.1068 13.8315 26.8126 13.752 26.4961 13.752H19.5C19.1829 13.752 18.8869 13.8318 18.6171 13.9917C18.3536 14.1463 18.1419 14.3585 17.9842 14.6244L17.9842 14.6244L17.9835 14.6255C17.8273 14.892 17.75 15.186 17.75 15.502V22.498C17.75 22.814 17.8273 23.1092 17.9826 23.3787L17.9826 23.3788L17.9852 23.3831C18.1429 23.6444 18.3536 23.8552 18.615 24.0129ZM26.6674 15.3237L26.6673 15.3238L26.6744 15.3306C26.7286 15.3827 26.75 15.4355 26.75 15.502V22.498C26.75 22.5648 26.7282 22.6214 26.6709 22.6787C26.6196 22.73 26.5658 22.752 26.4961 22.752H19.5C19.4315 22.752 19.3748 22.7306 19.3194 22.6786C19.2675 22.6232 19.2461 22.5665 19.2461 22.498V15.502C19.2461 15.4323 19.268 15.3785 19.3194 15.3272C19.3767 15.2699 19.4332 15.248 19.5 15.248H26.4961C26.5625 15.248 26.6153 15.2694 26.6674 15.3237Z"
      fill="currentcolor"
      stroke="currentcolor"
      strokeWidth="0.5"
    />
  </svg>
);

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select, take, takeEvery } from "redux-saga/effects";

import {
  authenticationComplete,
  requestAuthenticate,
} from "@carescribe/pkce/src/sagas/actions";
import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import {
  gotLicenceKeyRedemptionStatus,
  loginComplete,
  logoutComplete,
  optimisticLogin,
  requestGetMe,
  requestLicenceKeyRedemptionStatus,
} from "./actions";
import { setLoginStatus, setMe } from "../reducer";
import { selectLoginStatus } from "../reducer/selectors/selectLoginStatus";
import { logError } from "../utils/log";

/**
 * Show Welcome
 *
 * Determines what UI to display based on the licence key redemption status and
 * updates the state property depended on my the UI (loginStatus) accordingly.
 */
export const showWelcome = function* (): SagaIterator<void> {
  // Find out what UI to display
  yield put(requestLicenceKeyRedemptionStatus());

  const {
    payload: licenceKeyStatus,
  }: SagaReturnType<typeof gotLicenceKeyRedemptionStatus> = yield take(
    gotLicenceKeyRedemptionStatus
  );

  // Update login status: loading -> migrating/logged out
  yield put(
    setLoginStatus(
      licenceKeyStatus === "redeemable" ? "migrating" : "loggedOut"
    )
  );
};

/**
 * Start Login Flow
 *
 * Kicks off the login flow, which includes checking if the user is already
 * logged in, showing the welcome screen if they are not, and handling the
 * authentication process.
 */
export const startLoginFlow = function* (): SagaIterator<void> {
  const loginStatus: SagaReturnType<typeof selectLoginStatus> = yield select(
    selectLoginStatus
  );

  if (loginStatus === "loggedIn") {
    // If the user is already logged in (i.e. they refreshed the page)
    // pre-emptively keep the status as loggedIn so that we don't show a flash
    // of the login screen
    yield put(optimisticLogin());

    // Do a real check to find out if we're already logged in
    yield put(requestGetMe());
    const { payload: me }: SagaReturnType<typeof setMe> = yield take(setMe);

    if (me) {
      // If we're already logged in, we're done
      yield put(loginComplete(me));
      return;
    }

    // Any previous "loggedIn" status was not corroborated by the server, so
    // show a the appropriate state until we can decide what to do next
    yield put(setLoginStatus("loading"));
  } else {
    yield put(setLoginStatus("loading"));
  }

  if (getAppContext() === "desktop") {
    // A desktop app can only transition to the welcome screen after this
    // point whereas a browser app may be in the process of authenticating
    yield call(showWelcome);
  }

  yield put(requestAuthenticate());
  const {
    payload: authenticated,
  }: SagaReturnType<typeof authenticationComplete> = yield take(
    authenticationComplete
  );

  if (!authenticated) {
    yield call(showWelcome);
    return;
  }

  // Find out who the user is for real now that we have tokens
  yield put(requestGetMe());
  const { payload: meAfterAuth }: SagaReturnType<typeof setMe> = yield take(
    setMe
  );

  if (meAfterAuth) {
    yield put(loginComplete(meAfterAuth));
  } else {
    yield call(logError, "Failed to fetch user data after authentication");
    yield call(showWelcome);
  }
};

/**
 * Set Up Login
 *
 * Waits for all the things that login depends upon, then starts the login flow.
 * Also listens for logout events to restart the login flow.
 */
export const setUpLogin = function* (): SagaIterator<void> {
  yield call(startLoginFlow);

  yield takeEvery(logoutComplete, function* () {
    yield call(startLoginFlow);
  });
};

import type { ReactElement } from "react";

import { useSelector } from "react-redux";
import { match } from "ts-pattern";

import { selectLoginStatus } from "@talktype/user/src/reducer/selectors/selectLoginStatus";

import { Layout } from "./Layout";
import { Welcome } from "./Welcome";

export const Routes = (): ReactElement =>
  match(useSelector(selectLoginStatus))
    .with("loggedIn", () => <Layout />)
    .otherwise((variant) => <Welcome variant={variant} />);

import { messages as baseMessages } from "../../messages";
import { createMatchers } from "../../utils/createMatchers";
import { messages as commonMessages } from "../messages";

const { bold, on } = commonMessages;

export const messages = {
  matchers: createMatchers(bold, on),
  successful: "OK, bold is on!",
  failed: baseMessages.failureWithoutReason,
  listing: ["Bold on"],
};

import type { SagaIterator, Task } from "redux-saga";
import type { BaseOperation } from "slate";

import { cancel, takeEvery, delay, put, fork } from "redux-saga/effects";

import {
  secondsToMilliseconds,
  toSeconds,
} from "@carescribe/utilities/src/timing";

import { requestTrackEvent } from "@talktype/analytics";
import { calculateTextChangeCount } from "@talktype/utilities";

import { editorChanged, editorKeyUp } from "./actions";

const debounceTime = secondsToMilliseconds(toSeconds(5));

/**
 * Fires off tracking events when text editing takes place:
 * - insert: user inserts text
 * - delete: user deletes text
 *
 * Detection is triggered by the editorChanged action and is debounced
 * so that it is only fired after the user has stopped typing.
 *
 * A character count is included.
 */
export const trackTextEvents = function* (): SagaIterator<void> {
  let task: Task | null = null;
  let latestOperations: BaseOperation[] = [];
  let currentOperationBatch: BaseOperation[] = [];

  yield takeEvery(editorChanged, function* (action) {
    latestOperations = action.payload;
  });

  yield takeEvery(editorKeyUp, function* (): SagaIterator<void> {
    // Cancel existing tasks
    if (task) {
      yield cancel(task);
    }

    currentOperationBatch.push(...latestOperations);

    // Start a delayed task
    task = yield fork(function* (): SagaIterator<void> {
      yield delay(debounceTime);

      const { insertCount, removeCount } = calculateTextChangeCount(
        currentOperationBatch
      );

      if (insertCount) {
        yield put(
          requestTrackEvent({
            name: "Text Inserted",
            data: { characterCount: insertCount },
          })
        );
      }

      if (removeCount) {
        yield put(
          requestTrackEvent({
            name: "Text Deleted",
            data: { characterCount: removeCount },
          })
        );
      }

      currentOperationBatch = [];
    });
  });
};

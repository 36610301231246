import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, select, takeEvery } from "redux-saga/effects";

import { copyToClipboard } from "@carescribe/utilities/src/copyToClipboard";

import { requestCopyDictationHistoryEntry } from "./actions";
import { selectDictationHistoryEntry } from "../reducers/dictateToApp/selectors/selectDictationHistoryEntry";

export function* manageCopyDictationHistoryEntry(): SagaIterator<void> {
  yield takeEvery(
    requestCopyDictationHistoryEntry,
    function* ({ payload: { resultId } }) {
      const entry: SagaReturnType<
        ReturnType<typeof selectDictationHistoryEntry>
      > = yield select(selectDictationHistoryEntry({ resultId }));

      if (entry) {
        yield call(copyToClipboard, { text: entry.text });
      }
    }
  );
}

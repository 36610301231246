import type { BrowserOptions } from "@sentry/react";

import { pathOr } from "@carescribe/utilities/src/fp";

const analyticsProviders = [
  // Chameleon
  /assets\/chmln\/[a-z0-9]+\/chmln\.js$/,
  // Hotjar
  /packages\/shared\/libraries\/analytics\/dist\/hotjar\.js$/,
];

export const filterAnalyticsBeforeSend: NonNullable<
  BrowserOptions["beforeSend"]
> = (event) => {
  const path = pathOr(
    "",
    ["exception", "values", 0, "stacktrace", "frames", 0, "filename"],
    event
  );

  const ignore = analyticsProviders.some((provider) => provider.test(path));

  return ignore ? null : event;
};

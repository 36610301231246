import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, call, spawn } from "redux-saga/effects";

import { getAppContext } from "@carescribe/utilities/src/getAppContext";

import { setUpTracking } from "@talktype/analytics/src/sagas/setUpTracking";
import { setBuildId } from "@talktype/system/src/reducers/system";
import { getDesktopSystemMetadata } from "@talktype/system/src/utils/getDesktopSystemMetadata";

export const configureTracking = function* (): SagaIterator<void> {
  const context: SagaReturnType<typeof getAppContext> = yield call(
    getAppContext
  );

  const { payload: initialBuildId }: SagaReturnType<typeof setBuildId> =
    yield take(setBuildId);
  const buildId = initialBuildId ?? "";

  const desktopSystemMetadata: SagaReturnType<typeof getDesktopSystemMetadata> =
    yield call(getDesktopSystemMetadata);

  const eventData = desktopSystemMetadata
    ? { context, buildId, ...desktopSystemMetadata }
    : { context, buildId };

  yield spawn(setUpTracking, eventData);
};

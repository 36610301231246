import type { AppDispatch, RootState } from "@talktype/store";
import type { ColourScheme } from "@talktype/types/src/ColourScheme";
import type { ThemeFieldProps } from "@talktype/ui/src/PreferencesModal/ThemeField";

import { connect } from "react-redux";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { colourSchemes } from "@talktype/config";
import { setColourScheme } from "@talktype/preferences";
import { ThemeField as Component } from "@talktype/ui/src/PreferencesModal/ThemeField";

type StateProps = { isChecked: (value: string) => boolean };

type DispatchProps = { onChange: (value: ColourScheme) => void };

const mapStateToProps = ({
  preferences: { colourScheme },
}: RootState): StateProps => ({ isChecked: (value) => colourScheme === value });

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  onChange: (value: ColourScheme): void => {
    dispatch(broadcast(setColourScheme(value)));
  },
});

const mergeProps = (
  { isChecked }: StateProps,
  { onChange }: DispatchProps
): ThemeFieldProps => ({
  options: colourSchemes.map(({ display, value, icon }) => ({
    display,
    icon,
    name: "theme",
    checked: isChecked(value),
    onChange: () => onChange(value),
  })),
});

export const ThemeField = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Component);

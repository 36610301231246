import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  unsubscribedFromActiveApp,
  subscribedToActiveApp,
  requestActiveAppSubscriptionState,
  broadcastActiveAppSubscriptionState,
} from "./actions";

export type SubscriptionState =
  | { subscribed: true; onUnsubscribe: () => void }
  | { subscribed: false; onUnsubscribe: null };

/**
 * Tracks and broadcasts the current state of the active app subscription.
 */
export const trackActiveAppSubscriptionState =
  function* (): SagaIterator<void> {
    let state: SubscriptionState = { subscribed: false, onUnsubscribe: null };

    yield takeEvery(unsubscribedFromActiveApp, function* () {
      state = { subscribed: false, onUnsubscribe: null };
    });

    yield takeEvery(
      subscribedToActiveApp,
      function* ({ payload: { onUnsubscribe } }) {
        state = { subscribed: true, onUnsubscribe };
      }
    );

    yield takeEvery(requestActiveAppSubscriptionState, function* () {
      yield put(broadcastActiveAppSubscriptionState(state));
    });
  };

import type { KeyboardEvent, MouseEvent } from "react";

import { isMouseClick } from "@carescribe/utilities/src/isMouseClick";

import { InteractionMethod } from "@talktype/analytics/src/types/InteractionMethod";

export const getInteractionMethodFromEvent = (
  event: KeyboardEvent | MouseEvent
): Exclude<InteractionMethod, InteractionMethod.voice> => {
  if (isMouseClick(event)) {
    return InteractionMethod.click;
  }

  return InteractionMethod.keyboard;
};

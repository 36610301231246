import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { setColourScheme } from "../reducers";

export const trackSetColourScheme = function* (): SagaIterator<void> {
  yield takeEvery(setColourScheme, function* ({ payload }) {
    yield put(
      requestTrackEvent({
        name: "Preferences Updated",
        data: {
          preferencesProperty: "colour scheme",
          colourScheme: payload,
        },
      })
    );
  });
};

import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { editorLoaded, type EditorType } from "@talktype/editor";

/**
 * Cypress needs access to the editor in some cases in the same way that it
 * needs access to the Redux store. This saga ensures that the current editor
 * is attached to the window object when in a cypress test
 */
export const addEditorToWindowForCypress = function* (): SagaIterator<void> {
  yield takeEvery(editorLoaded, ({ payload: editor }) => {
    if ("Cypress" in window) {
      type Win = Window & { editor: EditorType };

      (window as Partial<Window> as Win).editor = editor;
    }
  });
};

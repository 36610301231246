import type { CommandCreator } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  requestCursorMove,
  movedCursor,
} from "@talktype/editor/src/sagas/actions";

export const move: CommandCreator<"up" | "down" | "left" | "right"> = (
  direction
) =>
  function* () {
    yield put(requestCursorMove(direction));

    const { payload: success }: SagaReturnType<typeof movedCursor> = yield take(
      movedCursor
    );

    return success;
  };

import { getIsDevelopment } from "@carescribe/utilities/src/dev/getIsDevelopment";

import { log, logWarning } from "./log";

/**
 * Registers a service worker for the web application once it has loaded.
 *
 * @param path - The relative path to the service worker file.
 *
 * The path is relative to ensure the service worker can be located when the
 * app is hosted from a variety of different origins.
 *
 * Take for instance an app hosted on:
 * 1. `https://app.talk-type.com/`
 * 2. `https://storage.googleapis.com/talktype-review-apps/abc/index.html`
 *
 * Registering with an absolute path would otherwise break for the second
 * domain, since the service worker would be expected at
 * `https://storage.googleapis.com/service-worker.js` instead of
 * `https://storage.googleapis.com/talktype-review-apps/abc/service-worker.js`.
 *
 * @example
 * registerServiceWorker({ path: '/service-worker.js' });
 */
export const registerServiceWorker = ({ path }: { path: string }): void => {
  const isDevelopment = getIsDevelopment();

  if (isDevelopment) {
    return;
  }

  if (!("serviceWorker" in navigator)) {
    logWarning("Service worker registration failed: not supported");
    return;
  }

  window.addEventListener("load", async () => {
    try {
      let relativePath = path;
      const removeStartingSlashRegex = /^\/(?!\/)/;
      relativePath = path.replace(removeStartingSlashRegex, "");

      const currentURL = new URL(window.location.href);
      const lastSegmentRegex = /\/[^/]*$/;
      currentURL.pathname = currentURL.pathname.replace(lastSegmentRegex, "/");

      relativePath = new URL(relativePath, currentURL).href;
      const registration = await navigator.serviceWorker.register(relativePath);
      log("Service worker registered: ", registration);
    } catch (registrationError) {
      logWarning("Service worker registration failed: ", registrationError);
    }
  });
};

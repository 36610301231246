import type { DictationSessionResponse } from "../../types/TranscriptionUrlResponse";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";
import { match, P } from "ts-pattern";

import { DICTATION_SESSIONS_URL } from "../../config/web";
import { validDictationSessionResponse } from "../../guards/isDictationSessionResponse";
import { validUnauthorizedResponse } from "../../guards/isUnauthorisedResponse";
import { logError } from "../../utils/log";
import { gotDictationSession, requestDictationSession } from "../actions";
import { authedRequest } from "../utils/authedRequest";

const handleError = () =>
  function* (): SagaIterator<void> {
    yield put(gotDictationSession({ error: "connection-error" }));
  };

const handleDictationSession = ({ data }: { data: DictationSessionResponse }) =>
  function* (): SagaIterator<void> {
    yield put(gotDictationSession(data));
  };

const handleUnauthorized = () =>
  function* (): SagaIterator<void> {
    yield put(gotDictationSession({ error: "permission-error" }));
  };

const handleUnknown = ({ data }: { data: unknown }) =>
  function* (): SagaIterator<void> {
    yield put(gotDictationSession({ error: "unknown-error" }));
    yield call(logError, "Unknown dictation session response format:", data);
  };

/**
 * Set Up Dictation Sessions
 *
 * Get a dictation session when requested
 */
export const setUpDictationSessions = function* (): SagaIterator<void> {
  yield takeEvery(requestDictationSession, function* () {
    const response: SagaReturnType<typeof authedRequest> = yield call(
      authedRequest,
      { method: "POST", pathname: DICTATION_SESSIONS_URL }
    );

    yield call(
      match(response)
        .with({ error: P.string }, handleError)
        .with({ data: validDictationSessionResponse }, handleDictationSession)
        .with({ data: validUnauthorizedResponse }, handleUnauthorized)
        .otherwise(handleUnknown)
    );
  });
};

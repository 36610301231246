import type { SagaIterator } from "redux-saga";

import { put, take, takeEvery } from "redux-saga/effects";

import {
  finalisedDictation,
  requestFinaliseDictation,
  requestStopDictating,
} from "@talktype/actions";

import { stopDictating } from "../../reducers";
import { editorUnloaded } from "../actions";

/**
 * Finalise in-progress results and stop dictation whenever:
 *
 * - dictation is stopped
 * - editor is unloaded
 *
 * This saga also ensures that dictation is stopped after finalisation.
 *
 * Certain processes rely on querying the dictation state (and state of
 * in-progress text and in-progress results, etc.) in order to know how to act.
 *
 * As such, we need to wait for a simulated final results to have been inserted
 * before actually marking dictation as complete. In effect, this happens
 * rapidly enough that it is imperceptible.
 */
export const finaliseAndStopDictation = function* (): SagaIterator<void> {
  yield takeEvery(
    [requestStopDictating, editorUnloaded],
    function* (): SagaIterator<void> {
      yield put(requestFinaliseDictation());

      yield take(finalisedDictation);

      yield put(stopDictating());
    }
  );
};

import type { ActivityStatus } from "../types/ActivityStatus";
import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import {
  activityStatusChanged,
  broadcastActivityStatus,
  requestActivityStatus,
} from "./actions";

/**
 * Tracks the current activity status and responds to requests for it.
 */
export const trackActivityStatus = function* ({
  initial,
}: {
  initial: ActivityStatus;
}): SagaIterator<void> {
  let activityStatus: ActivityStatus = initial;

  yield takeEvery(activityStatusChanged, function* ({ payload: newStatus }) {
    activityStatus = newStatus;
  });

  yield takeEvery(requestActivityStatus, function* () {
    yield put(broadcastActivityStatus(activityStatus));
  });
};

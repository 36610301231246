export type StatusBarColourOptions = {
  colour: string;
  iOSStyle: "default" | "black" | "black-translucent";
};

/**
 * Changes the status bar colour.
 *
 * How?
 *
 * The standard way of doing this is by using the `theme-color` meta tag.
 *
 * With iOS, however, things get a bit more complicated.
 *
 * For a light status bar:
 *
 * Set the `apple-mobile-web-app-status-bar-style` meta
 * tag to `default` will give it a white background with black text.
 *
 * For a dark status bar:
 *
 * Set the `apple-mobile-web-app-status-bar-style` meta tag to
 * `black-translucent`. This will make the status bar match the body's
 * theme-color background colour along with black text.
 */
export const setStatusBarColour = ({
  colour,
  iOSStyle,
}: StatusBarColourOptions): void => {
  let themeMetaTag = document.querySelector('meta[name="theme-color"]');
  let iOSStatusBarStyle = document.querySelector(
    'meta[name="apple-mobile-web-app-status-bar-style"]'
  );

  if (!themeMetaTag) {
    themeMetaTag = document.createElement("meta");
    themeMetaTag.setAttribute("name", "theme-color");
    document.head.appendChild(themeMetaTag);
  }

  if (!iOSStatusBarStyle) {
    iOSStatusBarStyle = document.createElement("meta");
    iOSStatusBarStyle.setAttribute(
      "name",
      "apple-mobile-web-app-status-bar-style"
    );
    document.head.appendChild(iOSStatusBarStyle);
  }

  themeMetaTag.setAttribute("content", colour);
  iOSStatusBarStyle.setAttribute("content", iOSStyle);
};

import type { DesktopSystemMetadata } from "../types/DesktopSystemMetadata";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

/**
 * Attempts to retrieve the desktop system metadata.
 * If none is available (e.g. in non-desktop environments), returns `null`.
 */
export const getDesktopSystemMetadata =
  function* (): SagaIterator<DesktopSystemMetadata | null> {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    const getAppVersion = ipc?.system?.appVersion?.get?.v1;
    const getOSVersion = ipc?.system?.osVersion?.get?.v1;

    if (!getAppVersion || !getOSVersion) {
      return null;
    }

    const appVersion: SagaReturnType<typeof getAppVersion> = yield call(
      getAppVersion
    );
    const osVersion: SagaReturnType<typeof getOSVersion> = yield call(
      getOSVersion
    );

    return { appVersion, osVersion };
  };

import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./headerBar.module.scss";

export type HeaderBarProps = {
  children?: ReactNode;
  isMacDesktop: boolean;
  style: "default" | "borderless";
};

export const HeaderBar = ({
  children,
  isMacDesktop,
  style,
}: HeaderBarProps): ReactElement => (
  <header
    className={classNames(
      styles.header,
      createSelectorClassName("header-bar", "section"),
      [isMacDesktop, styles.macDesktop],
      [style === "borderless", styles.borderLess]
    )}
  >
    {children}
  </header>
);

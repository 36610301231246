import type {
  HTMLAttributes,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";

import { classNames } from "@carescribe/utilities/src/classNames";

import styles from "./radioInput.module.scss";
import { IconContainer } from "../IconContainer";
import { ScreenReaderOnly } from "../ScreenReaderOnly";

export type RadioInputProps = {
  style: "default" | "icon-only";
  colour: "neutral" | "brand" | "positive" | "alert" | "destructive";
  hierarchy: "primary" | "secondary" | "tertiary" | "link" | "link-invert";
  size: "xs" | "sm" | "md" | "lg" | "xl";
  label: string;
  mainIcon?: ReactNode;
  trailingIcon?: ReactNode;
  labelProps?: HTMLAttributes<HTMLLabelElement>;
  /**
   * Radio buttons in a group must share the same name:
   * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#defining_a_radio_group }
   */
  inputProps: InputHTMLAttributes<HTMLInputElement> & { name: string };
};

export const RadioInput = ({
  colour,
  hierarchy,
  size,
  style,
  mainIcon,
  trailingIcon,
  label,
  labelProps,
  inputProps,
}: RadioInputProps): ReactElement => (
  <label
    {...(labelProps ?? {})}
    className={classNames(
      styles.container,
      styles[colour],
      styles[hierarchy],
      styles[size],
      labelProps?.className
    )}
  >
    {mainIcon && (
      <IconContainer className={styles.mainIcon}>{mainIcon}</IconContainer>
    )}

    {style === "icon-only" ? (
      <ScreenReaderOnly tag="span">{label}</ScreenReaderOnly>
    ) : (
      label
    )}
    <input
      {...inputProps}
      className={classNames(styles.input, inputProps?.className)}
      type="radio"
      /**
       * For some reason changing selected input by keyboard leads to a click
       * event. This can then propagate up and do weird stuff like close modals
       */
      onClick={(event): void => event.stopPropagation()}
    />

    {trailingIcon && (
      <IconContainer className={styles.trailingIcon}>
        {trailingIcon}
      </IconContainer>
    )}
  </label>
);

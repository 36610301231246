import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import {
  requestDisableScreenWakeLock,
  requestResetScreenWakeLock,
} from "../actions";

const onDisableScreenWakeLock = function* (): SagaIterator<void> {
  yield put(requestResetScreenWakeLock());
};

/**
 * Handles requests to disable the screen wake lock.
 *
 * It requests that the screen wake lock be reset.
 */
export const handleDisableScreenWakeLock = function* (): SagaIterator<void> {
  yield takeEvery(requestDisableScreenWakeLock, onDisableScreenWakeLock);
};

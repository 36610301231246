import type { Reshaper } from "./types/Reshaper";

import { createNewCustomWord } from "@talktype/preferences/src/sagas/setUpCustomWords/createNewCustomWord";

import { isLegacyCustomWords } from "../../../../guards";

/**
 * Reshape Custom Words
 *
 * Convert legacy custom words and copy them into preferences.
 */
export const reshapeCustomWords: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    !isLegacyCustomWords(legacyPreferences.custom_word)
      ? newPreferences
      : {
          ...newPreferences,
          customWords: legacyPreferences.custom_word.map((input) =>
            createNewCustomWord({ input })
          ),
        };

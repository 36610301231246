import type { SagaIterator } from "redux-saga";

import { call } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { setUpUser } from "@talktype/user/src/sagas/setUpUser";

import { DASHBOARD_URL } from "../env";

export const configureUser = function* (): SagaIterator<void> {
  yield retry(function* (): SagaIterator<void> {
    yield call(setUpUser, { dashboardUrl: DASHBOARD_URL });
  });
};

import type { ReactElement, ReactNode } from "react";

import { match } from "ts-pattern";

import { AppleInstruction } from "./AppleInstruction";
import { NativeInstruction } from "./NativeInstruction";

type MobileTarget = { type: "mobile"; system: "other" };
type AppleTarget = { type: "mobile"; system: "apple" };
type UnknownTarget = { type: string; system: string };
type InstallTarget = MobileTarget | AppleTarget | UnknownTarget;

export type PWAInstallPromptProps = {
  target: InstallTarget | null;
  supportsNativeInstall: boolean;
  app: { name: string; icon: ReactNode };
  slideIn?: boolean;
  onPrompt: () => void;
  onDismiss: () => void;
  onMount: (() => void) | null;
  onUnmount: (() => void) | null;
};

export const PWAInstallPrompt = ({
  target,
  supportsNativeInstall,
  ...props
}: PWAInstallPromptProps): ReactElement =>
  match({ target, supportsNativeInstall })
    .with(
      {
        target: { type: "mobile", system: "other" },
        supportsNativeInstall: true,
      },
      () => <NativeInstruction {...props} />
    )
    .with({ target: { type: "mobile", system: "apple" } }, () => (
      <AppleInstruction {...props} />
    ))
    .otherwise(() => <></>);

import type { ValueOf } from "@carescribe/types/src/ValueOf";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export type RequestOptions = {
  url: string;
  method?: "GET" | "POST";
  params?: Record<string, string>;
  logError?: typeof console.error;
};

const methods = {
  *GET(url: URL, params: Record<string, string>): SagaIterator<Response> {
    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });

    return yield call(fetch, url.toString());
  },

  *POST(url: URL, params: Record<string, string>): SagaIterator<Response> {
    return yield call(fetch, url.toString(), {
      method: "POST",
      headers,
      body: JSON.stringify(params),
    });
  },
};

export type RequestError = "url-error" | "network-error" | "parse-error";

type DataOrError =
  | { data: unknown; error?: never }
  | { data?: never; error: RequestError };

export const request = function* ({
  method = "GET",
  url,
  params = {},
  logError = console.error,
}: RequestOptions): SagaIterator<DataOrError> {
  let urlObject: URL;

  try {
    urlObject = typeof url === "string" ? new URL(url) : url;
  } catch (error) {
    yield call(logError, `Invalid URL: ${url}`);
    return { error: "url-error" };
  }

  let response: SagaReturnType<ValueOf<typeof methods>>;

  try {
    response = yield call(methods[method], urlObject, params);
  } catch (error) {
    yield call(logError, `Request to ${url} failed`, error);
    return { error: "network-error" };
  }

  let data: unknown;

  try {
    data = yield call([response, "json"]);
  } catch (error) {
    yield call(logError, `Failed to parse response from ${url}`, error);
    return { error: "parse-error" };
  }

  return { data };
};

import type { SagaIterator } from "redux-saga";

import { takeEvery } from "redux-saga/effects";

import { transcriberSocketConnected } from "@carescribe/transcriber-connection/src";
import { putSaga } from "@carescribe/utilities/src/sagas";

import { stopDictating } from "@talktype/editor";

import {
  setLastConnectionTimestamp,
  setLastDisconnectionTimestamp,
} from "../reducers/timings";

export const manageTimestamps = function* (): SagaIterator<void> {
  yield takeEvery(
    transcriberSocketConnected,
    putSaga(setLastConnectionTimestamp())
  );

  yield takeEvery(stopDictating, putSaga(setLastDisconnectionTimestamp()));
};

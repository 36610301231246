import type { Stage } from "../../../types";

import { newLineCharacter } from "@carescribe/slate";
import { ucFirst } from "@carescribe/utilities/src/ucFirst";

import {
  symbolsWithSpaceAfter,
  symbolsWithAddSpaceAfter,
} from "../../../config/punctuation";
import {
  textEndsWithCapitalizer,
  textStartsWithPunctuation,
  shouldBeNoSpaceBeforeText,
  shouldBeNoSpaceAfterText,
} from "../../../utils";

const addSpaceAfterIfRequired = (
  text: string,
  symbolsWithAddSpaceAfter: string[]
): string =>
  text.replace(
    new RegExp(`(${symbolsWithAddSpaceAfter.join("|")})(?!\\s)`, "g"),
    (match) => match + " "
  );

export const statefulGrammar: Stage = ({
  text,
  precedingText,
  precedingChunk,
}) => {
  if (text.length === 0) {
    return text;
  }

  text = addSpaceAfterIfRequired(text, symbolsWithAddSpaceAfter);

  const shouldCapitalize =
    !precedingText ||
    ["paragraph", "linebreak"].includes(precedingChunk.type) ||
    precedingText.endsWith(newLineCharacter);

  if (shouldCapitalize) {
    return ucFirst(text);
  }

  if (symbolsWithSpaceAfter.includes(text)) {
    return text;
  }

  const shouldLowerCase =
    !textEndsWithCapitalizer(precedingText) &&
    !text.startsWith("I'") &&
    !text.startsWith("I ");

  if (shouldLowerCase) {
    text = text[0].toLowerCase() + text.substring(1);
  }

  if (textEndsWithCapitalizer(precedingText)) {
    text = text[0].toUpperCase() + text.substring(1);
  }

  const shouldAddSpaceBefore =
    !precedingText.endsWith(" ") &&
    !["paragraph", "linebreak"].includes(precedingChunk.type) &&
    !textStartsWithPunctuation(text) &&
    !shouldBeNoSpaceBeforeText(text) &&
    !shouldBeNoSpaceAfterText(precedingText);

  if (shouldAddSpaceBefore) {
    text = " " + text;
  }

  return text;
};

import type { EditorType } from "../editor";
import type { EventChannel } from "redux-saga";

import { eventChannel } from "redux-saga";

import { pipe, noOp, pick } from "@carescribe/utilities/src/fp";

const properties = ["children", "history", "selection"] as const;

/**
 * Create Editor Change Channel
 *
 * Accept an editor as an argument and set up an event channel that emits some
 * of the editor's properties each time it changes.
 */
export const createEditorChangeChannel = (
  editor: EditorType
): EventChannel<Pick<EditorType, typeof properties[number]>> =>
  eventChannel<Pick<EditorType, typeof properties[number]>>((emit) => {
    /**
     * Extract the parts of the editor that we want to track and make them
     * available to other sagas by emitting them out of the channel
     */
    const handleChange = (): void => emit(pick(properties, editor));

    // Preserve existing onChange behaviour. Without this the editor becomes
    // readonly
    editor.onChange = pipe(editor.onChange, handleChange);

    return noOp;
  });

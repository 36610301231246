import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getIsDevelopment } from "@carescribe/utilities/src/dev/getIsDevelopment";

import * as env from "../env";
import { log } from "../log";

/**
 * Logs environment variables (DEVELOPMENT ONLY)
 */
export const logEnvIfDev = function* (): SagaIterator<void> {
  const isDevelopment: SagaReturnType<typeof getIsDevelopment> = yield call(
    getIsDevelopment
  );

  if (isDevelopment) {
    yield call(log, "Environment:");
    // eslint-disable-next-line no-console -- it's ok!
    yield call(console.table, { ...env });
  }
};

import type { Void } from "@carescribe/types";
import type { MenuItem } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { Gear, Question, SignOut, UserSquare } from "@phosphor-icons/react";
import { useRef, useEffect } from "react";

import { useLightDismiss } from "@carescribe/ui";
import { getPlatform } from "@carescribe/utilities/src/browser";

import { helpSiteUrl } from "@talktype/config/src/helpSiteUrl";
import { Menu } from "@talktype/ui/src/Menu";

import { messages } from "./messages";
import { PreferencesModal } from "../PreferencesModal";

export type MainMenuProps = {
  preferencesFields: ReactNode;
  logOut: Void;
  dashboardUrl: string;
};

export const MainMenu = ({
  preferencesFields,
  logOut,
  dashboardUrl,
}: MainMenuProps): ReactElement => {
  const modalRef = useRef<HTMLDialogElement>(null);
  useLightDismiss(modalRef);

  const showModal = (): void => modalRef.current?.showModal();

  const items: MenuItem[] = [
    {
      type: "button",
      id: "1",
      label: messages.preferences.label,
      Icon: Gear,
      shortcut: messages.preferences.shortcut,
      onClick: showModal,
    },
    {
      type: "link",
      id: "2",
      label: messages.account.label,
      Icon: UserSquare,
      href: dashboardUrl,
    },
    {
      type: "link",
      id: "3",
      label: messages.helpCentre.label,
      Icon: Question,
      href: helpSiteUrl,
    },
    {
      type: "button",
      id: "4",
      label: messages.logOut.label,
      Icon: SignOut,
      shortcut: null,
      onClick: logOut,
    },
  ];

  // MacOS only: open preferences modal on Cmd + ,
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      const platform = getPlatform();

      if (platform === "mac" && event.metaKey && event.key === ",") {
        event.preventDefault();
        showModal();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <>
      <Menu items={items} />
      <PreferencesModal modalRef={modalRef}>
        {preferencesFields}
      </PreferencesModal>
    </>
  );
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeEvery, call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities/src/getIpc";

import { requestFocusTalkType } from "./actions";

/**
 * Set Up Focus TalkType
 *
 * Listens for the request to focus TalkType and brings the window to the front.
 */
export const setUpFocusTalkType = function* (): SagaIterator<void> {
  yield takeEvery(requestFocusTalkType, function* () {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    if (!ipc?.system?.window?.show?.v1) {
      return;
    }

    yield call(ipc.system.window.show.v1);
  });
};

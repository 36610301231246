import type { InlineStyleMarks } from "@talktype/types";
import type { ReactElement, ReactNode } from "react";

import { Fragment } from "react";

import { Bold } from "./Bold";
import { Italic } from "./Italic";
import { Underline } from "./Underline";

type InlineStyledProps = {
  marks: InlineStyleMarks;
  children: ReactNode;
};

export const InlineStyled = ({
  marks,
  children,
}: InlineStyledProps): ReactElement => {
  const B = marks.bold ? Bold : Fragment;
  const I = marks.italic ? Italic : Fragment;
  const U = marks.underline ? Underline : Fragment;

  return (
    <U>
      <I>
        <B>{children}</B>
      </I>
    </U>
  );
};

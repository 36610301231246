import type { Editor, Range } from "slate";

import { combineRanges } from "./combineRanges";
import { getRangeOfLastInstance } from "./getRangeOfLastInstance";

/**
 * Get Range of Last Instances
 *
 * Gets a combined range of the last instances two search strings
 */
export const getRangeOfLastInstances = (
  editor: Editor,
  from: string,
  to: string
): Range | null => {
  const start = getRangeOfLastInstance(editor, from);
  const end = getRangeOfLastInstance(editor, to);

  return start && end ? combineRanges(start, end) : null;
};

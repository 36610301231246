import type { FormState } from "./FormState";
import type { Validators } from "./Validators";
import type { CustomShortcut } from "@talktype/types";
import type { ReactElement, RefObject } from "react";

import { ArrowBendDoubleUpRight } from "@phosphor-icons/react";
import { useState } from "react";

import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { createOnChangeHandler } from "./createOnChangeHandler";
import { createOnSubmitHandler } from "./createOnSubmitHandler";
import { messages } from "./messages";
import styles from "./shortcutsAddModal.module.scss";
import { FeaturedIcon } from "../FeaturedIcon";
import { Field } from "../Field";
import { Modal } from "../Modal";
import { ModalHeader } from "../ModalHeader";
import { StandardButton } from "../StandardButton";
import { TextInput } from "../TextInput";

const initialState: FormState = {
  input: { value: "", error: null },
  output: { value: "", error: null },
};

export type ShortcutsAddModalProps = {
  modalRef: RefObject<HTMLDialogElement>;
  validators: Validators;
  handleAdd: (partialShortcut: Omit<CustomShortcut, "id">) => void;
};

export const ShortcutsAddModal = ({
  modalRef,
  validators,
  handleAdd,
}: ShortcutsAddModalProps): ReactElement => {
  const [form, setForm] = useState<FormState>(initialState);
  const resetForm = (): void => setForm(initialState);

  const closeModal = (): void => modalRef.current?.close();

  const onValidSubmit = (partialShortcut: Omit<CustomShortcut, "id">): void => {
    handleAdd(partialShortcut);
    closeModal();
  };

  const getError = (field: keyof FormState): string => {
    const { value, error } = form[field];

    if (!error) {
      return "";
    }

    const message = messages.errors[error];
    return typeof message === "function" ? message(value) : message;
  };

  const hasError = (field: keyof FormState): boolean => {
    const { error } = form[field];

    return error !== null;
  };

  return (
    <Modal
      modalRef={modalRef}
      onClose={resetForm}
      className={createSelectorClassName("define-modal", "section")}
    >
      <ModalHeader
        style="compact"
        icon={
          <FeaturedIcon style="brand" weight="light" size="sm">
            <ArrowBendDoubleUpRight />
          </FeaturedIcon>
        }
        title={messages.addAShortcut}
        onClose={closeModal}
      />
      <form
        onSubmit={createOnSubmitHandler({
          form,
          setForm,
          validators,
          onValidSubmit,
          resetForm,
        })}
        className={styles.form}
      >
        <Field
          label={{ text: messages.input.label, visible: true }}
          explainer={null}
          error={
            hasError("input")
              ? {
                  text: getError("input"),
                  props: {
                    className: createSelectorClassName(
                      "input-error",
                      "element"
                    ),
                  },
                }
              : null
          }
          className={createSelectorClassName("input", "interactive")}
        >
          <TextInput
            size="md"
            inputProps={{
              name: "input",
              autofocus: "",
              placeholder: messages.input.placeholder,
              value: form.input.value,
              onChange: createOnChangeHandler({
                field: "input",
                setForm,
                validate: validators.input.onChange,
              }),
              "aria-invalid": hasError("input"),
            }}
          />
        </Field>
        <Field
          label={{ text: messages.output.label, visible: true }}
          explainer={null}
          error={
            hasError("output")
              ? {
                  text: getError("output"),
                  props: {
                    className: createSelectorClassName(
                      "output-error",
                      "element"
                    ),
                  },
                }
              : null
          }
          className={createSelectorClassName("output", "interactive")}
        >
          <TextInput
            size="md"
            inputProps={{
              name: "output",
              autofocus: "",
              placeholder: messages.output.placeholder,
              value: form.output.value,
              onChange: createOnChangeHandler({
                field: "output",
                setForm,
                validate: validators.output.onChange,
              }),
              "aria-invalid": hasError("output"),
            }}
          />
        </Field>
        <StandardButton
          colour="brand"
          hierarchy="primary"
          size="md"
          style="default"
          label={messages.submit.label}
          elementProps={{
            type: "submit",
            disabled: !(form.input.value && form.output.value),
            className: createSelectorClassName("submit", "interactive"),
          }}
        />
      </form>
    </Modal>
  );
};

import type { ReactElement, ReactNode } from "react";

import { classNames } from "@carescribe/utilities/src/classNames";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import styles from "./emptyPageHelp.module.scss";

export type EmptyPageHelpProps = {
  decoration: ReactNode;
  title: string;
  body: string;
  children?: ReactNode;
};

export const EmptyPageHelp = ({
  decoration,
  title,
  body,
  children,
}: EmptyPageHelpProps): ReactElement => (
  <div
    className={classNames(
      styles.container,
      createSelectorClassName("empty-page-help", "section")
    )}
  >
    {decoration && decoration}

    <div className={styles.text}>
      <h2
        className={classNames(
          styles.title,
          createSelectorClassName("title", "element")
        )}
      >
        {title}
      </h2>

      <p
        className={classNames(
          styles.body,
          createSelectorClassName("text", "element")
        )}
      >
        {body}
      </p>
    </div>

    <div>{children}</div>
  </div>
);

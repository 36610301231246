import type {
  KeyboardEventHandler,
  FocusEventHandler,
  MutableRefObject,
  CSSProperties,
} from "react";

import { useState, useRef } from "react";
import { v4 as uuid } from "uuid";

import { useKeepInViewport } from "@carescribe/ui/src/hooks/useKeepInViewport";

type UsePopoverReturn<ContainerElement> = {
  containerProps: {
    ref: MutableRefObject<ContainerElement | null>;
    tabIndex: number;
    onKeyDown: KeyboardEventHandler;
    onBlur: FocusEventHandler;
  };
  buttonProps: {
    "aria-expanded": boolean;
    "aria-controls": string;
    onClick: () => void;
  };
  popoverProps: {
    id: string;
    ref: MutableRefObject<HTMLDialogElement | null>;
    style: Pick<CSSProperties, "translate" | "visibility">;
    open: boolean;
  };
  isOpen: boolean;
};

/**
 * This hook makes it easy to control an element that is designed to be shown
 * and hidden by clicking or pressing a button.
 *
 * @returns
 *
 * `containerProps`
 * - `ref`: a reference to attach to the container element
 * - `tabIndex`: ensures compatibility with Safari
 *   - Safari ignores `onBlur` events on non-focusable elements. This means
 *     `tabIndex` must be set to either -1 or 0.
 * - `onKeyDown`: closes the popover when the `Escape` key is pressed
 * - `onBlur`: closes the popover when focus leaves the container
 *
 * `buttonProps`
 * - `aria-expanded`: indicates whether the popover is open
 * - `aria-controls`: the id of the popover element
 * - `onClick`: toggles the popover's visibility
 *
 * `popoverProps`
 * - `id`: a unique identifier for the popover element
 * - `ref`: a reference to attach to the popover dialog element
 * - `style`: contains visibility and the translate property (for positioning)
 * - `open`: a boolean indicating whether the popover is open
 *
 * `isOpen`: a boolean indicating whether the popover is open
 *
 * Note: while this hook enables functionality similar to that provided by the
 * Popover API, it does not make use of this API internally. This is due to
 * two reasons:
 * - challenges involved with positioning, which will mostly go away once CSS
 * anchoring becomes widely available.
 * - with the Popover API, opening one modal means the rest close, we don't
 * always want this behaviour (as is the case with smaller menus)
 *
 * @example
 * ```tsx
 * const { containerProps, buttonProps, popoverProps, isOpen } = usePopover();
 *
 * return (
 *   <ul {...containerProps}>
 *    <li>
 *     <button {...buttonProps}>Toggle Popover</button>
 *     <dialog {...popoverProps}>
 *       <ul>...</ul>
 *     </dialog>
 *    </li>
 *   </ul>
 * );
 * ```
 */
export const usePopover = <
  ContainerElement extends Element = HTMLElement
>(): UsePopoverReturn<ContainerElement> => {
  const id = useRef(uuid());
  const containerRef = useRef<ContainerElement>(null);
  const popoverRef = useRef<HTMLDialogElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  useKeepInViewport(popoverRef);

  const toggleShow = (): void => setIsOpen((isOpen) => !isOpen);

  const onKeyDown: KeyboardEventHandler = ({ key }) => {
    if (key === "Escape") {
      setIsOpen(false);
    }
  };

  const onBlur: FocusEventHandler = ({ relatedTarget }) => {
    const container = containerRef.current;
    const isFocusWithinContainer = container?.contains(relatedTarget);
    if (isFocusWithinContainer) {
      return;
    }
    setIsOpen(false);
  };

  const style: Pick<CSSProperties, "visibility"> = {
    visibility: isOpen ? "visible" : "hidden",
  };

  const containerProps = { ref: containerRef, tabIndex: -1, onKeyDown, onBlur };
  const popoverProps = { id: id.current, ref: popoverRef, style, open: isOpen };
  const buttonProps = {
    "aria-expanded": isOpen,
    "aria-controls": id.current,
    onClick: toggleShow,
  };

  return {
    containerProps,
    popoverProps,
    buttonProps,
    isOpen,
  };
};

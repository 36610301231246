import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, take, put } from "redux-saga/effects";

import { createDOMEventChannel } from "@carescribe/utilities/src/sagas";

import { requestTrackEvent } from "@talktype/analytics";

/**
 * Saga to track when the app is unloaded.
 *
 * Fires off a tracking event when:
 * - Browser tab/window closed or refreshed
 * - User navigates to a different page (external)
 */
export const trackAppUnloaded = function* (): SagaIterator<void> {
  const unloadChannel: SagaReturnType<typeof createDOMEventChannel> =
    yield call(createDOMEventChannel, window, "beforeunload");

  yield take(unloadChannel);

  yield put(requestTrackEvent({ name: "App Unloaded", data: null }));
};

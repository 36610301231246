import type { Void } from "@carescribe/types/src/Utility";

import { isEmpty, reject, isNil, complement } from "ramda";

export * from "ramda";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noOp: Void = () => {};

export const id = <T>(x: T): T => x;

export const headDef = <T>(values: T[], def: T): T =>
  values.length > 0 ? values[0] : def;

export const lastDef = <T>(values: T[], def: T): T =>
  values.length > 0 ? values[values.length - 1] : def;

export const indexDef = <T>(values: T[], index: number, def: T): T =>
  // eslint-disable-next-line no-undefined
  values.length > index && values[index] !== undefined ? values[index] : def;

/*
 * Handy Async versions of array iterator functions
 */
export const asyncFilter = async <Item>(
  values: Item[],
  predicate: (value: Item) => Promise<boolean>
): Promise<Item[]> =>
  // create an array of Promises using the async predicate
  Promise.all(values.map(predicate))
    // for each original value, check if it's true in the results array
    .then((results: boolean[]) => values.filter((_, index) => results[index]));

// TS still thinks an array might have null in it when you filter
// with a basic null check
export const filterableNotNull = <T>(value: T | null): value is T =>
  value !== null;

/*
 * Returns true when the given filterable object contains values that are not
 * null or undefined.
 *
 * @example
 * containsValues({}) => false
 * containsValues([]) => false
 * containsValues({foo: null}) => false
 * containsValues([null]) => false
 * containsValues({foo: 'bar'}) => true
 * containsValues([1]) => true
 */
export const containsValues = <T>(value: T | null): boolean =>
  !!value && !isEmpty(reject(isNil, value));

/**
 * Returns the first found non-null, non-undefined value from an arbitrary
 * number of arguments.
 */
export const findFirstNonNil = <T>(...possibleValues: T[]): T | null =>
  possibleValues.find(complement(isNil)) ?? null;

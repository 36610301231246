import type { DictationSessionResponse } from "../types/TranscriptionUrlResponse";

import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

export const validDictationSessionResponse = {
  url: P.string,
};

export const isDictationSessionResponse = createGuard<DictationSessionResponse>(
  validDictationSessionResponse
);

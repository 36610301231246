import { createSelector } from "@reduxjs/toolkit";

import { selectSystem } from "./selectSystem";

/**
 * Selects the active app from state.
 */
export const selectActiveApp = createSelector(
  selectSystem,
  (state) => state.activeApp
);

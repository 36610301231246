import type { PayloadAction } from "@reduxjs/toolkit";
import type { InteractionMethod } from "@talktype/analytics";
import type { Preferences, DrawerPage } from "@talktype/types";

type OpenDrawer = (
  state: Preferences,
  action: PayloadAction<{
    interactionMethod?: InteractionMethod;
    drawerPage: DrawerPage;
  }>
) => void;

/**
 * Handles the toggling of a drawer page.
 */
export const openDrawer: OpenDrawer = (state, { payload }): void => {
  state.drawer.page = payload.drawerPage;
  state.drawer.isOpen = true;
};

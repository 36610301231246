import { isUndefined } from "./isUndefined";
import { createLogger } from "./log";
import { isTest } from "./testing/isTest";

/**
 * Require Env
 *
 * Throws an error and logs a message when an env var is missing
 */
export const requireEnv = ({
  value,
  key,
  scope,
  testValue,
}: {
  value: string | undefined;
  key: string;
  scope?: string;
  testValue: string;
}): string => {
  if (isTest()) {
    return testValue;
  }

  // First attempt to access a value that is present on the current process.env.
  // This allows us to boot an environment from the command line and pass custom
  // env in. Useful for debugging production builds by enabling devtools, etc.
  const computedValue = process.env[key];

  if (computedValue) {
    return computedValue;
  }

  // If there's nothing in the current process.env that matches, return the
  // fallback value
  if (value) {
    return value;
  }

  // The fallback value didn't contain anything, and nor did the process.env, so
  // throw an error
  const message = `Missing required config: ${key}`;

  if (!isUndefined(scope)) {
    const { log } = createLogger(scope);
    log(message);
  }

  throw Error(message);
};

import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the always on top preference from state.
 */
export const selectAlwaysOnTop = createSelector(
  selectPreferences,
  (state) => state.alwaysOnTop
);

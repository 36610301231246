import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { convertLineBreaksToText } from "./convertLineBreakToText";
import { convertTextToLineBreaks } from "./convertTextToLineBreak";
import { removeZeroWidthSpacesFromPopulatedText } from "./removeZeroWidthSpacesFromPopulatedText";
import { splitLineBreaks } from "./splitLineBreaks";

export const setUpNormalizers = function* (): SagaIterator<void> {
  yield all(
    [
      removeZeroWidthSpacesFromPopulatedText,
      splitLineBreaks,
      convertTextToLineBreaks,
      convertLineBreaksToText,
    ].map(retry)
  );
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";

import { clamp } from "@carescribe/utilities/src/fp";

type SetSilenceTimeout = (
  state: Preferences,
  action: PayloadAction<number | "">
) => void;

/**
 * Handles the setting of silenceTimeout.
 */
export const setSilenceTimeout: SetSilenceTimeout = (state, { payload }) => {
  if (payload === "") {
    state.silenceTimeout = "";
    return;
  }

  state.silenceTimeout = {
    magnitude: clamp(0.1, 60, +payload),
    quantity: "time",
    unit: "minutes",
  };
};

import type { Stage } from "../../../types";

import { profanityRegex } from "../../../config/profanity";

export const profanity: Stage = ({ text, settings = {} }) => {
  if (!settings.filterProfanity) {
    return text;
  }

  return text.replace(profanityRegex, (word: string): string =>
    "*".repeat(word.length)
  );
};

import { prefixActionCreator } from "@carescribe/utilities/src/saga";

export const action = prefixActionCreator("saga/talktype-editor/results");

export const requestFinaliseText = action<void, "request-finalise-text">(
  "request-finalise-text"
);

export const finalisedText = action<void, "finalised-text">("finalised-text");

export const requestEditorPrecedingText = action<
  void,
  "request-preceding-text"
>("request-preceding-text");

export const broadcastEditorPrecedingText = action<
  string,
  "broadcast-preceding-text"
>("broadcast-preceding-text");

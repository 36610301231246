import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the dictation language setting from state.
 */
export const selectDictationLanguage = createSelector(
  selectPreferences,
  (state) => state.dictationLanguage
);

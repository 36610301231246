import type { Command } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { put, take } from "redux-saga/effects";

import {
  requestDeleteSelection,
  selectionDeleted,
} from "@talktype/editor/src/sagas/actions";

export const deleteSelection: Command = function* () {
  yield put(requestDeleteSelection());

  const { payload: success }: SagaReturnType<typeof selectionDeleted> =
    yield take(selectionDeleted);

  return success;
};

import type { Toast as ToastType } from "@talktype/types";
import type { ReactElement } from "react";

import { DictationToastInstance } from "@talktype/ui/src/DictationToastInstance";
import { FailedConnectionToast } from "@talktype/ui/src/FailedConnectionToast";
import { FailedMicrophoneAccessToast } from "@talktype/ui/src/FailedMicrophoneAccessToast";
import { InvalidInstallationToast } from "@talktype/ui/src/InvalidInstallationToast";
import { PoorConnectionToast } from "@talktype/ui/src/PoorConnectionToast";

import { UpdateAvailableToast } from "./UpdateAvailableToast";
import { UpdateErrorToast } from "./UpdateErrorToast";

export const Toast = (toast: ToastType): ReactElement => {
  switch (toast.type) {
    case "dictation":
      return <DictationToastInstance {...toast} />;
    case "poor_connection":
      return <PoorConnectionToast />;
    case "update_available":
      return <UpdateAvailableToast {...toast} />;
    case "update_error":
      return <UpdateErrorToast {...toast} />;
    case "invalid_installation":
      return <InvalidInstallationToast {...toast} />;
    case "failed_connection":
      return <FailedConnectionToast {...toast} />;
    case "failed_microphone_access":
      return <FailedMicrophoneAccessToast {...toast} />;
  }
};

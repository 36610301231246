import type { SagaIterator } from "redux-saga";

import { call, put } from "redux-saga/effects";

import { setTokens } from "../../../reducer";
import { logError } from "../../../utils/log";
import { pkceRequestFailed } from "../../actions";

export const handleUnknown = ({ data }: { data: unknown }) =>
  function* (): SagaIterator<void> {
    yield put(setTokens(null));
    yield put(pkceRequestFailed("token-format-error"));
    yield call(logError, "Invalid response from token request:", data);
  };

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { take, spawn } from "redux-saga/effects";

import { initSpa } from "@carescribe/sentry/src/initSpa";

import { setBuildId } from "@talktype/system/src/reducers/system";

import { SENTRY_DSN, SENTRY_ENV } from "../env";

export const configureSentry = function* (): SagaIterator<void> {
  const { payload: initialBuildId }: SagaReturnType<typeof setBuildId> =
    yield take(setBuildId);
  const buildId = initialBuildId ?? "";

  yield spawn(initSpa, {
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    initialScope: { tags: { buildId } },
  });
};

import type { TranscriptTextSegment } from "@talktype/types";

import { mergeRight } from "@carescribe/utilities/src/fp";

import { defaultLeafProperties } from "./entities/defaultLeafProperties";

export const textTranscriptSegment = (
  input?: Partial<TranscriptTextSegment> | string
): TranscriptTextSegment => {
  const inputIsString = typeof input === "string";

  return mergeRight(defaultLeafProperties, {
    type: "text",
    text: inputIsString ? input : "",
    inProgress: true,
    onInsert: null,
    ...(!inputIsString && input ? input : {}),
  });
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities/src/getIpc";

import { requestQuitTalkType } from "./actions";

/**
 * Set Up Quit TalkType
 *
 * Listens for the request to quit TalkType and closes the window.
 */
export const setUpQuitTalkType = function* (): SagaIterator<void> {
  yield takeEvery(requestQuitTalkType, function* () {
    const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

    if (!ipc?.system?.window?.close?.v1) {
      return;
    }

    yield call(ipc.system.window.close.v1);
  });
};

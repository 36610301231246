import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, take } from "redux-saga/effects";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { setBuildId } from "@talktype/system/src/reducers/system";
import { setUpUpdater } from "@talktype/updater";

import {
  AUTO_UPDATE_DISABLED,
  AUTO_UPDATE_DESKTOP_URL,
  AUTO_UPDATE_POLL_FREQUENCY_SECONDS,
} from "../env";
import { logWarning } from "../log";

const isDisabled = AUTO_UPDATE_DISABLED === "true";
const desktopUrl = AUTO_UPDATE_DESKTOP_URL;
const pollFrequency = toSeconds(parseInt(AUTO_UPDATE_POLL_FREQUENCY_SECONDS));

export const configureUpdater = function* (): SagaIterator<void> {
  if (isDisabled) {
    logWarning("Auto updates are disabled");
    return;
  }

  const { payload: initialBuildId }: SagaReturnType<typeof setBuildId> =
    yield take(setBuildId);
  const buildId = initialBuildId ?? "";

  const config = {
    desktop: { url: desktopUrl },
    browser: { buildId },
    pollFrequency,
  };

  yield call(setUpUpdater, config);
};

import type { TimingsState } from "./types";

export const initialState: TimingsState = {
  lastConnectionTimestamp: {
    magnitude: 0,
    quantity: "time",
    unit: "seconds",
  },
  lastDisconnectionTimestamp: {
    magnitude: 0,
    quantity: "time",
    unit: "seconds",
  },
  sumOfCompletedDictationDurations: {
    magnitude: 0,
    quantity: "time",
    unit: "seconds",
  },
};

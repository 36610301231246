import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { takeLatest, call } from "redux-saga/effects";

import { getCurrentSocket } from "@carescribe/transcriber-connection/src";

import { requestNewResult } from "./actions";

/**
 * Sends a finalisation request payload across the socket to force the
 * transcriber to finalise the current dictation.
 */
export const setUpFinalise = function* (): SagaIterator<void> {
  yield takeLatest(requestNewResult, function* () {
    const socket: SagaReturnType<typeof getCurrentSocket> = yield call(
      getCurrentSocket
    );

    if (socket && socket.readyState === WebSocket.OPEN) {
      yield call([socket, "send"], "finalise");
    }
  });
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, take } from "redux-saga/effects";

import { createDOMEventChannel } from "./createDOMEventChannel";

/**
 * Waits for the document to become visible.
 */
export const waitForDocumentToBeVisible = function* (): SagaIterator<void> {
  const visibilityChanged: SagaReturnType<typeof createDOMEventChannel> =
    yield call(createDOMEventChannel, document, "visibilitychange");

  while (true) {
    yield take(visibilityChanged);

    if (document.visibilityState === "visible") {
      yield call(visibilityChanged.close);
      break;
    }
  }
};

import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppMetadata, SystemState } from "@talktype/types";

type SetActiveApp = (
  state: SystemState,
  action: PayloadAction<AppMetadata>
) => void;

/**
 * Handles the setting of active app.
 */
export const setActiveApp: SetActiveApp = (state, { payload }) => {
  state.activeApp = payload;
};

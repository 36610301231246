import type { ReactElement } from "react";

export const LogoIcon = (): ReactElement => (
  <svg
    width="83"
    height="18"
    viewBox="0 0 83 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1657_204"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="83"
      height="18"
    >
      <path d="M82.0976 0H0V18H82.0976V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_1657_204)">
      <path
        d="M11.8707 2.20612H0.191498V5.04744H4.42961V15.4262H7.6326V5.04744H11.8707V2.20612Z"
        fill="currentColor"
      />
      <path
        d="M18.1325 5.52103V6.31026C17.3318 5.58021 16.3162 5.22504 15.0858 5.22504C13.7577 5.22504 12.6249 5.71832 11.6875 6.7049C10.7695 7.69143 10.3008 8.95424 10.3008 10.4736C10.3008 12.0127 10.7695 13.2755 11.6875 14.262C12.6249 15.2289 13.7577 15.7222 15.0858 15.7222C16.3162 15.7222 17.3318 15.367 18.1325 14.6369V15.4262H21.2574V5.52103H18.1325ZM15.7303 13.0387C14.3436 13.0387 13.3671 11.9929 13.3671 10.4736C13.3671 8.974 14.3436 7.90853 15.7303 7.90853C16.3748 7.90853 16.9412 8.1453 17.4099 8.63858C17.8982 9.13187 18.1325 9.74352 18.1325 10.4736C18.1325 11.2234 17.8982 11.8351 17.4099 12.3284C16.9412 12.8019 16.3748 13.0387 15.7303 13.0387Z"
        fill="currentColor"
      />
      <path
        d="M25.7604 1.21954H22.6355V15.4262H25.7604V1.21954Z"
        fill="currentColor"
      />
      <path
        d="M33.2868 10.2368L37.5836 5.52102H33.8141L30.7088 8.89509H30.2791V1.21954H27.1543V15.4262H30.2791V11.8548H30.8455L34.029 15.4262H38.0327L33.2868 10.2368Z"
        fill="currentColor"
      />
      <path
        d="M49.171 2.20612H37.4918V5.04744H41.7299V15.4262H44.9331V5.04744H49.171V2.20612Z"
        fill="currentColor"
      />
      <path
        d="M56.6529 5.50131L54.1729 11.2826L51.8482 5.50131H48.4893L52.552 15.0908L50.9697 18.7806H54.3485L60.0123 5.50131H56.6529Z"
        fill="currentColor"
      />
      <path
        d="M66.5652 5.22504C65.3346 5.22504 64.3191 5.58021 63.5183 6.31026V5.52103H60.3934V18.7805H63.5183V14.6369C64.3191 15.367 65.3346 15.7222 66.5652 15.7222C67.8932 15.7222 69.0263 15.2289 69.9439 14.262C70.8812 13.2755 71.3501 12.0127 71.3501 10.4736C71.3501 8.95424 70.8812 7.69143 69.9439 6.7049C69.0263 5.71832 67.8932 5.22504 66.5652 5.22504ZM65.9207 13.0387C65.2762 13.0387 64.7099 12.8019 64.2217 12.3284C63.7528 11.8351 63.5183 11.2234 63.5183 10.4736C63.5183 9.74352 63.7528 9.13187 64.2217 8.63858C64.7099 8.14529 65.2762 7.90853 65.9207 7.90853C67.3076 7.90853 68.2835 8.974 68.2835 10.4736C68.2835 11.9929 67.3076 13.0387 65.9207 13.0387Z"
        fill="currentColor"
      />
      <path
        d="M81.6928 10.4736C81.6735 8.89511 81.1655 7.6323 80.2084 6.66543C79.2514 5.69857 78.0598 5.22504 76.6343 5.22504C75.1693 5.22504 73.9392 5.71832 72.943 6.7049C71.9469 7.69143 71.4394 8.95424 71.4394 10.4736C71.4394 12.0127 71.9469 13.2755 72.943 14.262C73.9392 15.2289 75.1693 15.7222 76.615 15.7222C78.9001 15.7222 80.6382 14.6764 81.4781 12.6243L78.7048 12.0521C78.2943 12.8217 77.591 13.1965 76.615 13.1965C75.5798 13.1965 74.7593 12.4664 74.5055 11.2826H81.6928V10.4736ZM76.6343 7.79012C77.5717 7.79012 78.314 8.32288 78.6069 9.34888H74.5639C74.8572 8.38206 75.7168 7.79012 76.6343 7.79012Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

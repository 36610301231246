import type { ColourScheme } from "@talktype/types/src/ColourScheme";
import type { SagaIterator } from "redux-saga";

import { call, takeEvery, select } from "redux-saga/effects";

import {
  selectColourScheme,
  setColourScheme,
  setPreferences,
} from "@talktype/preferences";
import { replaceState } from "@talktype/store/src/actions";

export function* updateBodyColour(): SagaIterator<void> {
  const colourScheme: ColourScheme = yield select(selectColourScheme);
  document.body.className = colourScheme;
}

const actionsWhichImpactBodyColour = [
  setColourScheme,
  setPreferences,
  replaceState,
];

/**
 * Manages the application's colour scheme via the `<body>` element.
 *
 * User theme preference:
 *  - auto: apply light or dark colour scheme based on system preference
 *  - light: apply light colour scheme
 *  - dark: apply dark colour scheme
 *
 * A class is applied to the `<body>` element, which allows CSS variables to
 * be updated dynamically and child elements to be styled accordingly.
 */
export const manageBodyColour = function* (): SagaIterator<void> {
  yield call(updateBodyColour);

  yield takeEvery(actionsWhichImpactBodyColour, function* () {
    yield call(updateBodyColour);
  });
};

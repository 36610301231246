import type { ReactElement, ReactNode } from "react";

import { X } from "@phosphor-icons/react";

import { messages } from "./messages";
import styles from "./modalHeader.module.scss";
import { StandardButton } from "../StandardButton";

const headerStyles = {
  compact: styles.compactHeader,
  standard: styles.standardHeader,
};

type StandardHeader = {
  style: "standard";
  icon: null;
};

type CompactHeader = {
  style: "compact";
  icon: ReactNode;
};

export type ModalHeaderProps = (StandardHeader | CompactHeader) & {
  title: string;
  onClose: () => void;
};

export const ModalHeader = ({
  style,
  icon,
  title,
  onClose,
}: ModalHeaderProps): ReactElement => (
  <header className={headerStyles[style]}>
    {icon}

    <h2 className={styles.title}>{title}</h2>

    <StandardButton
      colour="neutral"
      hierarchy="link"
      size="sm"
      style="icon-only"
      label={messages.close}
      mainIcon={<X />}
      elementProps={{ onClick: onClose, className: styles.closeIcon }}
    />
  </header>
);

import type { SagaIterator } from "redux-saga";

import { put, call } from "redux-saga/effects";

import { setBuildId } from "../reducers/system";
import { logError } from "../utils/log";

export const getBuildIdFromDOM = function* (): SagaIterator<void> {
  const buildIdMetaTag = document.querySelector('meta[name="build-id"]');

  const buildId = buildIdMetaTag?.getAttribute("content") ?? null;

  if (!buildId) {
    yield call(logError, "Build ID not found in DOM.");
  }

  yield put(setBuildId(buildId));
};

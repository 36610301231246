import { joinStringsForRegExp } from "./joinStringsForRegExp";

type Config = {
  exclude: string[];
};

/**
 * Converts a string to a string of stars.
 *
 * @example
 * convertTextToStars("Hello world!");
 * // "***** *****!"
 *
 * convertTextToStars(
 *   "Lorem ipsum dolor sit amet.",
 *   { exclude: ["sit", "ipsum"] }
 * );
 * // "***** ipsum ***** sit ****."
 *
 * convertTextToStars(
 *   "Lorem ipsum dolor sit amet.",
 *   { exclude: ["ipsum dolor"] }
 * );
 * // "***** ipsum dolor *** ****."
 */
export const convertTextToStars = (
  text: string,
  config: Config = { exclude: [] }
): string =>
  text
    .split(new RegExp(`(${joinStringsForRegExp(config.exclude)})`, "i"))
    .map((part) =>
      config.exclude.includes(part.toLowerCase())
        ? part
        : part.replace(/\S/g, "*")
    )
    .join("");

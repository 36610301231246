import { joinStringsForRegExp } from "@carescribe/utilities/src/joinStringsForRegExp";

export const profanity = [
  "arse",
  "arsehole",
  "arseholes",
  "arses",
  "ass",
  "asses",
  "asshole",
  "assholes",
  "ball sack",
  "ball sacks",
  "ballsack",
  "ballsacks",
  "bastard",
  "bastardo",
  "bastardos",
  "bastards",
  "bastinado",
  "batties",
  "batty",
  "bitch",
  "bitched",
  "bitches",
  "bitching",
  "blow job",
  "blow jobs",
  "blowjob",
  "blowjobs",
  "bollock",
  "bollocked",
  "bollocking",
  "bollocks",
  "boner",
  "boners",
  "bonkers",
  "boob",
  "boobs",
  "bugger",
  "buggered",
  "buggering",
  "buggers",
  "buggery",
  "bukake",
  "bukkake",
  "bullshit",
  "bullshits",
  "bullshiting",
  "bullshitted",
  "bullshitting",
  "butt",
  "buttcheek",
  "buttcheeks",
  "butthole",
  "buttholes",
  "butts",
  "camel toe",
  "camel toes",
  "cameltoe",
  "cameltoes",
  "cam slut",
  "cam sluts",
  "camslut",
  "camsluts",
  "cam whore",
  "cam whores",
  "camwhore",
  "camwhores",
  "circle jerk",
  "circle jerked",
  "circle jerking",
  "circle jerks",
  "circlejerk",
  "circlejerked",
  "circlejerking",
  "circlejerks",
  "clit",
  "clits",
  "cluster fuck",
  "cluster fucks",
  "clusterfuck",
  "clusterfucks",
  "cock",
  "cocks",
  "coon",
  "coons",
  "corn hole",
  "corn holes",
  "cornhole",
  "cornholes",
  "crap",
  "crapped",
  "crapping",
  "craps",
  "cretin",
  "cretins",
  "cripple",
  "cripples",
  "cum",
  "cummed",
  "cumming",
  "cums",
  "cunt",
  "cunts",
  "derp",
  "derps",
  "dick",
  "dick head",
  "dick heads",
  "dickhead",
  "dickheads",
  "dicks",
  "dildo",
  "dildoes",
  "dildos",
  "downey",
  "downie",
  "downies",
  "downy",
  "dyke",
  "dykes",
  "fag",
  "faggot",
  "faggots",
  "fags",
  "fatherfucker",
  "fatherfuckering",
  "fatherfuckers",
  "feck",
  "fecked",
  "fecking",
  "fecks",
  "felch",
  "felched",
  "felches",
  "felching",
  "fellate",
  "fellated",
  "fellates",
  "fellating",
  "fellatio",
  "finger bang",
  "finger banged",
  "finger banging",
  "finger bangs",
  "finger-bang",
  "finger-banged",
  "finger-banging",
  "finger-bangs",
  "fingerbang",
  "fingerbanged",
  "fingerbanging",
  "fingerbangs",
  "fingered",
  "fingering",
  "fisted",
  "fisting",
  "fuck",
  "fucked",
  "fucker",
  "fuckers",
  "fuckin",
  "fucking",
  "fucks",
  "fucksake",
  "fucksakes",
  "fucktard",
  "fucktards",
  "gang bang",
  "gang banged",
  "gang banging",
  "gang bangs",
  "gangbang",
  "gangbanged",
  "gangbanging",
  "gangbangs",
  "gimp",
  "gimps",
  "hand job",
  "hand jobs",
  "handjob",
  "handjobs",
  "horny",
  "jizz",
  "jizzed",
  "jizzes",
  "jizzing",
  "junkie",
  "junkies",
  "knob head",
  "knob heads",
  "knobbed",
  "knobbing",
  "knobhead",
  "knobheads",
  "kunt",
  "kuntz",
  "loon",
  "looney",
  "loonie",
  "loonies",
  "loons",
  "loony",
  "midget",
  "midgets",
  "milf",
  "milfs",
  "minge",
  "minges",
  "mong",
  "mongs",
  "motherfuck",
  "motherfucks",
  "motherfucker",
  "motherfuckers",
  "motherfucking",
  "munter",
  "munters",
  "nig nog",
  "nig nogs",
  "nigga",
  "niggas",
  "nigger",
  "niggers",
  "nutter",
  "nutters",
  "paki",
  "pakis",
  "pigfucker",
  "pigfuckers",
  "pigfucking",
  "piss",
  "pissed",
  "pisses",
  "pissing",
  "poof",
  "poofs",
  "poon",
  "poons",
  "pube",
  "pubes",
  "punani",
  "punanies",
  "punanis",
  "punany",
  "pussied",
  "pussies",
  "pussy",
  "pussying",
  "queef",
  "queefed",
  "queefing",
  "queefs",
  "retard",
  "retarded",
  "retards",
  "rim job",
  "rim jobs",
  "rimjob",
  "rimjobs",
  "rimming",
  "schizo",
  "schizos",
  "shat",
  "shemale",
  "shemales",
  "shit",
  "shits",
  "shitted",
  "shitting",
  "shitty",
  "sideboob",
  "sideboobs",
  "slut",
  "sluts",
  "slutty",
  "spacca",
  "spaccas",
  "spacker",
  "spackers",
  "spakka",
  "spakkas",
  "spastic",
  "spastics",
  "spaz",
  "spazzes",
  "spazzy",
  "spic",
  "spick",
  "spicks",
  "spics",
  "tard",
  "tards",
  "tit",
  "tits",
  "titties",
  "titty",
  "tosser",
  "tossers",
  "trannies",
  "tranny",
  "twat",
  "twats",
  "twink",
  "twinkie",
  "twinkies",
  "twinks",
  "underboob",
  "underboobs",
  "wacko",
  "wackoes",
  "wackos",
  "wank",
  "wanked",
  "wanker",
  "wankers",
  "wanking",
  "wanks",
  "wankstain",
  "wankstains",
  "wanky",
  "whore",
  "whored",
  "whores",
  "whoring",
  "window licker",
  "window lickers",
  "windowlicker",
  "windowlickers",
];

export const profanityRegex = new RegExp(
  `\\b(${joinStringsForRegExp(profanity)})\\b`,
  "gi"
);

/**
 * Generate Code Challenge
 *
 * Generate a code challenge from a code verifier. A code challenge is a
 * base64url-encoded SHA-256 hash of the code verifier with the following
 * modifications:
 * - Remove any padding (`=`)
 * - Replace any `+` with `-`
 * - Replace any `/` with `_`
 *
 * More information:
 * {@link https://www.oauth.com/oauth2-servers/pkce/authorization-request/}
 */
export const generateCodeChallenge = async (
  codeVerifier: string
): Promise<string> => {
  const digest = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(codeVerifier)
  );

  return btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

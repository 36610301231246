import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { setFilterProfanity } from "../reducers";

export const trackSetFilterProfanity = function* (): SagaIterator<void> {
  yield takeEvery(setFilterProfanity, function* ({ payload }) {
    yield put(
      requestTrackEvent({
        name: "Preferences Updated",
        data: {
          preferencesProperty: "filter profanity",
          filterProfanity: payload,
        },
      })
    );
  });
};

import type { DocumentFontFamily } from "@talktype/types";
import type { ReactElement } from "react";

import { TextAa } from "@phosphor-icons/react";

import { usePopover } from "@carescribe/ui";
import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

import { FontFamilySettings } from "./FontFamilySettings";
import styles from "./fontSettings.module.scss";
import { FontSizeSettings } from "./FontSizeSettings";
import { messages } from "./messages";
import { ToggleableButton } from "../../ToggleableButton";

export type FontSettingsProps = {
  fontSize: number;
  onChangeFontSize: (increment: number) => void;
  fontFamily: DocumentFontFamily;
  onChangeFontFamily: (fontFamily: DocumentFontFamily) => void;
};

export const FontSettings = ({
  fontSize,
  onChangeFontSize,
  fontFamily,
  onChangeFontFamily,
}: FontSettingsProps): ReactElement => {
  const { containerProps, buttonProps, popoverProps, isOpen } =
    usePopover<HTMLDivElement>();

  return (
    <div {...containerProps} className={styles.container}>
      <ToggleableButton
        tooltipProps={{
          messages: { visible: messages },
          placement: "bottom",
          disabled: isOpen,
        }}
        buttonProps={{
          Icon: TextAa,
          active: isOpen,
          disabled: false,
          highlight: false,
          className: createSelectorClassName("font", "interactive"),
          ...buttonProps,
        }}
      />

      <dialog {...popoverProps} className={styles.menu}>
        <FontSizeSettings fontSize={fontSize} onChange={onChangeFontSize} />

        <hr className={styles.spacer} />

        <FontFamilySettings
          chosenFontFamily={fontFamily}
          onChange={onChangeFontFamily}
        />
      </dialog>
    </div>
  );
};

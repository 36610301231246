import { useState, useEffect } from "react";

/**
 * Subscribes to the prefers-reduced-motion media query and returns the current
 * value.
 */
export const usePrefersReducedMotion = (): boolean => {
  const motionQuery = "(prefers-reduced-motion: reduce)";
  const mediaQueryList = window.matchMedia(motionQuery);

  const [prefersReducedMotion, setPrefersReducedMotion] = useState(
    mediaQueryList.matches
  );

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent): void =>
      setPrefersReducedMotion(event.matches);

    mediaQueryList.addEventListener("change", onChange);

    return () => mediaQueryList.removeEventListener("change", onChange);
  }, [mediaQueryList]);

  return prefersReducedMotion;
};

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { notifyDesktopOnChange } from "./notifyDesktopOnChange";
import { reconnectOnPreferencesChange } from "./reconnectOnPreferencesChange";
import { setUpCustomShortcuts } from "./setUpCustomShortcuts";
import { setUpCustomWords } from "./setUpCustomWords";
import { setUpLegacyV2PreferencesMigration } from "./setUpLegacyV2PreferencesMigration";
import { trackSetAlwaysOnTop } from "./trackSetAlwaysOnTop";
import { trackSetColourScheme } from "./trackSetColourScheme";
import { trackSetDateFormat } from "./trackSetDateFormat";
import { trackSetDictationLanguage } from "./trackSetDictationLanguage";
import { trackSetDocumentFontFamily } from "./trackSetDocumentFontFamily";
import { trackSetDocumentFontSize } from "./trackSetDocumentFontSize";
import { trackSetDrawerPage } from "./trackSetDrawerPage";
import { trackSetFilterProfanity } from "./trackSetFilterProfanity";
import { trackSetNumberFormat } from "./trackSetNumberFormat";

export const setUpPreferences = function* (): SagaIterator<void> {
  yield all(
    [
      notifyDesktopOnChange,
      reconnectOnPreferencesChange,
      setUpCustomShortcuts,
      setUpCustomWords,
      setUpLegacyV2PreferencesMigration,
      trackSetAlwaysOnTop,
      trackSetColourScheme,
      trackSetDateFormat,
      trackSetDictationLanguage,
      trackSetDocumentFontFamily,
      trackSetDocumentFontSize,
      trackSetDrawerPage,
      trackSetFilterProfanity,
      trackSetNumberFormat,
    ].map(retry)
  );
};

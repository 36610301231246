export const messages = {
  title: "TalkType",

  loggedOut: {
    title: "Lightning-fast dictation, anywhere!",
    cta: "Login to TalkType",
    fallback: "Activate a licence key",
    explainer: [
      "We’ll open your web browser to login/register",
      "and then bring you back to the app.",
    ],
  },

  migrating: {
    title: "Dictation just got better!",
    subtitle:
      "Link your existing TalkType licence to an account to start using your new and improved dictation experience",
    cta: "Activate TalkType",
    fallback: "I've already done this",
    explainer: [
      "We’ll open your web browser to activate your upgrade",
      "and then bring you back to the app.",
    ],
  },
};

import type { ReactElement } from "react";

export const WindowsUnmaximiseIcon = (): ReactElement => (
  <svg
    width="46"
    height="38"
    viewBox="0 0 46 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-2-inside-1_3281_33" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 13C20.8954 13 20 13.8954 20 15L25 15C26.1046 15 27 15.8954 27 17V22C28.1046 22 29 21.1046 29 20V16C29 14.3431 27.6569 13 26 13H22Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 13C20.8954 13 20 13.8954 20 15L25 15C26.1046 15 27 15.8954 27 17V22C28.1046 22 29 21.1046 29 20V16C29 14.3431 27.6569 13 26 13H22Z"
      fill="currentcolor"
    />
    <path
      d="M20 15H18V17L20 17L20 15ZM25 15V17V15ZM27 22H25V24H27V22ZM22 15V15V11C19.7909 11 18 12.7909 18 15H22ZM20 17L25 17V13L20 13L20 17ZM25 17H29C29 14.7909 27.2091 13 25 13V17ZM25 17V22H29V17H25ZM27 20V24C29.2091 24 31 22.2091 31 20H27ZM27 16V20H31V16H27ZM26 15C26.5523 15 27 15.4477 27 16H31C31 13.2386 28.7614 11 26 11V15ZM22 15H26V11H22V15Z"
      fill="currentcolor"
      mask="url(#path-2-inside-1_3281_33)"
    />
    <rect
      x="18"
      y="17"
      width="7"
      height="7"
      rx="1"
      stroke="currentcolor"
      strokeWidth="2"
    />
  </svg>
);

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { manageNativeInstallPrompt } from "./manageNativeInstallPrompt";
import { trackBeforeInstallPromptEvent } from "./trackBeforeInstallPromptEvent";

export const setUpNativeInstallPrompt = function* (): SagaIterator<void> {
  yield all(
    [trackBeforeInstallPromptEvent, manageNativeInstallPrompt].map(retry)
  );
};

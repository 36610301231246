import { createSelectorClassName } from "@carescribe/utilities/src/createSelectorClassName";

const editorBottomGradientHeight = 32;
const editorTopGradientHeight = 16;

/**
 * Scroll Cursor into View
 *
 * Scroll the page to a position that maintains the cursor within the visible
 * area of the editor. This is needed because there are gradients and other
 * elements that partially obscure the borders of the editor, which are
 * interaction dead zones. If the cursor is behind one we need to scroll it
 * into a visible position.
 */
export const scrollCursorIntoView = (): boolean => {
  const browserSelection = document.getSelection();

  // Get the controls component, which takes up height at the bottom of the
  // editor, behind which the cursor should not appear
  const footer = document.querySelector(
    `.${createSelectorClassName("dictation-footer", "section")}`
  );

  if (!browserSelection || !footer || browserSelection.rangeCount === 0) {
    return false;
  }

  // Find out how far from the bottom of the screen the cursor can appear
  const footerHeight =
    footer.getBoundingClientRect().height + editorBottomGradientHeight;

  const currentRange = browserSelection.getRangeAt(0);

  // Make a copy of the current range and collapse it to its focus point
  const clonedRange = currentRange.cloneRange();
  const rangeIsReversed =
    browserSelection.anchorOffset > browserSelection.focusOffset;
  clonedRange.collapse(rangeIsReversed);

  // Find out if the cursor's position goes out of bounds
  const cursorPosition = clonedRange.getBoundingClientRect();
  const windowHeight = window.visualViewport?.height ?? window.innerHeight;
  const editorBottom = windowHeight - footerHeight;
  const editorTop = editorTopGradientHeight;
  const toScrollDown = cursorPosition.bottom - editorBottom;
  const toScrollUp = cursorPosition.top - editorTop;
  const offset = cursorPosition.top < editorTop ? toScrollUp : toScrollDown;
  const withinBounds = offset <= 0 && cursorPosition.top >= editorTop;

  // If the cursor is in a valid position, take no action
  if (withinBounds) {
    return false;
  }

  // Determine animation behaviour
  const motionQuery = "(prefers-reduced-motion: reduce)";
  const prefersReducedMotion = window.matchMedia(motionQuery).matches;
  const behavior = prefersReducedMotion ? "auto" : "smooth";

  // Scroll to a position that places the cursor within bounds
  window.scrollBy({ left: 0, top: offset, behavior });

  return true;
};

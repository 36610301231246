import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, takeEvery } from "redux-saga/effects";

import { getDictateToAppPrecedingText } from "@talktype/dictate-to-app/src/sagas/getDictateToAppPrecedingText";
import { getEditorPrecedingText } from "@talktype/editor/src/sagas/results/getEditorPrecedingText";

import { broadcastPrecedingText, requestPrecedingText } from "../actions";

/**
 * Manage Preceding Text
 *
 * Manages the broadcasting of preceding text. This comes from:
 * - The editor when the target app is TalkType
 * - Internal state based on previous result when the target app is not TalkType
 */
export const managePrecedingText = function* (): SagaIterator<void> {
  yield takeEvery(requestPrecedingText, function* ({ payload: result }) {
    const precedingText: SagaReturnType<
      typeof getEditorPrecedingText | typeof getDictateToAppPrecedingText
    > = result.targetApp.isSelf
      ? yield call(getEditorPrecedingText)
      : yield call(getDictateToAppPrecedingText, result);

    yield put(broadcastPrecedingText(precedingText));
  });
};

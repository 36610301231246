import type { Marks } from "@talktype/types";
import type { Editor } from "slate";

import { setMark } from "@carescribe/slate/src/utils/setMark";
import { entries } from "@carescribe/utilities/src/typedObject";

/**
 * Given a marks object, sets a mark at the current selection.
 *
 * @example
 * // To set bold as on
 * setMark(editor, 'bold', { bold: false, italic: false })
 */
export const setMarks = (editor: Editor, marks: Marks): void =>
  entries(marks).forEach(([mark, value]) =>
    setMark(editor, mark as keyof Marks, value)
  );

import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the colour scheme from state.
 */
export const selectColourScheme = createSelector(
  selectPreferences,
  (state) => state.colourScheme
);

import type { PayloadAction } from "@reduxjs/toolkit";
import type { Preferences } from "@talktype/types";
import type { DocumentFontFamily } from "@talktype/types/src/DocumentFontFamily";

type SetDocumentFontFamily = (
  state: Preferences,
  action: PayloadAction<DocumentFontFamily>
) => void;

/**
 * Handles the setting of document font family.
 */
export const setDocumentFontFamily: SetDocumentFontFamily = (
  state,
  { payload }
) => {
  state.document.fontFamily = payload;
};

import type { SagaIterator } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { toSeconds } from "@carescribe/utilities/src/timing";

import { addToast, dismissToast } from "@talktype/toasts/src/sagas/actions";

import { poorConnectionDetected, connectionRestored } from "./actions";

const POOR_DICTATION_TOAST_ID = "poor_dictation";

/**
 * This saga listens for transcriber related events and in response
 * dispatches the appropriate notifications.
 *
 * - "Poor internet connection" notification:
 *   - Displayed when poor connectivity detected
 *   - Dismissed when the connection is restored
 */
export const manageNotifications = function* (): SagaIterator<void> {
  yield takeEvery(poorConnectionDetected, function* () {
    yield put(
      addToast({
        id: POOR_DICTATION_TOAST_ID,
        type: "poor_connection",
        order: 0,
        dismissConfig: { after: null, notBefore: toSeconds(5) },
      })
    );
  });

  yield takeEvery(connectionRestored, function* () {
    yield put(dismissToast(POOR_DICTATION_TOAST_ID));
  });
};

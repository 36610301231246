import type { Reshaper } from "./types/Reshaper";

import { isLegacyNumberFormat } from "../../../../guards";

/**
 * Reshape Number Format
 *
 * Convert legacy number format and copy into preferences.
 */
export const reshapeNumberFormat: Reshaper =
  (legacyPreferences) => (newPreferences) =>
    !isLegacyNumberFormat(legacyPreferences.number_format)
      ? newPreferences
      : {
          ...newPreferences,
          numberFormat:
            legacyPreferences.number_format === "As numerals"
              ? "numerals"
              : "automatic",
        };

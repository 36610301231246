import type { ReactElement } from "react";

import { Editor as Component } from "./Editor";
import { ErrorBoundary } from "./ErrorBoundary";

export const Editor = (): ReactElement => (
  <ErrorBoundary>
    <Component />
  </ErrorBoundary>
);

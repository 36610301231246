import type { DownloadUpdate } from "../types/DownloadUpdate";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { put, takeEvery, call } from "redux-saga/effects";

import {
  updateReadyToDownload,
  requestDownloadUpdate,
  updateReadyToInstall,
  updateError,
} from "./actions";

/**
 * Downloads desktop updates when:
 * - the `updateReadyToDownload` action is dispatched
 * - the `requestDownloadUpdate` action is dispatched
 *
 *
 * If there is an error, dispatches `updateError` with `step` of
 * `download`.
 */
export const manageDownloadUpdate = function* ({
  desktop,
}: {
  desktop: { downloadUpdate: DownloadUpdate };
}): SagaIterator<void> {
  let isDownloading = false;

  yield takeEvery([requestDownloadUpdate, updateReadyToDownload], function* () {
    try {
      /**
       * Ignore requests when a download is already in progress.
       * May otherwise lead to weird errors (e.g. file missing).
       */
      if (isDownloading) {
        return;
      }

      isDownloading = true;

      const [, error]: SagaReturnType<typeof desktop.downloadUpdate> =
        yield call(desktop.downloadUpdate);

      if (error) {
        yield put(updateError({ type: "desktop", step: "download" }));
        return;
      }

      yield put(updateReadyToInstall({ type: "desktop" }));
    } finally {
      isDownloading = false;
    }
  });
};

import type { PayloadActionCreator } from "@reduxjs/toolkit";

import { isAction } from "@reduxjs/toolkit";

/**
 * Checks if a given action type matches a given action creator type.
 *
 * @example
 * const action = { type: 'ADD_TODO', payload: 'Learn TypeScript' };
 *
 * const result = isActionOfType(action, addedToDo); // true
 */
export const isActionOfType = <T>(
  action: unknown,
  match: PayloadActionCreator<T>
): action is ReturnType<PayloadActionCreator<T>> =>
  isAction(action) && action.type === match.type;

import { isNotNull } from "@carescribe/utilities/src/guards/isNotNull";

import { descendingMagnitudes } from "./dictionary";
import { getThreeDigitValue } from "./getThreeDigitValue";
import { pattern } from "./pattern";

/**
 * Words to Numbers
 *
 * Replace spelt out numbers in a string with their numerical value.
 *
 * @example
 * wordsToNumbers("lorem one hundred and twenty three ipsum")
 * // "lorem 123 ipsum"
 */
export const wordsToNumbers = (input: string): string =>
  input.replace(new RegExp(pattern, "gi"), (target, ...matches) => {
    const groups = matches.at(-1);

    const values = descendingMagnitudes
      .map(([name]) => {
        return getThreeDigitValue({
          hundred: groups[`hundred${name}s`]?.toLowerCase(),
          ten: groups[`ten${name}s`]?.toLowerCase(),
          teen: groups[`teen${name}s`]?.toLowerCase(),
          digit: groups[`digit${name}s`]?.toLowerCase(),
          magnitude: groups[`magnitude${name}`]?.toLowerCase(),
          hundredWord: groups.hundredword?.toLowerCase(),
        });
      })
      .filter(isNotNull);

    if (!values.length) {
      return target;
    }

    const trailingSpaces = target.match(/\s*$/)?.at(0) ?? "";

    const total = values.reduce((a, b) => a + b.number, 0).toString();

    const suffix = values.at(-1)?.suffix ?? "";

    return total + suffix + trailingSpaces;
  });

import type { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";

import { setUpTranscriberSocket } from "@carescribe/transcriber-connection/src";
import { retry } from "@carescribe/utilities/src/sagas/utils/retry";

import { listenForMessage } from "./listenForMessage";
import { manageNotifications } from "./manageNotifications";
import { monitorConnection } from "./monitorConnection";
import { setUpAutoReconnect } from "./setUpAutoReconnect";
import { setUpFinalise } from "./setUpFinalise";

export const setUpConnection = function* (): SagaIterator<void> {
  yield all(
    [
      listenForMessage,
      manageNotifications,
      setUpAutoReconnect,
      setUpFinalise,
      monitorConnection,
      setUpTranscriberSocket,
    ].map(retry)
  );
};

import { createSelector } from "@reduxjs/toolkit";

import { selectDrawer } from "./selectDrawer";

/**
 * Indicates the drawer page from state.
 */
export const selectDrawerPage = createSelector(
  selectDrawer,
  (drawer) => drawer.page
);

export const messages = {
  editShortcut: "Edit shortcut",
  submit: { label: "Save changes" },
  input: { label: "Add a trigger term", placeholder: "email" },
  output: { label: "Replace with…", placeholder: "jonny@talktype.com" },
  delete: { label: "Delete shortcut" },
  errors: {
    FIELD_REQUIRED: "Field is required",
    DUPLICATE_ENTRY: (input: string): string =>
      `‘${input}’ already exists as a custom word`,
    PROHIBIT_RESERVED_KEYWORDS:
      "This word is reserved for use by TalkType’s voice commands",
  },
};

import type { InternalRetryConfig } from "./types/InternalRetryConfig";
import type { Seconds } from "@carescribe/types";

import { secondsToMilliseconds } from "../../../timing";

/**
 * Get Next Retry Config
 *
 * - Calculate based on the elapsed time whether the retry count should be
 *   decremented or reset to the original
 * - Set the start time of next spawned run of the saga
 */
export const getNextRetryConfig = (
  startingConfig: InternalRetryConfig,
  currentConfig: InternalRetryConfig,
  resetAfter: Seconds
): InternalRetryConfig => {
  // Figure out whether the retry count should be reset to the original
  // based on whether it has been a while since the last error. This is
  // to prevent users with long running sessions from bumping into the
  // retry limit when in fact they only have intermittent errors.
  const now = +new Date();
  const { startTime } = currentConfig;
  const reset = now - startTime > secondsToMilliseconds(resetAfter);

  // Reset to original if it's been a while since last retry, otherwise
  // decrement the retry count so the loop isn't infinite
  const newRetries = reset ? startingConfig.retries : currentConfig.retries - 1;

  return {
    ...currentConfig,
    retries: newRetries,
    startTime: now,
  };
};

import type { Range, Editor } from "slate";

import { escapeStringRegexp } from "@carescribe/utilities/src/escapeStringRegexp";

import { safeAfter } from "../safeAfter";
import { safeBefore } from "../safeBefore";

const unit = "character";
/**
 * Get Range of Last Instance
 *
 * Gets the Slate Range of the last instance of a search string.
 */
export const getRangeOfLastInstance = (
  editor: Editor,
  target: string
): Range | null => {
  // Allow for matching across paragraph boundaries by making spaces optional
  const fuzzyTarget = escapeStringRegexp(target).replaceAll(/\s+/g, "\\s*");
  const regex = new RegExp(fuzzyTarget, "ig");

  // `editor.string([])` concatenates paragraphs, causing an inaccurate
  // representation of how many steps are needed to advance through the
  // document, so add a space between each paragraph to get the right count
  const text = editor.children.map((_, i) => editor.string([i])).join(" ");
  const match = text.match(regex)?.at(-1);

  if (!match) {
    // Target text doesn't exist in the editor
    return null;
  }

  // Get start and end indexes of the match
  const start = text.lastIndexOf(match);
  const end = start + match.length;

  // Create a range out of these indexes
  let anchor = safeAfter(editor, editor.start([]), { unit, distance: start });
  let focus = safeAfter(editor, editor.start([]), { unit, distance: end });

  // Remove dangling boundaries from range, i.e. if the whole range is in one
  // paragraph except for the anchor/focus being at the boundary of the next.
  if (editor.isEnd(anchor, anchor.path)) {
    anchor = safeAfter(editor, anchor);
  }
  if (editor.isStart(focus, focus.path)) {
    focus = safeBefore(editor, focus);
  }

  return { focus, anchor };
};

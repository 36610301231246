import type { ReactElement } from "react";

export const TalkTypeLogo = (): ReactElement => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_51_386)">
      <path
        d="M0.570694 19.9253C0.570694 13.2957 0.570694 9.98086 1.81986 7.431C3.01588 4.98961 4.99005 3.01544 7.43144 1.81941C9.9813 0.570251 13.2961 0.570251 19.9257 0.570251H36.0744C42.704 0.570251 46.0188 0.570251 48.5687 1.81941C51.01 3.01544 52.9842 4.98961 54.1802 7.431C55.4294 9.98086 55.4294 13.2957 55.4294 19.9253V36.074C55.4294 42.7036 55.4294 46.0184 54.1802 48.5682C52.9842 51.0096 51.01 52.9838 48.5687 54.1798C46.0188 55.429 42.704 55.429 36.0744 55.429H19.9257C13.2961 55.429 9.9813 55.429 7.43144 54.1798C4.99005 52.9838 3.01588 51.0096 1.81986 48.5682C0.570694 46.0184 0.570694 42.7036 0.570694 36.074V19.9253Z"
        fill="url(#paint0_linear_51_386)"
      />
    </g>
    <path
      d="M22.2533 15.7904H13.1058V18.0158H16.4252V26.1448H18.9339V18.0158H22.2533V15.7904Z"
      fill="white"
    />
    <path
      d="M27.158 18.3864V19.0046C26.5308 18.4328 25.7354 18.1546 24.7717 18.1546C23.7315 18.1546 22.8443 18.541 22.11 19.3137C21.391 20.0864 21.0239 21.0754 21.0239 22.2655C21.0239 23.4709 21.391 24.46 22.11 25.2327C22.8443 25.9899 23.7315 26.3763 24.7717 26.3763C25.7354 26.3763 26.5308 26.0981 27.158 25.5263V26.1445H29.6055V18.3864H27.158ZM25.2765 24.2745C24.1904 24.2745 23.4256 23.4554 23.4256 22.2655C23.4256 21.0909 24.1904 20.2564 25.2765 20.2564C25.7813 20.2564 26.2249 20.4418 26.592 20.8282C26.9744 21.2146 27.158 21.6936 27.158 22.2655C27.158 22.8527 26.9744 23.3318 26.592 23.7182C26.2249 24.089 25.7813 24.2745 25.2765 24.2745Z"
      fill="white"
    />
    <path d="M33.132 15.0164H30.6845V26.1435H33.132V15.0164Z" fill="white" />
    <path
      d="M39.0277 22.079L42.393 18.3854H39.4407L37.0085 21.0281H36.6719V15.0164H34.2245V26.1435H36.6719V23.3462H37.1156L39.6089 26.1435H42.7448L39.0277 22.079Z"
      fill="white"
    />
    <path
      d="M19.8377 28.0408H10.6902V30.2662H14.0097V38.3952H16.5185V30.2662H19.8377V28.0408Z"
      fill="white"
    />
    <path
      d="M25.6976 30.6216L23.7551 35.1497L21.9344 30.6216H19.3035L22.4856 38.1324L21.2463 41.0223H23.8927L28.3288 30.6216H25.6976Z"
      fill="white"
    />
    <path
      d="M33.4612 30.4056C32.4973 30.4056 31.702 30.6838 31.0748 31.2556V30.6375H28.6272V41.0227H31.0748V37.7773C31.702 38.3491 32.4973 38.6273 33.4612 38.6273C34.5013 38.6273 35.3888 38.241 36.1075 37.4837C36.8416 36.711 37.2089 35.7219 37.2089 34.5165C37.2089 33.3265 36.8416 32.3374 36.1075 31.5647C35.3888 30.792 34.5013 30.4056 33.4612 30.4056ZM32.9564 36.5255C32.4516 36.5255 32.008 36.3401 31.6257 35.9692C31.2584 35.5828 31.0748 35.1038 31.0748 34.5165C31.0748 33.9447 31.2584 33.4656 31.6257 33.0792C32.008 32.6929 32.4516 32.5074 32.9564 32.5074C34.0426 32.5074 34.807 33.3419 34.807 34.5165C34.807 35.7064 34.0426 36.5255 32.9564 36.5255Z"
      fill="white"
    />
    <path
      d="M45.3097 34.5165C45.2946 33.2802 44.8967 32.2911 44.1471 31.5338C43.3975 30.7765 42.4643 30.4056 41.3478 30.4056C40.2003 30.4056 39.2368 30.792 38.4566 31.5647C37.6764 32.3374 37.2789 33.3265 37.2789 34.5165C37.2789 35.7219 37.6764 36.711 38.4566 37.4837C39.2368 38.241 40.2003 38.6273 41.3326 38.6273C43.1224 38.6273 44.4837 37.8082 45.1415 36.201L42.9694 35.7528C42.6479 36.3555 42.097 36.6492 41.3326 36.6492C40.5218 36.6492 39.8791 36.0773 39.6804 35.1501H45.3097V34.5165ZM41.3478 32.4147C42.0819 32.4147 42.6634 32.832 42.8927 33.6356H39.7261C39.9558 32.8783 40.6291 32.4147 41.3478 32.4147Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_51_386"
        x="0.20239"
        y="0.3861"
        width="55.5953"
        height="55.5953"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.184152" />
        <feGaussianBlur stdDeviation="0.184152" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.28 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51_386"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51_386"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51_386"
        x1="28.0001"
        y1="0.570251"
        x2="28.0001"
        y2="55.429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#595BFF" />
        <stop offset="1" stopColor="#3F41B5" />
      </linearGradient>
    </defs>
  </svg>
);

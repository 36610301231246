import type { ReactElement } from "react";

import { ErrorBoundary } from "@carescribe/ui";

import { Routes } from "./Routes";

export const App = (): ReactElement => (
  <ErrorBoundary onError={null}>
    <Routes />
  </ErrorBoundary>
);

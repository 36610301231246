import type { Prompt } from "../../types/Prompt";
import type { Ref, Seconds } from "@carescribe/types";
import type { SagaIterator, Task } from "redux-saga";

import { put, takeEvery } from "redux-saga/effects";

import { defaultCommandDeadline } from "../../config";
import {
  action,
  requestTrackDeadline,
  requestTrackPrompt,
  requestTrackTask,
  trackedDeadline,
  trackedPrompt,
  trackedTask,
} from "../actions";

type State = {
  task: Task | null;
  deadline: Seconds;
  prompt: Prompt | null;
};

export const taskRef = action<Readonly<Ref<Readonly<State>>>, "task-ref">(
  "task-ref"
);

export const trackTask = function* (): SagaIterator<void> {
  const ref: Ref<State> = {
    current: {
      task: null,
      deadline: defaultCommandDeadline,
      prompt: null,
    },
  };

  yield takeEvery(requestTrackTask, function* ({ payload: task }) {
    ref.current.task = task;
    yield put(trackedTask());
  });

  yield takeEvery(requestTrackDeadline, function* ({ payload: deadline }) {
    ref.current.deadline = deadline;
    yield put(trackedDeadline());
  });

  yield takeEvery(requestTrackPrompt, function* ({ payload: prompt }) {
    ref.current.prompt = prompt;
    yield put(trackedPrompt());
  });

  yield put(taskRef(ref));
};

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { select, take, put } from "redux-saga/effects";

import { selectColourScheme } from "@talktype/preferences";
import {
  requestCustomInstallPromptVisibility,
  broadcastedCustomInstallPromptVisibility,
} from "@talktype/pwa/src/sagas/actions";
import { selectUserIsLoggedIn } from "@talktype/user/src/reducer/selectors/selectUserIsLoggedIn";

type FactorsAffectingStatusBarColour = {
  isLoggedIn: boolean;
  isPWAInstallPromptVisible: boolean;
  colourScheme: "light" | "dark";
};

export const getFactorsAffectingStatusBarColour =
  function* (): SagaIterator<FactorsAffectingStatusBarColour> {
    const isLoggedIn: SagaReturnType<typeof selectUserIsLoggedIn> =
      yield select(selectUserIsLoggedIn);

    yield put(requestCustomInstallPromptVisibility());
    const {
      payload: PWAInstallPromptVisibility,
    }: SagaReturnType<typeof broadcastedCustomInstallPromptVisibility> =
      yield take(broadcastedCustomInstallPromptVisibility);

    const isPWAInstallPromptVisible = PWAInstallPromptVisibility === "visible";

    const userThemePreference: SagaReturnType<typeof selectColourScheme> =
      yield select(selectColourScheme);

    const userSystemPreference = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches
      ? "dark"
      : "light";

    const colourScheme =
      userThemePreference === "normal"
        ? userSystemPreference
        : userThemePreference;

    return {
      isLoggedIn,
      isPWAInstallPromptVisible,
      colourScheme,
    };
  };

import type { MouseEvent, KeyboardEvent } from "react";
import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, put, select } from "redux-saga/effects";

import { broadcast } from "@carescribe/utilities/src/sagas/syncActionsAcrossWindows";

import { requestTrackEvent } from "@talktype/analytics/src/sagas/actions";
import {
  selectAutoPunctuation,
  setAutoPunctuation,
} from "@talktype/preferences/src/reducers/preferences";
import { getInteractionMethodFromEvent } from "@talktype/utilities/src/getInteractionMethodFromEvent";

export const handleAutoPunctuationButton = function* (
  event: KeyboardEvent | MouseEvent
): SagaIterator<void> {
  const currentPunctuationSetting: SagaReturnType<
    typeof selectAutoPunctuation
  > = yield select(selectAutoPunctuation);

  const newAutoPunctuationValue = !currentPunctuationSetting;

  yield put(broadcast(setAutoPunctuation(newAutoPunctuationValue)));

  const interactionMethod: SagaReturnType<
    typeof getInteractionMethodFromEvent
  > = yield call(getInteractionMethodFromEvent, event);

  yield put(
    requestTrackEvent({
      name: "Auto Punctuation Changed",
      data: {
        newAutoPunctuationValue: newAutoPunctuationValue ? "on" : "off",
        interactionMethod,
      },
    })
  );
};

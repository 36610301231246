import type { Command } from "../../../types";
import type { SagaReturnType } from "redux-saga/effects";

import { call } from "redux-saga/effects";

import { getIpc } from "@talktype/utilities";

export const openApp: Command = function* (parameters) {
  if (!parameters) {
    return false;
  }

  const ipc: SagaReturnType<typeof getIpc> = yield call(getIpc);

  if (!ipc?.system?.openApp?.request?.v1) {
    return false;
  }

  try {
    const success: SagaReturnType<typeof ipc.system.openApp.request.v1> =
      yield call(ipc.system.openApp.request.v1, parameters);

    return success;
  } catch {
    return false;
  }
};

import { escapeStringRegexp } from "@carescribe/utilities/src/escapeStringRegexp";

/**
 * Safely joins strings for use in a regular expression.
 * Escapes regex control characters found in each string.
 *
 * @example
 * joinStringsForRegExp(["a", "b"]); // returns "a|b"
 * joinStringsForRegExp(["a.b", "c.d"]); // returns "a\\.b|c\\.d"
 * joinStringsForRegExp(["a^b", "c^d"]); // returns "a\\^b|c\\^d"
 */
export const joinStringsForRegExp = (strings: string[]): string =>
  strings.map(escapeStringRegexp).join("|");

import type { SagaIterator } from "redux-saga";
import type { SagaReturnType } from "redux-saga/effects";

import { call, takeEvery } from "redux-saga/effects";
import { P, match } from "ts-pattern";

import { handleError } from "./responseHandlers/handleError";
import { handleTokensResponse } from "./responseHandlers/handleTokensResponse";
import { handleUnauthorized } from "./responseHandlers/handleUnauthorized";
import { handleUnknown } from "./responseHandlers/handleUnknown";
import { TOKEN_URL } from "../../config/urls";
import { validTokensResponse } from "../../guards/isTokensResponse";
import { validUnauthorizedResponse } from "../../guards/isUnauthorisedResponse";
import { requestTokens } from "../actions";
import { pkceRequest } from "../utils/pkceRequest";

/**
 * Set Up Request Tokens
 *
 * Get access/refresh tokens from the server when requested.
 */
export const setUpRequestTokens = function* (): SagaIterator<void> {
  yield takeEvery(
    requestTokens,
    function* ({ payload: { code, codePair } }): SagaIterator<void> {
      const response: SagaReturnType<typeof pkceRequest> = yield call(
        pkceRequest,
        {
          pathname: TOKEN_URL,
          params: { code, code_verifier: codePair.codeVerifier },
        }
      );

      yield call(
        match(response)
          .with({ error: P.string }, handleError)
          .with({ data: validTokensResponse }, handleTokensResponse)
          .with({ data: validUnauthorizedResponse }, handleUnauthorized)
          .otherwise(handleUnknown)
      );
    }
  );
};

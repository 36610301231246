import { Scrubber } from "slate";

import { convertTextToStars } from "@carescribe/utilities/src/convertTextToStars";

/**
 * Enables PII (Personally Identifiable Information) data scrubbing in Slate.
 *
 * When Slate throws an exception, it includes a stringified representation of
 * the relevant data; which can include sensitive information, such as the
 * content of text nodes.
 *
 * Scrubs the 'text' property of nodes.
 *
 * To use, call once, ideally before any Slate editor is created or utilised.
 *
 * @example
 * enablePIIDataScrubbing();
 */
export const enablePIIDataScrubbing = (): void =>
  Scrubber.setScrubber((key, value) =>
    key === "text" && typeof value === "string"
      ? convertTextToStars(value)
      : value
  );

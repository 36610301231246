import { P } from "ts-pattern";

import { createGuard } from "@carescribe/utilities/src/guards/createGuard";

type ObjectWithMessage = { message: string };

export const objectWithMessage = { message: P.string };

export const isObjectWithMessage =
  createGuard<ObjectWithMessage>(objectWithMessage);

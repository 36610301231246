import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

import { log } from "../../utils";
import {
  transcriberSocketClosedEnableAutoReconnection,
  transcriberSocketClosedEnablePromptedReconnection,
  transcriberSocketClosedDisallowReconnection,
  transcriberSocketClosedWithUnknownError,
  transcriberSocketClosed,
} from "../actions";

const codeInAutoReconnectionRange = (code: number): boolean =>
  code >= 1005 && code < 4100;

const codeInPromptForReconnectionRange = (code: number): boolean =>
  code >= 4100 && code < 4199;

const codeInDisallowReconnectionRange = (code: number): boolean =>
  code >= 4200 && code < 4299;

const codeInUnknownErrorRange = (code: number): boolean =>
  code > 1000 && code < 1005;

export const close = function* (event: CloseEvent): SagaIterator<void> {
  const disconnectCode = event.code;

  log(
    `WebSocket closed ${event.wasClean ? "cleanly" : "messily"}:`,
    disconnectCode,
    event.reason
  );

  if (codeInAutoReconnectionRange(disconnectCode)) {
    yield put(transcriberSocketClosedEnableAutoReconnection());
  } else if (codeInPromptForReconnectionRange(disconnectCode)) {
    yield put(
      transcriberSocketClosedEnablePromptedReconnection(disconnectCode)
    );
  } else if (codeInDisallowReconnectionRange(disconnectCode)) {
    yield put(transcriberSocketClosedDisallowReconnection(disconnectCode));
  } else if (codeInUnknownErrorRange(disconnectCode)) {
    yield put(transcriberSocketClosedWithUnknownError());
  }

  yield put(transcriberSocketClosed());
};

import type { ReactElement } from "react";

import Lottie from "lottie-react";

import animationData from "./animationData.json";

export const DictateAnywhereExplainer = ({
  className,
}: {
  className: string;
}): ReactElement => (
  <Lottie className={className} animationData={animationData} />
);

import type { SagaIterator } from "redux-saga";

import { put } from "redux-saga/effects";

import { requestTrackEvent } from "./actions";

/**
 * Saga to track when the app is loaded.
 *
 * This saga dispatches an action to track the "App Loaded" event, indicating
 * that the application has successfully loaded.
 */
export const trackAppLoaded = function* (): SagaIterator<void> {
  yield put(requestTrackEvent({ name: "App Loaded", data: null }));
};

import type { UnauthorisedErrorResponse } from "../../../guards/isUnauthorisedResponse";
import type { SagaIterator } from "redux-saga";

import { call, put } from "redux-saga/effects";

import { setTokens } from "../../../reducer";
import { logWarning } from "../../../utils/log";
import { pkceRequestFailed } from "../../actions";

export const handleUnauthorized = ({
  data,
}: {
  data: UnauthorisedErrorResponse;
}) =>
  function* (): SagaIterator<void> {
    yield put(setTokens(null));
    yield put(pkceRequestFailed("permission-error"));
    yield call(logWarning, data.error, data.status);
  };

import type { Seconds } from "@carescribe/types";

import { secondsToMilliseconds } from "@carescribe/utilities/src/timing";

/**
 * @param config - Configuration object for the timeout
 *   - `id`: ID of the timeout
 *   - `delay`: seconds to wait before executing the callback
 *   - `onTimeout`: callback to execute when the timeout is triggered
 */
type TimeoutConfig = {
  id: string;
  delay: Seconds;
  onTimeout: () => void;
};

/**
 * `TimeoutManager` manages a collection of timeouts.
 *
 * - **Methods:**
 *   - `add`: Adds a new timeout
 *   - `clear`: Clears a specific timeout by its ID
 *   - `clearAll`: Clears all timeouts
 */
export class TimeoutManager {
  private timeouts = new Map<string, ReturnType<typeof setTimeout>>();

  private registerTimeout = ({ id, delay, onTimeout }: TimeoutConfig): void => {
    const callback = (): void => {
      onTimeout();
      this.clear(id);
    };

    const delayMilliseconds = secondsToMilliseconds(delay);

    const timeout = setTimeout(callback, delayMilliseconds);

    this.timeouts.set(id, timeout);
  };

  /**
   * Adds a timeout.
   *
   * If an existing timeout with the same ID exists, does nothing.
   *
   * @param config - Configuration object for the timeout
   *
   * @example
   * const id = "TIMEOUT";
   * const delay = toSeconds(2);
   * const onTimeout = () => console.log("Time!");
   * timeoutManager.add({ id, delay: toSeconds(2), onTimeout }); // Added
   * timeoutManager.add({ id, delay: toSeconds(4), onTimeout }); // Ignored
   */
  public add = ({ id, delay, onTimeout }: TimeoutConfig): void => {
    const existingTimeout = this.timeouts.get(id);

    if (existingTimeout) {
      return;
    }

    this.registerTimeout({ id, delay, onTimeout });
  };

  /**
   * Clears a specific timeout by its ID.
   *
   * @param id - The ID of the timeout to clear.
   *
   * @example
   * timeoutManager.clear('TIMEOUT_A');
   */
  public clear = (id: string): void => {
    const timeoutId = this.timeouts.get(id);

    clearTimeout(timeoutId);
    this.timeouts.delete(id);
  };

  /**
   * Clears all timeouts.
   *
   * @example
   * timeoutManager.clearAll();
   */
  public clearAll = (): void => {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.timeouts.clear();
  };

  /**
   * Upserts a timeout.
   *
   * If an existing timeout with the same ID exists, it will be cleared before
   * setting the new timeout.
   *
   * @param config - Configuration object for the timeout
   *
   * @example
   * const id = "TIMEOUT";
   * const onTimeout = () => console.log("Time!");
   * timeoutManager.add({ id, delay: toSeconds(2), onTimeout }); // Added
   * timeoutManager.set({ id, delay: toSeconds(4), onTimeout }); // Overriden
   */
  public set = ({ id, delay, onTimeout }: TimeoutConfig): void => {
    const existingTimeout = this.timeouts.get(id);

    if (existingTimeout) {
      clearTimeout(existingTimeout);
    }

    this.registerTimeout({ id, delay, onTimeout });
  };

  /**
   * Returns the number of active timeouts at any given time.
   *
   * @example
   * timeoutManager.add({...});
   * timeoutManager.size; // 1
   *
   * timeoutManager.clearAll();
   * timeoutManager.size; // 0
   */
  public get size(): number {
    return this.timeouts.size;
  }
}

export const messages = {
  listening: "Listening",
  TalkType: "TalkType",
  unrecognised: "Unrecognised",
  noCommandDetected: "No command detected",
  completed: "Completed",
  dictatingTo: "Dictating to",
  unsupported: "Unsupported",
  commandIsNotSupportedIn: (app: string): string =>
    `Command is not supported in ${app}`,
};

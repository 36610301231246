import type { SagaIterator } from "redux-saga";

import { takeEvery, put } from "redux-saga/effects";

import { requestTrackEvent } from "@talktype/analytics";

import { editorKeyUp } from "./actions";

export const trackSplitEvents = function* (): SagaIterator<void> {
  yield takeEvery(editorKeyUp, function* (action) {
    if (action.payload.key === "Enter") {
      yield put(
        requestTrackEvent({
          name: "Text Split",
          data: { splitType: "new paragraph" },
        })
      );
    }
  });
};

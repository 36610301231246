import { createSelector } from "@reduxjs/toolkit";

import { selectPreferences } from "./selectPreferences";

/**
 * Selects the drawer from state.
 */
export const selectDrawer = createSelector(
  selectPreferences,
  (state) => state.drawer
);
